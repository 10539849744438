import React, { Component } from "react";
import logo from "../report/form/logo.png";
import { Link, withRouter, Redirect } from "react-router-dom";

class About extends Component {
    render() {
        return (
            <div className="static-page-container">
                <div className="page">
                    <div className="uk-flex uk-flex-middle uk-flex-center">
                        <div className="uk-width-5-6">
                            <div className="uk-flex uk-flex-center uk-flex-middle uk-flex-column uk-height-1-1">
                                <img
                                    src={logo}
                                    alt=""
                                    className="uk-width-1-4 uk-margin-large-bottom uk-margin-large-top"
                                />

                                <div className="uk-text-justify">
                                    <span className="uk-text-justify uk-text-bold uk-text-large">
                                        Contact us
                                    </span>
                                    <p className="uk-padding-remove  uk-margin-medium-top">
                                        <span className="uk-text-justify">
                                            EMS is a registered product of
                                            Spenta B.V., with chamber of
                                            commerce number (k.v.k): 60241098,
                                            The Hague, The Netherlands.
                                            <br />
                                            Copyright &copy; 2019,
                                            {` ${new Date().getFullYear()}, `}
                                            Spenta B.V., all rights reserved.
                                        </span>
                                    </p>
                                    <p className="uk-padding-remove  uk-margin-remove-top">
                                        <span className="uk-text-justify">
                                            Address:
                                        </span>
                                    </p>
                                    <p className="uk-padding-remove  uk-margin-remove-top">
                                        <span className="uk-text-justify">
                                            Spenta B.V.,
                                        </span>
                                    </p>
                                    <p className="uk-padding-remove  uk-margin-remove-top">
                                        <span className="uk-text-justify">
                                            Postbus 1268
                                        </span>
                                    </p>
                                    <p className="uk-padding-remove  uk-margin-remove-top">
                                        <span className="uk-text-justify">
                                            2260 BG, Leidschendam
                                        </span>
                                    </p>
                                    <p className="uk-padding-remove  uk-margin-remove-top">
                                        <span className="uk-text-justify">
                                            The Netherlands
                                        </span>
                                    </p>
                                    <p className="uk-padding-remove  uk-margin-remove-top">
                                        <span className="uk-text-justify">
                                            Tel. +31 85 060 0332
                                        </span>
                                    </p>
                                    <p className="uk-padding-remove  uk-margin-medium-top">
                                        <span className="uk-text-left">
                                            For Information:{" "}
                                            <a
                                                href="mailto:info@ems-soft.com"
                                                target="_top"
                                            >
                                                info@ems-soft.com
                                            </a>
                                        </span>
                                    </p>
                                    <p className="uk-padding-remove  uk-margin-remove-top uk-text-left uk-flex uk-flex-row">
                                        <span className="uk-text-left">
                                            For Support:
                                        </span>
                                        <span>
                                            &nbsp;
                                            <a
                                                href="mailto:support@ems-soft.com"
                                                target="_top"
                                            >
                                                support@ems-soft.com
                                            </a>
                                        </span>
                                    </p>
                                    <p className="uk-padding-remove  uk-margin-medium-top">
                                        <span className="uk-text-left">
                                            EMS corporate website:{" "}
                                            <a
                                                href="http://www.ems-soft.com"
                                                target="_blank"
                                            >
                                                www.ems-soft.com
                                            </a>
                                        </span>
                                    </p>
                                    <div className="uk-margin-top uk-flex uk-flex-middle">
                                        <div>
                                            <a
                                                href="/documents/Terms%20and%20Conditions%20Spenta%20B.V.-EMS.pdf"
                                                target="_blank"
                                            >
                                                <span className="uk-text-link">
                                                    Terms of Services
                                                </span>
                                            </a>
                                            <a
                                                href="/documents/PRIVACY.pdf"
                                                target="_blank"
                                                className="uk-margin-large-left"
                                            >
                                                <span className="uk-text-link">
                                                    Privacy Policy
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="uk-margin-top uk-flex uk-flex-middle">
                                        <div>
                                            <Link to="/about">
                                                <span className="uk-link">
                                                    About
                                                </span>
                                            </Link>
                                            <Link
                                                to="/register"
                                                className="uk-margin-large-left"
                                            >
                                                <span className="uk-link">
                                                    Register
                                                </span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-div uk-text-meta">
                    Copyright &copy; 2019, {`${new Date().getFullYear()}, `}
                    Spenta B.V., all rights reserved. Powered By&nbsp;
                    <a href="http://codixt.com" target="_blank">
                        codixt
                    </a>
                </div>
            </div>
        );
    }
}

export default withRouter(About);
