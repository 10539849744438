import React, { Component, Fragment } from "react";
import Dropzone from "./dropZone/dropZone";
import Progress from "./progressbar/progressbar";
import UploadTable from "./fileTypeTable/fileType";
import Papa from "papaparse";
import Toolbar from "../toolbar/toolbar";
import Form from "../report/form/form";
import ReportTitle from "../report/reportTitle/reportTitle";
import { Link } from "react-router-dom";
import * as tools from "../../tools";
import Table from "../table/table";
import UIkit from "uikit";

const ledgerDataLabelTweaks = {
    "EXECUTION DATE": "Transaction Date",
    "EXCECUTION DATE": "Transaction Date",
    "TRANSACTION TYPE": "Transaction type",
    "PRODUCT DESCRIPTION": "Product",
    "SUPPLY PERIOD": "Period",
    "SENDING PARTY (NAME)": "Old holder name",
    "SENDING PARTY EAN/RECS": "Old holder ean",
    "RECEIVING PARTY (NAME)": "New holder name",
    "RECEIVING PARTY EAN/RECS": "New holder ean",
    "SENDING PARTY ACCOUNT HOLDER ID": "Old holder ean",
    "RECEIVING PARTY ACCOUNT HOLDER ID": "New holder ean",
    "NEW ACCOUNT HOLDER ID": "New holder ean",
    "OLD ACCOUNT HOLDER ID": "Old holder ean",
    "TRANSACTION REFERENCE": "Reference"
};
const ledgerTransactionTypeValueTweaks = {
    ISSUE: "issue",
    ISSUED: "issue",
    IMPORT: "import",
    IMPORTED: "import",
    TRANSFER: "transfer",
    "INCOMING TRANSFER": "transfer",
    "OUTGOING TRANSFER": "transfer",
    WITHDRAWAL: "withdrawal",
    WITHDRAWN: "withdrawal",
    EXPIRY: "expiry",
    EXPIRED: "expiry",
    EXPORT: "export",
    EXPORTED: "exported",
    CANCEL: "cancel",
    CANCELLED: "cancel",
    REDEEM: "cancel",
    REDEEMED: "cancel"
};
/**Class upload */
class Upload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            tradingAccountFiles: [],
            userAccountFiles: [],
            transactionLedgerFiles: [],
            userLedgerFiles: [],
            uploading: false,
            uploadProgress: {},
            successfulUploaded: false,
            showPopUp: false,
            isUploadCompleted: false,
            tradingAccountIds: [],
            userAccountIds: [],
            showTradingAccountIds: [],
            showUserAccountIds: [],
            selectedTradingId: [],
            selectedUserId: [],
            selectedDomain: ""
        };

        this.countriesList = [
            { country: "Select your domain", code: "" },
            { country: "Austria", code: "AT" },
            { country: "Bosnia & Herzegovina", code: "BAH" },
            { country: "Republika Srpska", code: "BAS" },
            { country: "Belgium", code: "BE" },
            { country: "Belgium (Brussels)", code: "BEB" },
            { country: "Belgium (Flanders)", code: "BEF" },
            { country: "Belgium (Wallonia)", code: "BEW" },
            { country: "Bulgaria", code: "BG" },
            { country: "Switzerland", code: "CH" },
            { country: "Cyprus", code: "CY" },
            { country: "Czech Republic", code: "CZ" },
            { country: "Germany", code: "DE" },
            { country: "Denmark", code: "DK" },
            { country: "Estonia", code: "EE" },
            { country: "Spain", code: "ES" },
            { country: "Finland", code: "FI" },
            { country: "France", code: "FR" },
            { country: "United Kingdom", code: "GB" },
            { country: "Greece (interconnected)", code: "GRI" },
            { country: "Greece (non- interconnected)", code: "GRN" },
            { country: "Greece (unconnected autonomous)", code: "GRU" },
            { country: "Croatia", code: "HR" },
            { country: "Hungary", code: "HU" },
            { country: "Ireland", code: "IE" },
            { country: "Iceland", code: "IS" },
            { country: "Italy", code: "IT" },
            { country: "Lithuania", code: "LT" },
            { country: "Luxembourg", code: "LU" },
            { country: "Latvia", code: "LV" },
            { country: "Montenegro", code: "ME" },
            { country: "Malta", code: "MT" },
            { country: "Netherlands", code: "NL" },
            { country: "Norway", code: "NO" },
            { country: "Poland", code: "PL" },
            { country: "Portugal", code: "PT" },
            { country: "Serbia", code: "RS" },
            { country: "Sweden", code: "SE" },
            { country: "Slovenia", code: "SI" },
            { country: "Slovakia", code: "SK" }
        ];

        this.selectedTradingId = [];
        this.selectedUserId = [];

        this.onFilesAdded = this.onFilesAdded.bind(this);
        this.uploadFiles = this.uploadFiles.bind(this);
        this.sendRequest = this.sendRequest.bind(this);
        this.renderActions = this.renderActions.bind(this);
        this.handleFileLoaded = this.handleFileLoaded.bind(this);
        this.getDataColumn = this.getDataColumn.bind(this);
    }

    /**
     *
     * @param {object[]} arr
     * @param {string} column
     */
    getDataColumn(arr, column) {
        let newArr = arr.map(el => el[column]);
        newArr = newArr.filter(el => el);
        return newArr;
    }

    /**
     * isAccount file
     * @param {object[]} data
     */
    isAccountFile(data) {
        return data[0].indexOf("Status") > -1
            ? true
            : data[0].indexOf("SYSTEM") > -1;
    }

    isTradingLedger(data) {
        let idx =
            data[0].indexOf("Transaction type") > -1
                ? data[0].indexOf("Transaction type")
                : data[0].indexOf("Transaction Type");
        if (idx < 0) {
            throw new Error("File Is Not Supported");
        }
        let tempArray = data.slice(1);
        let tempArrayCancel = this.getDataColumn(tempArray, idx);
        tempArrayCancel = tempArrayCancel.filter(
            el =>
                !el.toUpperCase().includes("CANCEL") &&
                !el.toUpperCase().includes("REDEEM") &&
                !el.toUpperCase().includes("REDEEMED")
        );
        return tempArrayCancel.length > 0;
    }

    isTradingAccountFile(data) {
        // Phase 9 tweaks
        if (data[0].indexOf("Status") < 0 && data[0].indexOf("SYSTEM") > -1)
            return true;
        let tempData = data.slice(1);
        let idx = data[0].indexOf("Status");
        if (idx < 0) {
            throw new Error("File Is Not Supported");
        }
        let tempArray = this.getDataColumn(tempData, idx);
        tempArray = tempArray.filter(
            el =>
                el !== "CANCELLED" &&
                el.toUpperCase() !== "REDEEMED" &&
                el.toUpperCase() !== "REDEEM"
        );
        return tempArray.length > 0;
    }

    extractAccountIdAndHolderName(data) {
        const newHolderIdx = data[0].indexOf("New holder ean");
        const oldHolderIdx = data[0].indexOf("Old holder ean");
        const newHolderNameIdx = data[0].indexOf("New holder name");
        const oldHolderNameIdx = data[0].indexOf("Old holder name");
        const countryIdx = data[0].indexOf("Country");

        if (newHolderIdx < 0) {
            throw new Error("File Is Not Supported");
        }
        const tempArray = data.slice(1);
        const newAccountId = this.getDataColumn(tempArray, newHolderIdx); //.filter(el=>el!=="NA");
        const oldAccountId = this.getDataColumn(tempArray, oldHolderIdx); //.filter(el=>el!=="NA");
        const newHolderName = this.getDataColumn(tempArray, newHolderNameIdx);
        const oldHolderName = this.getDataColumn(tempArray, oldHolderNameIdx);
        const country = this.getDataColumn(tempArray, countryIdx);
        const objectsArray = [];
        newAccountId.map((e, index) => {
            objectsArray.push({
                accountId: e,
                country: country[index],
                owner: newHolderName[index]
            });
            objectsArray.push({
                accountId: oldAccountId[index],
                country: country[index],
                owner: oldHolderName[index]
            });
        });
        const scannedId = [];
        const result = [];
        for (let i = 0, n = objectsArray.length; i < n; i++) {
            if (
                !scannedId.includes(
                    objectsArray[i].accountId + objectsArray[i].country
                )
            ) {
                result.push(Object.assign({}, objectsArray[i]));
                scannedId.push(
                    objectsArray[i].accountId + objectsArray[i].country
                );
            }
        }

        return result;
    }

    /**
     * file type detection
     * @param {object[]} fileData
     */
    getFileType(fileData) {
        let fileType;
        if (this.isAccountFile(fileData)) {
            if (this.isTradingAccountFile(fileData)) {
                fileType = "ta";
            } else {
                fileType = "ua";
            }
        } else {
            if (this.isTradingLedger(fileData)) {
                fileType = "tl";
            } else {
                fileType = "ul";
            }
        }
        return fileType;
    }

    handleDataNamingTweaks(data, fileType) {
        if (fileType === "tl" || fileType === "ul") {
            data[0] = data[0].map(column => {
                let modified = ledgerDataLabelTweaks[column.toUpperCase()]
                    ? ledgerDataLabelTweaks[column.toUpperCase()]
                    : column;
                return modified;
            });
            const transactionTypeIdx = data[0].indexOf("Transaction type");
            let headerlessData = data.slice(1);
            headerlessData.map((row, rowIndex) => {
                if (
                    row[transactionTypeIdx] &&
                    ledgerTransactionTypeValueTweaks[
                        row[transactionTypeIdx].toUpperCase()
                    ]
                ) {
                    data[rowIndex + 1][transactionTypeIdx] =
                        ledgerTransactionTypeValueTweaks[
                            row[transactionTypeIdx].toUpperCase()
                        ];
                }
            });
        }
        return data;
    }

    /**
     * upload single file
     * state update based on progress
     * @param {event} e
     * @param {string} fileName
     */
    handleFileLoaded(e, fileName) {
        let parsed = Papa.parse(e.target.result);
        if (parsed.errors.length > 0) {
            throw new Error(fileName, parsed.errors);
        }

        let country = [];
        const index = parsed.data[0].indexOf("Country");

        if (index === -1) {
            UIkit.alert(
                `The ${fileName} does not contain "Country" column, please check it and re-upload`
            );
            return;
        }

        for (let i = 1, n = parsed.data.length; i < n; i++) {
            country.push(parsed.data[i][index]);
        }

        country = [...new Set(country)].filter(n => n);

        // if (country.length !== 1) {
        //     UIkit.modal.alert(
        //         `The ${fileName.name} file contains two countries, which is not allowed, please make sure to have your files per country`
        //     );
        //     return;
        // }

        let fileType = this.getFileType(parsed.data);
        parsed.data = this.handleDataNamingTweaks(parsed.data, fileType);
        switch (fileType) {
            case "ta":
                this.setState({
                    tradingAccountFiles: this.state.tradingAccountFiles.concat([
                        {
                            title: fileName.name,
                            extra: Math.ceil(fileName.size / 1024) + " KB"
                        }
                    ])
                });
                break;
            case "tl":
                const tl = this.extractAccountIdAndHolderName(parsed.data);
                this.setState({
                    transactionLedgerFiles: this.state.transactionLedgerFiles.concat(
                        [
                            {
                                title: fileName.name,
                                extra: Math.ceil(fileName.size / 1024) + " KB"
                            }
                        ]
                    ),
                    tradingAccountIds: this.state.tradingAccountIds.concat(tl),
                    showTradingAccountIds: this.state.tradingAccountIds.concat(
                        tl
                    )
                });
                break;
            case "ua":
                this.setState({
                    userAccountFiles: this.state.userAccountFiles.concat([
                        {
                            title: fileName.name,
                            extra: Math.ceil(fileName.size / 1024) + " KB"
                        }
                    ])
                });
                break;
            case "ul":
                const ul = this.extractAccountIdAndHolderName(parsed.data);
                this.setState({
                    userLedgerFiles: this.state.userLedgerFiles.concat([
                        {
                            title: fileName.name,
                            extra: Math.ceil(fileName.size / 1024) + " KB"
                        }
                    ]),
                    userAccountIds: this.state.userAccountIds.concat(ul),
                    showUserAccountIds: this.state.userAccountIds.concat(ul)
                });
                break;
            default:
                //throw new Error("Unknown file");
                break;
        }
    }

    /**
     *
     * @param {string} fileName
     */
    parseFile(fileName) {
        let fileReader = new FileReader();
        let loaded = function(fileName, funcName) {
            return function(evt) {
                funcName(evt, fileName);
            };
        };
        fileReader.onloadend = loaded(fileName, this.handleFileLoaded);
        fileReader.readAsText(fileName, "utf8");
    }

    /**
     *
     * @param {string} file
     */
    isCsv(file) {
        let tempFileName = file.name.split(".");
        const allowedExtension = ["csv", "xls", "xlsx"];
        if (
            tempFileName.length !== 2 ||
            allowedExtension.indexOf(tempFileName[1]) < 0
        ) {
            return false;
        }
        return true;
    }

    /**
     *
     * @param {string[]} files
     */
    onFilesAdded(files) {
        let tempFiles = [];
        for (let i = 0, n = files.length; i < n; i++) {
            if (this.isCsv(files[i])) {
                tempFiles.push(files[i]);
                this.parseFile(files[i]);
            }
        }
        this.setState(prevState => ({
            files: prevState.files.concat(tempFiles)
        }));
    }

    getAccountObject(selectedId, isTrading) {
        const accounts = isTrading
            ? [...this.state.tradingAccountIds]
            : [...this.state.userAccountIds];
        return {
            accountId: accounts[selectedId][1],
            holderName: accounts[selectedId][2],
            country: accounts[selectedId][3]
        };
    }

    forceClosePopUp() {
        UIkit.toggle(document.getElementById("modal-overflow")).toggle();
    }
    /**
     * upload all files
     */
    async uploadFiles() {
        // if (!this.state.confirmed) {
        //     return;
        // }

        if (this.state.isUploadCompleted) {
            this.props.history.push("/");
            return;
        }

        if (this.state.uploading) {
            return;
        }

        if (this.state.files.length > 0) {
            const tradingAccounts = [];
            const tradingAccountFiltered = this.filterIdsPerDomainCountry(
                this.state.showTradingAccountIds
            );
            for (let i = 0, n = this.selectedTradingId.length; i < n; i++) {
                tradingAccounts.push(
                    tradingAccountFiltered[this.selectedTradingId[i]]
                );
            }

            const userAccounts = [];
            const userAccountFiltered = this.filterIdsPerDomainCountry(
                this.state.showUserAccountIds
            );
            for (let i = 0, n = this.selectedUserId.length; i < n; i++) {
                userAccounts.push(userAccountFiltered[this.selectedUserId[i]]);
            }
            const countries = userAccounts.map(el => el.country);
            tools.deleteSingleParameter("hasInvalid");
            this.setState({ deleteSent: true });
            const t = await tools.axiosInstance.delete("/report", {
                data: { countries }
            });

            this.setState(
                {
                    deleteCompleted: true,
                    uploadProgress: {},
                    uploading: true,
                    showPopUp: true
                },
                async () => {
                    const promises = [];
                    tools.sendTrack("upload");
                    this.state.files.forEach(file => {
                        promises.push(this.sendRequest(file));
                    });
                    try {
                        !!tradingAccounts.length &&
                            tools.axiosInstance
                                .post("/report/trading-account-list", {
                                    accounts: tradingAccounts
                                })
                                .catch(console.log);
                        !!userAccounts &&
                            tools.axiosInstance
                                .post("/report/user-account-list", {
                                    accounts: userAccounts
                                })
                                .catch(console.log);
                        await Promise.all(promises);
                        this.setState({
                            successfulUploaded: true,
                            uploading: false
                        });
                    } catch (e) {
                        this.setState({
                            successfulUploaded: true,
                            uploading: false
                        });
                    }
                }
            );
        }
        // this.setState({ showPopUp: !this.state.showPopUp });
    }

    /**
     *
     * @param {File} file
     */
    uploadProgress(file) {
        return eventProgress => {
            let tempUploadProgress = { ...this.state.uploadProgress };
            tempUploadProgress[file.name] = {
                progress: 0,
                hasError: false,
                completed: false
            };
            if (eventProgress.lengthComputable) {
                tempUploadProgress[file.name] =
                    eventProgress.loaded / eventProgress.total;
            } else {
                tempUploadProgress[file.name] = eventProgress.loaded;
            }
            this.setState({ uploadProgress: tempUploadProgress });
        };
    }

    /**
     *
     * @param {File} file
     * @param {int} idx
     */
    sendRequest(file, idx) {
        return new Promise((resolve, reject) => {
            if (this.state.uploadProgress[file.name]) {
                return;
            }
            const formData = new FormData();
            const { tradingAccountIds, userAccountIds } = this.state;

            const tradingAccounts = [];
            for (let i = 0, n = this.selectedTradingId.length; i < n; i++) {
                tradingAccounts.push(
                    tradingAccountIds[this.selectedTradingId[i]]
                );
            }

            const userAccounts = [];
            for (let i = 0, n = this.selectedUserId.length; i < n; i++) {
                userAccounts.push(userAccountIds[this.selectedUserId[i]]);
            }
            formData.append("uploaded", file);
            formData.append("tradingAccounts", JSON.stringify(tradingAccounts));
            formData.append("userAccounts", JSON.stringify(userAccounts));

            let config = {
                onUploadProgress: this.uploadProgress(file, idx).bind(this)
            };

            tools.axiosInstance
                .post("/upload/my-files", formData, config)
                .then(suc => {
                    let tempUploadProgress = { ...this.state.uploadProgress };
                    tempUploadProgress[file.name] = {
                        progress: 100,
                        hasError: !suc.data[0].status,
                        completed: true
                    };
                    this.setState(
                        { uploadProgress: tempUploadProgress },
                        this.isUploadCompleted
                    );
                })
                .catch(err => {
                    let tempUploadProgress = { ...this.state.uploadProgress };
                    tempUploadProgress[file.name] = {
                        progress: 0,
                        hasError: true,
                        completed: true
                    };
                    this.setState({ uploadProgress: tempUploadProgress });
                });
        });
    }

    isUploadCompleted() {
        let tempProgress = { ...this.state.uploadProgress };
        let completed = true;
        if (Object.keys(tempProgress).length === 0) {
            this.setState({ isUploadCompleted: false });
            return;
        }

        for (let key in tempProgress) {
            completed &= tempProgress[key].completed;
        }
        this.setState({ isUploadCompleted: completed > 0 });
    }

    renderProgress() {
        let tempProgress = { ...this.state.uploadProgress };
        let progressArray = [];
        for (let key in tempProgress) {
            progressArray.push(
                <Progress
                    progress={tempProgress[key].progress}
                    title={key}
                    hasError={tempProgress[key].hasError}
                    completed={tempProgress[key].completed}
                    key={`progress-${key}`}
                />
            );
        }
        return progressArray;
    }

    renderConfirmation() {
        const countriesList = [
            { country: "Select your domain", code: "" },
            { country: "Austria", code: "AT" },
            { country: "Bosnia & Herzegovina", code: "BAH" },
            { country: "Republika Srpska", code: "BAS" },
            { country: "Belgium", code: "BE" },
            { country: "Belgium (Brussels)", code: "BEB" },
            { country: "Belgium (Flanders)", code: "BEF" },
            { country: "Belgium (Wallonia)", code: "BEW" },
            { country: "Bulgaria", code: "BG" },
            { country: "Switzerland", code: "CH" },
            { country: "Cyprus", code: "CY" },
            { country: "Czech Republic", code: "CZ" },
            { country: "Germany", code: "DE" },
            { country: "Denmark", code: "DK" },
            { country: "Estonia", code: "EE" },
            { country: "Spain", code: "ES" },
            { country: "Finland", code: "FI" },
            { country: "France", code: "FR" },
            { country: "United Kingdom", code: "GB" },
            { country: "Greece (interconnected)", code: "GRI" },
            { country: "Greece (non- interconnected)", code: "GRN" },
            { country: "Greece (unconnected autonomous)", code: "GRU" },
            { country: "Croatia", code: "HR" },
            { country: "Hungary", code: "HU" },
            { country: "Ireland", code: "IE" },
            { country: "Iceland", code: "IS" },
            { country: "Italy", code: "IT" },
            { country: "Lithuania", code: "LT" },
            { country: "Luxembourg", code: "LU" },
            { country: "Latvia", code: "LV" },
            { country: "Montenegro", code: "ME" },
            { country: "Malta", code: "MT" },
            { country: "Netherlands", code: "NL" },
            { country: "Norway", code: "NO" },
            { country: "Poland", code: "PL" },
            { country: "Portugal", code: "PT" },
            { country: "Serbia", code: "RS" },
            { country: "Sweden", code: "SE" },
            { country: "Slovenia", code: "SI" },
            { country: "Slovakia", code: "SK" }
        ];

        return (
            <>
                <p className="uk-margin-top uk-margin-bottom">
                    Please select your domain of activity
                </p>
                <select
                    className="uk-select"
                    onChange={event =>
                        this.setState({ domainSelected: !!event.target.value })
                    }
                >
                    {countriesList.map((el, idx) => (
                        <option value={el.code} key={el.code}>
                            {idx
                                ? `${el.country} (${el.code})`
                                : `${el.country}`}
                        </option>
                    ))}
                </select>
                <div className="uk-margin-top uk-flex uk-flex-center">
                    <button
                        className="uk-button uk-width-auto@s uk-border-rounded uk-button-primary"
                        data-uk-toggle="target: #modal-overflow-upload"
                        onClick={this.uploadFiles.bind(this)}
                        disabled={this.state.domainSelected ? false : true}
                        onClick={() => {
                            this.setState({ confirmed: true });
                            this.uploadFiles();
                        }}
                    >
                        Upload
                    </button>
                </div>
            </>
        );
    }

    arraysEqual(a, b) {
        if (a == null || b == null) return false;
        if (a.length !== b.length) return false;

        for (let i = 0; i < a.length; i++) {
            if (b.indexOf(a[i]) === -1) return false;
            if (a.indexOf(b[i]) === -1) return false;
        }

        return true;
    }

    extractCountryFromAccount(account) {
        if (!Array.isArray(account)) {
            return [];
        }
        return [...new Set(account.map(el => el.country))];
    }

    renderActions() {
        const {
            tradingAccountFiles,
            transactionLedgerFiles,
            userAccountFiles,
            userLedgerFiles,
            selectedTradingId,
            selectedUserId,
            tradingAccountIds,
            userAccountIds,
            showPopUp,
            confirmed
        } = this.state;
        const showButton =
            tradingAccountFiles.length +
                transactionLedgerFiles.length +
                userAccountFiles.length +
                userLedgerFiles.length >
            0;

        let enableUpload = false;
        if (tradingAccountFiles.length || transactionLedgerFiles.length) {
            // check countries
            let tradingCountries = tradingAccountIds.map(el => el.country);
            tradingCountries = [...new Set(tradingCountries)];

            const userSelectedTradingAccounts = [];
            for (let i = 0, n = selectedTradingId.length; i < n; i++) {
                userSelectedTradingAccounts.push(
                    this.state.tradingAccountIds[selectedTradingId[i]]
                );
            }

            let userSelectedTradingAccountCountries = userSelectedTradingAccounts.map(
                el => el && el.country
            );
            userSelectedTradingAccountCountries = [
                ...new Set(userSelectedTradingAccountCountries)
            ];

            const userSelectedUserAccounts = [];
            for (let i = 0, n = selectedUserId.length; i < n; i++) {
                userSelectedUserAccounts.push(
                    this.state.userAccountIds[selectedUserId[i]]
                );
            }

            let userSelectedUserAccountCountries = userSelectedUserAccounts.map(
                el => el && el.country
            );
            userSelectedUserAccountCountries = [
                ...new Set(userSelectedUserAccountCountries)
            ];

            enableUpload =
                tradingAccountFiles.length &&
                tradingAccountFiles.length === transactionLedgerFiles.length &&
                this.arraysEqual(
                    userSelectedUserAccountCountries,
                    userSelectedTradingAccountCountries
                );

            if (userAccountFiles.length || userLedgerFiles.length) {
                let userCountries = userAccountIds.map(el => el.country);
                userCountries = [...new Set(userCountries)];

                const userSelectedUserAccounts = [];
                for (let i = 0, n = selectedUserId.length; i < n; i++) {
                    userSelectedUserAccounts.push(
                        this.state.userAccountIds[selectedUserId[i]]
                    );
                }

                let userSelectedUserAccountCountries = userSelectedUserAccounts.map(
                    el => el && el.country
                );
                userSelectedUserAccountCountries = [
                    ...new Set(userSelectedUserAccountCountries)
                ];

                enableUpload =
                    userAccountFiles.length &&
                    userAccountFiles.length === userLedgerFiles.length &&
                    transactionLedgerFiles.length ===
                        tradingAccountFiles.length &&
                    this.arraysEqual(
                        userSelectedTradingAccountCountries,
                        userSelectedUserAccountCountries
                    ) &&
                    userSelectedTradingAccountCountries.length;
            }
        } else if (userAccountFiles.length || userLedgerFiles.length) {
            let userCountries = userAccountIds.map(el => el.country);
            userCountries = [...new Set(userCountries)];

            const userSelectedUserAccounts = [];
            for (let i = 0, n = selectedUserId.length; i < n; i++) {
                userSelectedUserAccounts.push(
                    this.state.userAccountIds[selectedUserId[i]]
                );
            }

            let userSelectedUserAccountCountries = userSelectedUserAccounts.map(
                el => el && el.country
            );
            userSelectedUserAccountCountries = [
                ...new Set(userSelectedUserAccountCountries)
            ];

            const userSelectedTradingAccounts = [];
            for (let i = 0, n = selectedTradingId.length; i < n; i++) {
                userSelectedTradingAccounts.push(
                    this.state.tradingAccountIds[selectedTradingId[i]]
                );
            }

            let userSelectedTradingAccountCountries = userSelectedTradingAccounts.map(
                el => el && el.country
            );
            userSelectedTradingAccountCountries = [
                ...new Set(userSelectedTradingAccountCountries)
            ];

            enableUpload =
                userAccountFiles.length &&
                userAccountFiles.length === userLedgerFiles.length &&
                this.arraysEqual(
                    userSelectedTradingAccountCountries,
                    userSelectedUserAccountCountries
                ) &&
                userSelectedTradingAccountCountries.length;

            if (tradingAccountFiles.length || transactionLedgerFiles.length) {
                // check countries
                let tradingCountries = tradingAccountIds.map(el => el.country);
                tradingCountries = [...new Set(tradingCountries)];

                const userSelectedTradingAccounts = [];
                for (let i = 0, n = selectedTradingId.length; i < n; i++) {
                    userSelectedTradingAccounts.push(
                        this.state.tradingAccountIds[selectedTradingId[i]]
                    );
                }

                let userSelectedTradingAccountCountries = userSelectedTradingAccounts.map(
                    el => el && el.country
                );
                userSelectedTradingAccountCountries = [
                    ...new Set(userSelectedTradingAccountCountries)
                ];

                enableUpload =
                    userAccountFiles.length &&
                    userAccountFiles.length === userLedgerFiles.length &&
                    transactionLedgerFiles.length ===
                        tradingAccountFiles.length &&
                    this.arraysEqual(
                        userSelectedUserAccountCountries,
                        userSelectedTradingAccountCountries
                    );
            }
        }
        if (showButton) {
            return (
                <div className="uk-flex uk-flex-right">
                    <button
                        className={`uk-button uk-width-auto@s uk-border-rounded ${
                            enableUpload
                                ? "uk-button-primary"
                                : "uk-button-primary uk-disabled"
                        }`}
                        disabled={!enableUpload}
                        data-uk-toggle={
                            this.state.isUploadCompleted
                                ? null
                                : enableUpload
                                ? "target: #modal-overflow-upload"
                                : null
                        }
                        onClick={() => {
                            this.uploadFiles();
                        }}
                    >
                        {this.state.isUploadCompleted
                            ? "Go To Reports"
                            : "Upload"}
                    </button>
                </div>
            );
        }
        return null;
    }

    popUpClosedHandler() {
        this.setState({ showPopUp: false });
    }

    convertObjectValuesToArray(obj) {
        const result = ["", obj.accountId, obj.owner];
        return result;
    }

    updateSelectedRow(ids, isTrading) {
        if (isTrading) {
            this.setState({ selectedTradingId: [...ids] });
            this.selectedTradingId = [...ids];
        } else {
            this.setState({ selectedUserId: [...ids] });
            this.selectedUserId = [...ids];
        }
    }

    filterIdsPerDomainCountry(ids) {
        if (!ids) {
            return [];
        }

        const domain = this.state.selectedDomain;
        return ids.filter(el => el.country === domain);
    }

    renderTradingAccountTable() {
        const data = [];
        const ids = this.filterIdsPerDomainCountry(
            this.state.showTradingAccountIds
        );
        ids.map(el => data.push(this.convertObjectValuesToArray(el)));
        return (
            <div className="uk-flex uk-flex-column">
                {ids.length > 0 && (
                    <>
                        <div className="uk-margin-bottom">
                            <span className="uk-text-bold">
                                Choose your Trading A/c (s):
                            </span>
                        </div>
                        <Table
                            select
                            headers={["", "Trading A/c", "Holder Name"]}
                            placeHolders={[
                                "",
                                "Search Trading A/c",
                                "Search Holder Name"
                            ]}
                            data={data}
                            selectedRows={ids =>
                                this.updateSelectedRow(ids, true)
                            }
                            filter={true}
                            key="user-account-table"
                        />
                    </>
                )}
            </div>
        );
    }

    renderDomainCombo() {
        const domainsDuplicated = [
            ...this.state.showTradingAccountIds,
            ...this.state.showUserAccountIds
        ];
        const domainList = [
            ...new Set(domainsDuplicated.map(el => el.country))
        ];

        const countries = [{ country: "Select", code: "" }].concat(
            this.countriesList.filter(
                el => domainList.findIndex(domain => domain === el.code) > -1
            )
        );

        // console.log(this.state.selectedDomain);

        // if (countries.length && !this.state.selectedDomain && !this.state.selectedDomain[0]) {
        //     this.setState({ selectedDomain: countries[0].code });
        // }

        return (
            <select
                className="uk-select uk-form-small uk-margin-small-left"
                onChange={event => this.domainChangeHandler(event)}
                style={{ width: "200px" }}
            >
                {countries.map((el, index) => (
                    <option value={el.code} key={`domain-${el}-${index}`}>
                        {index ? `${el.country} (${el.code})` : `${el.country}`}
                    </option>
                ))}
            </select>
        );
    }

    domainChangeHandler(event) {
        this.setState({ selectedDomain: event.target.value });
    }

    renderUserAccountTable() {
        const data = [];
        const ids = this.filterIdsPerDomainCountry(
            this.state.showUserAccountIds
        );
        ids.map(el => data.push(this.convertObjectValuesToArray(el)));
        return (
            <div className="uk-flex uk-flex-column">
                {ids.length > 0 && (
                    <>
                        <div className="uk-margin-bottom">
                            <span className="uk-text-bold">
                                Choose your User A/c (s):
                            </span>
                        </div>
                        <Table
                            select
                            headers={["", "User A/c", "Holder Name"]}
                            placeHolders={[
                                "",
                                "Search User A/c",
                                "Search Holder Name"
                            ]}
                            data={data}
                            selectedRows={ids =>
                                this.updateSelectedRow(ids, false)
                            }
                            filter={true}
                            key="trading-account-table"
                        />
                    </>
                )}
            </div>
        );
    }

    render() {
        return (
            <main>
                <div
                    className="page  uk-grid-small uk-grid-match uk-grid"
                    data-uk-grid
                >
                    <div className="menu-bar uk-background-secondary uk-visible@m">
                        <Toolbar />
                    </div>
                    <div className="uk-width-expand  page-content">
                        <div className="ems-page-container">
                            <div
                                style={{
                                    borderBottom: "2px solid #e5e5e5",
                                    background: "white"
                                }}
                                data-uk-sticky
                            >
                                <ReportTitle title="Data Upload" />
                                <Form onlyTitle={true} title="Data Upload" />
                            </div>
                            <div className="uk-padding">
                                <div className="uk-grid uk-child-width-1-1">
                                    <div className="uk-visible@s">
                                        <p className="uk-text-break">
                                            <span>
                                                Please select the files you want
                                                to upload (
                                                <span className="uk-text-danger">
                                                    in .csv format
                                                </span>
                                                ) by clicking on “Browse your
                                                files” and selecting your files
                                                and upload them.
                                            </span>
                                        </p>
                                    </div>
                                    <Dropzone
                                        onFilesAdded={this.onFilesAdded}
                                    />
                                    <div>
                                        <div className="uk-grid uk-margin-medium-top uk-child-width-1-2@s uk-child-width-1-4@l">
                                            <div>
                                                <UploadTable
                                                    title="Trading Account"
                                                    data={
                                                        this.state
                                                            .tradingAccountFiles
                                                    }
                                                />
                                            </div>
                                            <div>
                                                <UploadTable
                                                    title="Trading Account Transactions file (this is also called Trading Ledger)"
                                                    data={
                                                        this.state
                                                            .transactionLedgerFiles
                                                    }
                                                />
                                            </div>
                                            <div>
                                                <UploadTable
                                                    title="User Account"
                                                    data={
                                                        this.state
                                                            .userAccountFiles
                                                    }
                                                />
                                            </div>
                                            <div>
                                                <UploadTable
                                                    title="User Account Transactions file (this is also called User Ledger)"
                                                    data={
                                                        this.state
                                                            .userLedgerFiles
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <hr />
                                        {this.state.showTradingAccountIds
                                            .length > 0 && (
                                            <>
                                                <div className="uk-child-width-1-2@m uk-child-width-1-1@s uk-margin-top">
                                                    <span className="uk-text-bold">
                                                        Choose your local domain
                                                        country:{" "}
                                                    </span>
                                                    {this.renderDomainCombo()}
                                                </div>
                                                <hr />
                                            </>
                                        )}
                                        <div
                                            className="uk-child-width-1-2@m uk-child-width-1-1@s uk-margin-top"
                                            data-uk-grid
                                        >
                                            {this.renderTradingAccountTable()}
                                            {this.renderUserAccountTable()}
                                        </div>
                                        <div className="uk-margin-top">
                                            {this.renderActions()}
                                        </div>
                                    </div>
                                </div>
                                <div id="modal-overflow-upload" data-uk-modal>
                                    {this.state.deleteCompleted && (
                                        <div className="uk-modal-dialog">
                                            <button
                                                className="uk-modal-close-default"
                                                type="button"
                                                data-uk-close
                                            ></button>
                                            <div className="uk-modal-header uk-text-center">
                                                <p className="uk-margin-small-top">
                                                    Upload Progress
                                                </p>
                                            </div>
                                            <div
                                                className="uk-modal-body"
                                                uk-overflow-auto="true"
                                            >
                                                {this.renderProgress()}
                                            </div>
                                        </div>
                                    )}
                                    {this.state.deleteSent &&
                                        !this.state.deleteCompleted && (
                                            <div className="uk-modal-dialog">
                                                <button
                                                    className="uk-modal-close-default"
                                                    type="button"
                                                    data-uk-close
                                                ></button>
                                                <div className="uk-modal-header uk-text-center">
                                                    <p className="uk-margin-small-top">
                                                        Delete Selected
                                                        Countries Data
                                                    </p>
                                                </div>
                                                <div
                                                    className="uk-modal-body"
                                                    uk-overflow-auto="true"
                                                >
                                                    <p>
                                                        Your upload will start
                                                        shortly after the
                                                        related data is deleted.
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                </div>
                                <div id="modal-overflow-confirm" data-uk-modal>
                                    {
                                        <div className="uk-modal-dialog">
                                            <button
                                                className="uk-modal-close-default"
                                                type="button"
                                                data-uk-close
                                            ></button>
                                            <div className="uk-modal-header uk-text-center">
                                                <p className="uk-margin-small-top">
                                                    Confirm Your Domain
                                                </p>
                                            </div>
                                            <div
                                                className="uk-modal-body"
                                                uk-overflow-auto="true"
                                            >
                                                {this.renderConfirmation()}
                                            </div>
                                        </div>
                                    }
                                    {this.state.deleteSent &&
                                        !this.state.deleteCompleted && (
                                            <div className="uk-modal-dialog">
                                                <button
                                                    className="uk-modal-close-default"
                                                    type="button"
                                                    data-uk-close
                                                ></button>
                                                <div className="uk-modal-header uk-text-center">
                                                    <p className="uk-margin-small-top">
                                                        Delete Selected
                                                        Countries Data
                                                    </p>
                                                </div>
                                                <div
                                                    className="uk-modal-body"
                                                    uk-overflow-auto="true"
                                                >
                                                    <p>
                                                        Your upload will start
                                                        shortly after the
                                                        related data is deleted
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                </div>

                                <div id="modal-overflow-how" data-uk-modal>
                                    <div className="uk-modal-dialog">
                                        <button
                                            className="uk-modal-close-default"
                                            type="button"
                                            data-uk-close
                                        ></button>
                                        <div className="uk-modal-header uk-text-center">
                                            <h5 className="uk-margin-small-top">
                                                How it works!
                                            </h5>
                                        </div>
                                        <div className="uk-modal-body uk-text-justify">
                                            <p className="uk-panel uk-margin-small-top">
                                                For each file, you need first to
                                                download it from the Country
                                                Protocol Issuer System in your
                                                country (If you don't know this{" "}
                                                <Link to="/ems">
                                                    contact us
                                                </Link>
                                                ). Log in to the Protocol Issuer
                                                System and download and save
                                                each file as .csv{" "}
                                                <span className="uk-text-danger">
                                                    (don’t open them in Excel!)
                                                </span>
                                                . Make sure you download all the
                                                files and not few of them, in
                                                this case, your information can
                                                or will be incomplete.
                                            </p>
                                            <p className="uk-panel uk-margin-small-top">
                                                Organize the files in a folder
                                                in the order provided hereunder
                                                and browse your files and select
                                                them one by one until they are
                                                all selected and then click
                                                "Next". Each file is going to be
                                                uploaded. Once ALL files have
                                                been successfully uploaded,
                                                close the upload message box and
                                                click the home icon and view
                                                your operational data and
                                                results.
                                            </p>
                                            <p className="uk-panel uk-margin-small-top uk-text-danger">
                                                Always make sure that your
                                                latest data will be uploaded for
                                                analysis and analytics.
                                            </p>
                                            <p className="uk-panel uk-margin-small-top">
                                                If you have any issues with
                                                uploading of files,
                                                contact&nbsp;
                                                <Link to="/ems">EMS Team</Link>.
                                                &nbsp;We will get in touch with
                                                you.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Toolbar hidden={true} />
                <div
                    className={
                        window.innerWidth < 900
                            ? "uk-margin-top uk-flex uk-flex-right uk-text-meta"
                            : "footer-div uk-text-meta"
                    }
                >
                    <span>
                        Copyright &copy; 2019, {new Date().getFullYear()} Spenta
                        B.V., all rights reserved. Powered By codixt.com
                    </span>
                </div>
            </main>
        );
    }
}

export default Upload;
