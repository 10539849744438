import axios from "axios";
import * as browser from "./browserStorage";
const https = require("https");

axios.defaults.headers.post["Content-Type"] = "application/json";

const getDomain = () => {
    if (process.env.REACT_APP_ENV === "production") {
        return "https://ems-soft.nl";
    } else if (process.env.REACT_APP_ENV === "staging") {
        return "https://staging.ems-soft.nl";
    } else {
        return "http://localhost:3003";
    }
};

export const axiosInstance = axios.create({
    baseURL: `${getDomain()}/api/v1`,
    timeout: 1000000,
    httpsAgent: new https.Agent({
        rejectUnauthorized: false
    })
});
/**
 * axios interceptor, adds session to all requests
 * @param {httpRequest} request
 */
const requestHandler = request => {
    let userSession = browser.readDataFromBrowser("session");
    if (
        !userSession ||
        userSession === null ||
        userSession === undefined ||
        Object.keys(userSession).length === 0
    ) {
        return request;
    }
    request.headers["userSession"] = userSession;
    return request;
};

axiosInstance.interceptors.request.use(request => requestHandler(request));
