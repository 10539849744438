import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import * as tools from "../../tools";
/**Class toolbar */
class Toolbar extends Component {
    constructor(params) {
        super(params);
        this.state = {
            isAdmin: false,
            userName: null
        };
    }

    /**
     * ger user profile
     */
    componentDidMount() {
        tools.axiosInstance
            .get("/user")
            .then(recData => {
                let receivedBody = recData.data.data[0];
                this.setState({
                    isAdmin: receivedBody.isAdmin,
                    userName: `${receivedBody.firstName} ${receivedBody.lastName}`
                });
            })
            .catch(err => console.log(err));
    }

    /**
     * to support safari prn click
     * @param {string} to
     */
    handleLinkClick(to) {
        this.props.history.push(to);
        //console.log({event,to})
    }

    render() {
        if (this.props.hidden) {
            return (
                <div id="offcanvas-usage" uk-offcanvas="overlay: true">
                    <div className="uk-offcanvas-bar">
                        <div className="menu-bar uk-background-secondary uk-hidden">
                            <div className="uk-flex uk-flex-column uk-flex-middle">
                                <Link
                                    to="#"
                                    uk-tooltip={`title: ${this.state.userName}; delay: 500; pos: bottom`}
                                    className="uk-margin-large-top user"
                                >
                                    <span
                                        className={
                                            this.props.location.pathname.includes(
                                                "profile"
                                            )
                                                ? "uk-text-danger"
                                                : "uk-light"
                                        }
                                        uk-icon="icon: user; ratio: 1"
                                    ></span>
                                </Link>
                                <Link
                                    to="#"
                                    uk-tooltip="title: Home; delay: 500; pos: bottom"
                                >
                                    <span
                                        className={
                                            this.props.location.pathname ===
                                                "/" ||
                                            this.props.location.pathname.includes(
                                                "report"
                                            )
                                                ? "uk-text-danger"
                                                : "uk-light"
                                        }
                                        uk-icon="icon: home; ratio: 1"
                                    ></span>
                                </Link>
                                <Link
                                    to="#"
                                    uk-tooltip="title: Upload; delay: 500; pos: bottom"
                                >
                                    <span
                                        className={
                                            this.props.location.pathname.includes(
                                                "upload"
                                            )
                                                ? "uk-text-danger"
                                                : "uk-light"
                                        }
                                        uk-icon="icon: cloud-upload; ratio: 1"
                                    ></span>
                                </Link>
                                <Link
                                    to="#"
                                    uk-tooltip="title: Log out; delay: 500; pos: bottom"
                                >
                                    <span
                                        className="uk-light"
                                        uk-icon="icon: sign-out; ratio: 1"
                                    ></span>
                                </Link>
                                {this.state.isAdmin ? (
                                    <Link
                                        to="#"
                                        uk-tooltip="title: Admin; delay: 500; pos: bottom"
                                    >
                                        <span
                                            className="uk-light"
                                            uk-icon="icon: server; ratio: 1"
                                        ></span>
                                    </Link>
                                ) : null}
                            </div>
                        </div>
                        <div className="uk-flex uk-flex-column uk-flex-left uk-flex-between uk-text-small">
                            <div className="uk-flex uk-flex-column  uk-margin-small-top">
                                <Link
                                    to="/profile"
                                    className="uk-margin-small-top"
                                    onClick={() =>
                                        this.handleLinkClick("/profile")
                                    }
                                    onTouchStart={() =>
                                        this.handleLinkClick("/profile")
                                    }
                                >
                                    <span
                                        className={
                                            this.props.location.pathname.includes(
                                                "profile"
                                            )
                                                ? "uk-text-danger"
                                                : "uk-light"
                                        }
                                    >
                                        {this.state.userName}
                                    </span>
                                </Link>
                                <hr></hr>
                                <Link
                                    to="/"
                                    className="pointer"
                                    onClick={() => this.handleLinkClick("/")}
                                    onTouchStart={() =>
                                        this.handleLinkClick("/")
                                    }
                                >
                                    <span
                                        className={
                                            this.props.location.pathname ===
                                                "/" ||
                                            this.props.location.pathname.includes(
                                                "report"
                                            )
                                                ? "uk-text-danger"
                                                : "uk-light"
                                        }
                                    >
                                        Home
                                    </span>
                                </Link>
                                <hr></hr>
                                <Link
                                    to="/upload"
                                    className="pointer"
                                    onClick={() =>
                                        this.handleLinkClick("/upload")
                                    }
                                    onTouchStart={() =>
                                        this.handleLinkClick("/upload")
                                    }
                                >
                                    <span
                                        className={
                                            this.props.location.pathname.includes(
                                                "upload"
                                            )
                                                ? "uk-text-danger"
                                                : "uk-light"
                                        }
                                    >
                                        Upload
                                    </span>
                                </Link>
                                <hr></hr>
                                <Link
                                    to="/logout"
                                    className="pointer"
                                    onClick={() =>
                                        this.handleLinkClick("/logout")
                                    }
                                    onTouchStart={() =>
                                        this.handleLinkClick("/logout")
                                    }
                                >
                                    <span className="uk-light">Log out</span>
                                </Link>
                                <hr></hr>
                                <Link
                                    to="/delete"
                                    className="pointer"
                                    onClick={() =>
                                        this.handleLinkClick("/delete")
                                    }
                                    onTouchStart={() =>
                                        this.handleLinkClick("/delete")
                                    }
                                >
                                    <span
                                        className={
                                            this.props.location.pathname.includes(
                                                "delete"
                                            )
                                                ? "uk-text-danger"
                                                : "uk-light"
                                        }
                                    >
                                        Delete
                                    </span>
                                </Link>
                                <hr></hr>
                                <Link
                                    to="/ems"
                                    className="pointer"
                                    onClick={() => this.handleLinkClick("/ems")}
                                    onTouchStart={() =>
                                        this.handleLinkClick("/ems")
                                    }
                                >
                                    <span
                                        className={
                                            this.props.location.pathname.includes(
                                                "ems"
                                            )
                                                ? "uk-text-danger"
                                                : "uk-light"
                                        }
                                    >
                                        EMS
                                    </span>
                                </Link>
                                <hr></hr>
                                {this.state.isAdmin ? (
                                    <Link
                                        to="/admin"
                                        className="pointer"
                                        onClick={() =>
                                            this.handleLinkClick("/admin")
                                        }
                                        onTouchStart={() =>
                                            this.handleLinkClick("/admin")
                                        }
                                    >
                                        <span
                                            className={
                                                this.props.location.pathname.includes(
                                                    "admin"
                                                )
                                                    ? "uk-text-danger"
                                                    : "uk-light"
                                            }
                                        >
                                            Admin
                                        </span>
                                    </Link>
                                ) : null}
                            </div>
                            <a
                                href="https://ems-soft.com/emssoftmax-knowledgebase"
                                target="_blank"
                                className="pointer uk-hidden@m"
                            >
                                <span
                                    className={
                                        this.props.location.pathname.includes(
                                            "ems"
                                        )
                                            ? "uk-text-danger"
                                            : "uk-light"
                                    }
                                >
                                    Help
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div className="uk-flex uk-flex-column uk-flex-between uk-margin-small-top">
                <div className="uk-flex uk-flex-column uk-flex-middle uk-margin-small-top">
                    <Link
                        to="#"
                        uk-toggle="target: #offcanvas-usage"
                        className="pointer"
                    >
                        <span className="uk-light" uk-icon="menu"></span>
                    </Link>
                    <Link
                        to="/profile"
                        uk-tooltip="title: Profile; delay: 100; pos: bottom"
                        onClick={() => this.handleLinkClick("/profile")}
                        onTouchStart={() => this.handleLinkClick("/profile")}
                    >
                        <span
                            className={
                                this.props.location.pathname.includes("profile")
                                    ? "uk-text-danger"
                                    : "uk-light"
                            }
                            uk-icon="icon: user; ratio: 1"
                        ></span>
                    </Link>
                    <Link
                        to="/"
                        uk-tooltip="title: Home; delay: 500; pos: bottom"
                        onClick={() => this.handleLinkClick("/")}
                        onTouchStart={() => this.handleLinkClick("/")}
                    >
                        <span
                            className={
                                this.props.location.pathname === "/" ||
                                this.props.location.pathname.includes("report")
                                    ? "uk-text-danger"
                                    : "uk-light"
                            }
                            uk-icon="icon: home; ratio: 1"
                        ></span>
                    </Link>
                    <Link
                        to="/upload"
                        uk-tooltip="title: Upload; delay: 500; pos: bottom"
                        onClick={() => this.handleLinkClick("/upload")}
                        onTouchStart={() => this.handleLinkClick("/upload")}
                    >
                        <span
                            className={
                                this.props.location.pathname.includes("upload")
                                    ? "uk-text-danger"
                                    : "uk-light"
                            }
                            uk-icon="icon: cloud-upload; ratio: 1"
                        ></span>
                    </Link>
                    <Link
                        to="/logout"
                        uk-tooltip="title: Log out; delay: 500; pos: bottom"
                        onClick={() => this.handleLinkClick("/logout")}
                        onTouchStart={() => this.handleLinkClick("/logout")}
                    >
                        <span
                            uk-icon="icon: sign-out; ratio: 1"
                            className="uk-light"
                        ></span>
                    </Link>
                    <Link
                        to="/delete"
                        uk-tooltip="title: Delete; delay: 500; pos: bottom"
                        onClick={() => this.handleLinkClick("/delete")}
                        onTouchStart={() => this.handleLinkClick("/delete")}
                    >
                        <span
                            className={
                                this.props.location.pathname.includes("delete")
                                    ? "uk-text-danger"
                                    : "uk-light"
                            }
                            uk-icon="icon: trash; ratio: 1"
                        ></span>
                    </Link>
                    <Link
                        to="/ems"
                        uk-tooltip="title: EMS; delay: 500; pos: bottom"
                        onClick={() => this.handleLinkClick("/ems")}
                        onTouchStart={() => this.handleLinkClick("/ems")}
                    >
                        <span
                            className={
                                this.props.location.pathname.includes("ems")
                                    ? "uk-text-danger"
                                    : "uk-light"
                            }
                            uk-icon="icon: receiver; ratio: 1"
                        ></span>
                    </Link>
                    {this.state.isAdmin ? (
                        <Link
                            to="/admin"
                            uk-tooltip="title: Admin; delay: 500; pos: bottom"
                            onClick={() => this.handleLinkClick("/admin")}
                            onTouchStart={() => this.handleLinkClick("/admin")}
                        >
                            <span
                                className={
                                    this.props.location.pathname.includes(
                                        "admin"
                                    )
                                        ? "uk-text-danger"
                                        : "uk-light"
                                }
                                uk-icon="icon: server; ratio: 1"
                            ></span>
                        </Link>
                    ) : null}
                </div>
                <a
                    href="https://ems-soft.com/emssoftmax-knowledgebase"
                    target="_blank"
                    uk-tooltip="title: Help; delay: 500; pos: bottom"
                >
                    <span
                        className={
                            this.props.location.pathname.includes("help")
                                ? "uk-text-danger"
                                : "uk-light"
                        }
                        uk-icon="icon: question; ratio: 1"
                    ></span>
                </a>
            </div>
        );
    }
}

export default withRouter(Toolbar);
