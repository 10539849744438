import React, { Component } from "react";

/**@module Tab */
export default class Tab extends Component {
    /**
     * call the change tab listener
     * @param {event} event
     * @param {int} index
     */
    handleLiClick(event, index) {
        if (typeof this.props.tabChangeListener === "function") {
            this.props.tabChangeListener(index);
        }
    }
    /**
     * render tab header
     */
    createTabLinks() {
        const { index } = this.props;
        let links = this.props.titles.map((el, idx) => (
            <li
                key={`tabButton-${idx}`}
                className={index === idx ? "uk-active" : ""}
            >
                <a
                    href="#"
                    onClick={event => this.handleLiClick(event, idx)}
                    key={`a-${idx}`}
                >
                    {el}
                </a>
            </li>
        ));

        return (
            <ul
                className="ems-tabs"
                uk-tab={`animation: uk-animation-fade; swiping: true`}
                index={index ? index : 0}
            >
                {links}
            </ul>
        );
    }

    /**
     * render tab body
     */
    createTabBody() {
        let tempChildren = this.props.children;
        if (!Array.isArray(tempChildren)) {
            tempChildren = [tempChildren];
        }

        let tempChildrenLi = tempChildren.map((el, idx) => (
            <li key={`li-child-${idx}`}>{el}</li>
        ));
        return <ul className="uk-switcher">{tempChildrenLi}</ul>;
    }

    render() {
        return (
            <div className="ems-tables uk-padding-remove">
                {this.createTabLinks()}
                {this.createTabBody()}
            </div>
        );
    }
}
