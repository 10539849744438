const formatDate = date => {
    if (!date) {
        return date;
    }

    const params = date.replace("Z", "").split("T");

    const output = params[0]
        .split("-")
        .reverse()
        .join("-")
        .concat(" ")
        .concat(params[1])
        .split(".")[0];
    return output;
};

const formatMonth = month => (month < 10 ? `0${month}` : `${month}`);

const dateFromTimeStamp = timestamp => {
    const date = new Date(timestamp).toISOString().split(".")[0];
    const dateSplitted = date.split("T");

    return `${dateSplitted[0]
        .split("-")
        .reverse()
        .join("-")} ${dateSplitted[1]} GMT`;
};

export { formatDate, dateFromTimeStamp };
