import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import * as tools from "../../tools";
/**class Auth
 * validate open urls
 */
class Auth extends Component {
    constructor(props) {
        super(props);
        this.openUrls = [
            "register",
            "login",
            "forgot",
            "verify",
            "logout",
            "about",
            "contact",
            "qrcode",
            "documents"
        ];
    }

    /**
     * validate user session from browser storage
     */
    validateSession() {
        let temp = this.props.location.pathname;
        const isNew = tools.readDataFromBrowser("newLogIn");
        if (parseInt(isNew) === 1) {
            tools.saveDataToBrowser("newLogIn", 0);
            tools.readDataFromBrowser("newLogIn");
            window.location.reload();
        }
        temp = temp.substr(1, temp.length);
        temp = temp.split("/")[0];
        if (this.openUrls.indexOf(temp) > -1) {
            return true;
        }

        let session = tools.readDataFromBrowser("session");
        if (session && typeof session === "string" && session.length > 0) {
            return true;
        }
        return false;
    }

    render() {
        if (this.validateSession()) {
            return this.props.children;
        } else {
            return <Redirect to="/login" />;
        }
    }
}

export default withRouter(Auth);
