import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import * as tools from "../../tools";
import Toolbar from "../toolbar/toolbar";
import ReportTitle from "../report/reportTitle/reportTitle";
import Table from "./table";
import Form from "../report/form/form";
import UserData from "./userData";
import UIkit from "uikit";

export default class UserList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accountHolder: null,
            userList: [],
            userToShowInTable: [],
            rowClickedId: -1,
            editMode: false
        };

        this.headers = [
            "User Id",
            "Type",
            "Country",
            "City",
            "Email",
            "Status",
            "Purchase Date",
            "Registration Date"
        ];

        this.parametersToAdd = [
            "userId",
            "isClient",
            "country",
            "city",
            "email",
            "status",
            "purchaseDate",
            "registrationDate"
        ];
    }

    componentDidMount() {
        const id = this.props.match.params.id;
        tools.axiosInstance
            .get(`/user/client/${id}`)
            .then(rec => {
                const users = rec.data.data.map(el => {
                    el.status = "Activated";
                    if (el.activeStatus) {
                        el.status = "Activated";
                    }
                    if (!el.parentId) {
                        el.isClient = "A/c Holder";
                        return el;
                    }
                    el.isClient = "User";
                    return el;
                });
                const accountHolder = rec.data.data[0];
                const userList = [...rec.data.data];
                const userToShowInTable = [];
                for (let i = 0, n = userList.length; i < n; i++) {
                    userList[i].status = "Activated";
                    if (!userList[i].activeStatus) {
                        userList[i].status = "Deactivated";
                    }

                    if (userList[i].purchaseDate) {
                        userList[i].purchaseDate = tools.formatDate(
                            userList[i].purchaseDate
                        );
                    }

                    if (userList[i].registrationDate) {
                        userList[i].registrationDate = tools.formatDate(
                            userList[i].registrationDate
                        );
                    }

                    userToShowInTable.push(
                        this.convertObjectToArray(
                            userList[i],
                            this.parametersToAdd
                        )
                    );
                }
                this.setState({
                    accountHolder,
                    userList,
                    userToShowInTable
                });
            })
            .catch(console.log);
    }

    convertObjectToArray(obj, parametersToAdd) {
        const tempArray = [];
        if (!obj) {
            return tempArray;
        }

        const objectKeys = Object.keys(obj);
        if (objectKeys.length === 0) {
            return tempArray;
        }

        if (!parametersToAdd || parametersToAdd.length === 0) {
            for (let i = 0, n = objectKeys.length; i < n; i++) {
                tempArray.push(obj[objectKeys[i]]);
            }
            return tempArray;
        } else {
            for (let i = 0, n = parametersToAdd.length; i < n; i++) {
                tempArray.push(obj[parametersToAdd[i]]);
            }
            return tempArray;
        }
    }

    audit(index) {
        const id = this.state.userList[index].userId;
        const url = `/admin/audit/${id}`;
        this.setState({ rowClickedId: index });
        this.props.history.push(url);
    }

    handleInputChange(fieldName, newValue) {
        this.setState(oldState => {
            oldState.userList[oldState.rowClickedId][fieldName] = newValue;
            return oldState;
        });
    }

    renderSpanOrInput(fieldName) {
        if (this.state.editMode == false) {
            return (
                <span>
                    &nbsp;
                    {this.state.userList[this.state.rowClickedId][fieldName]}
                </span>
            );
        }

        return (
            <input
                value={this.state.userList[this.state.rowClickedId][fieldName]}
                className="uk-input"
                onChange={event =>
                    this.handleInputChange(fieldName, event.target.value)
                }
            />
        );
    }

    /**
     * render pop up contains user data
     */
    renderUserData() {
        if (this.state.rowClickedId === -1) {
            return null;
        }

        let userData = this.state.userList[this.state.rowClickedId];
        let userNumber = userData.userId;

        return (
            <div>
                <h2>{`Client Number: ${
                    userData.parentId ? `${userData.parentId}-` : ""
                } ${userData.country}-${userNumber}`}</h2>
                <label className="profileStatus">
                    {this.state.userList[this.state.rowClickedId] &&
                    !this.state.userList[this.state.rowClickedId].activeStatus
                        ? "(deactivated)"
                        : `(activated) ${
                              this.state.userList[this.state.rowClickedId]
                                  .userCount
                                  ? `${this.state.userList[this.state.rowClickedId].userCount} users`
                                  : "0 users"
                          }`}
                </label>
                <ul uk-accordion="multiple: true" className="">
                    <li className="uk-open uk-card uk-card-small uk-card-default uk-box-shadow-small">
                        <a
                            className="uk-accordion-title uk-padding-small"
                            href="#"
                        >
                            Company Details
                        </a>
                        <div className="uk-accordion-content uk-padding-small">
                            <div
                                className="uk-grid uk-width-1-1 uk-children-width-1-7 uk-children-width-1-1@s"
                                data-uk-grid
                            >
                                <div>
                                    <span className="uk-text-bold">
                                        Company Name:
                                    </span>
                                    <span>&nbsp;{userData.companyName}</span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        Company/Business Email:
                                    </span>
                                    <span>&nbsp;{userData.email}</span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        Company Number:
                                    </span>
                                    <span>&nbsp;{userData.mobileNumber}</span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        Country:
                                    </span>
                                    <span>&nbsp;{userData.country}</span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">City:</span>
                                    <span>&nbsp;{userData.city}</span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        Zip Code:
                                    </span>
                                    <span>&nbsp;{userData.zipCode}</span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        Address:
                                    </span>
                                    <span>&nbsp;{userData.address}</span>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="uk-open uk-card uk-card-small uk-card-default uk-box-shadow-small">
                        <a
                            className="uk-accordion-title uk-padding-small"
                            href="#"
                        >
                            Company Trading Details
                        </a>
                        <div className="uk-accordion-content uk-padding-small">
                            <div
                                className="uk-grid uk-width-1-1 uk-children-width-1-3 uk-children-width-1-1@s"
                                data-uk-grid
                            >
                                <div>
                                    <span className="uk-text-bold">
                                        Fiscal Number:
                                    </span>
                                    <span>
                                        &nbsp;
                                        {userData.companyFiscalNumber}
                                    </span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        Trading Number: &nbsp;
                                        {userData.companyTradeNumber}
                                    </span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        RESC Code:
                                    </span>
                                    <span>&nbsp;{userData.rescCode}</span>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="uk-open uk-card uk-card-small uk-card-default uk-box-shadow-small">
                        <a
                            className="uk-accordion-title uk-padding-small"
                            href="#"
                        >
                            Personal Details
                        </a>
                        <div className="uk-accordion-content uk-padding-small">
                            <div
                                className="uk-grid uk-width-1-1 uk-children-width-1-4 uk-children-width-1-1@s"
                                data-uk-grid
                            >
                                <div>
                                    <span className="uk-text-bold">
                                        Initials:
                                    </span>
                                    {this.renderSpanOrInput("initials")}
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        Company/Business Email:
                                    </span>
                                    {this.renderSpanOrInput("email")}
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        First Name:
                                    </span>
                                    {this.renderSpanOrInput("firstName")}
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        Last Name:
                                    </span>
                                    {this.renderSpanOrInput("lastName")}
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        Mobile Number:
                                    </span>
                                    {this.renderSpanOrInput("mobileNumber")}
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        Registration Date:{" "}
                                    </span>
                                    <span>
                                        {userData.registerTime
                                            ? userData.registerTime
                                            : ""}
                                    </span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        Contact Type:
                                    </span>
                                    <span>&nbsp;{userData.contactType}</span>
                                </div>
                            </div>

                            <div className="uk-margin-top">
                                <button
                                    className="uk-button uk-button-primary"
                                    onClick={event => this.changeMode()}
                                >
                                    {this.state.editMode ? "Save" : "Edit"}
                                </button>

                                {this.state.editMode && (
                                    <button
                                        className="uk-button uk-button-danger uk-margin-left"
                                        onClick={event => this.cancelEdit()}
                                    >
                                        {"Cancel"}
                                    </button>
                                )}
                            </div>
                        </div>
                    </li>
                    <li className="uk-open uk-card uk-card-small uk-card-default uk-box-shadow-small">
                        <a
                            className="uk-accordion-title uk-padding-small"
                            href="#"
                        >
                            Purchase Details
                        </a>
                        <div className="uk-accordion-content uk-padding-small">
                            <div
                                className="uk-grid uk-width-1-1 uk-children-width-1-4 uk-children-width-1-1@s"
                                data-uk-grid
                            >
                                <div>
                                    <span className="uk-text-bold">
                                        Base Amount:
                                    </span>
                                    <span>&nbsp;{userData.baseAmount}</span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        Tax Amount:
                                    </span>
                                    <span>&nbsp;{userData.taxAmount}</span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        Total Amount:
                                    </span>
                                    <span>&nbsp;{userData.totalAmount}</span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        Reference Code:
                                    </span>
                                    <span>&nbsp;{userData.referenceCode}</span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        Purchase Date:
                                    </span>
                                    <span>
                                        &nbsp;
                                        {userData.purchaseDate
                                            ? userData.purchaseDate
                                            : ""}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <div>
                    <div className="uk-flex uk-flex-center uk-flex-between">
                        {this.state.userList[this.state.rowClickedId] &&
                        !this.state.userList[this.state.rowClickedId]
                            .activeStatus ? (
                            <button
                                className="uk-button-primary uk-width-1-3@m uk-width-1-4@l uk-text-large"
                                data-uk-toggle="target: #modal-overflow-amount"
                            >
                                Activate
                            </button>
                        ) : (
                            <button
                                className="uk-button-primary uk-width-1-3@m uk-width-1-4@l uk-text-large"
                                onClick={this.sendDeactivation.bind(this)}
                            >
                                Deactivate
                            </button>
                        )}
                        <button
                            className={`uk-button-primary uk-width-1-3@m uk-width-1-4@l uk-text-large uk-button-danger ${
                                this.state.userList[this.state.rowClickedId] &&
                                !this.state.userList[this.state.rowClickedId]
                                    .activeStatus
                                    ? ""
                                    : "uk-hidden"
                            }`}
                            data-uk-toggle="target: #modal-overflow-delete"
                            onClick={() => this.deletePendingRequest()}
                        >
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    deletePendingRequest() {
        UIkit.modal
            .confirm("Are you sure?", { labels: { cancel: "No", ok: "Yes" } })
            .then(() => {
                let tempChosenUser = this.state.userList[
                    this.state.rowClickedId
                ];
                tools.axiosInstance
                    .post(`/user/enable/${tempChosenUser.userId}`, {
                        isHidden: true,
                        isActive: false
                    })
                    .then(suc => {
                        window.location.reload();
                    })
                    .catch(err => {
                        console.log(err);
                    });
            });
    }

    sendPayment() {
        let tempChosenUser = this.state.userList[this.state.rowClickedId];
        tools.axiosInstance
            .post(`/user/enable/${tempChosenUser.userId}`, {
                isActive: true,
                isHidden: false
            })
            .then(suc => {
                //console.log("activateClickHandler", suc);
                let paymentLoad = {
                    baseAmount: this.state.baseAmount,
                    taxAmount: this.state.taxAmount,
                    totalAmount: this.state.totalAmount,
                    refCode: this.state.referenceCode
                };
                tools.axiosInstance
                    .post(
                        `/user/purchase/${tempChosenUser.userId}`,
                        paymentLoad
                    )
                    .then(suc1 => {
                        if (suc1.data.data[0].success) {
                            window.location.reload();
                        } else {
                            console.log("not success");
                        }
                    })
                    .catch(err1 => console.log("purchase", err1));
            })
            .catch(err => {
                console.log("activateClickHandler", err);
            });
    }

    renderPayment() {
        return (
            <div id="modal-overflow-amount" data-uk-modal="stack: true">
                <div className="uk-modal-dialog">
                    <button
                        className="uk-modal-close-default"
                        type="button"
                        data-uk-close
                    ></button>
                    <div className="uk-modal-header uk-text-center">
                        <p className="uk-margin-small-top">
                            User Payment Details
                        </p>
                    </div>
                    <div className="uk-modal-body uk-overflow-auto">
                        <form className="uk-form-stacked">
                            <div className="uk-margin">
                                <label
                                    className="uk-form-label"
                                    htmlFor="form-stacked-text"
                                >
                                    Base Amount
                                </label>
                                <div className="uk-form-controls">
                                    <input
                                        className="uk-input"
                                        id="form-stacked-text"
                                        type="text"
                                        placeholder="Base Amount"
                                        onChange={event =>
                                            this.handleInputChangeForPayment(
                                                event,
                                                "baseAmount"
                                            )
                                        }
                                        value={this.state.baseAmount}
                                    />
                                </div>
                            </div>

                            <div className="uk-margin">
                                <label
                                    className="uk-form-label"
                                    htmlFor="form-stacked-text"
                                >
                                    Tax Amount
                                </label>
                                <div className="uk-form-controls">
                                    <input
                                        className="uk-input"
                                        id="form-stacked-text"
                                        type="text"
                                        placeholder="Tax Amount"
                                        onChange={event =>
                                            this.handleInputChangeForPayment(
                                                event,
                                                "taxAmount"
                                            )
                                        }
                                        value={this.state.taxAmount}
                                    />
                                </div>
                            </div>

                            <div className="uk-margin">
                                <label
                                    className="uk-form-label"
                                    htmlFor="form-stacked-text"
                                >
                                    Total Amount
                                </label>
                                <div className="uk-form-controls">
                                    <input
                                        className="uk-input"
                                        id="form-stacked-text"
                                        type="text"
                                        placeholder="Total Amount"
                                        onChange={event =>
                                            this.handleInputChangeForPayment(
                                                event,
                                                "totalAmount"
                                            )
                                        }
                                        value={this.state.totalAmount}
                                    />
                                </div>
                            </div>

                            <div className="uk-margin">
                                <label
                                    className="uk-form-label"
                                    htmlFor="form-stacked-text"
                                >
                                    Reference Code
                                </label>
                                <div className="uk-form-controls">
                                    <input
                                        className="uk-input"
                                        id="form-stacked-text"
                                        type="text"
                                        placeholder="Reference Code"
                                        onChange={event =>
                                            this.handleInputChangeForPayment(
                                                event,
                                                "referenceCode"
                                            )
                                        }
                                        value={this.state.referenceCode}
                                    />
                                </div>
                            </div>
                            <div onClick={this.sendPayment.bind(this)}>
                                <span className="uk-button uk-button-primary uk-width-auto@s">
                                    Set Payment
                                </span>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    handleInputChangeForPayment(event, variableName) {
        let tempState = {};
        tempState[variableName] = event.target.value;
        this.setState(tempState);
    }

    changeMode() {
        if (this.state.editMode) {
            // send save request
            tools.axiosInstance
                .put(
                    `/user/${this.state.userList[this.state.rowClickedId].userId}`,
                    this.state.userList[this.state.rowClickedId]
                )
                .then(() => window.location.reload())
                .catch(console.log);
        }

        this.setState(oldState => {
            oldState.editMode = !oldState.editMode;
            return oldState;
        });
    }

    cancelEdit() {
        this.setState({ editMode: false }, () => window.location.reload());
    }

    sendDeactivation() {
        UIkit.modal
            .confirm("Are you sure", { labels: { cancel: "No", ok: "Yes" } })
            .then(() => {
                const tempChosenUser = this.state.userList[
                    this.state.rowClickedId
                ];

                tools.axiosInstance
                    .post(`/user/enable/${tempChosenUser.userId}`, {
                        isActive: false,
                        isHidden: false
                    })
                    .then(() => window.location.reload())
                    .catch(() => window.location.reload());
            });
    }

    renderAccountHolderSummery() {
        if (!this.state.parentId) {
            return null;
        }

        const { accountHolder, userToShowInTable } = this.state;

        return (
            <>
                <div className="uk-card" data-uk-grid>
                    <div>
                        <span className="uk-text-bold">Company Name: </span>
                        <span>{accountHolder.companyName || "--"}</span>
                    </div>
                    <div>
                        <span className="uk-text-bold">
                            Company ID Number:{" "}
                        </span>
                        <span>
                            {accountHolder.country}-{accountHolder.userId}
                        </span>
                    </div>
                    <div>
                        <span className="uk-text-bold">
                            Number of users excl. A/c holder:{" "}
                        </span>
                        <span>{userToShowInTable.length - 1}</span>
                    </div>
                </div>
                <hr />
            </>
        );
    }

    render() {
        const rowButtons = [
            {
                title: "info",
                className:
                    "uk-button uk-button-primary uk-width-auto@s uk-border-rounded uk-margin-small-bottom uk-margin-small-right uk-icon",
                onClick: index => this.setState({ rowClickedId: index }),
                "data-uk-toggle": `target: #modal-overflow`,
                icon: "info"
            },
            {
                title: "audit",
                className:
                    "uk-button uk-button-danger uk-width-auto@s uk-border-rounded uk-margin-small-bottom uk-icon",
                onClick: this.audit.bind(this)
            }
        ];
        return (
            <main>
                <div
                    className="page uk-grid-small uk-grid-match uk-grid"
                    data-uk-grid
                >
                    <div className="menu-bar uk-background-secondary uk-visible@m">
                        <Toolbar />
                    </div>
                    <div className="uk-width-expand page-content">
                        <div className="ems-page-container ems-admin">
                            <div
                                style={{
                                    borderBottom: "2px solid #e5e5e5",
                                    background: "white"
                                }}
                                data-uk-sticky
                            >
                                <ReportTitle title="Admin: Company list of users" />
                                <Form
                                    onlyTitle={true}
                                    title="Admin: Company List of Users"
                                />
                            </div>
                            <div
                                className="uk-padding uk-flex uk-flex-column"
                                style={{
                                    borderTop: "2px solid rgb(229, 229, 229)"
                                }}
                            >
                                {this.renderAccountHolderSummery()}
                                <Table
                                    headers={this.headers}
                                    body={this.state.userToShowInTable}
                                    rowButtons={rowButtons}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modal-overflow" data-uk-modal>
                    <div className="uk-modal-dialog uk-width-8-10">
                        <button
                            className="uk-modal-close-default"
                            type="button"
                            data-uk-close
                            onClick={() => this.cancelEdit()}
                        ></button>
                        <div className="uk-modal-header uk-text-center">
                            <h5 className="uk-margin-small-top">
                                Profile of Company and User
                            </h5>
                        </div>
                        <div className="uk-modal-body uk-overflow-auto">
                            {this.renderUserData()}
                        </div>
                        <div className="uk-modal-body uk-overflow-auto">
                            {this.renderPayment()}
                        </div>
                    </div>
                </div>
                <Toolbar hidden={true} />
                <div
                    className={
                        window.innerWidth < 900
                            ? "uk-margin-top uk-flex uk-flex-right uk-text-meta"
                            : "footer-div uk-text-meta"
                    }
                >
                    <span>
                        Copyright &copy; 2019, {new Date().getFullYear()} Spenta
                        B.V., all rights reserved. Powered By codixt.com
                    </span>
                </div>
            </main>
        );
    }
}
