import React, { Component } from "react";
import * as tools from "../../tools";

export default class UserData extends Component {
    constructor(props) {
        super(props);
        this.fieldsToEdit = [
            "firstName",
            "lastName",
            "mobileNumber",
            "email",
            "initials"
        ];

        this.state = { ...this.props.userData };

        this.state["editMode"] = false;
    }

    sendDeactivation() {
        const tempChosenUser = this.state.userList[this.state.rowClickedId];

        tools.axiosInstance
            .post(`/user/enable/${tempChosenUser.id}`, {
                isActive: false,
                isHidden: false
            })
            .then(
                suc => window.location.reload(),
                err => window.location.reload()
            );
    }

    sendEdit() {
        const tempChosenUser = this.state.userList[this.state.rowClickedId];

        tools.axiosInstance
            .post(`/user/enable/${tempChosenUser.id}`, {
                isActive: false,
                isHidden: false
            })
            .then(
                suc => window.location.reload(),
                err => window.location.reload()
            );
    }

    handleInputChange(fieldName, newValue) {
        this.setState(oldState => {
            oldState[fieldName] = newValue;
            return oldState;
        });
    }

    renderSpanOrInput(fieldName) {
        if (this.state.editMode == false) {
            return <span>&nbsp;{this.state[fieldName]}</span>;
        }

        return (
            <input
                value={this.state[fieldName]}
                className="uk-input"
                onChange={event =>
                    this.handleInputChange(fieldName, event.target.value)
                }
            />
        );
    }

    async changeMode() {
        if (this.state.editMode) {
            // send save request
            tools.axiosInstance
                .put(`/user/${this.state.id}`, this.state)
                .then(console.log)
                .catch(console.log);
        }

        this.setState(oldState => {
            oldState.editMode = !oldState.editMode;
            return oldState;
        });
    }

    render() {
        const userData = this.state;
        return (
            <div>
                <h2>{`Client Number: ${
                    userData.accountHolder ? `${userData.accountHolder}-` : ""
                } ${userData.country}-${userData.id}`}</h2>
                <label className="profileStatus">
                    {this.state.isPending ? "(pending)" : "(activated)"}
                </label>
                <ul uk-accordion="multiple: true" className="">
                    <li className="uk-open uk-card uk-card-small uk-card-default uk-box-shadow-small">
                        <a
                            className="uk-accordion-title uk-padding-small"
                            href="#"
                        >
                            Company Details
                        </a>
                        <div className="uk-accordion-content uk-padding-small">
                            <div
                                className="uk-grid uk-width-1-1 uk-children-width-1-7 uk-children-width-1-1@s"
                                data-uk-grid
                            >
                                <div>
                                    <span className="uk-text-bold">
                                        Company Name:
                                    </span>
                                    <span>&nbsp;{userData.companyName}</span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        Company Number:
                                    </span>
                                    <span>&nbsp;{userData.mobileNumber}</span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        Country:
                                    </span>
                                    <span>&nbsp;{userData.country}</span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">City:</span>
                                    <span>&nbsp;{userData.city}</span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        Zip Code:
                                    </span>
                                    <span>&nbsp;{userData.zipCode}</span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        Address:
                                    </span>
                                    <span>&nbsp;{userData.address}</span>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="uk-open uk-card uk-card-small uk-card-default uk-box-shadow-small">
                        <a
                            className="uk-accordion-title uk-padding-small"
                            href="#"
                        >
                            Company Trading Details
                        </a>
                        <div className="uk-accordion-content uk-padding-small">
                            <div
                                className="uk-grid uk-width-1-1 uk-children-width-1-3 uk-children-width-1-1@s"
                                data-uk-grid
                            >
                                <div>
                                    <span className="uk-text-bold">
                                        Fiscal Number:
                                    </span>
                                    <span>
                                        &nbsp;
                                        {userData.companyFiscalNumber}
                                    </span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        Trading Number: &nbsp;
                                        {userData.companyTradeNumber}
                                    </span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        RESC Code:
                                    </span>
                                    <span>&nbsp;{userData.rescCode}</span>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="uk-open uk-card uk-card-small uk-card-default uk-box-shadow-small">
                        <a
                            className="uk-accordion-title uk-padding-small"
                            href="#"
                        >
                            Personal Details
                        </a>
                        <div className="uk-accordion-content uk-padding-small">
                            <div className="uk-flex uk-flex-column">
                                <div
                                    className="uk-grid uk-width-1-1 uk-children-width-1-4 uk-children-width-1-1@s"
                                    data-uk-grid
                                >
                                    <div>
                                        <span className="uk-text-bold">
                                            Initials:
                                        </span>
                                        {this.renderSpanOrInput("initials")}
                                    </div>
                                    <div>
                                        <span className="uk-text-bold">
                                            Company/Business Email:
                                        </span>
                                        {this.renderSpanOrInput("email")}
                                    </div>
                                    <div>
                                        <span className="uk-text-bold">
                                            First Name:
                                        </span>
                                        {this.renderSpanOrInput("firstName")}
                                    </div>
                                    <div>
                                        <span className="uk-text-bold">
                                            Last Name:
                                        </span>
                                        {this.renderSpanOrInput("lastName")}
                                    </div>
                                    <div>
                                        <span className="uk-text-bold">
                                            Mobile Number:
                                        </span>
                                        {this.renderSpanOrInput("mobileNumber")}
                                    </div>
                                    <div>
                                        <span className="uk-text-bold">
                                            Registration Date:{" "}
                                        </span>
                                        <span>
                                            {userData.registerTime
                                                ? userData.registerTime
                                                      .split("T")[0]
                                                      .split("-")
                                                      .reverse()
                                                      .join("-")
                                                : ""}
                                        </span>
                                    </div>
                                </div>

                                <div className="uk-margin-top">
                                    <button
                                        className="uk-button uk-button-primary"
                                        onClick={event => this.changeMode()}
                                    >
                                        {this.state.editMode ? "Save" : "Edit"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="uk-open uk-card uk-card-small uk-card-default uk-box-shadow-small">
                        <a
                            className="uk-accordion-title uk-padding-small"
                            href="#"
                        >
                            Purchase Details
                        </a>
                        <div className="uk-accordion-content uk-padding-small">
                            <div
                                className="uk-grid uk-width-1-1 uk-children-width-1-4 uk-children-width-1-1@s"
                                data-uk-grid
                            >
                                <div>
                                    <span className="uk-text-bold">
                                        Base Amount:
                                    </span>
                                    <span>&nbsp;{userData.baseAmount}</span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        Tax Amount:
                                    </span>
                                    <span>&nbsp;{userData.taxAmount}</span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        Total Amount:
                                    </span>
                                    <span>&nbsp;{userData.totalAmount}</span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        Reference Code:
                                    </span>
                                    <span>&nbsp;{userData.referenceCode}</span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        Purchase Date:
                                    </span>
                                    <span>
                                        &nbsp;
                                        {userData.purchaseDate
                                            ? userData.purchaseDate
                                                  .split("T")[0]
                                                  .split("-")
                                                  .reverse()
                                                  .join("-")
                                            : ""}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <div>
                    <div className="uk-flex uk-flex-center uk-flex-between">
                        {this.state.isActive && (
                            <button
                                className="uk-button-primary uk-width-1-3@m uk-width-1-4@l uk-text-large"
                                onClick={this.sendDeactivation.bind(this)}
                            >
                                Deactivate
                            </button>
                        )}
                        <button
                            className={`uk-button-primary uk-width-1-3@m uk-width-1-4@l uk-text-large uk-button-danger ${
                                this.props.hasDelete ? "" : "uk-hidden"
                            }`}
                            data-uk-toggle="target: #modal-overflow-delete"
                        >
                            {this.props.hasDelete ? "Delete" : ""}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
