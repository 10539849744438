import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class Table extends Component {
    renderButtons(rowId) {
        if (!this.props.rowButtons) {
            return null;
        }

        const buttonCells = this.props.rowButtons.map((el, index) => {
            return (
                <div>
                    <button
                        {...el}
                        onClick={e =>
                            typeof el.onClick == "function" && el.onClick(rowId)
                        }
                        data-uk-icon={el.icon ? `icon: ${el.icon}` : null}
                        data-uk-tooltip={el.tooltip ? `${el.tooltip}` : null}
                    >
                        {el.icon ? null : el.title}
                    </button>
                </div>
            );
        });
        return <td className="uk-flex uk-flex-wrap">{buttonCells}</td>;
    }

    renderHeader() {
        if (!this.props.headers) {
            return null;
        }

        const header = this.props.headers.map(el => <td>{el}</td>);
        if (!this.props.hideArrow) {
            header.push(
                <td>
                    <button
                        className="uk-button uk-button-primary uk-border-rounded"
                        style={{ backgroundColor: "blueviolet" }}
                        onClick={() => this.props.history.goBack()}
                        uk-icon="icon: arrow-left"
                    ></button>
                </td>
            );
        } else {
            header.push(
                <td>
                    <button
                        className="uk-button uk-button-primary uk-border-rounded uk-hidden"
                        style={{ backgroundColor: "blueviolet" }}
                        onClick={() => this.props.history.goBack()}
                        uk-icon="icon: arrow-left"
                    ></button>
                </td>
            );
        }

        return (
            <thead>
                <tr style={{ color: "#1058a2" }}>{header}</tr>
            </thead>
        );
    }

    renderBody() {
        if (!this.props.body) {
            return null;
        }

        const body = this.props.body.map((el, index) => {
            if (!this.props.useColor) {
                return (
                    <tr>
                        {el.map(cell => (
                            <td>{cell}</td>
                        ))}
                        {this.renderButtons(index)}
                    </tr>
                );
            } else {
                if (el.includes("User")) {
                    return (
                        <tr>
                            {el.map(cell => (
                                <td>{cell}</td>
                            ))}
                            {this.renderButtons(index)}
                        </tr>
                    );
                } else {
                    return (
                        <tr>
                            {el.map(cell => (
                                <td className="uk-text-success">{cell}</td>
                            ))}
                            {this.renderButtons(index)}
                        </tr>
                    );
                }
            }
        });

        return <tbody>{body}</tbody>;
    }

    render() {
        return (
            <table className="uk-table uk-table-divider">
                {this.renderHeader()}
                {this.renderBody()}
            </table>
        );
    }
}
export default withRouter(Table);
