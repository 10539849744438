import React, { Component } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { DateRangePicker } from "react-dates";
import * as tools from "../../../tools";
import moment from "moment";
import shortId from "shortid";
import { Link } from "react-router-dom";
import logo from "./logo.png";

/**Class search form */
class FormCustom extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productionFrom: null,
            productionTo: null,
            issueFrom: null,
            issueTo: null,
            expiryFrom: null,
            expiryTo: null,
            transactionTo: null,
            transactionFrom: null,
            ownConsumption: "false",
            productionInstallationId: "",
            basketId: "",
            technologyCodes: [],
            technologySelected: "",
            dateType: "transaction",
            showForm: false,
            transactionType: this.props.transactionType,
            productCode: "",
            productCodeList: [],
            countryList: [],
            foreignList: [],
            country: "All Countries",
            startDate: moment().subtract(2, "year"),
            endDate: moment(),
            focusedInput: null,
            userAccountList: [],
            tradingAccountList: [],
            disableAccounts: true,
            tradingAccount: "All trading A/c",
            userAccount: "All trading A/c",
            hasInvalid: false,
            tradingAccountOriginalList: [],
            userAccountOriginalList: [],
            showRelations: props.showRelations,
            relationAccountList: [],
            relationAccount: "All Accounts",
            relationNameList: [],
            relationName: "All Relations",
            certificateTypeList: [],
            certificateType: "All Certificates",
            citiesList: [],
            city: "All Cities",
            operationalDateList: [],
            operationalDate: "All Operationals",
            foreignCountry: "With Foreign"
        };

        this.updating = {
            technology: false,
            userAccount: false,
            tradingAccount: false,
            country: false,
            relationAccount: false,
            relationName: false,
            productCode: false,
            certificateType: false,
            foreign: false,
            city: false,
            operationalDate: false
        };
    }

    underUpdate() {
        const keys = Object.keys(this.updating);
        for (let i = 0, n = keys.length; i < n; i++) {
            if (this.updating[keys[i]]) {
                return true;
            }
        }
        return false;
    }

    /**
     * handles form parameters inputs
     * sendRequest:true send search request
     * @param {event} event
     * @param {string} fieldName
     * @param {bool} sendRequest
     */
    getTechnology(requestBody) {
        this.updating.technology = true;
        return tools.axiosInstance
            .post("/report/technology-list", requestBody)
            .then(res => {
                let tempSearch = ["All Technologies"];
                tempSearch = tempSearch.concat(res.data.data);
                const shouldUpdateTech = tempSearch.includes(
                    this.state.technologySelected
                );
                let tech = tempSearch.map(el => ({
                    value: el,
                    showName: el
                }));
                shouldUpdateTech
                    ? this.setState(
                          { technologyCodes: tech },
                          () => delete this.updating.technology
                      )
                    : this.setState(
                          {
                              technologyCodes: tech,
                              technologySelected: tech[0].value
                          },
                          () => delete this.updating.technology
                      );
            })
            .catch(() => delete this.updating.technology);
    }

    getForeignList(requestBody) {
        this.updating.foreign = true;
        const foreign = [
            { value: "With Foreign", showName: "With Foreign" },
            { value: "Only Foreign", showName: "Only Foreign" },
            { value: "Only Local", showName: "Only Local" }
        ];
        return tools.axiosInstance
            .post("/report/foreign-list", requestBody)
            .then(res => {
                const foreign =
                    res.data.data && res.data.data.length
                        ? [
                              {
                                  value: "With Foreign",
                                  showName: "With Foreign"
                              },
                              {
                                  value: "Only Foreign",
                                  showName: "Only Foreign"
                              },
                              {
                                  value: "Only Local",
                                  showName: "Only Local"
                              }
                          ]
                        : [{ value: "Only Local", showName: "Only Local" }];
                res.data.data.map(el => {
                    foreign.push({
                        value: `With ${el}`,
                        showName: `With ${el}`
                    });
                    foreign.push({
                        value: `Only ${el}`,
                        showName: `Only ${el}`
                    });
                });

                // if (res.data.data && res.data.data.length === 1) {
                //     this.setState({
                //         foreignList: foreign,
                //         foreignCountry: foreign[4].value
                //     });
                //     // return;
                // }
                const shouldUpdate = foreign
                    .map(el => el.value)
                    .includes(this.state.foreignCountry);
                shouldUpdate
                    ? this.setState(
                          { foreignList: foreign },
                          () => delete this.updating.foreign
                      )
                    : this.setState(
                          {
                              foreignList: foreign,
                              foreignCountry: foreign[0].value
                          },
                          () => delete this.updating.foreign
                      );
            })
            .catch(err => {
                this.setState(
                    { foreignList: foreign },
                    () => delete this.updating.foreign
                );
            });
    }

    getProductList(requestBody) {
        this.updating.productCode = true;
        return tools.axiosInstance
            .post("/report/product-code-list", requestBody)
            .then(res => {
                let tempSearch = [];
                tempSearch = tempSearch.concat(res.data.data);
                const shouldUpdate = tempSearch
                    .map(el => el.key)
                    .includes(this.state.productCode);
                let tech = tempSearch.map(el => ({
                    value: el.key,
                    showName: `${el.key}`
                }));
                shouldUpdate
                    ? this.setState(
                          { productCodeList: tech },
                          () => delete this.updating.productCode
                      )
                    : this.setState(
                          {
                              productCodeList: tech,
                              productCode: tech[0].value
                          },
                          () => delete this.updating.productCode
                      );
            })
            .catch(err => {
                delete this.updating.productCode;
            });
    }

    getRelationAccount(requestBody) {
        this.updating.relationAccount = true;
        return tools.axiosInstance
            .post("/report/relations-account", requestBody)
            .then(res => {
                let tempSearch = [
                    {
                        relationAccount: "All Accounts"
                    }
                ];
                tempSearch = tempSearch.concat(res.data.data);
                let relationAccountList = tempSearch.map(
                    el => el.relationAccount
                );
                relationAccountList = [...new Set(relationAccountList)];
                this.setState(
                    { relationAccountList },
                    () => delete this.updating.relationAccount
                );
            })
            .catch(err => {
                delete this.updating.relationAccount;
            });
    }

    getRelationName(requestBody) {
        this.updating.relationName = true;
        return tools.axiosInstance
            .post("/report/relations", {
                ...requestBody,
                relationName: undefined
            })
            .then(res => {
                let tempSearch = [
                    {
                        relationName: "All Relations"
                    }
                ];
                // tempSearch = tempSearch.concat(res.data.data);
                tempSearch = res.data.data;
                let relationNameList = tempSearch.map(el =>
                    el.relationName === "NA" || !el.relationName
                        ? "Other"
                        : el.relationName
                );
                relationNameList = [...new Set(relationNameList)];
                relationNameList = ["All Relations"].concat(
                    relationNameList.sort((a, b) =>
                        a < b ? -1 : a > b ? 1 : 0
                    )
                );
                this.setState(
                    { relationNameList },
                    () => delete this.updating.relationName
                );
            })
            .catch(err => {
                delete this.updating.relationName;
            });
    }

    getCertificateType(requestBody) {
        this.updating.certificateType = true;
        return tools.axiosInstance
            .post("/report/certificate-type", requestBody)
            .then(res => {
                let tempSearch = ["All Certificates"];
                tempSearch = tempSearch.concat(res.data.data);
                const shouldUpdate =
                    tempSearch.includes(this.state.certificateType) &&
                    this.state.certificateType !== tempSearch[0];
                let certificateTypeList = [...new Set(tempSearch)];
                shouldUpdate
                    ? this.setState(
                          { certificateTypeList },
                          () => delete this.updating.certificateType
                      )
                    : this.setState(
                          {
                              certificateTypeList,
                              certificateType: certificateTypeList[0]
                          },
                          () => delete this.updating.certificateType
                      );
            })
            .catch(err => {
                delete this.updating.certificateType;
            });
    }

    getCity(requestBody) {
        this.updating.city = true;
        return tools.axiosInstance
            .post("/report/city", requestBody)
            .then(res => {
                let tempSearch = ["All Cities"];
                tempSearch = tempSearch.concat(res.data.data);
                let citiesList = tempSearch;
                const shouldUpdate =
                    tempSearch.includes(this.state.city) &&
                    this.state.city !== tempSearch[0];
                shouldUpdate
                    ? this.setState(
                          { citiesList },
                          () => delete this.updating.city
                      )
                    : this.setState(
                          { citiesList, city: citiesList[0] },
                          () => delete this.updating.city
                      );
            })
            .catch(err => {
                delete this.updating.city;
            });
    }

    getOperationalDate(requestBody) {
        this.updating.operationalDate = true;
        return tools.axiosInstance
            .post("/report/operational-date", requestBody)
            .then(res => {
                let tempSearch = ["All Operationals"];
                if (res.data.data)
                    tempSearch = tempSearch.concat(
                        res.data.data.sort().reverse()
                    );
                let operationalDateList = tempSearch;
                const shouldUpdate =
                    operationalDateList.includes(this.state.operationalDate) &&
                    this.state.operationalDate !== tempSearch[0];
                shouldUpdate
                    ? this.setState(
                          { operationalDateList },
                          () => delete this.updating.operationalDate
                      )
                    : this.setState(
                          {
                              operationalDateList,
                              operationalDate: operationalDateList[0]
                          },
                          () => delete this.updating.operationalDate
                      );
            })
            .catch(err => {
                delete this.updating.operationalDate;
            });
    }

    async handleChange(event, fieldName, sendRequest) {
        let value = event.target.value;
        if (event.target.type === "select-one") {
            event.target.blur();
        }

        let newObject = {};
        newObject[fieldName] = value;
        if (fieldName.toLowerCase() === "country" && value === "") {
            newObject.disableAccounts = true;
            newObject.tradingAccount = "All Trading A/c";
            newObject.userAccount = "All User A/c";
            newObject.technologySelected = "All Technologies";
            newObject.foreignCountry = "With Foreign";
        }
        if (fieldName.toLowerCase() === "country" && value !== "") {
            newObject.disableAccounts = false;
            newObject.technologySelected = "All Technologies";
            newObject.foreignCountry = "With Foreign";
        }

        if (fieldName === "foreignCountry") {
            newObject.technologySelected = "All Technologies";
        }

        if (fieldName === "relationName") {
            newObject.relationAccount = "All Accounts";
        }

        if (fieldName === "relationName" || fieldName === "relationAccount") {
            //newObject.relationAccount = "All Accounts";
            newObject.foreignCountry = "With Foreign";
        }

        this.setState(newObject, async () => {
            if (typeof this.props.beingSubmitted === "function") {
                this.props.beingSubmitted();
            }

            const m = Date.now();
            let requestBody = this.modifyDataBeforeSendSearchRequest();
            await this.getRelationName(requestBody);
            requestBody = this.modifyDataBeforeSendSearchRequest();
            await this.getRelationAccount(requestBody);
            requestBody = this.modifyDataBeforeSendSearchRequest();
            await this.getProductList(requestBody);
            requestBody = this.modifyDataBeforeSendSearchRequest();
            await this.getCertificateType(requestBody);
            requestBody = this.modifyDataBeforeSendSearchRequest();
            await this.getTechnology(requestBody);
            requestBody = this.modifyDataBeforeSendSearchRequest();
            await this.getForeignList(requestBody);
            requestBody = this.modifyDataBeforeSendSearchRequest();
            await this.getCity(requestBody);
            requestBody = this.modifyDataBeforeSendSearchRequest();
            await this.getOperationalDate(requestBody);

            if (sendRequest) {
                const interval = setInterval(() => {
                    console.log("to be sent");
                    if (!this.underUpdate()) {
                        clearInterval(interval);
                        console.log("time taken", Date.now() - m);
                        this.submitFor(event, true);
                        console.log("sent");
                    }
                }, 100);
            }
        });
    }

    buildForeignList() {
        // console.log("buildForeignList", this.state.foreignList);
        return (
            <select
                className="uk-select uk-form-small uk-form-width-small"
                onChange={event =>
                    this.handleChange(event, "foreignCountry", true)
                }
                value={this.state.foreignCountry}
            >
                {this.state.foreignList.map((option, index) => (
                    <option key={`option-${index}`} value={option.value}>
                        {option.showName}
                    </option>
                ))}
            </select>
        );
    }

    buildRelationName() {
        if (!this.props.showRelations) {
            return null;
        }
        return (
            <select
                className="uk-select uk-form-small uk-form-width-small"
                onChange={event =>
                    this.handleChange(event, "relationName", true)
                }
                value={this.state.relationName}
            >
                {this.state.relationNameList.map((option, index) => (
                    <option key={`option-${index}`} value={option}>
                        {option}
                    </option>
                ))}
            </select>
        );
    }

    buildRelationAccount() {
        if (!this.props.showRelations) {
            return null;
        }
        return (
            <select
                className="uk-select uk-form-small uk-form-width-small"
                onChange={event =>
                    this.handleChange(event, "relationAccount", true)
                }
                value={this.state.relationAccount}
            >
                {this.state.relationAccountList.map((option, index) => (
                    <option key={`option-${index}`} value={option}>
                        {option}
                    </option>
                ))}
            </select>
        );
    }

    buildCertificateList() {
        return (
            <select
                className="uk-select uk-form-small uk-form-width-small"
                onChange={event =>
                    this.handleChange(event, "certificateType", true)
                }
                value={this.state.certificateType}
            >
                {this.state.certificateTypeList.map((option, index) => (
                    <option key={`option-${index}`} value={option}>
                        {option}
                    </option>
                ))}
            </select>
        );
    }

    buildCityList() {
        return (
            <select
                className="uk-select uk-form-small uk-form-width-small"
                onChange={event => this.handleChange(event, "city", true)}
                value={this.state.city}
            >
                {this.state.citiesList.map((option, index) => (
                    <option key={`option-${index}`} value={option}>
                        {option}
                    </option>
                ))}
            </select>
        );
    }

    buildOperationalList() {
        return (
            <select
                className="uk-select uk-form-small uk-form-width-small"
                onChange={event =>
                    this.handleChange(event, "operationalDate", true)
                }
                value={this.state.operationalDate}
            >
                {this.state.operationalDateList.map((option, index) => (
                    <option key={`option-${index}`} value={option}>
                        {option}
                    </option>
                ))}
            </select>
        );
    }

    buildTechnologyList() {
        return (
            <select
                className="uk-select uk-form-small uk-form-width-small"
                onChange={event =>
                    this.handleChange(event, "technologySelected", true)
                }
                value={this.state.technologySelected}
            >
                {this.state.technologyCodes.map((option, index) => (
                    <option key={`option-${index}`} value={option.value}>
                        {option.showName}
                    </option>
                ))}
            </select>
        );
    }

    buildUserAccountList() {
        const { country, userAccount, userAccountOriginalList } = this.state;
        let correct = true;
        if (
            country &&
            userAccountOriginalList.length &&
            userAccount &&
            !userAccount.includes("All")
        ) {
            const ta = userAccountOriginalList.filter(
                el =>
                    el.accountId === userAccount.split(" ")[0] &&
                    el.country === country
            );
            correct = ta.length === 1;
        }
        return (
            <select
                className={`uk-select uk-form-small uk-form-width-small ${
                    correct ? "" : "uk-text-danger"
                }`}
                onChange={event =>
                    this.handleChange(event, "userAccount", true)
                }
                disabled={this.state.disableAccounts}
                value={this.state.userAccount}
            >
                {this.state.userAccountList.map((option, index) => (
                    <option key={`option-${index}`} value={option.value}>
                        {option.showName}
                    </option>
                ))}
            </select>
        );
    }

    buildTradingAccountList() {
        const {
            country,
            tradingAccount,
            tradingAccountOriginalList
        } = this.state;
        let correct = true;
        if (
            country &&
            tradingAccountOriginalList.length &&
            tradingAccount &&
            !tradingAccount.includes("All")
        ) {
            const ta = tradingAccountOriginalList.filter(
                el =>
                    el.accountId === tradingAccount.split(" ")[0] &&
                    el.country === country
            );
            correct = ta.length === 1;
        }

        return (
            <select
                className={`uk-select uk-form-small uk-form-width-small ${
                    correct ? "" : "uk-text-danger"
                }`}
                onChange={event =>
                    this.handleChange(event, "tradingAccount", true)
                }
                disabled={this.state.disableAccounts}
                value={this.state.tradingAccount}
            >
                {this.state.tradingAccountList.map((option, index) => (
                    <option key={`option-${index}`} value={option.value}>
                        {option.showName}
                    </option>
                ))}
            </select>
        );
    }

    buildProductCodeList() {
        return (
            <select
                className="uk-select uk-form-width-medium"
                onChange={event => this.handleChange(event, "productCode")}
            >
                {this.state.productCodeList.map((option, index) => (
                    <option key={`option-${index}`} value={option.value}>
                        {option.showName}
                    </option>
                ))}
            </select>
        );
    }

    shouldSendSearchRequest(data) {
        if (data.from && data.to) {
            return true;
        }

        if (data.productionFrom && data.productionTo) {
            return true;
        }

        if (data.issueFrom && data.issueTo) {
            return true;
        }

        if (data.transactionFrom && data.transactionTo) {
            return true;
        }

        if (data.expiryFrom && data.expiryTo) {
            return true;
        }

        if (
            data.productionInstallationId ||
            data.technologySelected ||
            data.country
        ) {
            return true;
        }

        return false;
    }
    /**
     * send searchRequest
     * @param {event} event
     */
    submitFor(event, fromComboBox) {
        if (event) event.preventDefault();
        let body = this.modifyDataBeforeSendSearchRequest();
        if (!fromComboBox) {
            if (!this.shouldSendSearchRequest(body)) {
                return;
            }
        }

        if (Object.keys(body)) {
            if (typeof this.props.beingSubmitted === "function") {
                this.props.beingSubmitted();
            }
            if (this.props.disableDateList) {
                body.dateType = "transaction";
            }
            tools.axiosInstance
                .post("/report/search", body)
                .then(res => {
                    if (typeof this.props.submitted === "function") {
                        this.props.submitted(res.data.data, body, fromComboBox);
                    }
                })
                .catch(err => {
                    this.props.submitted(null, body, fromComboBox);
                });
        }
    }

    /**
     * validates data before submitting search
     */
    modifyDataBeforeSendSearchRequest() {
        let data = { ...this.state };
        Object.keys(data).map(
            key => key.toLocaleLowerCase().includes("list") && delete data[key]
        );
        if (data.page) {
            data.from = (data.page - 1) * 100;
            data.to = data.page * 100;
            delete data.page;
        }

        if (data.disableAccounts) {
            delete data.tradingAccount;
            delete data.userAccount;
        }

        if (
            !data.productionFrom ||
            !data.productionTo ||
            data.productionFrom.format("YYYY-MM-DD") ===
                data.productionTo.format("YYYY-MM-DD")
        ) {
            delete data.productionFrom;
            delete data.productionTo;
        }

        if (
            !data.issueFrom ||
            !data.issueTo ||
            data.issueFrom.format("YYYY-MM-DD") ===
                data.issueTo.format("YYYY-MM-DD")
        ) {
            delete data.issueFrom;
            delete data.issueTo;
        }

        if (
            !data.expiryFrom ||
            !data.expiryTo ||
            data.expiryFrom.format("YYYY-MM-DD") ===
                data.expiryTo.format("YYYY-MM-DD")
        ) {
            delete data.expiryFrom;
            delete data.expiryTo;
        }

        if (
            Math.abs(Date.parse(data.startDate) - Date.parse(data.endDate)) <
            2000
        ) {
            delete data.startDate;
            delete data.endDate;
        }

        if (data.operationalDate.includes("All")) {
            delete data.operationalDate;
        }

        if (
            !data.transactionFrom ||
            !data.transactionTo ||
            data.transactionFrom.format("YYYY-MM-DD") ===
                data.transactionTo.format("YYYY-MM-DD")
        ) {
            delete data.transactionFrom;
            delete data.transactionTo;
        }

        if (data.productionInstallationId === "") {
            delete data.productionInstallationId;
        }

        if (
            data.technologySelected === "" ||
            data.technologySelected.includes("All")
        ) {
            delete data.technologySelected;
        }

        if (!data.country || data.country.includes("All Cou")) {
            delete data.country;
        }

        if (!data.tradingAccount || data.tradingAccount.includes("All")) {
            delete data.tradingAccount;
        } else {
            data.tradingAccount = data.tradingAccount.split("_")[0].trim();
        }

        if (!data.userAccount || data.userAccount.includes("All ")) {
            delete data.userAccount;
        } else {
            data.userAccount = data.userAccount.split("_")[0].trim();
        }

        if (!this.props.showRelations || data.relationName.includes("All")) {
            delete data.relationName;
        }

        if (!this.props.showRelations || data.relationAccount.includes("All")) {
            delete data.relationAccount;
        }

        if (data.productCode.includes("All")) {
            delete data.productCode;
        }

        if (data.certificateType.includes("All")) {
            delete data.certificateType;
        }

        if (data.city.includes("All")) {
            delete data.city;
        }

        return data;
    }

    componentDidMount() {
        let filterParameters = this.props.parameters;
        if (!this.props.resetParameters) {
            this.setState(this.props.parameters);
        } else {
            tools.saveDataToBrowser("filter", "");
        }
        const hasInvalid = tools.readDataFromBrowser("hasInvalid");

        if (hasInvalid == null) {
            tools.axiosInstance
                .get("/report/has-invalid-accounts")
                .then(res => {
                    if (!res.data.data[0]) {
                        this.setState({
                            hasInvalid: res.data.data[0]
                        });
                        tools.saveDataToBrowser("hasInvalid", res.data.data[0]);
                    }
                    setTimeout(() => {
                        tools.axiosInstance
                            .get("/report/has-invalid-accounts")
                            .then(res => {
                                this.setState({
                                    hasInvalid: res.data.data[0]
                                });
                                tools.saveDataToBrowser(
                                    "hasInvalid",
                                    res.data.data[0]
                                );
                            })
                            .catch(err => {
                                console.log("report title", err);
                            });
                    }, 10 * 1000);
                })
                .catch(err => {
                    console.log("report title", err);
                });
        } else {
            this.setState({ hasInvalid: hasInvalid === "true" });
        }

        if (this.props.onlyTitle) {
            return;
        }

        const { transactionType } = this.state;
        const requestBody = transactionType ? { transactionType } : {};
        tools.axiosInstance
            .post("/report/technology-list", requestBody)
            .then(res => {
                let tempSearch = ["All Technologies"];
                tempSearch = tempSearch.concat(res.data.data);
                let tech = tempSearch.map(el => ({
                    value: el,
                    showName: el
                }));
                this.setState({ technologyCodes: tech });
            })
            .catch(err => {
                //console.log("form component did mount", err);
            });

        tools.axiosInstance
            .post("/report/relations-account", requestBody)
            .then(res => {
                let tempSearch = [
                    {
                        relationAccount: "All Accounts"
                    }
                ];
                tempSearch = tempSearch.concat(res.data.data);
                let relationAccountList = tempSearch.map(
                    el => el.relationAccount
                );
                relationAccountList = [...new Set(relationAccountList)];
                this.setState({ relationAccountList });
            })
            .catch(err => {
                //console.log("form component did mount", err);
            });

        tools.axiosInstance
            .post("/report/relations", requestBody)
            .then(res => {
                let tempSearch = [
                    {
                        relationName: "All Relations"
                    }
                ];
                tempSearch = res.data.data;
                let relationNameList = tempSearch.map(el =>
                    el.relationName === "NA" || !el.relationName
                        ? "Other"
                        : el.relationName
                );
                relationNameList = [...new Set(relationNameList)];
                relationNameList = ["All Relations"].concat(
                    relationNameList.sort((a, b) =>
                        a < b ? -1 : a > b ? 1 : 0
                    )
                );
                this.setState({ relationNameList });
            })
            .catch(err => {
                //console.log("form component did mount", err);
            });

        tools.axiosInstance
            .post("/report/certificate-type", requestBody)
            .then(res => {
                let tempSearch = ["All Certificates"];
                tempSearch = tempSearch.concat(res.data.data);
                let certificateTypeList = [...new Set(tempSearch)];
                console.log({ certificateTypeList });
                this.setState({ certificateTypeList });
            })
            .catch(err => {
                //console.log("form component did mount", err);
            });

        tools.axiosInstance
            .post("/report/city", requestBody)
            .then(res => {
                let tempSearch = ["All Cities"];
                tempSearch = tempSearch.concat(res.data.data);
                let citiesList = tempSearch;
                this.setState({ citiesList });
            })
            .catch(err => {
                //console.log("form component did mount", err);
            });

        tools.axiosInstance
            .post("/report/operational-date", requestBody)
            .then(res => {
                let tempSearch = ["All Operationals"];
                if (res.data.data)
                    tempSearch = tempSearch.concat(
                        res.data.data.sort().reverse()
                    );
                let operationalDateList = tempSearch;
                this.setState({ operationalDateList });
            })
            .catch(err => {
                //console.log("form component did mount", err);
            });

        const foreign = [
            { value: "With Foreign", showName: "With Foreign" },
            { value: "Only Foreign", showName: "Only Foreign" },
            { value: "Only Local", showName: "Only Local" }
        ];
        tools.axiosInstance
            .post("/report/foreign-list", requestBody)
            .then(res => {
                const foreign =
                    res.data.data && res.data.data.length
                        ? [
                              {
                                  value: "With Foreign",
                                  showName: "With Foreign"
                              },
                              {
                                  value: "Only Foreign",
                                  showName: "Only Foreign"
                              },
                              { value: "Only Local", showName: "Only Local" }
                          ]
                        : [{ value: "Only Local", showName: "Only Local" }];
                res.data.data.map(el => {
                    foreign.push({
                        value: `With ${el}`,
                        showName: `With ${el}`
                    });
                    foreign.push({
                        value: `Only ${el}`,
                        showName: `Only ${el}`
                    });
                });
                this.setState({ foreignList: foreign });
            })
            .catch(err => {
                this.setState({ foreignList: foreign });
            });

        tools.axiosInstance
            .post("/report/product-code-list", requestBody)
            .then(res => {
                let tempSearch = [];
                tempSearch = tempSearch.concat(res.data.data);
                let tech = tempSearch.map(el => ({
                    value: el.key,
                    showName: `${el.key}`
                }));
                console.log({ tempSearch });
                this.setState({ productCodeList: tech });
            })
            .catch(err => {
                console.log("form component did mount", err);
            });

        tools.axiosInstance
            .get("/report/country-list")
            .then(res => {
                let tempSearch = [{ key: "", doc_count: "" }];
                tempSearch = tempSearch.concat(res.data.data.searchResult);
                let countryList = [
                    { value: "", showName: "All Countries" }
                ].concat(
                    res.data.data.map(el => ({
                        value: el.key,
                        showName: `${el.key}`
                    }))
                );
                this.setState({ countryList });
            })
            .catch(err => {
                console.log("form component did mount", err);
            });

        let userAccountList = [{ value: "", showName: "No User A/c" }];
        tools.axiosInstance
            .get("/report/user-account-list")
            .then(res => {
                userAccountList = [
                    { value: "", showName: "All User A/c" }
                ].concat(
                    res.data.data.map(el => ({
                        value: el.account,
                        showName: `${el.accountId} ______ ${el.owner}`
                    }))
                );
                this.setState({
                    userAccountList,
                    userAccountOriginalList: res.data.data
                });
            })
            .catch(err => {
                this.setState({ userAccountList, userAccountOriginalList: [] });
            });

        let tradingAccountList = [{ value: "", showName: "No Trading A/c" }];
        tools.axiosInstance
            .get("/report/trading-account-list")
            .then(res => {
                tradingAccountList = [
                    { value: "", showName: "All Trading A/c" }
                ].concat(
                    res.data.data.map(el => ({
                        value: el.account,
                        showName: `${el.accountId} ______ ${el.owner}`
                    }))
                );
                this.setState({
                    tradingAccountList,
                    tradingAccountOriginalList: res.data.data
                });
            })
            .catch(err => {
                this.setState({
                    tradingAccountList,
                    tradingAccountOriginalList: []
                });
            });
    }

    /**
     * renders input elements
     * @param {string} legend
     * @param {string} placeholder
     * @param {string} variableName
     */
    renderInputElement(legend, placeholder, variableName) {
        if (this.props.onlyTitle) {
            return null;
        }
        return (
            <div className="uk-margin-small-left uk-padding-remove uk-margin-small-top">
                <div className="uk-form-controls">
                    <legend className="uk-legend uk-text-small">
                        {legend}
                    </legend>
                    <input
                        className="uk-input uk-form-width-medium"
                        type="text"
                        onChange={event => {
                            this.handleChange(event, variableName);
                        }}
                        placeholder={placeholder}
                        //value={this.state[variableName]}
                    />
                </div>
            </div>
        );
    }

    /**
     * render date range picker
     * @param {string} legend
     * @param {string} variableName1
     * @param {string} variableName2
     * @param {string} focusVariable
     */
    renderFieldSet(legend, variableName1, variableName2, focusVariable) {
        if (this.props.onlyTitle) {
            return null;
        }
        return (
            <div className="uk-margin-small-left uk-padding-remove uk-margin-small-top">
                <legend className="uk-legend uk-text-small">{legend}</legend>
                <DateRangePicker
                    endDate={this.state[variableName2]}
                    endDateId={`${legend}-endDate`}
                    focusedInput={this.state[focusVariable]}
                    isOutsideRange={this.isOutsideRange}
                    onDatesChange={dates =>
                        this.handleDateChange(
                            dates,
                            variableName1,
                            variableName2
                        )
                    }
                    onFocusChange={focusedInput =>
                        this.handleFocusChange(focusedInput, focusVariable)
                    }
                    startDate={this.state[variableName1]}
                    startDateId={`${legend}-startDate`}
                    small={true}
                    withFullScreenPortal={this.props.fullScreenDate}
                />
            </div>
        );
    }

    /**
     * handle date range picker change event
     */
    handleDateChange = (dates, variableName1, variableName2) => {
        let tempState = {};

        if (dates.startDate) {
            tempState[variableName1] = moment(dates.startDate);
        }

        if (dates.endDate) {
            tempState[variableName2] = moment(dates.endDate);
        }
        this.setState({ ...this.state, ...tempState });
    };

    /**
     * handle date range picker focus event
     */
    handleFocusChange = (focusedInput, stateVar) => {
        let tempState = {};
        tempState[stateVar] = focusedInput;
        return this.setState({ ...tempState });
    };

    isOutsideRange = () => false;

    componentWillReceiveProps(nextProps, nextState) {
        if (nextProps.yearSelectedIndx !== this.state.yearSelectedIndx)
            this.setState({
                yearSelectedIndx: nextProps.yearSelectedIndx
            });
        if (nextProps.showRelations !== this.state.showRelations) {
            this.setState(
                {
                    showRelations: nextProps.showRelations
                },
                () => this.submitFor(null, true)
            );
        }
    }

    createProductCodeList(showSmall) {
        if (this.props.onlyTitle) {
            return null;
        }
        return (
            <select
                // className="uk-select uk-form-small ems-years"
                className={`uk-select uk-form-small uk-form-width-small ${
                    showSmall ? "ems-years" : ""
                }`}
                onChange={event =>
                    this.handleChange(event, "productCode", true)
                }
                value={this.state.productCode}
            >
                {this.state.productCodeList.map(el => (
                    <option value={el.value} key={shortId.generate()}>
                        {el.showName}
                    </option>
                ))}
            </select>
        );
    }

    createCountryList() {
        if (this.props.onlyTitle) {
            return null;
        }

        return (
            <select
                className="uk-select uk-form-small uk-form-width-small"
                onChange={event => this.handleChange(event, "country", true)}
                value={this.state.country}
            >
                {this.state.countryList.map(el => (
                    <option value={el.value} key={shortId.generate()}>
                        {el.showName}
                    </option>
                ))}
            </select>
        );
    }

    createChartTypeList() {
        if (this.props.onlyTitle) {
            return null;
        }
        return (
            <div data-uk-dropdown="pos: bottom-justify">
                <ul className="uk-nav uk-dropdown-nav">
                    <li
                        className="uk-padding-small pointer"
                        onClick={e => this.resetFilter()}
                    >
                        <a href="#" className="uk-link">
                            Reset Search
                        </a>
                    </li>
                    <li className="uk-nav-divider"></li>
                    <li
                        className="uk-padding-small pointer"
                        onClick={e => this.handleChartTypeSelect("Line")}
                    >
                        <a href="#" className="uk-link">
                            Line Chart
                        </a>
                    </li>
                    <li
                        className="uk-padding-small pointer"
                        onClick={e => this.handleChartTypeSelect("Bar")}
                    >
                        <a href="#" className="uk-link">
                            Bar Chart
                        </a>
                    </li>
                    <li className="uk-nav-divider"></li>
                    <li
                        className="uk-padding-small pointer"
                        onTouchStart={event => this.handleDownloadChart(event)}
                        onClick={event => this.handleDownloadChart(event)}
                    >
                        <a href="#" className="uk-link">
                            Download Chart
                        </a>
                    </li>
                    <li
                        className="uk-padding-small pointer"
                        onClick={this.handleDownloadDataClick.bind(this)}
                    >
                        <a href="#" className="uk-link">
                            Download as XLSX
                        </a>
                    </li>
                    <li className="uk-nav-divider"></li>
                    <li className="uk-padding-small pointer">
                        <a
                            className="uk-link"
                            href="mailto:support@ems-soft.com?subject=Feedback"
                        >
                            Send Feedback
                        </a>
                    </li>
                </ul>
            </div>
        );
    }

    createSupReportList() {
        return (
            <div data-uk-dropdown="pos: bottom-right">
                <div
                    className="uk-dropdown-grid uk-flex uk-flex-row"
                    data-uk-grid
                >
                    <div className="uk-flex uk-flex-column">
                        <ul className="uk-nav uk-dropdown-nav">
                            <li className="uk-nav-header">Inventory</li>
                            <li className="pointer">
                                <a href="/#overview" className="uk-link">
                                    {" "}
                                    Overview{" "}
                                </a>
                            </li>
                            <li className="pointer">
                                <a
                                    href="/report/expiration"
                                    className="uk-link"
                                >
                                    {" "}
                                    Expiration{" "}
                                </a>
                            </li>
                            <li className="pointer">
                                <a href="/#relations" className="uk-link">
                                    {" "}
                                    Relations{" "}
                                </a>
                            </li>
                            {/* <li className="uk-nav-divider"></li>
                            <li className="pointer">
                                <a href="/report/begin" className="uk-link">
                                    {" "}
                                    Beginning{" "}
                                </a>
                            </li>
                            <li className="pointer">
                                <a href="/report/mutation" className="uk-link">
                                    {" "}
                                    Net Change{" "}
                                </a>
                            </li>
                            <li className="pointer">
                                <a href="/report/end" className="uk-link">
                                    {" "}
                                    End{" "}
                                </a>
                            </li> */}
                        </ul>
                    </div>
                    <div className="uk-flex uk-flex-column">
                        <ul className="uk-nav uk-dropdown-nav">
                            <li className="uk-nav-header">Additions</li>
                            <li className="pointer">
                                <a href="/report/issue" className="uk-link">
                                    {" "}
                                    Issued{" "}
                                </a>
                            </li>
                            <li className="pointer">
                                <a href="/report/import" className="uk-link">
                                    {" "}
                                    Import{" "}
                                </a>
                            </li>
                            <li className="pointer">
                                <a
                                    href="/report/transferTo"
                                    className="uk-link"
                                >
                                    {" "}
                                    Transferred To{" "}
                                </a>
                            </li>
                            <li className="pointer">
                                <a href="/report/increase" className="uk-link">
                                    {" "}
                                    Increase{" "}
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="uk-flex uk-flex-column">
                        <ul className="uk-nav uk-dropdown-nav">
                            <li className="uk-nav-header">Deductions</li>
                            <li className="pointer">
                                <a
                                    href="/report/transferOut"
                                    className="uk-link"
                                >
                                    {" "}
                                    Transferred Out{" "}
                                </a>
                            </li>
                            <li className="pointer">
                                <a href="/report/cancel" className="uk-link">
                                    {" "}
                                    Cancelled{" "}
                                </a>
                            </li>
                            <li className="pointer">
                                <a
                                    href="/report/cancelledForThirdParty"
                                    className="uk-link"
                                >
                                    {" "}
                                    Cancelled For Third Party{" "}
                                </a>
                            </li>
                            <li className="pointer">
                                <a href="/report/expiry" className="uk-link">
                                    {" "}
                                    Expired{" "}
                                </a>
                            </li>
                            <li className="pointer">
                                <a
                                    href="/report/withdrawal"
                                    className="uk-link"
                                >
                                    {" "}
                                    Withdrawals{" "}
                                </a>
                            </li>
                            <li className="pointer">
                                <a href="/report/export" className="uk-link">
                                    {" "}
                                    Exported{" "}
                                </a>
                            </li>
                            <li className="pointer">
                                <a href="/report/decrease" className="uk-link">
                                    {" "}
                                    Decrease{" "}
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="uk-flex uk-flex-column">
                        <ul className="uk-nav uk-dropdown-nav">
                            <li className="uk-nav-header">Others</li>
                            <li className="pointer">
                                <a
                                    href="/report/issueOwnUse"
                                    className="uk-link"
                                >
                                    {" "}
                                    Issued for Own Use{" "}
                                </a>
                            </li>
                            <li className="pointer">
                                <a
                                    href="/report/cancelledByThirdParty"
                                    className="uk-link"
                                >
                                    {" "}
                                    Cancelled By Third Party{" "}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }

    handleChartTypeSelect(event) {
        if (typeof this.props.handleChartTypeSelect === "function") {
            this.props.handleChartTypeSelect(event);
        }
    }

    /**
     *
     * @param {event} event
     */
    handleDownloadChart(event) {
        // handle the non stopped propagation
        // by saving last request time
        // if the difference > 1 s then download
        if (event.stopPropagation) {
            event.stopPropagation();
        } else {
            if (!this.lastRequestTime) {
                this.lastRequestTime = Date.now();
            } else {
                if (Date.now() - this.lastRequestTime < 1000) {
                    this.lastRequestTime = Date.now();
                    return;
                }
            }
        }

        const canvas = document.getElementsByClassName(
            "chartjs-render-monitor"
        )[0];

        let w = canvas.clientWidth;
        let h = canvas.clientHeight;
        if (w === 0 || h === 0) {
            return;
        }

        let context = canvas.getContext("2d");
        const data = context.getImageData(0, 0, w, h);

        context.beginPath();
        let compositeOperation = context.globalCompositeOperation;
        context.globalCompositeOperation = "destination-over";
        context.fillStyle = "#fff";
        context.fillRect(0, 0, w, h);
        context.globalCompositeOperation = "source-over";
        context.fillStyle = "#6F6A64";
        context.font = "20px proxima bold";
        let chartTitle = this.props.title ? this.props.title : "Operations";
        if (!this.props.disableYearList) {
            chartTitle +=
                " " + this.props.yearList[this.state.yearSelectedIndx - 1];
        }

        context.fillText(chartTitle, 10, 15);
        const image = canvas.toDataURL("image/png;base64");

        context.globalCompositeOperation = compositeOperation;
        context.putImageData(data, 0, 0);
        const link = document.createElement("a");
        link.download = `EEMS-Chart.png`;
        link.href = image;
        link.click();
    }

    /**
     * call the download function
     */
    handleDownloadDataClick() {
        if (typeof this.props.handleDownloadDataClick === "function") {
            this.props.handleDownloadDataClick();
        }
    }

    /**
     *
     * @param {event} event
     */
    handleDropDownYearSelectChange(event) {
        event.target.blur();
        if (typeof this.props.handleDropDownYearSelectChange === "function") {
            this.props.handleDropDownYearSelectChange(event);
        }
        this.setState({ yearSelectedIndx: event.target.value });
    }

    createDropDownYearList() {
        if (this.props.onlyTitle) {
            return null;
        }
        let yearListOptions = this.props.yearList.map((el, index) => (
            <option value={index + 1} key={`year-div-list-${index}`}>
                {el}
            </option>
        ));
        return (
            <select
                className="uk-select uk-form-small ems-years"
                onChange={event => this.handleDropDownYearSelectChange(event)}
                value={this.state.yearSelectedIndx}
                disabled={this.props.disableYearList}
            >
                {yearListOptions}
            </select>
        );
    }

    createPageList() {
        if (!this.props.dataLength || this.props.onlyTitle) {
            return null;
        }
        let pageCount = Math.ceil(this.props.dataLength / 100);
        let pages = [];
        for (let i = 0; i < pageCount; i++) {
            pages[i] = i + 1;
        }
        let pageList = pages.map((el, index) => (
            <option value={index + 1} key={`page-div-list-${index}`}>
                {"p." + el}
            </option>
        ));
        return (
            <select
                // className="uk-select uk-form-small ems-years"
                className="uk-select uk-form-small uk-form-width-small"
                onChange={event => {
                    this.setState({ page: event.target.value }, event => {
                        this.submitFor(event, true);
                    });
                }}
                value={this.state.page ? this.state.page : 1}
            >
                {pageList}
            </select>
        );
    }

    resetFilter() {
        window.location.reload();
    }

    searchButtonClick() {
        this.setState({ openForm: !this.state.openForm }, () => {
            !!this.props.searchClicked &&
                this.props.searchClicked(this.state.openForm);
        });
    }

    render() {
        let yearList = null;
        if (this.props.yearList) {
            yearList = this.createDropDownYearList();
        }

        return (
            <React.Fragment>
                <div
                    className="uk-card-header uk-flex uk-flex-between uk-padding-small uk-padding-remove-horizontal uk-visible"
                    style={{ borderBottom: "none !important" }}
                >
                    <div className="uk-width1-1 uk-flex uk-flex-wrap uk-flex-between">
                        <div className="uk-row uk-visible@m uk-flex uk-flex-column">
                            <div>
                                <figure className="ems-logo uk-margin-remove-bottom uk-margin-small-right uk-border-circle">
                                    <Link to="/">
                                        <img src={logo} alt="" />
                                    </Link>
                                </figure>
                            </div>
                            <div className="uk-flex uk-flex-row">
                                <h3 className="uk-card-title uk-margin-remove">
                                    {this.props.title
                                        ? this.props.title
                                        : "Operations"}
                                </h3>
                                <span
                                    className="pointer uk-icon"
                                    uk-icon="more-vertical"
                                    style={{ marginTop: "7px" }}
                                ></span>
                                {this.createSupReportList()}
                                {this.state.hasInvalid && (
                                    <span
                                        className="uk-text-danger"
                                        data-uk-icon="icon: warning"
                                        data-uk-tooltip="title: One or more of your Trading or User A/c (s) are invalid"
                                    />
                                )}
                                {this.props.onlyTitle === true ? null : (
                                    <h6 className="uk-margin-small-top">
                                        &nbsp; (
                                        {this.props.disableDateList
                                            ? "expiry"
                                            : this.state.dateType.replace(
                                                  /_/g,
                                                  " "
                                              )}{" "}
                                        date)
                                    </h6>
                                )}
                            </div>
                        </div>

                        <div
                            className="uk-row uk-margin-remove uk-grid-margin uk-flex uk-flex-between"
                            // style={{
                            //     width:
                            //         this.props.dataLength &&
                            //         this.props.dataLength > 100
                            //             ? "550px"
                            //             : "520px"
                            // }}
                        >
                            {this.props.onlyTitle === true ? null : (
                                <>
                                    <div className="uk-flex uk-flex-middle uk-flex-right uk-flex-row uk-flex-left@s uk-visible@s">
                                        <div className="uk-flex uk-flex-column">
                                            <div className="uk-flex uk-flex-row uk-margin-small-bottom  uk-flex-wrap">
                                                <div className="uk-margin-small-right">
                                                    <div className="uk-form-controls">
                                                        {this.createCountryList()}
                                                    </div>
                                                </div>
                                                <div className="uk-margin-small-right">
                                                    <div className="uk-form-controls">
                                                        {this.buildTradingAccountList()}
                                                    </div>
                                                </div>
                                                <div className="uk-margin-small-right">
                                                    <div className="uk-form-controls">
                                                        {this.buildUserAccountList()}
                                                    </div>
                                                </div>
                                                {this.props.showRelations && (
                                                    <div className="uk-margin-small-right">
                                                        <div className="uk-form-controls">
                                                            {this.buildRelationName()}
                                                        </div>
                                                    </div>
                                                )}
                                                {this.props.showRelations && (
                                                    <div className="uk-margin-small-right">
                                                        <div className="uk-form-controls">
                                                            {this.buildRelationAccount()}
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="uk-margin-small-right">
                                                    <div className="uk-form-controls">
                                                        {this.createProductCodeList(
                                                            this.props
                                                                .showRelations
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="uk-margin-small-right">
                                                    <div className="uk-form-controls">
                                                        {this.buildCertificateList()}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="uk-flex uk-flex-row  uk-flex-wrap">
                                                {this.props.dataLength &&
                                                this.props.dataLength > 100 ? (
                                                    <div
                                                        className="uk-margin-small-right"
                                                        style={{
                                                            visibility:
                                                                "hidden",
                                                            display: "none"
                                                        }}
                                                    >
                                                        {this.createPageList()}
                                                    </div>
                                                ) : null}

                                                <div className="uk-margin-small-right">
                                                    <div className="uk-form-controls">
                                                        {this.buildForeignList()}
                                                    </div>
                                                </div>
                                                <div className="uk-margin-small-right">
                                                    <div className="uk-form-controls">
                                                        {this.buildTechnologyList()}
                                                    </div>
                                                </div>
                                                <div className="uk-margin-small-right">
                                                    <div className="uk-form-controls">
                                                        {this.buildCityList()}
                                                    </div>
                                                </div>
                                                <div className="uk-margin-small-right">
                                                    <div className="uk-form-controls">
                                                        {this.buildOperationalList()}
                                                    </div>
                                                </div>

                                                <div className="uk-margin-small-right">
                                                    <div className="uk-form-controls">
                                                        <select
                                                            className="uk-select uk-form-small uk-form-width-small"
                                                            onChange={event =>
                                                                this.handleChange(
                                                                    event,
                                                                    "dateType",
                                                                    true
                                                                )
                                                            }
                                                            value={
                                                                this.props
                                                                    .disableDateList
                                                                    ? "expiry"
                                                                    : this.state
                                                                          .dateType
                                                            }
                                                            disabled={
                                                                this.props
                                                                    .disableDateList
                                                            }
                                                        >
                                                            <option value="transaction">
                                                                Transaction
                                                            </option>
                                                            <option value="expiry">
                                                                Expiry
                                                            </option>
                                                            <option value="issue">
                                                                Issue
                                                            </option>
                                                            <option value="production_end">
                                                                Production End
                                                            </option>
                                                            <option value="production_start">
                                                                Production Start
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="uk-margin-small-right">
                                                    {yearList}
                                                </div>
                                                {this.props.dataLength &&
                                                this.props.dataLength > 100 ? (
                                                    <div className="uk-margin-small-right">
                                                        <div className="uk-form-controls">
                                                            {this.createPageList()}
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="uk-flex uk-flex-column uk-flex-right">
                                            <div className="uk-margin-small-bottom">
                                                <span
                                                    uk-icon="icon: search; ratio: 1.1"
                                                    className="pointer"
                                                    onClick={() =>
                                                        this.searchButtonClick()
                                                    }
                                                ></span>
                                            </div>
                                            <div className="uk-margin-small">
                                                <span
                                                    className="pointer"
                                                    uk-icon="icon: more-vertical; ratio: 1.1"
                                                ></span>
                                                {this.createChartTypeList()}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                        {this.props.onlyTitle === true ? null : (
                            <div
                                className="mobile-vertical uk-margin-remove"
                                data-uk-grid
                            >
                                <div className="uk-row uk-width-1-1 uk-flex uk-flex-row uk-first-column uk-grid-collapse uk-padding-remove uk-margin-small-top">
                                    <div className="uk-margin-small-right">
                                        {this.createCountryList()}
                                    </div>
                                </div>
                                <div className="uk-row uk-width-1-1 uk-flex uk-flex-row uk-first-column uk-grid-collapse uk-padding-remove uk-margin-small-top">
                                    <div className="uk-margin-small-right">
                                        {this.buildTradingAccountList()}
                                    </div>
                                    <div className="uk-margin-small-right">
                                        {this.buildUserAccountList()}
                                    </div>

                                    {this.props.showRelations && (
                                        <div className="uk-margin-small-right">
                                            <div className="uk-form-controls">
                                                {this.buildRelationName()}
                                            </div>
                                        </div>
                                    )}

                                    {this.props.showRelations && (
                                        <div className="uk-margin-small-right">
                                            <div className="uk-form-controls">
                                                {this.buildRelationAccount()}
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className="uk-row uk-width-1-1 uk-flex uk-flex-row uk-first-column uk-grid-collapse uk-padding-remove uk-margin-small-top">
                                    <div className="uk-margin-small-right">
                                        <div className="uk-form-controls">
                                            {this.createProductCodeList(
                                                this.props.showRelations
                                            )}
                                        </div>
                                    </div>

                                    <div className="uk-margin-small-right">
                                        <div className="uk-form-controls">
                                            {this.buildCertificateList()}
                                        </div>
                                    </div>
                                    <div className="uk-margin-small-right">
                                        <div className="uk-form-controls">
                                            {this.buildForeignList()}
                                        </div>
                                    </div>

                                    <div className="uk-margin-small-right">
                                        <div className="uk-form-controls">
                                            {this.buildTechnologyList()}
                                        </div>
                                    </div>

                                    <div className="uk-margin-small-right">
                                        <div className="uk-form-controls">
                                            {this.buildCityList()}
                                        </div>
                                    </div>

                                    <div className="uk-margin-small-right">
                                        <div className="uk-form-controls">
                                            {this.buildOperationalList()}
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-row uk-width-1-1 uk-flex uk-flex-row uk-first-column uk-grid-collapse uk-padding-remove uk-margin-small-top">
                                    <div className="uk-margin-small-right">
                                        {yearList}
                                    </div>
                                    <div className="uk-margin-small-right">
                                        <div className="uk-form-controls">
                                            <select
                                                className="uk-select uk-form-small uk-form-width-small"
                                                onChange={event =>
                                                    this.handleChange(
                                                        event,
                                                        "dateType",
                                                        true
                                                    )
                                                }
                                                value={this.state.dateType}
                                            >
                                                <option value="transaction">
                                                    Transaction Date
                                                </option>
                                                <option value="expiry">
                                                    Expiry Date
                                                </option>
                                                <option value="issue">
                                                    Issue Date
                                                </option>
                                                <option value="production">
                                                    Production Date
                                                </option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="">
                                        <span
                                            className="pointer"
                                            uk-icon="more-vertical"
                                        ></span>
                                        {this.createChartTypeList()}
                                    </div>
                                </div>

                                <div className="uk-row uk-width-1-1 uk-flex uk-flex-row uk-first-column uk-grid-collapse uk-padding-remove uk-margin-small-top">
                                    <div className="uk-margin-small-right">
                                        {this.props.dataLength &&
                                        this.props.dataLength > 100 ? (
                                            <div className="uk-margin-small-right">
                                                {this.createPageList()}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {this.props.onlyTitle === true ? null : (
                    <div className="uk-visible">
                        <ul
                            data-uk-accordion
                            className="uk-accordion"
                            style={{ height: "auto", margin: 0 }}
                        >
                            <div
                                className="ems-controller uk-margin-small-top uk-margin-remove uk-visible uk-flex uk-flex-wrap uk-flex-wrap-around"
                                aria-hidden={this.state.openForm}
                                id="search-ui"
                                hidden={!this.state.openForm}
                            >
                                {this.renderInputElement(
                                    "GLN/EAN",
                                    "GLN/EAN",
                                    "productionInstallationId"
                                )}
                                {this.renderInputElement(
                                    "Basket",
                                    "Basket",
                                    "basketId"
                                )}
                                {this.props.onlyTitle === true ? null : (
                                    <div className="uk-margin-small-left uk-padding-remove uk-margin-small-top">
                                        <div className="uk-form-controls">
                                            <legend className="uk-legend uk-text-small">
                                                Certificate
                                            </legend>
                                            <div className="uk-flex uk-flex-row">
                                                <input
                                                    className="uk-input uk-form-width-medium"
                                                    type="text"
                                                    onChange={event =>
                                                        this.handleChange(
                                                            event,
                                                            "certificateStartId"
                                                        )
                                                    }
                                                    placeholder="Start Id"
                                                />
                                                <input
                                                    className="uk-input uk-form-width-medium uk-margin-small-left"
                                                    type="text"
                                                    onChange={event =>
                                                        this.handleChange(
                                                            event,
                                                            "certificateEndId"
                                                        )
                                                    }
                                                    placeholder="End Id"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {this.renderFieldSet(
                                    "Production Date",
                                    "productionFrom",
                                    "productionTo",
                                    "productionFocus"
                                )}
                                {this.renderFieldSet(
                                    "Issue Date",
                                    "issueFrom",
                                    "issueTo",
                                    "issueFocus"
                                )}
                                {this.renderFieldSet(
                                    "Expiry Date",
                                    "expiryFrom",
                                    "expiryTo",
                                    "expiryFocus"
                                )}
                                {this.renderFieldSet(
                                    "Transaction Date",
                                    "transactionFrom",
                                    "transactionTo",
                                    "transactionFocus"
                                )}

                                <div className="uk-margin uk-margin-small-left uk-padding-remove">
                                    <div className="uk-form-controls">
                                        <legend className="uk-legend"></legend>
                                        <input
                                            type="submit"
                                            value={
                                                this.props.submit || "Search"
                                            }
                                            className="uk-button uk-button-small uk-button-primary"
                                            onClick={this.submitFor.bind(this)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </ul>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

export default FormCustom;
