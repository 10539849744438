import React, { Component } from "react";
import Toolbar1 from "../toolbar/toolbar";
import ReportTitle from "../report/reportTitle/reportTitle";
import Form from "../report/form/form";
import Uikit from "uikit";
import * as tools from "../../tools";
import { Link, withRouter } from "react-router-dom";

class Delete extends Component {
    state = {
        isDeleted: false,
        failed: false,
        //noData: true,
        requestSent: false
    };

    noRef = React.createRef();

    goBack() {
        this.props.history.push("/");
    }

    deleteClick() {
        if (this.state.requestSent) {
            return;
        }
        Uikit.modal
            .confirm("Are you sure?", { labels: { cancel: "No", ok: "Yes" } })
            .then(suc => {
                tools.deleteSingleParameter("hasInvalid");
                this.setState({ requestSent: true }, () => {
                    tools.axiosInstance
                        .delete("/report")
                        .then(suc => {
                            tools.axiosInstance
                                .get("/report/consolidated")
                                .then(data => {
                                    this.setState({
                                        isDeleted: false,
                                        noData: true,
                                        failed: false,
                                        requestSent: false
                                    });
                                })
                                .catch(error => {
                                    this.setState({
                                        isDeleted: true,
                                        noData: true,
                                        failed: false,
                                        requestSent: false
                                    });
                                });

                            tools.sendTrack("delete");
                        })
                        .catch(err => {
                            this.setState({ requestSent: false, failed: true });
                        });
                });
            })
            .catch(err => console.log("cancel"));
    }

    componentDidMount() {
        tools.axiosInstance
            .get("/report/consolidated")
            .then(data => {
                this.setState({ noData: false });
            })
            .catch(error => {
                this.setState({ noData: true });
            });
    }

    render() {
        return (
            <main>
                <div
                    className="page  uk-grid-small uk-grid-match uk-grid"
                    data-uk-grid
                >
                    <div className="menu-bar uk-background-secondary uk-visible@m">
                        <Toolbar1 />
                    </div>
                    <div className="uk-width-expand  page-content">
                        <div className="ems-page-container">
                            <div
                                style={{
                                    borderBottom: "2px solid #e5e5e5",
                                    background: "white"
                                }}
                                data-uk-sticky
                            >
                                <ReportTitle title="Data Delete" />
                                <Form onlyTitle={true} title="Data Delete" />
                            </div>
                            <div
                                className="uk-padding-small uk-flex-column uk-width-1-1"
                                style={{ borderTop: "2px solid #e5e5e5" }}
                            >
                                <div className="uk-flex uk-flex-center uk-flex-middle uk-flex-column ">
                                    <div className="uk-width-2-3">
                                        <h3 className="uk-text-bold uk-padding-small">
                                            Delete Your Data From EMS Platform
                                        </h3>
                                        <p className="uk-padding-small  uk-margin-remove-top">
                                            <span
                                                className={`uk-text-justify ${
                                                    this.state.isDeleted
                                                        ? "uk-text-danger"
                                                        : ""
                                                }`}
                                            >
                                                {this.state.requestSent
                                                    ? "Deleting your data, please wait. "
                                                    : this.state.failed
                                                    ? `Oops! There was an error and the deletion of your data was unsuccessful, please try again later.`
                                                    : this.state.isDeleted
                                                    ? "Your data has been successfully deleted."
                                                    : this.state.noData
                                                    ? "You don't have any data on EMS platform to delete."
                                                    : "You are about to delete your data from the EMS SaaS (software as a service) platform. You can at any time upload your data again. You can also leave your data on the EMS platform as you may wish."}
                                            </span>
                                            <span>
                                                &nbsp;If you have any questions,
                                                contact&nbsp;
                                                <Link to="/ems">EMS TEAM</Link>.
                                                &nbsp;
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                <div className="uk-flex uk-flex-row uk-flex-center uk-flex-middle">
                                    <div className="uk-flex-left">
                                        <span
                                            className="uk-button uk-button-primary pointer"
                                            onClick={this.goBack.bind(this)}
                                        >
                                            Go Back
                                        </span>
                                    </div>
                                    {this.state.requestSent ||
                                    this.state.isDeleted ||
                                    this.state.noData ? null : (
                                        <div
                                            className="uk-flex-right uk-margin-xlarge-left"
                                            onClick={this.deleteClick.bind(
                                                this
                                            )}
                                        >
                                            <span
                                                className="uk-button uk-button-danger pointer"
                                                //data-uk-toggle={this.state.isDeleted || this.state.noData || this.state.requestSent?"false":"target: #modal-overflow-delete"}
                                            >
                                                Delete
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Toolbar1 hidden={true} />
                <div
                    className={
                        window.innerWidth < 900
                            ? "uk-margin-top uk-flex uk-flex-right uk-text-meta"
                            : "footer-div uk-text-meta"
                    }
                >
                    <span>
                        Copyright &copy; 2019, {new Date().getFullYear()} Spenta
                        B.V., all rights reserved. Powered By codixt.com
                    </span>
                </div>
            </main>
        );
    }
}

export default withRouter(Delete);
