import React, { Component } from "react";
import { Link } from "react-router-dom";
import Footer from "../footer/footer";
import Form from "./form/form";
import Chart from "./chart/chart";
import Toolbar from "../toolbar/toolbar";
import * as tools from "../../tools";
import { Redirect } from "react-router-dom";
import zipcelx from "zipcelx";
import ReportTitle from "./reportTitle/reportTitle";
import MyTable from "../table/table";

class Report extends Component {
    constructor(props) {
        super(props);
        this.monthList = [
            "Jan.",
            "Feb.",
            "Mar.",
            "Apr.",
            "May",
            "Jun.",
            "Jul.",
            "Aug.",
            "Sep.",
            "Oct.",
            "Nov.",
            "Dec."
        ];
        this.state = {
            data: [],
            dataToPlot: [],
            types: [
                "begin",
                "issue",
                "import",
                "export",
                "withdrawal",
                "transferTo",
                "transferOut",
                "cancel",
                "expiry",
                "issueOwnUse",
                "cancelledByThirdParty",
                "cancelledForThirdParty",
                "increase",
                "decrease",
                "netChange",
                "end",
                "expiration",
                "mutation",
                "ownConsumption"
            ],
            labels: [],
            clickedRowIndex: -1,
            showTable: true,
            futureData: [],
            yearCount: 3,
            legend: "",
            clickedRowData: [],
            clickedLegend: "",
            userData: null,
            yearSelectedIndx: 1,
            chartTitle: [],
            tableData: [[]],
            tableHeader: [],
            tableFooter: [],
            fromColumn: 0,
            toColumn:
                window.innerWidth > 1200 ? 12 : window.innerWidth > 900 ? 6 : 3,
            currentIndex: 0,
            columnsToShow:
                window.innerWidth > 1200 ? 12 : window.innerWidth > 900 ? 6 : 3,
            noData: false,
            chartType: "Bar"
        };

        this.reportTitle = {
            begin: "Beginning",
            issue: "Issued",
            import: "Imported",
            export: "Exported",
            withdrawal: "Withdrawals",
            transferTo: "Transfer To",
            transferOut: "Transferred Out",
            cancel: "Cancel",
            expiry: "Expired",
            issueOwnUse: "Issued for own use",
            cancelledByThirdParty: "Cancelled by Third party",
            cancelledForThirdParty: "Cancelled for Third party",
            increase: "Increase",
            decrease: "Decrease",
            netChange: "Net Change",
            end: "End",
            expiration: "Expiration",
            mutation: "Net Change",
            ownConsumption: "Own Consumption"
        };

        this.tableTitle = {
            begin: "Beginning",
            issue: "Issued",
            import: "Imported",
            transferTo: "Transferred To Trading Account",
            export: "Exported",
            transferOut: "Transferred Out Of Trading Account",
            expiry: "Expired",
            cancel: "Cancelled",
            withdrawal: "Withdrawals ",
            issueOwnUse: "Issued For Own Use",
            cancelledByThirdParty: "Cancelled By Third Party",
            cancelledForThirdParty: "Cancelled For Third Party",
            increase: "Increase in inventory",
            decrease: "Decrease in inventory",
            netChange: "Net Inventory Change",
            end: "End of Inventory",
            expiration: "Future Expiration",
            mutation: "Mutation Movement"
        };

        this.chartTitle = {
            begin: "Inventory",
            issue: "Issued",
            import: "Imported",
            transferTo: "Transferred To Trading Account",
            export: "Exported",
            transferOut: "Transferred Out Of Trading Account",
            expiry: "Expired",
            cancel: "Cancelled",
            withdrawal: "Withdrawals ",
            issueOwnUse: "Issued For Own Use",
            cancelledByThirdParty: "Cancelled By Third Party",
            cancelledForThirdParty: "Cancelled For Third Party",
            increase: "Increase in inventory",
            decrease: "Decrease in inventory",
            netChange: "Net Inventory Change",
            end: "End of Inventory",
            expiration: "Future Expiration",
            ownConsumption: "Own Consumption",
            mutation: "Mutation Movement"
        };

        this.monthList = [
            "Jan.",
            "Feb.",
            "Mar.",
            "Apr.",
            "May",
            "Jun.",
            "Jul.",
            "Aug.",
            "Sep.",
            "Oct.",
            "Nov.",
            "Dec."
        ];
        this.monthHeader = [];
        this.headers = [];
        this.yearCount = null;
        this.isExpiration = null;
        this.dateType = "transaction date";
    }

    getColumnToShowCount() {
        return window.innerWidth > 1200 ? 12 : window.innerWidth > 900 ? 6 : 3;
    }

    getIsExpiration() {
        return this.props.match.params.id === "expiration";
    }

    fillTableData() {
        let tempTableData = [[]];

        if (this.state.data) {
            tempTableData = this.state.data.map(el => this.createTableRow(el));
        }
        return tempTableData;
    }

    getFixedColumnCount() {
        if (window.innerWidth < 600) return 0;
        return this.getIsExpiration() ? 2 : 3;
    }

    createTableRow(data) {
        let fixedColCount = this.getFixedColumnCount();
        return data
            .slice(0, 1)
            .concat(
                data.slice(
                    this.state.currentIndex * this.state.columnsToShow +
                        this.state.fromColumn +
                        1,
                    this.state.currentIndex * this.state.columnsToShow +
                        this.state.fromColumn +
                        this.state.columnsToShow +
                        1
                )
            )
            .concat(data.slice(data.length - fixedColCount, data.length))
            .map(el => (el ? el : 0));
    }

    getTotalHorizontalScrollCount() {
        if (!this.state.data) {
            return 1;
        }
        let columnsToShowCount = this.getColumnToShowCount();
        let fixedCount = this.getFixedColumnCount() + 1;
        return Math.floor(
            (this.state.data[0].length - fixedCount) / columnsToShowCount
        );
    }

    showHorizontalPagination() {
        return this.state.currentIndex < this.getTotalHorizontalScrollCount();
    }

    showNextPagination() {
        return this.showHorizontalPagination();
    }

    showPreviousPagination() {
        return this.state.currentIndex != 0;
    }

    shouldGoToNextYear(currentIndex) {
        let countToGo = 12 / this.state.columnsToShow;
        let temp = currentIndex % countToGo;
        return temp === 0;
    }

    goToFirstView() {
        this.setState({ currentIndex: 0, yearSelectedIndx: 1 });
    }

    goToLastView() {
        this.setState({
            currentIndex: this.getTotalHorizontalScrollCount() - 1,
            yearSelectedIndx: this.yearCount
        });
    }

    handleDropDownYearSelectChange(event) {
        if (event.target) {
            this.setState({
                yearSelectedIndx: parseInt(event.target.value),
                currentIndex:
                    ((event.target.value - 1) * 12) / this.state.columnsToShow
            });
        } else {
            this.setState({
                yearSelectedIndx: parseInt(event),
                currentIndex: ((event - 1) * 12) / this.state.columnsToShow
            });
        }
    }

    goToNextView() {
        let currentIndex = this.state.currentIndex;
        if (currentIndex < this.getTotalHorizontalScrollCount() - 1) {
            currentIndex++;
            if (this.shouldGoToNextYear(currentIndex)) {
                let yearSelectedIndx = this.state.yearSelectedIndx + 1;
                if (yearSelectedIndx > this.yearCount) {
                    yearSelectedIndx = this.yearCount;
                }
                this.setState({ currentIndex, yearSelectedIndx });
            } else {
                this.setState({ currentIndex });
            }
        }
    }

    goToPreviousView() {
        let currentIndex = this.state.currentIndex;
        if (currentIndex > 0) {
            currentIndex--;
            if (this.shouldGoToNextYear(currentIndex + 1)) {
                let yearSelectedIndx = this.state.yearSelectedIndx - 1;
                if (yearSelectedIndx < 0) {
                    yearSelectedIndx = 0;
                }
                this.setState({ currentIndex, yearSelectedIndx });
            } else {
                this.setState({ currentIndex });
            }
        }
    }

    updatePlot(
        data,
        value,
        headers,
        rowVal,
        colVal,
        rowIdx,
        colIdx,
        fromColumn,
        toColumn
    ) {
        this.setState({
            clickedRowIndex: rowIdx,
            clickedLegend: rowVal
        });
    }

    dropDownChanged(data, headers, footer, index) {
        this.setState(
            {
                yearSelectedIndx:
                    Math.floor((index * this.state.columnsToShow) / 12) === 0
                        ? 1
                        : Math.floor((index * this.state.columnsToShow) / 12)
            },
            () => {
                let t = this.state.yearSelectedIndx;
            }
        );
    }

    createData() {
        let dataset = {
            label:
                this.chartTitle[this.props.match.params.id] +
                " for (" +
                this.state.dataToPlot[0] +
                ")",
            backgroundColor: "rgb(255, 99, 132)",
            borderColor: "rgb(255, 99, 132)",
            data: [
                ...this.state.dataToPlot.slice(
                    this.state.fromColumn,
                    this.state.toColumn
                )
            ]
        };

        let data1 = {
            labels: [...this.state.labels.slice(1)],
            datasets: [dataset]
        };
        return data1;
    }

    createTableMonthHeader(isExpiration) {
        let today = new Date();
        let prevDate =
            isExpiration === true
                ? today
                : new Date(today.setMonth(today.getMonth() - 24));
        let headers = [];
        for (let i = 0; i < 24; i++) {
            today = new Date(prevDate.setMonth(prevDate.getMonth() + 1));
            headers.push(
                `${today.getFullYear()}-${
                    today.getMonth() + 1 < 10
                        ? "0" + (today.getMonth() + 1)
                        : today.getMonth() + 1
                }`
            );
        }
        return headers;
    }

    createTableYearHeader(isExpiration) {
        let today = new Date();
        let prevDate = new Date(
            today.setFullYear(
                today.getFullYear() - (isExpiration === true ? 1 : 3)
            )
        );
        let headers = [];
        for (let i = 0; i < 3; i++) {
            today = new Date(prevDate.setFullYear(prevDate.getFullYear() + 1));
            headers.push(`${today.getFullYear()}`);
        }
        return headers;
    }

    createNewTableHeader(yearCount, isExpiration) {
        let headers = ["ID/EAN number"];
        let today = new Date();
        let monthCount = yearCount * 12;
        let fromYear =
            today.getFullYear() -
            yearCount +
            (isExpiration ? yearCount - 2 : 1);
        today = new Date(`${fromYear}-01-01`);
        this.monthHeader = [];
        let toYear = isExpiration ? yearCount - 1 : yearCount;
        for (let i = 0; i < yearCount; i++) {
            this.monthHeader = this.monthHeader.concat(this.monthList);
        }
        for (let i = 0; i < monthCount; i++) {
            headers.push(
                `${today.getFullYear()}-${
                    today.getMonth() + 1 < 10
                        ? "0" + (today.getMonth() + 1)
                        : today.getMonth() + 1
                }`
            );
            today = new Date(today.setMonth(today.getMonth() + 1));
        }
        today = new Date();
        fromYear = new Date().getFullYear() - 2 + (isExpiration ? 2 : 0);
        for (let i = isExpiration ? 1 : 0; i < 3; i++) {
            this.monthHeader.push(fromYear);
            headers.push("Total " + fromYear);
            fromYear++;
        }
        this.headers = headers;
        return headers;
    }

    calculateFooter() {
        let tempFooter = [];
        if (this.state.data.length === 0) {
            return null;
        }

        tempFooter = this.state.data.reduce(function(r, a) {
            a.forEach(function(b, i) {
                r[i] = (r[i] || 0) + b;
            });
            return r;
        }, []);
        tempFooter[0] = "Total";

        return tempFooter;
    }

    mergeMonthYearData(row) {
        if (row.month) {
            return row.month.concat(row.year);
        }
        return [];
    }

    getPreviousMonthsHeaderIdx(header) {
        let years = header.slice(header.length - 3, header.length); // 3 years
        let tempYear = header.map(el => {
            let temp = el.split("-");
            if (temp && temp.length > 1) {
                return temp[0];
            }
            return el;
        });

        let headerIdx = [];
        let yearIdx = -1;
        for (let i = 0, n = years.length; i < n; i++) {
            yearIdx = tempYear.findIndex(el => el === years[i]);
            if (yearIdx > -1) {
                headerIdx.push(yearIdx);
            }
        }

        return headerIdx;
    }

    calculateYearSum(header, data) {
        let headerIdx = this.getPreviousMonthsHeaderIdx(header);
        let tempSum = data[data.length - 3];
        for (let i = 1, n = data.length; i < n; i++) {
            if (i < headerIdx[1]) {
                tempSum -= data[i];
            } else {
                break;
            }
        }
        return tempSum;
    }

    newFitDataIntoTableBody(data, yearCount, isExpiration) {
        let tempData = data;
        let tempHeader = this.createNewTableHeader(yearCount, isExpiration);
        let resultData = [];
        let headerIdx = -1;
        let headerLength = tempHeader.length;
        let tempDataRow = [];
        for (let i = 0, n = tempData.length; i < n; i++) {
            tempDataRow = [""].concat(this.mergeMonthYearData(tempData[i]));
            tempDataRow[0] = tempData[i].productInstallationId;

            let tempRow = [];

            for (let j = 0, m = headerLength; j < m; j++) {
                headerIdx = tempDataRow.findIndex(el => {
                    if (el.month) {
                        return el.month === tempHeader[j];
                    } else return "Total " + el.year === tempHeader[j];
                });
                if (headerIdx > 0) {
                    tempRow[j] = tempDataRow[headerIdx].quantity.value;
                } else {
                    tempRow[j] = 0;
                }
            }
            tempRow = [tempData[i].productInstallationId].concat(
                tempRow.slice(1)
            );
            resultData[i] = tempRow;
        }
        return resultData;
    }

    searchReturn(data, formState) {
        // console.log(data);
        if (!data) {
            this.setState({ noData: true });
            return;
        }

        let yearCount = data.yearCount;
        this.yearCount = data.yearCount;
        this.isExpiration = this.props.match.params.id === "expiration";
        let result = data.result;
        let dataLength = data.productCount;

        let min = [];
        let minYear = 0;
        let currentTemp = 0;
        for (let i = 0, n = result.length; i < n; i++) {
            currentTemp = result[i]
                //.reverse()
                .findIndex((el, idx) => el > 0 && idx > 0);
            if (currentTemp > -1) {
                min.push(currentTemp);
            }
            //result[i].reverse();
        }
        min = Math.min(...min);
        if (isNaN(minYear) || !isFinite(minYear)) {
            this.setState(
                {
                    data: result,
                    searchParameters: { ...formState },
                    footer: data.footer,
                    dataLength,
                    noData: false
                },
                () => this.updateDimensions()
            );
            return;
        }
        minYear = yearCount - Math.floor(min / 12);
        minYear = minYear > 0 ? minYear : 1;

        let updateYear = false;
        // if (
        //     this.state.searchParameters &&
        //     (this.state.searchParameters.dateType != formState.dateType ||
        //         this.state.searchParameters.technologySelected !==
        //             formState.technologySelected)
        // ) {
        //     updateYear = false;
        // } else {
        //     updateYear = false;
        // }

        this.setState(
            {
                data: result,
                searchParameters: { ...formState },
                footer: data.footer,
                dataLength,
                noData: false
            },
            () => {
                this.updateDimensions();
                if (updateYear & !isNaN(minYear)) {
                    this.handleDropDownYearSelectChange(
                        this.yearCount - minYear + 1
                    );
                }
            }
        );
    }

    isMutationReport() {
        return (
            this.props.match.params.id === "mutation" ||
            this.props.match.params.id === "net"
        );
    }

    isIncreaseReport() {
        return this.props.match.params.id === "increase";
    }

    isDecreaseReport() {
        return this.props.match.params.id === "decrease";
    }

    isEndReport() {
        return this.props.match.params.id === "end";
    }

    isBeginReport() {
        return this.props.match.params.id === "begin";
    }

    isSpecialReport() {
        return (
            this.isDecreaseReport() ||
            this.isIncreaseReport() ||
            this.isMutationReport() ||
            this.isEndReport() ||
            this.isBeginReport()
        );
    }

    sumTwoArrays(first, second) {
        if (first.length === 0) {
            return second;
        }

        if (second.length === 0) {
            return first;
        }

        let tempArray = [];
        for (let i = 0, n = first.length; i < n; i++) {
            tempArray[i] = first[i] + second[i];
        }
        return tempArray;
    }

    minusTwoArrays(first, second) {
        if (first.length === 0) {
            return second.map(el => -el);
        }

        if (second.length === 0) {
            return first;
        }

        let tempArray = [];
        for (let i = 0, n = first.length; i < n; i++) {
            tempArray[i] = first[i] - second[i];
        }
        return tempArray;
    }

    sumArrayBasedOnProductionId(first, second) {
        if (first.length === 0) {
            return second;
        }

        if (second.length === 0) {
            return first;
        }

        let firstProductId = first.map(el => el[0]);
        let secondProductId = second.map(el => el[0]);
        let tempIdx = -1;
        let result = [];
        /*
        if first array contains the same productId sum the two related rows
        otherwise add the related row from the next array
         */
        for (let i = 0, n = secondProductId.length; i < n; i++) {
            tempIdx = firstProductId.findIndex(el => el === secondProductId[i]);
            if (tempIdx === -1) {
                result.push(second[i]);
            } else {
                result.push(
                    [first[tempIdx][0]].concat(
                        this.sumTwoArrays(
                            first[tempIdx].slice(1),
                            second[i].slice(1)
                        )
                    )
                ); // exclude productId
            }
        }

        /*
        check if the first productId has some rows that does not exist in the second and put them in output
         */
        for (let i = 0, n = firstProductId.length; i < n; i++) {
            tempIdx = secondProductId.findIndex(el => el === firstProductId[i]);
            if (tempIdx === -1) {
                result.push(first[i]);
            }
        }
        return result;
    }

    minusArrayBasedOnProductionId(first, second) {
        if (first.length === 0) {
            return this.negativeArray(second);
        }

        if (second.length === 0) {
            return first;
        }

        let firstProductId = first.map(el => el[0]);
        let secondProductId = second.map(el => el[0]);
        let tempIdx = -1;
        let result = [];
        /*
        if first array contains the same productId sum the two related rows
        otherwise add the related row from the next array
         */
        for (let i = 0, n = secondProductId.length; i < n; i++) {
            tempIdx = firstProductId.findIndex(el => el === secondProductId[i]);
            if (tempIdx === -1) {
                result.push(this.negativeArray(second[i]));
            } else {
                result.push(
                    [first[tempIdx][0]].concat(
                        this.minusTwoArrays(
                            first[tempIdx].slice(1),
                            second[i].slice(1)
                        )
                    )
                ); // exclude productId
            }
        }

        /*
        check if the first productId has some rows that does not exist in the second and put them in output
         */
        for (let i = 0, n = firstProductId.length; i < n; i++) {
            tempIdx = secondProductId.findIndex(el => el === firstProductId[i]);
            if (tempIdx === -1) {
                result.push(first[i]);
            }
        }
        return result;
    }

    negativeArrayRow(arr) {
        if (arr.length === 0) {
            return arr;
        }
        // 2d array
        if (Array.isArray(arr[0])) {
            return arr.map(el =>
                el.map((col, idx) => (idx === 0 ? col : -col))
            );
        }

        return arr.map((el, idx) => (idx === 0 ? el : -el));
    }

    sortResultBasedOnProductInstallationId(arr) {
        return arr.sort(function(x, y) {
            if (x[0] < y[0]) return -1;
            if (x[0] > y[0]) return 1;
            return 0;
        });
    }

    specialReport(data, yearCount) {
        let withdrawal = this.newFitDataIntoTableBody(
            this.getArrayByType(data.additionDeduction, "withdrawal"),
            this.yearCount,
            false
        );
        let exported = this.newFitDataIntoTableBody(
            this.getArrayByType(data.additionDeduction, "exported"),
            this.yearCount,
            false
        );
        let imported = this.newFitDataIntoTableBody(
            this.getArrayByType(data.additionDeduction, "imported"),
            this.yearCount,
            false
        );
        let expired = this.newFitDataIntoTableBody(
            this.getArrayByType(data.additionDeduction, "expiry"),
            this.yearCount,
            false
        );
        let transferOut = this.newFitDataIntoTableBody(
            data.transferOut,
            this.yearCount,
            false
        );
        let transferTo = this.newFitDataIntoTableBody(
            data.transferTo,
            this.yearCount,
            false
        );
        let cancelled = this.newFitDataIntoTableBody(
            data.cancelled,
            this.yearCount,
            false
        );
        let cancelledForThirdParty = this.newFitDataIntoTableBody(
            data.cancelledForThirdParty,
            this.yearCount,
            false
        );
        let issuedForTrading = this.newFitDataIntoTableBody(
            data.issuedForTrading,
            this.yearCount,
            false
        );
        if (this.isIncreaseReport()) {
            let tempVal = this.sumArrayBasedOnProductionId(
                issuedForTrading,
                transferTo
            );
            tempVal = this.sumArrayBasedOnProductionId(tempVal, imported);
            return tempVal;
        }
        if (this.isDecreaseReport()) {
            let tempVal = this.sumArrayBasedOnProductionId(
                withdrawal,
                transferOut
            );
            tempVal = this.sumArrayBasedOnProductionId(tempVal, exported);
            tempVal = this.sumArrayBasedOnProductionId(tempVal, expired);
            tempVal = this.sumArrayBasedOnProductionId(tempVal, cancelled);
            tempVal = this.sumArrayBasedOnProductionId(
                tempVal,
                cancelledForThirdParty
            );
            return tempVal;
        }

        if (this.isMutationReport()) {
            let tempValSum = this.sumArrayBasedOnProductionId(
                issuedForTrading,
                transferTo
            );
            tempValSum = this.sumArrayBasedOnProductionId(tempValSum, imported);

            let tempVal = this.sumArrayBasedOnProductionId(
                withdrawal,
                transferOut
            );
            tempVal = this.sumArrayBasedOnProductionId(tempVal, exported);
            tempVal = this.sumArrayBasedOnProductionId(tempVal, expired);
            tempVal = this.sumArrayBasedOnProductionId(tempVal, cancelled);
            tempVal = this.sumArrayBasedOnProductionId(
                tempVal,
                cancelledForThirdParty
            );
            tempVal = this.minusArrayBasedOnProductionId(tempValSum, tempVal);
            return tempVal;
        }

        if (this.isEndReport()) {
            let tempValSum = this.sumArrayBasedOnProductionId(
                issuedForTrading,
                transferTo
            );
            tempValSum = this.sumArrayBasedOnProductionId(tempValSum, imported);

            let tempVal = this.sumArrayBasedOnProductionId(
                withdrawal,
                transferOut
            );
            tempVal = this.sumArrayBasedOnProductionId(tempVal, exported);
            tempVal = this.sumArrayBasedOnProductionId(tempVal, expired);
            tempVal = this.sumArrayBasedOnProductionId(tempVal, cancelled);
            tempVal = this.sumArrayBasedOnProductionId(
                tempVal,
                cancelledForThirdParty
            );
            tempVal = this.minusArrayBasedOnProductionId(tempValSum, tempVal);
            let mutationAccumulated = [];
            let end = [];
            let tempEnd = [];

            for (let i = 0, n = tempVal.length; i < n; i++) {
                mutationAccumulated = tempVal[i].slice(1);
                tempEnd = [];
                mutationAccumulated.reduce(
                    (prev, curr, i) => (tempEnd[i] = prev + curr),
                    0
                );
                end[i] = [tempVal[i][0]]
                    .concat(tempEnd)
                    .slice(0, 12 * this.yearCount + 1);
            }

            for (let i = 0, n = end.length; i < n; i++) {
                for (let j = this.yearCount - 3; j < this.yearCount; j++) {
                    end[i].push(end[i][(j + 1) * 12]);
                }
            }
            return end;
        }

        if (this.isBeginReport()) {
            let tempValSum = this.sumArrayBasedOnProductionId(
                issuedForTrading,
                transferTo
            );
            tempValSum = this.sumArrayBasedOnProductionId(tempValSum, imported);

            let tempVal = this.sumArrayBasedOnProductionId(
                withdrawal,
                transferOut
            );
            tempVal = this.sumArrayBasedOnProductionId(tempVal, exported);
            tempVal = this.sumArrayBasedOnProductionId(tempVal, expired);
            tempVal = this.sumArrayBasedOnProductionId(tempVal, cancelled);
            tempVal = this.sumArrayBasedOnProductionId(
                tempVal,
                cancelledForThirdParty
            );

            tempVal = this.minusArrayBasedOnProductionId(tempValSum, tempVal);
            let mutationAccumulated = [];
            let end = [];
            let tempEnd = [];

            for (let i = 0, n = tempVal.length; i < n; i++) {
                mutationAccumulated = tempVal[i].slice(1);
                tempEnd = [];
                mutationAccumulated.reduce(
                    (prev, curr, i) => (tempEnd[i] = prev + curr),
                    0
                );
                end[i] = [tempVal[i][0]]
                    .concat(tempEnd)
                    .slice(1, 12 * this.yearCount + 1);
            }

            for (let i = 0, n = end.length; i < n; i++) {
                for (let j = this.yearCount - 3; j < this.yearCount; j++) {
                    end[i].push(end[i][(j + 1) * 12]);
                }
            }

            let begin = [];
            for (let i = 0, n = end.length; i < n; i++) {
                begin[i] = [tempVal[i][0], 0].concat(
                    end[i].slice(0, 12 * this.yearCount)
                );
                for (let j = this.yearCount - 3; j < this.yearCount; j++) {
                    begin[i].push(end[i][j * 12 - 1]);
                }
            }
            return begin;
        }
    }

    getArrayByType(arr, type) {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i][type]) {
                return arr[i].productInstallationId;
            }
        }
        return [];
    }

    makeTableResponsive() {
        if (document.getElementsByClassName("ems-chart").length > 0) {
            const chartHeight = document.getElementsByClassName("ems-chart")[0]
                .clientHeight;

            const chartHeight1 = document.getElementsByClassName(
                "uk-card-small"
            )[0].clientHeight;
            const bodyHeight = document.getElementsByTagName("body")[0]
                .clientHeight;
            const overflow = document.getElementsByClassName(
                "ems-table-container"
            );
            if (overflow && overflow.length > 0) {
                if (window.innerWidth < 600 || window.innerHeight < 600) {
                    overflow[0].setAttribute("style", `height: "auto"`);
                } else {
                    overflow[0].setAttribute(
                        "style",
                        `height: ${bodyHeight -
                            chartHeight -
                            chartHeight1 -
                            130}px`
                    );
                }
            }
            //54 px is the row height
            let rowsPerPage =
                Math.floor(
                    (bodyHeight - chartHeight - chartHeight1 - 110) / 54
                ) + 1;
            // if (rowsPerPage > 2) {
            //     rowsPerPage -= 2;
            // }
            this.setState({ rowsPerPage });
        }
    }
    updateDimensions = () => {
        this.makeTableResponsive();
        if (window.innerWidth > 1366) {
            this.setState({
                columnsToShow: 12,
                divWidth: 100 / 17,
                currentIndex: Math.floor(
                    (this.state.currentIndex * this.state.columnsToShow) / 12
                )
            });
        } else {
            if (window.innerWidth > 900) {
                this.setState({
                    columnsToShow: 6,
                    divWidth: 100 / 10,
                    currentIndex: Math.floor(
                        (this.state.currentIndex *
                            this.state.columnsToShow *
                            2) /
                            12
                    )
                });
            } else {
                this.setState({
                    columnsToShow: 3,
                    divWidth: 100 / 7,
                    currentIndex: Math.floor(
                        (this.state.currentIndex *
                            this.state.columnsToShow *
                            4) /
                            12
                    )
                });
            }
        }
    };

    freezePage(event) {
        event.preventDefault();
    }

    componentWillMount() {
        window.addEventListener("resize", this.updateDimensions.bind(this));
        window.addEventListener("orientationchange", event =>
            this.updateDimensions(event)
        );
        document.body.addEventListener(
            "touchmove",
            event => this.freezePage(event),
            false
        );
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
        window.removeEventListener("orientationchange", event =>
            this.updateDimensions(event)
        );
        document.body.removeEventListener(
            "touchmove",
            event => this.freezePage(event),
            false
        );
    }

    getFilterParameters() {
        let filterParameters = ""; //tools.readDataFromBrowser("filterParameters");
        if (filterParameters) {
            filterParameters = JSON.parse(filterParameters);
        } else {
            filterParameters = {};
        }
        filterParameters.transactionType = this.props.match.params.id;
        return filterParameters;
    }

    componentDidMount() {
        let filterParameters = this.getFilterParameters();
        if (
            this.props.match.params.id &&
            this.reportTitle[this.props.match.params.id]
        ) {
            tools.sendTrack(this.reportTitle[this.props.match.params.id]);
        }

        tools.axiosInstance
            .get("/user")
            .then(rec => {
                this.setState({ userData: rec.data.data[0] });
            })
            .catch(err => {
                // console.log(err);
                this.setState({ userError: true });
            });
        tools.axiosInstance
            .post("/report/search", filterParameters)
            .then(recData => {
                let yearCount = recData.data.data.yearCount;
                this.yearCount = yearCount;
                this.isExpiration = this.props.match.params.id === "expiration";
                let result = recData.data.data.result;
                let footer = recData.data.data.footer;
                this.headers = recData.data.data.headers;
                let dataLength = recData.data.data.productCount;
                // if (this.isSpecialReport()) {
                //     result = this.specialReport(recData.data.data.result);
                // } else {
                //     result = this.newFitDataIntoTableBody(
                //         recData.data.data.result,
                //         yearCount,
                //         this.props.match.params.id === "expiration"
                //     );
                // }

                this.setState(
                    {
                        data: result,
                        yearCount: yearCount,
                        footer,
                        dataLength,
                        noData: false
                    },
                    () => {
                        this.handleDropDownYearSelectChange(yearCount);
                        setTimeout(() => {
                            if (
                                document.getElementsByClassName("ems-chart")
                                    .length === 0
                            ) {
                                return;
                            }
                            const chartHeight = document.getElementsByClassName(
                                "ems-chart"
                            )[0].clientHeight;

                            const chartHeight1 = document.getElementsByClassName(
                                "uk-card-small"
                            )[0].clientHeight;
                            const bodyHeight = document.getElementsByTagName(
                                "body"
                            )[0].clientHeight;
                            const overflow = document.getElementsByClassName(
                                "ems-table-container"
                            );
                            if (overflow && overflow.length > 0) {
                                if (
                                    window.innerWidth < 600 ||
                                    window.innerHeight < 600
                                ) {
                                    overflow[0].setAttribute(
                                        "style",
                                        `height: "auto"`
                                    );
                                } else {
                                    overflow[0].setAttribute(
                                        "style",
                                        `height: ${bodyHeight -
                                            chartHeight -
                                            chartHeight1 -
                                            130}px`
                                    );
                                }
                            }

                            //54 px is the row height
                            let rowsPerPage =
                                Math.floor(
                                    (bodyHeight -
                                        chartHeight -
                                        chartHeight1 -
                                        110) /
                                        54
                                ) + 1;
                            // if (rowsPerPage > 2) {
                            //     rowsPerPage -= 2;
                            // }
                            if (rowsPerPage !== this.state.rowsPerPage)
                                this.setState({ rowsPerPage });
                        }, 10);
                    }
                );
            })
            .catch(err => {
                // console.log(err);
                this.setState({ noData: true });
            });
    }

    searchClicked(visible) {
        if (!visible) {
            setTimeout(() => {
                if (document.getElementsByClassName("ems-chart").length === 0) {
                    return;
                }
                const chartHeight = document.getElementsByClassName(
                    "ems-chart"
                )[0].clientHeight;

                const chartHeight1 = document.getElementsByClassName(
                    "uk-card-small"
                )[0].clientHeight;
                const bodyHeight = document.getElementsByTagName("body")[0]
                    .clientHeight;
                const overflow = document.getElementsByClassName(
                    "ems-table-container"
                );
                if (overflow && overflow.length > 0) {
                    if (window.innerWidth < 600 || window.innerHeight < 600) {
                        overflow[0].setAttribute("style", `height: "auto"`);
                    } else {
                        overflow[0].setAttribute(
                            "style",
                            `height: ${bodyHeight -
                                chartHeight -
                                chartHeight1 -
                                130}px`
                        );
                    }
                }

                //54 px is the row height
                let rowsPerPage =
                    Math.floor(
                        (bodyHeight - chartHeight - chartHeight1 - 110) / 54
                    ) + 1;
                // if (rowsPerPage > 2) {
                //     rowsPerPage -= 2;
                // }
                if (rowsPerPage !== this.state.rowsPerPage)
                    this.setState({ rowsPerPage });
            }, 10);
        } else {
            setTimeout(() => {
                if (document.getElementsByClassName("ems-chart").length === 0) {
                    return;
                }
                const searchUi = document.getElementById("search-ui")
                    .clientHeight;
                const chartHeight = document.getElementsByClassName(
                    "ems-chart"
                )[0].clientHeight;

                const chartHeight1 = document.getElementsByClassName(
                    "uk-card-small"
                )[0].clientHeight;
                const bodyHeight = document.getElementsByTagName("body")[0]
                    .clientHeight;
                const overflow = document.getElementsByClassName(
                    "ems-table-container"
                );
                if (overflow && overflow.length > 0) {
                    if (window.innerWidth < 600 || window.innerHeight < 600) {
                        overflow[0].setAttribute("style", `height: "auto"`);
                    } else {
                        overflow[0].setAttribute(
                            "style",
                            `height: ${bodyHeight -
                                chartHeight -
                                chartHeight1 -
                                searchUi}px`
                        );
                    }
                }

                //54 px is the row height
                let rowsPerPage =
                    Math.floor(
                        (bodyHeight - chartHeight - chartHeight1 - searchUi) /
                            54
                    ) + 1;
                // if (rowsPerPage > 2) {
                //     rowsPerPage -= 2;
                // }
                if (rowsPerPage !== this.state.rowsPerPage)
                    this.setState({ rowsPerPage });
            }, 10);
        }
    }
    beingSubmitted() {
        this.setState({ showTable: false });
    }

    createTableYearList(yearCount, isExpiration) {
        let today = new Date();
        let fromYear = today.getFullYear() - yearCount + (isExpiration ? 2 : 1);
        let yearList = [];
        for (let i = 1; i <= yearCount; i++) {
            yearList.push(fromYear);
            fromYear++;
        }
        return yearList;
    }

    createDropDownYearList() {
        let yearList = this.createTableYearList(
            this.yearCount,
            this.props.match.params.id === "expiration"
        );
        let yearListOptions = yearList.map((el, index) => (
            <option value={index + 1} key={`year-div-list-${index}`}>
                {el}
            </option>
        ));
        return (
            <select
                className="uk-select uk-form-small ems-years"
                onChange={event => this.handleDropDownYearSelectChange(event)}
                value={this.state.yearSelectedIndx}
            >
                {yearListOptions}
            </select>
        );
    }

    createTechnologyCodeList() {
        return (
            <select
                className="uk-select uk-form-small ems-years"
                onChange={event => this.handleDropDownYearSelectChange(event)}
            >
                <option value={0}>Go</option>
            </select>
        );
    }

    createChartTypeList() {
        return (
            <div>
                <select
                    className="uk-select uk-form-small uk-width-small"
                    onChange={event => this.handleChartTypeSelect(event)}
                >
                    <option value={0}>Line Chart</option>
                    <option value={1}>Bar Chart</option>
                </select>
            </div>
        );
    }

    handleChartTypeSelect(event) {
        this.setState({ chartType: event });
    }

    generateXLSXRow(data, dataType = "string") {
        return data.map((el, index) => {
            return { value: el, type: index === 0 ? "string" : dataType };
        });
    }

    handleDownloadDataClick() {
        let tempHeader = [""].concat(
            this.headers.slice(
                (this.state.yearSelectedIndx - 1) * 12 + 1,
                this.state.yearSelectedIndx * 12 + 1
            )
        );

        tempHeader[0] =
            this.reportTitle[this.props.match.params.id] + "-" + this.dateType;

        let tempData = this.state.data.map(el =>
            [el[0]].concat(
                el.slice(
                    (this.state.yearSelectedIndx - 1) * 12 + 1,
                    this.state.yearSelectedIndx * 12 + 1
                )
            )
        );
        tempData = tempData.map(el => this.generateXLSXRow(el, "number"));
        tempData = [this.generateXLSXRow(tempHeader)].concat(tempData);

        const config = {
            filename: `EMS-${tempHeader[0]}`,
            sheet: {
                data: tempData
            }
        };
        zipcelx(config);
    }

    createChartTitle(data) {
        if (!data) {
            return this.headers.slice(
                this.state.currentIndex * this.state.columnsToShow +
                    this.state.fromColumn +
                    1,
                this.state.currentIndex * this.state.columnsToShow +
                    this.state.fromColumn +
                    this.state.columnsToShow +
                    1
            );
        }
        return data.slice(
            this.state.currentIndex * this.state.columnsToShow +
                this.state.fromColumn +
                1,
            this.state.currentIndex * this.state.columnsToShow +
                this.state.fromColumn +
                this.state.columnsToShow +
                1
        );
    }

    getChartData() {
        let footer = this.state.footer;
        let chartData = [[]];
        let chartLegend = [];

        if (footer && footer.length > 0) {
            chartData[0] = footer.slice(
                this.state.currentIndex * this.state.columnsToShow +
                    this.state.fromColumn +
                    1,
                this.state.currentIndex * this.state.columnsToShow +
                    this.state.fromColumn +
                    this.state.columnsToShow +
                    1
            );
            chartLegend = ["Total"];

            if (
                this.state.clickedRowIndex > -1 &&
                this.state.clickedRowIndex < this.state.data.length &&
                this.state.data &&
                this.state.data.length > 0 &&
                this.state.data[this.state.clickedRowIndex].length > 0
            ) {
                chartData[1] = this.createChartTitle(
                    this.state.data[this.state.clickedRowIndex]
                );
                chartLegend[1] = this.state.data[this.state.clickedRowIndex][0];
            }
        }

        let chartTitle = this.createChartTitle();
        return { chartData, chartLegend, chartTitle };
    }

    render() {
        if (this.state.userError) {
            return (
                <main className="uk-background-muted">
                    <div className="uk-flex uk-flex-center uk-flex-middle uk-width-3-5">
                        <div className="uk-text-justify">
                            <p>
                                Your account might have been disable, please{" "}
                                <Link to="/contact">contact</Link> the admin.
                            </p>
                            <p>
                                You can <Link to="/login">login</Link> again
                            </p>
                        </div>
                    </div>
                </main>
            );
        }

        if (this.state.types.indexOf(this.props.match.params.id) < 0) {
            return <Redirect to="/" />;
        }

        let footer = this.state.footer;

        if (footer && footer.length > 0) {
            footer = this.createTableRow(footer);
        }

        let chartData = [];
        let chartLegend = [];
        if (footer && footer.length) {
            chartData = [this.createChartTitle(footer)];
            chartLegend = ["Total Sum Monthly"];
        }

        let select = null;
        if (this.state.data) {
            select = this.createDropDownYearList();
        }

        if (this.state.clickedRowIndex > -1) {
            let tempLegend = this.state.clickedLegend.split(" ")[0];
            if (
                chartLegend.indexOf(tempLegend) < 0 &&
                chartLegend.indexOf(this.state.clickedLegend) < 0
            ) {
                chartData[1] = this.createChartTitle(
                    this.state.tableData[this.state.clickedRowIndex]
                );
                chartLegend[1] = this.state.clickedLegend;
            }
        }

        let chartTitle = [...this.state.chartTitle];
        if (chartTitle.length === 0) {
            chartTitle = this.state.headers;
        }
        let yearList = this.createTableYearList(
            this.yearCount,
            this.props.match.params.id === "expiration"
        );

        let chartDetails = this.getChartData();

        return (
            <React.Fragment>
                <main className="uk-background-muted">
                    <div
                        className="page uk-height-1-1 uk-grid-small uk-grid-match uk-grid"
                        data-uk-grid
                    >
                        <div className="menu-bar uk-background-secondary uk-visible@m">
                            <Toolbar />
                        </div>
                        <div className="uk-width-expand uk-height-1-1 page-content">
                            <div className="ems-page-container">
                                <div>
                                    <div className="uk-card uk-card-default uk-card-small uk-width-1-1 uk-box-shadow-small">
                                        <ReportTitle
                                            title={`${
                                                this.reportTitle[
                                                    this.props.match.params.id
                                                ]
                                            } (${
                                                this.state.searchParameters
                                                    ? this.state
                                                          .searchParameters
                                                          .dateType
                                                    : "transaction"
                                            } date)`}
                                        />
                                        <Form
                                            dataLength={this.state.dataLength}
                                            fullScreenDate={
                                                this.state.columnsToShow < 6
                                            }
                                            handleDownloadDataClick={this.handleDownloadDataClick.bind(
                                                this
                                            )}
                                            yearList={yearList}
                                            handleDropDownYearSelectChange={this.handleDropDownYearSelectChange.bind(
                                                this
                                            )}
                                            submitted={this.searchReturn.bind(
                                                this
                                            )}
                                            handleChartTypeSelect={this.handleChartTypeSelect.bind(
                                                this
                                            )}
                                            yearSelectedIndx={
                                                this.state.yearSelectedIndx
                                            }
                                            disableDateList={
                                                this.props.match.params.id ===
                                                "expiration"
                                            }
                                            title={
                                                this.reportTitle[
                                                    this.props.match.params.id
                                                ]
                                            }
                                            transactionType={
                                                this.props.match.params.id
                                            }
                                            searchClicked={clicked =>
                                                this.searchClicked(clicked)
                                            }
                                            showRelations={true}
                                        />
                                    </div>
                                    {!this.state.noData &&
                                    footer &&
                                    footer.length > 0 ? (
                                        <Chart
                                            data={chartDetails.chartData}
                                            legend={chartDetails.chartLegend}
                                            title={chartDetails.chartTitle}
                                            chartType={this.state.chartType}
                                        />
                                    ) : null}
                                </div>
                                <div className="ems-tables uk-padding-remove">
                                    {!this.state.noData &&
                                    footer &&
                                    footer.length > 0 ? (
                                        <MyTable
                                            useCache={true}
                                            headers={this.createTableRow(
                                                this.headers
                                            )}
                                            data={this.fillTableData()}
                                            footers={footer ? footer : [[]]}
                                            trClickHandler={(
                                                data,
                                                value,
                                                headers,
                                                rowVal,
                                                colVal,
                                                rowIdx,
                                                colIdx,
                                                fromColumn,
                                                toColumn,
                                                footer
                                            ) =>
                                                this.updatePlot(
                                                    data,
                                                    value,
                                                    headers,
                                                    rowVal,
                                                    colVal,
                                                    rowIdx,
                                                    colIdx,
                                                    fromColumn,
                                                    toColumn,
                                                    footer
                                                )
                                            }
                                            useEvents={true}
                                            rowsPerPage={
                                                this.state.rowsPerPage
                                                    ? this.state.rowsPerPage
                                                    : 1
                                            }
                                        />
                                    ) : null}
                                    {footer &&
                                    footer.length > 0 &&
                                    this.showHorizontalPagination() ? (
                                        <React.Fragment>
                                            <div className="uk-background-muted uk-width-1-1 uk-position-fixed uk-position-bottom">
                                                <div className="uk-flex uk-flex-center uk-flex-middle uk-margin-small-top">
                                                    <div className="uk-flex uk-flex-row">
                                                        <span
                                                            className="uk-icon pointer"
                                                            uk-icon="chevron-double-left"
                                                            onClick={this.goToFirstView.bind(
                                                                this
                                                            )}
                                                        ></span>
                                                        <span
                                                            className="uk-icon uk-margin-left pointer"
                                                            uk-icon="chevron-left"
                                                            onClick={this.goToPreviousView.bind(
                                                                this
                                                            )}
                                                        ></span>
                                                    </div>
                                                    <div className="uk-flex uk-flex-row uk-margin-large-left">
                                                        <span
                                                            className="uk-icon pointer"
                                                            uk-icon="chevron-right"
                                                            onClick={this.goToNextView.bind(
                                                                this
                                                            )}
                                                        ></span>
                                                        <span
                                                            className="uk-icon uk-margin-left pointer"
                                                            uk-icon="chevron-double-right"
                                                            onClick={this.goToLastView.bind(
                                                                this
                                                            )}
                                                        ></span>
                                                    </div>
                                                </div>
                                                <Footer />
                                            </div>
                                        </React.Fragment>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Toolbar hidden={true} />
                </main>
            </React.Fragment>
        );
    }
}

export default Report;
