import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import * as tools from "../../tools";

class PageFooter extends Component {
    render() {
        let linksWithUrl = [
            "forgot",
            "login",
            "about",
            "contact",
            "register",
            "verify"
        ];
        let tempLink = this.props.location.pathname.substr(1).split("/")[0];
        if (!tempLink) {
            tempLink = "";
        }
        if (linksWithUrl.findIndex(el => el === tempLink) > -1) {
            return (
                <div className="uk-text-meta footer-div">
                    Copyright &copy; 2019, {new Date().getFullYear()} Spenta
                    B.V., all rights reserved. Powered By&nbsp;
                    <a href="http://codixt.com" target="_blank">
                        codixt
                    </a>
                </div>
            );
        }
        return (
            <div className="uk-background-muted uk-text-meta uk-margin-remove-top uk-flex uk-flex-right">
                <span>
                    Copyright &copy; 2019, {new Date().getFullYear()} Spenta
                    B.V., all rights reserved. Powered By codixt.com
                </span>
            </div>
        );
    }
}
export default withRouter(PageFooter);
