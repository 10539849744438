import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import * as tools from "../../tools";
import Toolbar from "../toolbar/toolbar";
import ReportTitle from "../report/reportTitle/reportTitle";
import Table from "./table";
import Form from "../report/form/form";

export default class UserList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accountHolder: null,
            actionList: [],
            actionsToShowInTable: [],
            rowClickedId: -1
        };

        this.headers = ["Email", "Date", "Action", "IP Address"];

        this.parametersToAdd = [
            "userEmail",
            "recordDateTime",
            "eventName",
            "ipAddress"
        ];
    }

    componentDidMount() {
        const id = this.props.match.params.id;
        tools.axiosInstance
            .get(`/user/audit/${id}`)
            .then(rec => {
                let [
                    actionList,
                    accountHolderArray,
                    currentMonthUser,
                    previousMonthUser,
                    currentMonthTotal,
                    previousMonthTotal
                ] = rec.data.data;
                if (!currentMonthTotal) {
                    currentMonthTotal = [...currentMonthUser];
                    previousMonthTotal = [...previousMonthUser];
                }

                accountHolderArray.map(el => {
                    if (!el.accountHolder) {
                        el.isClient = "A/c Holder";
                        return el;
                    }
                    el.isClient = "User";
                    return el;
                });

                const actionsToShowInTable = [];
                for (let i = 0, n = actionList.length; i < n; i++) {
                    actionList[i].recordDateTime = tools.dateFromTimeStamp(
                        actionList[i].t1
                    );
                    actionsToShowInTable.push(
                        this.convertObjectToArray(
                            actionList[i],
                            this.parametersToAdd
                        )
                    );
                }

                const accountHolder = accountHolderArray[0];

                this.setState({
                    accountHolder,
                    actionList,
                    actionsToShowInTable,
                    currentMonthUser,
                    previousMonthUser,
                    currentMonthTotal,
                    previousMonthTotal
                });
            })
            .catch(console.log);
    }

    convertObjectToArray(obj, parametersToAdd) {
        const tempArray = [];
        if (!obj) {
            return tempArray;
        }

        const objectKeys = Object.keys(obj);
        if (objectKeys.length === 0) {
            return tempArray;
        }

        if (!parametersToAdd || parametersToAdd.length === 0) {
            for (let i = 0, n = objectKeys.length; i < n; i++) {
                tempArray.push(obj[objectKeys[i]]);
            }
            return tempArray;
        } else {
            for (let i = 0, n = parametersToAdd.length; i < n; i++) {
                tempArray.push(obj[parametersToAdd[i]]);
            }
            return tempArray;
        }
    }

    audit(index) {
        const id = this.state.userList[index].id;
        const url = `/admin/audit/${id}`;
        this.setState({ rowClickedId: index });
        this.props.history.push(url);
    }

    /**
     * render pop up contains user data
     */
    renderUserData() {
        if (this.state.rowClickedId === -1) {
            return null;
        }

        let userData = this.state.userList[this.state.rowClickedId];
        let userNumber = userData.id;

        return (
            <div>
                <h2>{`Client Number ${userNumber}`}</h2>
                <label className="profileStatus">
                    {this.state.isPending ? "(pending)" : "(activated)"}
                </label>
                <ul uk-accordion="multiple: true" className="">
                    <li className="uk-open uk-card uk-card-small uk-card-default uk-box-shadow-small">
                        <a
                            className="uk-accordion-title uk-padding-small"
                            href="#"
                        >
                            Company Details
                        </a>
                        <div className="uk-accordion-content uk-padding-small">
                            <div
                                className="uk-grid uk-width-1-1 uk-children-width-1-7 uk-children-width-1-1@s"
                                data-uk-grid
                            >
                                <div>
                                    <span className="uk-text-bold">
                                        Company Name:
                                    </span>
                                    <span>&nbsp;{userData.companyName}</span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        Company/Business Email:
                                    </span>
                                    <span>&nbsp;{userData.email}</span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        Company Number:
                                    </span>
                                    <span>&nbsp;{userData.mobileNumber}</span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        Country:
                                    </span>
                                    <span>&nbsp;{userData.country}</span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">City:</span>
                                    <span>&nbsp;{userData.city}</span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        Zip Code:
                                    </span>
                                    <span>&nbsp;{userData.zipCode}</span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        Address:
                                    </span>
                                    <span>&nbsp;{userData.address}</span>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="uk-open uk-card uk-card-small uk-card-default uk-box-shadow-small">
                        <a
                            className="uk-accordion-title uk-padding-small"
                            href="#"
                        >
                            Company Trading Details
                        </a>
                        <div className="uk-accordion-content uk-padding-small">
                            <div
                                className="uk-grid uk-width-1-1 uk-children-width-1-3 uk-children-width-1-1@s"
                                data-uk-grid
                            >
                                <div>
                                    <span className="uk-text-bold">
                                        Fiscal Number:
                                    </span>
                                    <span>
                                        &nbsp;
                                        {userData.companyFiscalNumber}
                                    </span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        Trading Number: &nbsp;
                                        {userData.companyTradeNumber}
                                    </span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        RESC Code:
                                    </span>
                                    <span>&nbsp;{userData.rescCode}</span>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="uk-open uk-card uk-card-small uk-card-default uk-box-shadow-small">
                        <a
                            className="uk-accordion-title uk-padding-small"
                            href="#"
                        >
                            Personal Details
                        </a>
                        <div className="uk-accordion-content uk-padding-small">
                            <div
                                className="uk-grid uk-width-1-1 uk-children-width-1-4 uk-children-width-1-1@s"
                                data-uk-grid
                            >
                                <div>
                                    <span className="uk-text-bold">
                                        First Name:
                                    </span>
                                    <span>&nbsp;{userData.firstName}</span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        Last Name:
                                    </span>
                                    <span>&nbsp;{userData.lastName}</span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        Mobile Number:
                                    </span>
                                    <span>&nbsp;{userData.mobileNumber}</span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        Registration Date:{" "}
                                    </span>
                                    <span>
                                        {userData.registerTime
                                            ? userData.registerTime
                                                  .split("T")[0]
                                                  .split("-")
                                                  .reverse()
                                                  .join("-")
                                            : ""}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="uk-open uk-card uk-card-small uk-card-default uk-box-shadow-small">
                        <a
                            className="uk-accordion-title uk-padding-small"
                            href="#"
                        >
                            Purchase Details
                        </a>
                        <div className="uk-accordion-content uk-padding-small">
                            <div
                                className="uk-grid uk-width-1-1 uk-children-width-1-4 uk-children-width-1-1@s"
                                data-uk-grid
                            >
                                <div>
                                    <span className="uk-text-bold">
                                        Base Amount:
                                    </span>
                                    <span>&nbsp;{userData.baseAmount}</span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        Tax Amount:
                                    </span>
                                    <span>&nbsp;{userData.taxAmount}</span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        Total Amount:
                                    </span>
                                    <span>&nbsp;{userData.totalAmount}</span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        Reference Code:
                                    </span>
                                    <span>&nbsp;{userData.referenceCode}</span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        Purchase Date:
                                    </span>
                                    <span>
                                        &nbsp;
                                        {userData.purchaseDate
                                            ? userData.purchaseDate
                                                  .split("T")[0]
                                                  .split("-")
                                                  .reverse()
                                                  .join("-")
                                            : ""}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <div>
                    <div className="uk-flex uk-flex-center uk-flex-between">
                        {this.state.isActive && (
                            <button
                                className="uk-button-primary uk-width-1-3@m uk-width-1-4@l uk-text-large"
                                onClick={this.sendDeactivation.bind(this)}
                            >
                                Deactivate
                            </button>
                        )}
                        <button
                            className="uk-button-primary uk-width-1-3@m uk-width-1-4@l uk-text-large uk-button-danger uk-hidden"
                            data-uk-toggle="target: #modal-overflow-delete"
                        >
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    sendDeactivation() {
        const tempChosenUser = this.state.userList[this.state.rowClickedId];

        tools.axiosInstance
            .post(`/user/enable/${tempChosenUser.id}`, {
                isActive: false,
                isHidden: false
            })
            .then(
                suc => window.location.reload(),
                err => window.location.reload()
            );
    }

    renderUpload() {
        let {
            currentMonthUser,
            previousMonthUser,
            currentMonthTotal,
            previousMonthTotal
        } = this.state;
        return (
            <>
                <span className="uk-text-bold">This Month:</span>
                <div className="uk-card" data-uk-grid>
                    <div>
                        <span className="uk-text-bold">
                            Total uploaded file count:{" "}
                        </span>
                        <span>
                            {currentMonthUser.length
                                ? currentMonthUser[0].uploadCount
                                : 0}
                        </span>
                    </div>
                    <div>
                        <span className="uk-text-bold">
                            Total uploaded file size:{" "}
                        </span>
                        <span>
                            {currentMonthUser.length
                                ? (
                                      currentMonthUser[0].totalFileSize /
                                      (1024 * 1024)
                                  ).toFixed(2) + " MB"
                                : "0 MB"}
                        </span>
                    </div>
                    <div>
                        <span className="uk-text-bold">
                            Total uploaded file count by all users:{" "}
                        </span>
                        <span>
                            {currentMonthTotal.length
                                ? currentMonthTotal[0].uploadCount
                                : 0}
                        </span>
                    </div>
                    <div>
                        <span className="uk-text-bold">
                            Total uploaded file size by all users:{" "}
                        </span>
                        <span>
                            {currentMonthTotal.length
                                ? (
                                      currentMonthTotal[0].totalFileSize /
                                      (1024 * 1024)
                                  ).toFixed(2) + " MB"
                                : "0 MB"}
                        </span>
                    </div>
                </div>
                <hr />
                <span className="uk-text-bold">Previous Month:</span>
                <div className="uk-card" data-uk-grid>
                    <div>
                        <span className="uk-text-bold">
                            Total uploaded file count:{" "}
                        </span>
                        <span>
                            {previousMonthUser.length
                                ? previousMonthUser[0].uploadCount
                                : 0}
                        </span>
                    </div>
                    <div>
                        <span className="uk-text-bold">
                            Total uploaded file size:{" "}
                        </span>
                        <span>
                            {previousMonthUser.length
                                ? (
                                      previousMonthUser[0].totalFileSize /
                                      (1024 * 1024)
                                  ).toFixed(2) + " MB"
                                : "0 MB"}
                        </span>
                    </div>
                    <div>
                        <span className="uk-text-bold">
                            Total uploaded file count by all users:{" "}
                        </span>
                        <span>
                            {previousMonthTotal.length
                                ? previousMonthTotal[0].uploadCount
                                : 0}
                        </span>
                    </div>
                    <div>
                        <span className="uk-text-bold">
                            Total uploaded file size by all users:{" "}
                        </span>
                        <span>
                            {previousMonthTotal.length
                                ? (
                                      previousMonthTotal[0].totalFileSize /
                                      (1024 * 1024)
                                  ).toFixed(2) + " MB"
                                : "0 MB"}
                        </span>
                    </div>
                </div>
            </>
        );
    }

    renderAccountHolderSummery() {
        if (!this.state.accountHolder) {
            return null;
        }

        const { accountHolder } = this.state;

        return (
            <>
                <div className="uk-card" data-uk-grid>
                    <div>
                        <span className="uk-text-bold">User Id: </span>
                        <span className="uk-text-success">
                            {accountHolder.parentId
                                ? `${accountHolder.parentId}-`
                                : ""}
                            {accountHolder.country || "--"}-
                            {accountHolder.id || "--"}
                        </span>
                    </div>
                    <div>
                        <span className="uk-text-bold">Type: </span>
                        <span>
                            {!accountHolder.parentId ? "A/c Holder" : "User"}
                        </span>
                    </div>
                    <div>
                        <span className="uk-text-bold">Company Name: </span>
                        <span>{accountHolder.companyName || "--"}</span>
                    </div>
                    <div>
                        <span className="uk-text-bold">Country: </span>
                        <span>{accountHolder.country || "--"}</span>
                    </div>
                    <div>
                        <span className="uk-text-bold">City: </span>
                        <span>{accountHolder.city || "--"}</span>
                    </div>
                    <div>
                        <span className="uk-text-bold">
                            Latest User Email:{" "}
                        </span>
                        <span className="uk-text-success">
                            {accountHolder.email || "--"}
                        </span>
                    </div>
                    <div>
                        <span className="uk-text-bold">Purchase Date: </span>
                        <span>
                            {tools.formatDate(accountHolder.recordDateTime) ||
                                "--"}
                        </span>
                    </div>
                </div>
                <hr />
                {this.renderUpload()}
                <hr />
            </>
        );
    }

    render() {
        return (
            <main>
                <div
                    className="page uk-grid-small uk-grid-match uk-grid"
                    data-uk-grid
                >
                    <div className="menu-bar uk-background-secondary uk-visible@m">
                        <Toolbar />
                    </div>
                    <div className="uk-width-expand page-content">
                        <div className="ems-page-container ems-admin">
                            <div
                                style={{
                                    borderBottom: "2px solid #e5e5e5",
                                    background: "white"
                                }}
                                data-uk-sticky
                            >
                                <ReportTitle title="Admin" />
                                <Form
                                    onlyTitle={true}
                                    title="Admin: User Audit"
                                />
                            </div>
                            <div
                                className="uk-padding uk-flex uk-flex-column"
                                style={{
                                    borderTop: "2px solid rgb(229, 229, 229)"
                                }}
                            >
                                {this.renderAccountHolderSummery()}
                                <Table
                                    headers={this.headers}
                                    body={this.state.actionsToShowInTable}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modal-overflow" data-uk-modal>
                    <div className="uk-modal-dialog uk-width-8-10">
                        <button
                            className="uk-modal-close-default"
                            type="button"
                            data-uk-close
                        ></button>
                        <div className="uk-modal-header uk-text-center">
                            <h5 className="uk-margin-small-top">
                                Profile of Company and User
                            </h5>
                        </div>
                        <div className="uk-modal-body uk-overflow-auto">
                            {this.renderUserData()}
                        </div>
                    </div>
                </div>
                <Toolbar hidden={true} />
                <div
                    className={
                        window.innerWidth < 900
                            ? "uk-margin-top uk-flex uk-flex-right uk-text-meta"
                            : "footer-div uk-text-meta"
                    }
                >
                    <span>
                        Copyright &copy; 2019, {new Date().getFullYear()} Spenta
                        B.V., all rights reserved. Powered By codixt.com
                    </span>
                </div>
            </main>
        );
    }
}
