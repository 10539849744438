import React, { Component } from "react";
import * as shortId from "shortid";
import Accordion from "../accordion/accordionSection";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";

class CustomTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [...props.data],
            clickedRowIndex: -1,
            clickedColumnIdx: -1,
            valueIndex: 1,
            openedGroups: this.props.groups
                ? new Array(this.props.groups.length)
                : [],
            select: props.select,
            selected: Array.isArray(props.selected) ? [...props.selected] : [],
            dataToShow: [...props.data],
            selectedCheck: []
        };
        this.Headers = [];
        this.rowsCount = 0;
        this.rowClicked = -1;
        this.colClicked = -1;
        this.previousClickedRow = -1;
        this.headerRefs = {};
    }

    handleTdClick(
        data,
        value,
        headers,
        rowVal,
        colVal,
        rowIdx,
        colIdx,
        fromColumn,
        toColumn,
        isLeftSticky,
        isRightSticky
    ) {
        if (this.props.select) {
            return;
        }

        if (typeof this.props.trClickHandler === "function") {
            let headers = this.createHeaderArray();
            this.props.trClickHandler(
                this.createRow(
                    data,
                    this.state.IndexesToShowFrom,
                    this.state.IndexesToShowTo
                ),
                value,
                headers,
                rowVal,
                colVal,
                rowIdx,
                colIdx,
                fromColumn,
                toColumn,
                isLeftSticky,
                isRightSticky,
                this.getFooterContent()
            );
        }
        if (rowIdx !== this.state.clickedRowIndex) {
            this.setState({
                clickedRowIndex: rowIdx,
                clickedColumnIdx: colIdx
            });
        }
    }

    createTableHeader() {
        let tableHeader = [...this.props.headers];
        let headers = this.props.headers.concat();

        this.Headers = [...headers];
        let footers = [[]];
        let hasFooters = false;
        if (this.props.footers) {
            footers = this.getFooterContent();
            hasFooters = footers.length > 1;
        }

        tableHeader = headers.map((el, index) => {
            if (index === 0) {
                if (!hasFooters && headers.length < 6) {
                    return null;
                }
                return (
                    <div
                        className={
                            hasFooters
                                ? "ems-table-header-title uk-flex uk-flex-column"
                                : "ems-table-header-title"
                        }
                        style={{
                            marginLeft:
                                hasFooters && headers.length < 6
                                    ? "60px"
                                    : "0px"
                        }}
                        key={shortId.generate()}
                    >
                        <div key={shortId.generate()}>
                            <span>{el}</span>
                        </div>
                        {hasFooters ? (
                            <div key={shortId.generate()}>
                                <span>
                                    {isNaN(parseInt(footers[index]))
                                        ? footers[index]
                                        : footers[index].toLocaleString()}
                                </span>
                            </div>
                        ) : null}
                    </div>
                );
            }
            if (!hasFooters) {
                return (
                    <div
                        className={
                            this.props.select
                                ? `uk-width-${
                                      el.includes("Name")
                                          ? "2-5"
                                          : el.includes("Domain")
                                          ? "1-5"
                                          : "1-3"
                                  }`
                                : hasFooters
                                ? "uk-flex uk-flex-column"
                                : ""
                        }
                        key={shortId.generate()}
                        style={
                            this.props.select
                                ? {}
                                : index === 1
                                ? {
                                      marginLeft: this.props.user
                                          ? null
                                          : headers.length < 6
                                          ? "50px"
                                          : null
                                  }
                                : { width: this.props.user ? "20%" : null }
                        }
                    >
                        <div key={shortId.generate()}>
                            <span>{el}</span>
                        </div>
                    </div>
                );
            }

            return (
                <div
                    className={hasFooters ? "uk-flex uk-flex-column" : ""}
                    key={shortId.generate()}
                >
                    <div key={shortId.generate()}>
                        <span>{el}</span>
                    </div>
                    {hasFooters ? (
                        <div
                            key={shortId.generate()}
                            className={
                                isNaN(parseInt(footers[index]))
                                    ? "uk-text-bold"
                                    : parseInt(footers[index]) < 0
                                    ? "uk-text-bold uk-text-danger"
                                    : "uk-text-bold"
                            }
                        >
                            <span className="uk-text-bold">
                                {isNaN(parseInt(footers[index]))
                                    ? footers[index]
                                    : footers[index].toLocaleString()}
                            </span>
                        </div>
                    ) : null}
                </div>
            );
        });

        let tempTableHeader = [];
        tempTableHeader[0] = tableHeader[0];

        tempTableHeader[1] = (
            <div
                key={shortId.generate()}
                className={
                    this.props.select
                        ? "uk-flex uk-flex-row uk-margin-large-left"
                        : ""
                }
                style={{
                    paddingLeft: hasFooters
                        ? headers.length > 6
                            ? "50px"
                            : "0px"
                        : "5px"
                }}
            >
                {tableHeader.splice(1)}
            </div>
        );
        return (
            <div
                className={`ems-table${
                    this.props.user ? "-profile" : ""
                }-header-container uk-margin-small-top uk-margin-small-bottom`}
                key={shortId.generate()}
            >
                <div
                    className={
                        this.props.select
                            ? "uk-width-1-1"
                            : `uk-text-small ems-table${
                                  this.props.user ? "-profile" : ""
                              }-header uk-flex`
                    }
                    key={shortId.generate()}
                >
                    {tempTableHeader}
                </div>
            </div>
        );
    }

    handleInputChange(event, fieldName, id) {
        const value = event.target.value;
        const dataToShow = this.inputChanged(value, id);

        this.setState(
            prevState => {
                prevState[fieldName] = value;
                prevState[`${fieldName}Error`] = true;
                if (dataToShow.length) {
                    prevState.dataToShow = [...dataToShow];
                    prevState[`${fieldName}Error`] = false;
                }
                return prevState;
            },
            () => {
                this.headerRefs[fieldName].current.focus();
            }
        );
    }

    filterContent(values, input) {
        return values.filter(e =>
            e.toLowerCase().includes(input.toLowerCase())
        );
    }

    splitDataById(id) {
        const data = [...this.props.data];
        const res = data.map(el => el[id]);
        return res;
    }

    inputChanged(value, id) {
        const refs = Object.keys(this.headerRefs);

        let dataToShow = [];
        let currentValue = "";
        let allEmpty = true;
        for (let i = 0; i < refs.length; i++) {
            currentValue = this.headerRefs[refs[i]].current.value;
            if (currentValue) {
                allEmpty = false;
            }
        }
        if (allEmpty) {
            dataToShow = [...this.props.data];
            return dataToShow;
        }

        for (let i = 0; i < refs.length; i++) {
            currentValue = this.headerRefs[refs[i]].current.value;
            if (currentValue) {
                dataToShow = this.filterShowAccountIds(
                    id,
                    this.filterContent(this.splitDataById(id), currentValue)
                );
            }
        }

        return dataToShow;
    }

    filterShowAccountIds(colId, newValues) {
        const previousValues = [...this.state.dataToShow];
        const length = newValues.length;
        const filtered = previousValues
            .map((el, index) => {
                if (newValues.findIndex(inp => inp === el[colId]) > -1)
                    return previousValues[index];
                else {
                    return null;
                }
            })
            .filter(n => n);
        return filtered;
    }

    findSelectedRow(currentId) {
        const currentDataRow = this.state.dataToShow[currentId];
        const originalData = [...this.props.data];
        const rowLength = currentDataRow.length;
        let rowFound = false;
        for (let i = 0, n = originalData.length; i < n; i++) {
            rowFound = true;
            for (let j = 0; j < rowLength; j++) {
                if (originalData[i][j] !== currentDataRow[j]) {
                    rowFound = false;
                }
            }
            if (rowFound) {
                return i;
            }
        }
        return -1;
    }

    createTableFilter() {
        if (!this.props.filter) {
            return null;
        }
        let tableHeader = [...this.props.headers];
        if (!tableHeader.length) {
            return null;
        }

        let headers = this.props.headers.concat();

        if (!Object.keys(this.headerRefs).length) {
            for (let i = 1; i < tableHeader.length; i++) {
                this.headerRefs[tableHeader[i]] = React.createRef();
            }
        }

        this.Headers = [...headers];
        const placeHolders = this.props.placeHolders;

        tableHeader = headers.map((el, index) => {
            if (index === 0) {
                if (headers.length < 6) {
                    return null;
                }
                return (
                    <div
                        className={`ems-table${
                            this.props.user ? "-profile" : ""
                        }-header-title`}
                        style={{
                            marginLeft: headers.length < 6 ? "60px" : "0px"
                        }}
                        key={shortId.generate()}
                    >
                        <div key={shortId.generate()}>
                            <input
                                placeholder={placeHolders[index]}
                                onChange={event =>
                                    this.handleInputChange(event, el)
                                }
                                value={this.state[el]}
                            />
                        </div>
                    </div>
                );
            }

            return (
                <div
                    className={
                        this.props.select
                            ? `uk-width-${
                                  el.includes("Name")
                                      ? "2-5"
                                      : el.includes("Domain")
                                      ? "1-5"
                                      : "1-3"
                              }`
                            : ""
                    }
                    key={shortId.generate()}
                    style={
                        this.props.select
                            ? {}
                            : index === 1
                            ? {
                                  marginLeft:
                                      headers.length < 6 ? "50px" : "-30px"
                              }
                            : null
                    }
                >
                    <div key={shortId.generate()}>
                        <input
                            className={`${
                                el.includes("Name")
                                    ? "holder-input"
                                    : "uk-form-width-medium"
                            } uk-input ${
                                this.state[`${el}Error`] ? "uk-form-danger" : ""
                            }`}
                            placeholder={placeHolders[index]}
                            onChange={event =>
                                this.handleInputChange(event, el, index)
                            }
                            value={this.state[el]}
                            ref={this.headerRefs[el]}
                        />
                    </div>
                </div>
            );
        });

        let tempTableHeader = [];
        tempTableHeader[0] = tableHeader[0];

        tempTableHeader[1] = (
            <div
                key={shortId.generate()}
                className={
                    this.props.select
                        ? "uk-flex uk-flex-row uk-margin-large-left"
                        : ""
                }
                style={{ paddingLeft: "5px" }}
            >
                {tableHeader.splice(1)}
            </div>
        );
        return (
            <div
                className={`ems-table${
                    this.props.user ? "-profile" : ""
                }-header-container uk-margin-small-top uk-margin-small-bottom`}
                key={shortId.generate()}
            >
                <div
                    className={
                        this.props.select
                            ? "uk-width-1-1"
                            : `uk-text-small ems-table${
                                  this.props.user ? "-profile" : ""
                              }-header uk-flex`
                    }
                    key={shortId.generate()}
                >
                    {tempTableHeader}
                </div>
            </div>
        );
    }

    getFooterContent(showFrom, showTo) {
        return this.props.footers;
    }

    createTableFooter() {
        let footers = this.getFooterContent();

        let tableFooter = footers.map((el, index) => {
            if (index === 0) {
                return (
                    <div
                        className={`ems-table${
                            this.props.user ? "-profile" : ""
                        }-header-title`}
                        key={shortId.generate()}
                    >
                        <span>{el}</span>
                    </div>
                );
            }

            return (
                <div key={shortId.generate()}>
                    <span>{el === 0 || el ? el.toLocaleString() : null}</span>
                </div>
            );
        });

        let tempTableHeader = [];
        tempTableHeader[0] = tableFooter[0];
        tempTableHeader[1] = (
            <div key={shortId.generate()}>{tableFooter.splice(1)}</div>
        );
        return (
            <div
                className={`ems-table${
                    this.props.user ? "-profile" : ""
                }-header-container uk-margin-small-top uk-margin-small-bottom`}
            >
                <div
                    className={`uk-text-small ems-table${
                        this.props.user ? "-profile" : ""
                    }-header uk-flex`}
                >
                    {tempTableHeader}
                </div>
            </div>
        );
    }

    getTableBody(from, to) {
        let tableBody = [];
        let leftBody = [];
        let rightBody = [];
        let body = [];
        let tempData = this.state.dataToShow;
        let tempLength = 0;
        let sliceTo = 0;
        for (let i = 0, n = tempData.length; i < n; i++) {
            rightBody = [];
            body = [];
            tempLength = tempData[i].length;
            if (tempLength === 0) {
                // exclude empty rows
                continue;
            }

            sliceTo =
                tempLength > this.state.stickyLeftIndex
                    ? this.state.stickyLeftIndex
                    : tempLength;
            leftBody = tempData[i].slice(0, sliceTo);

            if (tempLength > this.state.IndexesToShowTo) {
                rightBody = tempData[i].slice(
                    tempData[i].length -
                        (this.props.right ? this.props.right : 3),
                    tempData[i].length
                );
                if (from > -1) {
                    body = tempData[i].slice(
                        from + this.state.stickyLeftIndex,
                        to + this.state.stickyLeftIndex
                    );
                } else {
                    body = tempData[i].slice(
                        this.state.IndexesToShowFrom +
                            this.state.stickyLeftIndex,
                        this.state.IndexesToShowTo + this.state.stickyLeftIndex
                    );
                }
            }
            tableBody[i] = leftBody.concat(body).concat(rightBody);
        }
        return tableBody;
    }

    createTableBodyForTableComponent() {
        let { noHighlight } = this.props;
        if (this.state.clickedRowIndex > -1) {
            if (
                this.previousClickedRow > -1 &&
                this[`tr_${this.previousClickedRow}`].current
            ) {
                if (this.previousClickedRow % 2 === 0)
                    this[
                        `tr_${this.previousClickedRow}`
                    ].current.style.backgroundColor = "#F8F8F8";
                else {
                    this[
                        `tr_${this.previousClickedRow}`
                    ].current.style.backgroundColor = "#FFF";
                }
                this[`tr_${this.previousClickedRow}`].current.style.color =
                    "#666";
            }
            if (this[`tr_${this.state.clickedRowIndex}`].current) {
                this[
                    `tr_${this.state.clickedRowIndex}`
                ].current.style.backgroundColor = "#6193BF";
                this[`tr_${this.state.clickedRowIndex}`].current.style.color =
                    "#EBF0F5";
            }
        }
        if (
            !this.props.groups &&
            this.createGroupedTableBodyObject &&
            this.props.useCache
        ) {
            let temp = this.createGroupedTableBodyObject;
            return temp;
        }
        let tableBody = this.state.dataToShow;
        let headers = [...this.props.headers];
        tableBody = tableBody.map((el, index) => {
            let temp = el;
            /** Changed to hidd Beginning and Net Change and End from list**/
            let noDetails = undefined;
            if (
                el[0] === "Beginning" ||
                el[0] === "Net Change" ||
                el[0] === "End"
            ) {
                noDetails = true;
            }
            noHighlight = this.props.noHighlight
                ? this.props.noHighlight
                : noDetails;
            el = el.map((col, idx) => {
                if (idx === 0) {
                    return (
                        <td
                            style={{
                                cursor: noHighlight ? "default" : "pointer"
                            }}
                            className={`ems-table${
                                this.props.user ? "-profile" : ""
                            }-header-title uk-padding-remove-horizontal`}
                            key={shortId.generate()}
                            onClick={() =>
                                this.handleTdClick(
                                    this.state.dataToShow[index],
                                    this.state.dataToShow[index][idx],
                                    [...this.Headers],
                                    this.state.dataToShow[index][0],
                                    this.Headers[idx],
                                    index,
                                    idx,
                                    this.state.IndexesToShowFrom,
                                    this.state.IndexesToShowTo,
                                    false,
                                    false
                                )
                            }
                        >
                            {col === 0 || col ? col.toLocaleString() : null}
                        </td>
                    );
                }

                return (
                    <div
                        key={shortId.generate()}
                        onClick={() => {
                            this.rowClicked = index;
                            this.colClicked = index;
                            this.handleTdClick(
                                this.state.dataToShow[index],
                                this.state.dataToShow[index][idx],
                                [...this.Headers],
                                this.state.dataToShow[index][0],
                                this.Headers[idx],
                                index,
                                idx,
                                this.state.IndexesToShowFrom,
                                this.state.IndexesToShowTo,
                                false,
                                false
                            );
                        }}
                        className={
                            this.props.select && headers[idx]
                                ? headers[idx].includes("Name")
                                    ? "uk-width-2-5"
                                    : headers[idx].includes("A/c")
                                    ? "uk-width-1-3"
                                    : ""
                                : ""
                        }
                        style={{ width: "20% !important" }}
                    >
                        <span
                            className={
                                this.props.select &&
                                this.state.selectedCheck.includes(index) &&
                                this.props.data[index].includes(el[1])
                                    ? "uk-text-break uk-text-bold uk-text-success"
                                    : col === true
                                    ? "uk-text-break uk-text-success"
                                    : col === false
                                    ? "uk-text-break  uk-text-danger"
                                    : isNaN(parseInt(col))
                                    ? "uk-text-break "
                                    : parseInt(col) < 0
                                    ? "uk-text-break uk-text-danger"
                                    : "uk-text-break"
                            }
                            data-uk-icon={
                                col === true
                                    ? "icon: check"
                                    : col === false
                                    ? "icon: warning"
                                    : null
                            }
                        >
                            {col === true || col === false
                                ? ""
                                : col === 0 || col
                                ? col.toLocaleString()
                                : null}
                        </span>
                    </div>
                );
            });
            el.push(
                index === this.state.clickedRowIndex &&
                    this.props.groups &&
                    this.props.groups.length > 0 ? (
                    <div
                        key={shortId.generate()}
                        style={{ cursor: noHighlight ? "default" : "pointer" }}
                    >
                        <span
                            style={{
                                cursor: noHighlight ? "default" : "pointer"
                            }}
                            className={
                                this.state.selectedCheck.includes(index)
                                    ? "uk-text-bold uk-text-success"
                                    : "uk-text-small"
                            }
                            uk-icon={noHighlight ? null : "more"}
                            uk-tooltip={noHighlight ? null : "Details"}
                            onClick={event =>
                                !noHighlight &&
                                this.handleRowButtonClick(event, temp)
                            }
                            onTouchStart={event =>
                                !noHighlight &&
                                this.handleRowButtonClick(event, temp)
                            }
                        ></span>
                    </div>
                ) : null
            );
            return el;
        });

        for (let i = 0, n = tableBody.length; i < n; i++) {
            this[`tr_${i}`] = React.createRef();
        }

        //const checkBoxes = this.props.data.filter()
        const toBeInBold = ["end", "increase", "decrease"];
        const { dataToShow } = this.state;
        tableBody = tableBody.map((el, index) => {
            return (
                <tr
                    ref={this[`tr_${index}`]}
                    className={
                        this.props.select
                            ? "uk-card uk-card-small uk-card-default uk-width-1-1"
                            : `uk-card uk-card-small uk-card-default ${
                                  dataToShow &&
                                  dataToShow[index][0] &&
                                  toBeInBold.includes(
                                      dataToShow[index][0].toLowerCase()
                                  )
                                      ? " uk-text-bold"
                                      : ""
                              }`
                    }
                    key={shortId.generate()}
                    onClick={() =>
                        this.handleTdClick(
                            this.state.dataToShow[index],
                            null,
                            [...this.Headers],
                            this.state.dataToShow[index][0],
                            null,
                            index,
                            -1,
                            this.state.IndexesToShowFrom,
                            this.state.IndexesToShowTo,
                            false,
                            false
                        )
                    }
                    style={
                        index === this.state.clickedRowIndex && !noHighlight
                            ? { backgroundColor: "#6193BF", color: "#EBF0F5" }
                            : index % 2 === 0
                            ? { backgroundColor: "#F8F8F8" }
                            : { backgroundColor: "#FFFF" }
                    }
                >
                    {[
                        !!this.props.select ? (
                            <td
                                style={{
                                    cursor: noHighlight ? "default" : "pointer",
                                    width: "10px"
                                }}
                                key={shortId.generate()}
                            >
                                <input
                                    type="checkbox"
                                    className="uk-checkbox"
                                    onChange={event =>
                                        this.handleCheckbox(
                                            index,
                                            event.target.checked
                                        )
                                    }
                                    checked={
                                        this.state.selectedCheck.includes(index) //&& this.state.dataToShow && this.props.data[index].includes(this.state.dataToShow[1])}
                                    }
                                    disabled={this.state.dataToShow[
                                        index
                                    ][1].includes("NA")}
                                />
                            </td>
                        ) : (
                            el[0]
                        ),
                        <td
                            style={{
                                cursor: noHighlight ? "default" : "pointer"
                            }}
                            className={
                                this.props.select
                                    ? "uk-width-1-1 uk-flex uk-flex-row uk-margin-medium-left"
                                    : ""
                            }
                            key={shortId.generate()}
                        >
                            {el.slice(1)}
                        </td>
                    ]}
                </tr>
            );
        });
        if (this.props.groups) {
            tableBody = (
                <ul
                    uk-accordion="multiple: true"
                    className="uk-accordion uk-overflow-auto"
                >
                    {this.createGroupedTableBody(tableBody)}
                </ul>
            );
        } else {
            tableBody = (
                <table
                    className={
                        this.props.select
                            ? "uk-table uk-table-divider uk-margin-remove"
                            : `uk-table uk-table-divider ems-table${
                                  this.props.user ? "-profile" : ""
                              } uk-margin-remove`
                    }
                >
                    <tbody
                        className={
                            this.props.select
                                ? "uk-text-small uk-width-1-1"
                                : "uk-text-small"
                        }
                    >
                        {tableBody}
                    </tbody>
                </table>
            );
        }
        this.createGroupedTableBodyObject = tableBody;
        return tableBody;
    }

    handleCheckbox(index, checked) {
        const realSelectedRow = this.findSelectedRow(index);
        if (checked) {
            this.setState(
                prevState => ({
                    selected: [...prevState.selected, realSelectedRow],
                    selectedCheck: [...prevState.selectedCheck, index]
                }),
                () => {
                    typeof this.props.selectedRows === "function" &&
                        this.props.selectedRows(this.state.selected);
                }
            );
        } else {
            this.setState(
                prevState => ({
                    selected: prevState.selected.filter(
                        el => el !== realSelectedRow
                    ),
                    selectedCheck: prevState.selectedCheck.filter(
                        el => el !== index
                    )
                }),
                () =>
                    typeof this.props.selectedRows === "function" &&
                    this.props.selectedRows(this.state.selected)
            );
        }
    }

    onKeyPressed = event => {
        if (
            event.code === "PageUp" ||
            event.code === "PageDown" ||
            event.code === "Home" ||
            event.code === "End" ||
            event.code === "ArrowUp" ||
            event.code === "ArrowDown"
        ) {
            event.preventDefault();
            event.stopPropagation();
        }
        if (event.code === "PageUp") {
            let rowsPerPage = this.props.rowsPerPage
                ? this.props.rowsPerPage
                : 1;
            let currentRowIndex =
                this.state.clickedRowIndex > -1
                    ? this.state.clickedRowIndex
                    : 0;
            let newRowIndex = currentRowIndex - rowsPerPage;

            if (currentRowIndex < rowsPerPage) {
                newRowIndex = 0;
            }

            if (this[`tr_${newRowIndex}`].current) {
                this[`tr_${newRowIndex}`].current.scrollIntoView({
                    block: "end",
                    inline: "nearest",
                    behaviour: "smooth"
                });
            }
            this.handleTdClick(
                this.state.dataToShow[newRowIndex],
                this.state.dataToShow[newRowIndex][this.state.clickedColumnIdx],
                [...this.Headers],
                this.state.dataToShow[newRowIndex][0],
                this.Headers[this.state.clickedColumnIdx],
                newRowIndex,
                this.state.clickedColumnIdx,
                this.state.IndexesToShowFrom,
                this.state.IndexesToShowTo,
                false,
                false
            );
        }
        if (event.code === "PageDown") {
            let rowsPerPage = this.props.rowsPerPage
                ? this.props.rowsPerPage
                : 1;
            let currentRowIndex =
                this.state.clickedRowIndex > -1
                    ? this.state.clickedRowIndex
                    : 0;
            let newRowIndex = currentRowIndex + rowsPerPage;

            if (newRowIndex > this.state.dataToShow.length - 1) {
                newRowIndex = this.state.dataToShow.length - 1;
            }

            if (
                this[`tr_${newRowIndex}`] &&
                this[`tr_${newRowIndex}`].current
            ) {
                this[`tr_${newRowIndex}`].current.scrollIntoView({
                    block: "end",
                    inline: "nearest",
                    behaviour: "smooth"
                });
                this.handleTdClick(
                    this.state.dataToShow[newRowIndex],
                    this.state.dataToShow[newRowIndex][
                        this.state.clickedColumnIdx
                    ],
                    [...this.Headers],
                    this.state.dataToShow[newRowIndex][0],
                    this.Headers[this.state.clickedColumnIdx],
                    newRowIndex,
                    this.state.clickedColumnIdx,
                    this.state.IndexesToShowFrom,
                    this.state.IndexesToShowTo,
                    false,
                    false
                );
            }
        }
        if (event.code === "Home") {
            if (this[`tr_0`].current)
                this[`tr_0`].current.scrollIntoView({
                    block: "end",
                    inline: "nearest",
                    behaviour: "smooth"
                });
            this.handleTdClick(
                this.state.dataToShow[0],
                this.state.dataToShow[0][this.state.clickedColumnIdx],
                [...this.Headers],
                this.state.dataToShow[0][0],
                this.Headers[this.state.clickedColumnIdx],
                0,
                this.state.clickedColumnIdx,
                this.state.IndexesToShowFrom,
                this.state.IndexesToShowTo,
                false,
                false
            );
        }
        if (event.code === "End") {
            if (this[`tr_${this.state.dataToShow.length - 1}`].current) {
                this[
                    `tr_${this.state.dataToShow.length - 1}`
                ].current.scrollIntoView({
                    block: "end",
                    inline: "nearest",
                    behaviour: "smooth"
                });
            }
            this.handleTdClick(
                this.state.dataToShow[this.state.dataToShow.length - 1],
                this.state.dataToShow[this.state.dataToShow.length - 1][
                    this.state.clickedColumnIdx
                ],
                [...this.Headers],
                this.state.dataToShow[this.state.dataToShow.length - 1][0],
                this.Headers[this.state.clickedColumnIdx],
                this.state.dataToShow.length - 1,
                this.state.clickedColumnIdx,
                this.state.IndexesToShowFrom,
                this.state.IndexesToShowTo,
                false,
                false
            );
        }
        if (event.code === "ArrowUp" && this.state.clickedRowIndex > -1) {
            if (
                this.state.clickedRowIndex === 1 ||
                this.state.clickedRowIndex === 0
            ) {
                if (this[`tr_0`].current)
                    this[`tr_0`].current.scrollIntoView({
                        block: "end",
                        inline: "end",
                        behaviour: "smooth"
                    });
            } else if (this[`tr_${this.state.clickedRowIndex - 1}`].current) {
                this[
                    `tr_${this.state.clickedRowIndex - 1}`
                ].current.scrollIntoView({
                    block: "end",
                    inline: "nearest",
                    behaviour: "smooth"
                });
            }
            let currentRowIndex = this.state.clickedRowIndex;
            if (currentRowIndex - 1 > -1) {
                currentRowIndex--;
                //this.setState({ clickedRowIndex: currentRowIndex });
                this.handleTdClick(
                    this.state.dataToShow[currentRowIndex],
                    this.state.dataToShow[currentRowIndex][
                        this.state.clickedColumnIdx
                    ],
                    [...this.Headers],
                    this.state.dataToShow[currentRowIndex][0],
                    this.Headers[this.state.clickedColumnIdx],
                    currentRowIndex,
                    this.state.clickedColumnIdx,
                    this.state.IndexesToShowFrom,
                    this.state.IndexesToShowTo,
                    false,
                    false
                );
            }
        }
        if (event.code === "ArrowDown" && this.state.clickedRowIndex > -1) {
            let currentRowIndex =
                this.state.clickedRowIndex > -1
                    ? this.state.clickedRowIndex
                    : 0;
            let newRowIndex = currentRowIndex + 1;
            if (
                this[`tr_${newRowIndex}`] &&
                this[`tr_${newRowIndex}`].current
            ) {
                this[`tr_${newRowIndex}`].current.scrollIntoView({
                    block: "end",
                    inline: "nearest",
                    behaviour: "smooth"
                });
            }

            if (currentRowIndex > this.state.dataToShow.length - 1) {
                newRowIndex = this.state.dataToShow.length - 1;
            }
            if (currentRowIndex < this.state.dataToShow.length) {
                currentRowIndex++;
                currentRowIndex =
                    currentRowIndex < this.state.dataToShow.length
                        ? currentRowIndex
                        : this.state.dataToShow.length - 1;
                this.handleTdClick(
                    this.state.dataToShow[currentRowIndex],
                    this.state.dataToShow[currentRowIndex][
                        this.state.clickedColumnIdx
                    ],
                    [...this.Headers],
                    this.state.dataToShow[currentRowIndex][0],
                    this.Headers[this.state.clickedColumnIdx],
                    currentRowIndex,
                    this.state.clickedColumnIdx,
                    this.state.IndexesToShowFrom,
                    this.state.IndexesToShowTo,
                    false,
                    false
                );
            }
        }
    };

    onScroll(e) {
        if (this.state.clickedRowIndex > 0) {
            let newRowIndex = Math.round(e.target.scrollTop / 54);
            this.setState({ clickedRowIndex: newRowIndex });
            this.handleTdClick(
                this.state.dataToShow[newRowIndex],
                this.state.dataToShow[newRowIndex][this.state.clickedColumnIdx],
                [...this.Headers],
                this.state.dataToShow[newRowIndex][0],
                this.Headers[this.state.clickedColumnIdx],
                newRowIndex,
                this.state.clickedColumnIdx,
                this.state.IndexesToShowFrom,
                this.state.IndexesToShowTo,
                false,
                false
            );
        }
    }

    componentWillUnmount() {
        if (this.props.useEvents) {
            window.removeEventListener("keydown", this.onKeyPressed, false);
        }
    }

    componentWillReceiveProps(nextProps, nextState) {
        if (
            nextProps.showFrom !== this.state.IndexesToShowFrom ||
            nextProps.showTo !== this.state.IndexesToShowTo
        ) {
            this.createGroupedTableBodyObject = null;
            this.setState({
                IndexesToShowFrom: nextProps.showFrom,
                IndexesToShowTo: nextProps.showTo,
                valueIndex: Math.floor(
                    nextProps.showTo / (nextProps.showTo - nextProps.showFrom)
                ),
                IndexToAddPerScroll: nextProps.showTo - nextProps.showFrom,
                clickedRowIndex: -1,
                dataToShow: [...nextProps.data]
            });
        } else {
            this.setState({
                dataToShow: [...nextProps.data]
            });
        }
    }

    handleRowButtonClick(event, data) {
        event.stopPropagation();
        if (typeof this.props.rowButtonClick === "function") {
            this.props.rowButtonClick(data);
        }
    }

    openChangeHandler(event, id) {
        let openedGroups = [...this.state.openedGroups];
        openedGroups[id] = !openedGroups[id];
        this.setState({ openedGroups });
    }

    createGroup(rows, title, id) {
        const key = shortId.generate();
        return (
            <Accordion
                title={title}
                key={shortId.generate()}
                closed={this.state.openedGroups[id]}
                id={key}
                openChangeHandler={event => this.openChangeHandler(event, id)}
            >
                <table
                    className={
                        window.innerWidth < 400
                            ? `uk-table uk-table-striped uk-table-responsive uk-table-divider ems-table${
                                  this.props.user ? "-profile" : ""
                              }`
                            : `uk-table uk-table-striped uk-table-divider ems-table${
                                  this.props.user ? "-profile" : ""
                              }`
                    }
                    aria-hidden="false"
                >
                    <tbody className="uk-text-small">{rows}</tbody>
                </table>
            </Accordion>
        );
    }

    createGroupedTableBody(tableRows) {
        let groups = [...this.props.groups];
        let firstGroupRow = groups[0].fromRow;
        let newRows = tableRows.slice(0, firstGroupRow);
        for (let i = 0, n = groups.length; i < n; i++) {
            newRows = newRows.concat(
                this.createGroup(
                    tableRows.slice(groups[i].fromRow, groups[i].toRow),
                    groups[i].title,
                    i
                )
            );
        }
        return newRows;
    }

    createRow(data, from, to) {
        return data;
    }

    createHeaderArray(showFrom, showTo) {
        return this.props.headers;
    }

    compareArray(arr1, arr2) {
        if (!arr1 || !arr2) {
            return false;
        }

        if (arr1.length !== arr2.length) {
            return true;
        }

        return JSON.stringify(arr1) !== JSON.stringify(arr2);
    }

    componentDidMount() {
        if (this.props.useEvents) {
            window.addEventListener("keydown", this.onKeyPressed, false);
        } else {
            window.removeEventListener("keydown", this.onKeyPressed);
        }
        this.createGroupedTableBodyObject = null;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (
            this.props.groups ||
            (nextProps.data.length === 1 && nextProps.data[0].length > 1) ||
            nextState.clickedRowIndex !== this.state.clickedRowIndex ||
            this.compareArray(nextProps.data, this.state.dataToShow) ||
            this.compareArray(this.props.headers, nextProps.headers) ||
            this.compareArray(nextProps.footers, this.props.footers)
        ) {
            if (nextState.clickedRowIndex !== this.state.clickedRowIndex) {
                this.previousClickedRow = this.state.clickedRowIndex;
            }
            this.createGroupedTableBodyObject = null;
            return true;
        }

        if (nextProps.data.length < 2) {
            return false;
        }

        return true;
    }

    buildTheRestTable() {}

    render() {
        const filter = this.createTableFilter();
        let headers = this.createTableHeader();
        let body = this.createTableBodyForTableComponent();

        return (
            <div className="uk-flex-column">
                {this.props.groups ? (
                    <div
                        className={`ems-table${
                            this.props.user ? "-profile" : ""
                        }-container`}
                    >
                        {headers}
                        {body}
                    </div>
                ) : (
                    <React.Fragment>
                        {headers}
                        {filter}
                        <div
                            className={
                                this.props.select
                                    ? ""
                                    : `uk-overflow-auto ems-table${
                                          this.props.user ? "-profile" : ""
                                      }-container`
                            }
                        >
                            <div
                                className={
                                    this.props.select ? "" : "uk-overflow"
                                }
                                ref="test"
                            >
                                {body}
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </div>
        );
    }
}

export default CustomTable;
