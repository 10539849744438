import React, { Component } from "react";
import * as tools from "../../tools";
import logo from "../report/form/logo.png";
import { Link, withRouter, Redirect } from "react-router-dom";
import Footer from "../footer/footer";
/**Class Forgot, handle forgot page */
class Forgot extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            exists: false,
            isSuccess: null,
            recEmail: "",
            isValid: null,
            isSet: false
        };
    }

    email = "";
    newPassword = "";
    retypedPassword = "";

    /**
     * sends forgot request
     * @param {event} event
     */
    handleClick(event) {
        event.preventDefault();
        var payload = {
            email: this.email
        };
        tools.axiosInstance
            .post("user/forgot", payload)
            .then(suc => {
                this.setState({
                    exists: suc.data.data[0].exists,
                    isSuccess: true
                });
            })
            .catch(err => {
                this.setState({ isSuccess: false });
            });
    }

    /**
     * set new password
     * @param {event} event
     */
    handleSetPasswordClick(event) {
        if (this.newPassword !== this.retypedPassword || !this.newPassword) {
            this.setState({ passwordDontMatch: true });
            return;
        }
        var payload = {
            email: this.state.recEmail,
            password: this.newPassword
        };
        tools.axiosInstance
            .post(`user/reset-password/${payload.email}`, payload)
            .then(suc => {
                this.setState({
                    exists: suc.data.data[0].exists,
                    isSuccess: true,
                    isSet: suc.data.data[0].set,
                    resetSuccess: true
                });
            })
            .catch(err => {
                this.setState({ isSuccess: false, resetSuccess: false });
            });
    }

    /**
     * render success message
     */
    renderSuccess() {
        if (this.state.resetSuccess) {
            return (
                <React.Fragment>
                    <div className="uk-text-justify uk-margin-top">
                        Your password has been set successfully, you can{" "}
                        <a href="\login">Login</a> using your new password
                    </div>
                    <div className="uk-text-center uk-text-center uk-margin-top">
                        <a
                            href="https://ems-soft.com/#about-us"
                            target="_blank"
                        >
                            <span className="uk-link uk-margin-right">
                                About
                            </span>
                        </a>
                        <a href="https://ems-soft.com/#contact" target="_blank">
                            <span className="uk-link">Contact Us</span>
                        </a>
                    </div>
                </React.Fragment>
            );
        }
        if (this.state.resetSuccess === false) {
            return (
                <React.Fragment>
                    <div className="uk-text-justify uk-margin-top">
                        Oops! Something went wrong, please{" "}
                        <Link
                            to="\forgot"
                            onClick={() => window.location.reload()}
                        >
                            try again
                        </Link>
                    </div>
                    <div className="uk-text-center uk-text-center uk-margin-top">
                        <Link to="/about">
                            <span className="uk-link uk-margin-right">
                                About
                            </span>
                        </Link>
                        <Link to="/contact">
                            <span className="uk-link">Contact Us</span>
                        </Link>
                    </div>
                </React.Fragment>
            );
        }
        if (this.state.isSet) {
            return <Redirect to="/login" />;
        }
        if (this.state.isValid) {
            return this.renderResetPassword();
        }
        if (this.state.isSuccess === true) {
            return (
                <React.Fragment>
                    <div className="uk-text-justify uk-margin-top">
                        {this.state.exists
                            ? `We have sent a recovery email to ${this.email} please check your inbox including the spam folder`
                            : `The email ${this.email} does not have an account, Create a new account`}
                        {this.state.exists ? null : (
                            <Link to="/register"> Sign up</Link>
                        )}
                    </div>
                    <div className="uk-text-center uk-text-center uk-margin-top">
                        <Link to="/about">
                            <span className="uk-link uk-margin-right">
                                About
                            </span>
                        </Link>
                        <Link to="/contact">
                            <span className="uk-link">Contact Us</span>
                        </Link>
                    </div>
                </React.Fragment>
            );
        }
        if (this.state.isSuccess === null) {
            return this.renderRegisterForm();
        }

        return (
            <React.Fragment>
                <div className="uk-text-justify uk-margin-top">
                    Oops! Something Went wrong please try again later, or
                    contact the Admin
                </div>
                <div className="uk-text-center uk-text-center uk-margin-top">
                    <Link to="/about">
                        <span className="uk-link uk-margin-right">About</span>
                    </Link>
                    <Link to="/contact">
                        <span className="uk-link">Contact Us</span>
                    </Link>
                </div>
            </React.Fragment>
        );
    }

    /**
     * renders forgot form
     */
    renderRegisterForm() {
        return (
            <React.Fragment>
                <form
                    className="uk-form-stacked uk-grid-small uk-child-width-1-1"
                    data-uk-grid
                    onSubmit={event => this.handleClick(event)}
                >
                    <div>
                        <label className="uk-form-label uk-text-bold">
                            Company/Business Email
                        </label>
                        <div className="uk-form-controls uk-inline uk-width-1-1">
                            <span
                                className="uk-form-icon"
                                uk-icon="icon: mail"
                            ></span>
                            <input
                                className="uk-input uk-border-rounded"
                                type="email"
                                placeholder="Company/Business Email"
                                onChange={event =>
                                    (this.email = event.target.value)
                                }
                            />
                        </div>
                    </div>
                    <div className="uk-margin-large-top">
                        <button
                            className="uk-button uk-button-primary uk-width-1-1 uk-border-rounded"
                            type="submit"
                        >
                            Reset
                        </button>
                    </div>
                    <div>
                        <p className="uk-text-small uk-text-center">
                            have an account?{" "}
                            <Link to="/login">
                                <span className="uk-link">Login</span>
                            </Link>
                        </p>
                    </div>
                </form>
                <div className="uk-text-center uk-text-center uk-margin-top">
                    <Link to="/about">
                        <span className="uk-link uk-margin-right">About</span>
                    </Link>
                    <Link to="/contact">
                        <span className="uk-link">Contact Us</span>
                    </Link>
                </div>
            </React.Fragment>
        );
    }

    componentDidMount() {
        let code = this.props.match.params.id;
        if (code) {
            tools.axiosInstance
                .get(`user/forgot/${code}`)
                .then(suc => {
                    if (suc.data.data[0].valid) {
                        let temp = Buffer.from(code, "base64").toString("utf8");
                        temp = temp.split(":");
                        if (temp.length !== 2) {
                            throw new Error("a");
                        }
                        this.setState({
                            recEmail: temp[0],
                            isSuccess: true,
                            exists: true,
                            isValid: true
                        });
                    }
                })
                .catch(err => {
                    this.setState({ isSuccess: false });
                });
        }
    }

    /**
     * render reset password form
     */
    renderResetPassword() {
        return (
            <React.Fragment>
                <form
                    className="uk-form-stacked uk-grid-small uk-child-width-1-1"
                    data-uk-grid
                >
                    <div>
                        <label className="uk-form-label uk-text-bold">
                            Password
                        </label>
                        <div className="uk-form-controls uk-inline uk-width-1-1">
                            <span
                                className="uk-form-icon"
                                uk-icon="icon: lock"
                            ></span>
                            <input
                                className="uk-input uk-border-rounded"
                                type="password"
                                placeholder="Password"
                                onChange={event =>
                                    (this.newPassword = event.target.value)
                                }
                            />
                        </div>
                    </div>

                    <div>
                        <label className="uk-form-label uk-text-bold">
                            Re-Type Password
                        </label>
                        <div className="uk-form-controls uk-inline uk-width-1-1">
                            <span
                                className="uk-form-icon"
                                uk-icon="icon: lock"
                            ></span>
                            <input
                                className="uk-input uk-border-rounded"
                                type="password"
                                placeholder="Password"
                                onChange={event =>
                                    (this.retypedPassword = event.target.value)
                                }
                            />
                        </div>
                    </div>

                    {this.state.passwordDontMatch ? (
                        <div className="uk-flex uk-flex-center uk-text-danger">
                            Password do not match!
                        </div>
                    ) : (
                        <div></div>
                    )}

                    <div className="uk-margin-large-top">
                        <span
                            className="uk-button uk-button-primary uk-width-1-1 uk-border-rounded"
                            onClick={event =>
                                this.handleSetPasswordClick(event)
                            }
                        >
                            Reset
                        </span>
                    </div>

                    <div>
                        <p className="uk-text-small uk-text-center">
                            Already have an account,{" "}
                            <Link to="/login">
                                <span className="uk-link"> Log In</span>
                            </Link>
                        </p>
                    </div>
                </form>
                <div className="uk-text-center uk-text-center uk-margin-top">
                    <Link to="/about">
                        <span className="uk-link uk-margin-right">About</span>
                    </Link>
                    <Link to="/contact">
                        <span className="uk-link">Contact Us</span>
                    </Link>
                </div>
            </React.Fragment>
        );
    }

    render() {
        return (
            <main>
                <div className="uk-width-1-1 uk-child-width-1-1 page uk-flex uk-flex-center uk-flex-middle uk-flex-wrap uk-flex-wrap-around uk-margin-large-top uk-hidden@m">
                    <img src={logo} alt="" className="uk-width-1-3" />
                    <div className="uk-flex uk-flex-center">
                        <div className="uk-width-4-5">
                            <div className="uk-flex uk-flex-center uk-flex-middle uk-height-1-1 uk-flex-column">
                                <h3 className="uk-text-bold">Reset Password</h3>
                                <div className="uk-flex uk-flex-center uk-flex-column">
                                    {this.renderSuccess()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="uk-width-1-1 page uk-flex uk-flex-center uk-flex-middle uk-flex-row uk-visible@m">
                    <div className="uk-width-1-2 uk-flex-right uk-flex uk-margin-large-right">
                        <div className="uk-width-4-5@m uk-width-3-5@l uk-width-2-5@xl">
                            <div className="uk-flex uk-flex-center uk-flex-middle uk-height-1-1 uk-flex-column">
                                <h3 className="uk-text-bold uk-margin-large-bottom">
                                    Reset Password
                                </h3>
                                <div className="uk-flex uk-flex-center uk-flex-column">
                                    {this.renderSuccess()}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-margin-large-left">
                        <div className="uk-margin-large-bottom"></div>
                        <img
                            src={logo}
                            alt=""
                            className="uk-width-4-5@m uk-width-3-5@l uk-width-2-5@xl"
                        />
                    </div>
                </div>
                <Footer />
            </main>
        );
    }
}

export default withRouter(Forgot);
