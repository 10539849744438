import React, { Component } from "react";

class Dropzone extends Component {
    constructor(props) {
        super(props);
        this.state = { hightlight: false };
        this.fileInputRef = React.createRef();

        this.openFileDialog = this.openFileDialog.bind(this);
        this.onFilesAdded = this.onFilesAdded.bind(this);
        this.onDragOver = this.onDragOver.bind(this);
        this.onDragLeave = this.onDragLeave.bind(this);
        this.onDrop = this.onDrop.bind(this);
    }

    openFileDialog() {
        if (this.props.disabled) return;
        this.fileInputRef.current.click();
    }

    onFilesAdded(evt) {
        if (this.props.disabled) return;
        const files = evt.target.files;
        if (this.props.onFilesAdded) {
            const array = this.fileListToArray(files);
            this.props.onFilesAdded(array);
        }
    }

    onDragOver(event) {
        event.preventDefault();
        if (this.props.disabed) return;
        this.setState({ hightlight: true });
    }

    onDragLeave(event) {
        event.preventDefault();
        this.setState({ hightlight: false });
    }

    onDrop(event) {
        event.preventDefault();
        if (this.props.disabed) return;
        const files = event.dataTransfer.files;
        if (this.props.onFilesAdded) {
            const array = this.fileListToArray(files);
            this.props.onFilesAdded(array);
        }
        this.setState({ hightlight: false });
    }

    fileListToArray(list) {
        const array = [];
        for (var i = 0; i < list.length; i++) {
            array.push(list.item(i));
        }
        return array;
    }

    /**onDragOver={this.onDragOver}
                onDragLeave={this.onDragLeave}
                onDrop={this.onDrop} */
    render() {
        return (
            <div className="uk-placeholder uk-text-center uk-border-rounded uk-margin-left">
                <div className="uk-flex uk-flex-column">
                    <span uk-icon="icon: cloud-upload;ratio: 1.5"></span>
                    <div className="uk-margin-small-top">
                        <div className="uk-form-custom pointer">
                            <input
                                ref={this.fileInputRef}
                                type="file"
                                multiple
                                onChange={this.onFilesAdded}
                                style={{ display: "none" }}
                                accept=".csv"
                            />
                            <span
                                className="uk-text-primary pointer"
                                onClick={event => this.openFileDialog()}
                            >
                                &nbsp;Browse your files
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Dropzone;
