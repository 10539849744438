import React, { Component } from "react";
import { Bar, Line } from "react-chartjs-2";
/**Class chart */
class Chart extends Component {
    /**Constructor */
    constructor(props) {
        super(props);
        this.state = {
            types: this.props.types ? [...this.props.types] : ["Line", "Bar"],
            dataSetToPlot: [...this.props.data],
            data: [...this.props.data],
            title: [...this.props.title],
            chartType: this.props.types ? [...this.props.types][0] : "Line"
        };
        this.colors = this.props.colors
            ? [...this.props.colors]
            : [
                  {
                      color: "rgba(6,71,137,0.2)",
                      border: "rgba(6,71,137,0.6)",
                      hoverColor: "rgba(6,71,137,0.6)",
                      hoverBorder: "rgba(6,71,137,1)"
                  },
                  {
                      color: "rgba(211,21,49,0.2)",
                      border: "rgba(211,21,49,0.6)",
                      hoverColor: "rgba(211,21,49,0.6)",
                      hoverBorder: "rgba(211,21,49,1)"
                  },
                  {
                      color: "rgba(103,148,54,0.2)",
                      border: "rgba(103,148,54,0.6)",
                      hoverColor: "rgba(103,148,54,0.6)",
                      hoverBorder: "rgba(103,148,54,1)"
                  },
                  {
                      color: "rgba(247,225,31,0.2)",
                      border: "rgba(247,225,31,0.6)",
                      hoverColor: "rgba(247,225,31,0.6)",
                      hoverBorder: "rgba(247,225,31,1)"
                  },
                  {
                      color: "rgba(2,10,33,0.2)",
                      border: "rgba(2,10,33,0.6)",
                      hoverColor: "rgba(2,10,33,0.6)",
                      hoverBorder: "rgba(2,10,33,1)"
                  }
              ];
    }

    /**
     * create chart object
     * see chartjs documentation
     */
    createChart() {
        let data = {
            labels: this.props.title,
            datasets: []
        };
        let suggestedMin = Infinity;
        let suggestedMax = 0;
        let tempMin = Infinity;
        let tempMax = 0;
        for (let i = 0; i < this.props.legend.length; i++) {
            if (!this.props.data[i]) {
                continue;
            }
            data.datasets.push({
                label: this.props.legend[i],
                backgroundColor: this.colors[i].color,
                borderColor: this.colors[i].border,
                borderWidth: 1,
                hoverBackgroundColor: this.colors[i].hoverColor,
                hoverBorderColor: this.colors[i].hoverBorder,
                data: [...this.props.data[i]]
            });
            tempMin = this.props.data[i].reduce((p, v) => (p < v ? p : v));
            tempMax = this.props.data[i].reduce((p, v) => (p > v ? p : v));

            suggestedMin = suggestedMin < tempMin ? suggestedMin : tempMin;
            suggestedMax = suggestedMax > tempMax ? suggestedMax : tempMax;
        }
        let options = {
            maintainAspectRatio: false,
            // scales: {
            //     xAxes: [{ stacked: true }],
            //     yAxes: [
            //         {
            //             stacked: true,
            //             ticks: { max: suggestedMax * 2, min: suggestedMin }
            //         }
            //     ]
            // },
            scales: {
                yAxes: [
                    {
                        ticks: {
                            beginAtZero: true
                        }
                    }
                ]
            },
            responsive: true
        };
        switch (this.props.chartType) {
            case "Line":
                return <Line data={data} options={options} ref={"chart"} />;
            case "Bar":
                return <Bar options={options} data={data} ref={"chart"} />;
            default:
                return <Line data={data} options={options} />;
        }
    }

    render() {
        return (
            <div className="uk-card uk-card-small uk-width-1-1 ems-chart">
                {this.createChart()}
            </div>
        );
    }
}

export default Chart;
