import React, { Component } from "react";

class Progress extends Component {
    constructor(props) {
        super(props);
        this.state = { ...props };
    }

    componentWillReceiveProps(nextProps, nextState) {
        this.setState({ ...nextProps });
    }

    render() {
        if (
            this.state.completed === false ||
            this.state.completed === null ||
            this.state.completed === undefined
        ) {
            return (
                <div className="uk-flex uk-flex-between uk-flex-row uk-margin-small-top">
                    <span className="uk-flex uk-flex-left">
                        {this.state.title ? this.state.title : " Uploading ..."}
                    </span>
                    <span className="uk-flex uk-flex-right">Uploading ...</span>
                </div>
            );
        } else {
            return (
                <div className="uk-flex uk-flex-between uk-flex-row uk-margin-small-top">
                    <span className="uk-flex uk-flex-left">
                        {this.state.title}
                    </span>
                    <span className="uk-flex uk-flex-left">
                        {this.state.type}
                    </span>
                    <div className="uk-flex uk-flex-right">
                        <span
                            className={`uk-margin-small-left ${
                                this.state.hasError ? "uk-text-danger" : ""
                            }`}
                            uk-icon={
                                this.state.hasError
                                    ? "icon:close"
                                    : "icon:check"
                            }
                        ></span>
                    </div>
                </div>
            );
        }
    }
}

export default Progress;
