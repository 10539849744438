import React, { Component } from "react";
import * as tools from "../../tools";
import { Link, withRouter, Redirect } from "react-router-dom";
import logo from "../report/form/logo.png";
import Footer from "../footer/footer";

class VerifyEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            exists: false,
            isSuccess: false,
            recEmail: "",
            isValid: null,
            isSet: false,
            valid: false
        };
    }

    keys = [
        "initial",
        "firstName",
        "lastName",
        "contactType",
        "company",
        "companyTelephone",
        "mobile",
        "country",
        "city",
        "address",
        "zipCode",
        "password"
    ];

    required = [
        "Initials",
        "First Name",
        "Last Name",
        "Position",
        "Company",
        "Company Telephone",
        "Mobile",
        "Country",
        "City",
        "Address",
        "ZipC ode",
        "Password"
    ];

    handleClick(event) {
        event.preventDefault();
        let payload = {
            ...this.state
        };
        payload.email = this.state.recEmail;
        let valid = true;
        let required = [];
        for (let key in this.keys) {
            if (!payload[this.keys[key]]) {
                valid = false;
                required.push(this.required[key]);
            }
        }
        if (payload.accountNumber) {
            if (!payload.accountNumber.includes("-")) {
                valid = false;
                required.push("Company ID");
            }

            const splitted = payload.accountNumber.split("-");
            if (splitted.length !== 2) {
                valid = false;
                required.push("Company ID");
            }

            if (isNaN(parseInt(splitted[1]))) {
                valid = false;
                required.push("Company ID");
            } else {
                payload.accountNumber = splitted[1];
            }
        }

        required = [...new Set(required)];
        this.setState({ valid, required });
        if (valid) {
            tools.axiosInstance
                .post("user", payload)
                .then(suc => {
                    if (suc.data.data[0].isNew) {
                        this.setState({ isSet: true });
                    } else {
                        return <Redirect to="/register" />;
                    }
                })
                .catch(err => {
                    //this.setState({ isSuccess: false });
                    // console.log("click err", err);
                });
        }
    }

    handleInputChange(event, variableName) {
        let tempState = {};
        tempState[variableName] = event.target.value;
        this.setState(tempState);
    }

    componentDidMount() {
        let code = this.props.match.params.id;
        if (code) {
            tools.axiosInstance
                .post(`user/validate-email-code`, { code })
                .then(suc => {
                    if (suc.data.data[0].validResult) {
                        let temp = suc.data.data[0].email;

                        this.setState({
                            recEmail: temp,
                            isSuccess: true,
                            exists: true,
                            isValid: true
                        });
                    } else {
                        this.setState({
                            isSuccess: false,
                            exists: false,
                            isValid: false
                        });
                    }
                })
                .catch(err => {
                    this.setState({ isSuccess: false, isValid: false });
                });
        } else {
            this.props.history.push("/register");
        }
    }

    renderCompleted() {
        return (
            <>
                <div className="uk-width-1-1 uk-child-width-1-1 page uk-flex uk-flex-center uk-flex-middle uk-flex-wrap uk-flex-wrap-around uk-margin-large-top uk-hidden@m">
                    <img
                        src={logo}
                        alt=""
                        className="uk-width-1-3 uk-margin-xlarge-top"
                    />
                    <div className="uk-flex uk-flex-center">
                        <div className="uk-width-4-5">
                            <div className="uk-flex uk-flex-center uk-flex-middle uk-flex-column">
                                <h3 className="uk-text-bold">
                                    Signup Completed Successfully!
                                </h3>
                                <div className="uk-flex uk-flex-center uk-flex-column">
                                    <p className="uk-padding-medium uk-text-justify uk-margin-top">
                                        <span className="uk-text-justify">
                                            Thank you for registration, your
                                            registration has been completed. A
                                            representative of EMS will contact
                                            you.
                                        </span>{" "}
                                    </p>
                                    <p className="uk-text-justify">EMS TEAM</p>
                                    <div className="uk-text-center uk-text-center uk-margin-top">
                                        <a
                                            href="https://ems-soft.com/#about-us"
                                            target="_blank"
                                        >
                                            <span className="uk-link uk-margin-right">
                                                About
                                            </span>
                                        </a>
                                        <a
                                            href="https://ems-soft.com/#contact"
                                            target="_blank"
                                        >
                                            <span className="uk-link">
                                                Contact Us
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="uk-width-1-1 page uk-flex uk-flex-center uk-flex-middle uk-flex-row uk-visible@m">
                    <div className="uk-width-1-2 uk-flex-right uk-flex uk-margin-large-right">
                        <div className="uk-width-4-5@m uk-width-3-5@l uk-width-2-5@xl">
                            <h3 className="uk-text-bold uk-text-center uk-margin-large-bottom">
                                Signup Completed Successfully!
                            </h3>
                            <div className="uk-flex uk-flex-center uk-flex-column">
                                <p className="uk-padding-medium uk-text-justify uk-margin-xlarge-top">
                                    <span className="uk-text-justify">
                                        Thank you for registration, your
                                        registration has been completed. A
                                        representative of EMS will contact you.
                                    </span>{" "}
                                </p>
                                <p className="uk-text-justify">EMS TEAM</p>
                                <div className="uk-text-center uk-text-center uk-margin-top">
                                    <a
                                        href="https://ems-soft.com/#about-us"
                                        target="_blank"
                                    >
                                        <span className="uk-link uk-margin-right">
                                            About
                                        </span>
                                    </a>
                                    <a
                                        href="https://ems-soft.com/#contact"
                                        target="_blank"
                                    >
                                        <span className="uk-link">
                                            Contact Us
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-margin-large-left">
                        <div className="uk-margin-large-bottom"></div>
                        <img
                            src={logo}
                            alt=""
                            className="uk-width-4-5@m uk-width-3-5@l uk-width-2-5@xl"
                        />
                    </div>
                </div>
            </>
        );
    }

    renderCountry() {
        const countriesList = [
            { country: "Select your domain", code: "" },
            { country: "Austria", code: "AT" },
            { country: "Bosnia & Herzegovina", code: "BAH" },
            { country: "Republika Srpska", code: "BAS" },
            { country: "Belgium", code: "BE" },
            { country: "Belgium (Brussels)", code: "BEB" },
            { country: "Belgium (Flanders)", code: "BEF" },
            { country: "Belgium (Wallonia)", code: "BEW" },
            { country: "Bulgaria", code: "BG" },
            { country: "Switzerland", code: "CH" },
            { country: "Cyprus", code: "CY" },
            { country: "Czech Republic", code: "CZ" },
            { country: "Germany", code: "DE" },
            { country: "Denmark", code: "DK" },
            { country: "Estonia", code: "EE" },
            { country: "Spain", code: "ES" },
            { country: "Finland", code: "FI" },
            { country: "France", code: "FR" },
            { country: "United Kingdom", code: "GB" },
            { country: "Greece (interconnected)", code: "GRI" },
            { country: "Greece (non- interconnected)", code: "GRN" },
            { country: "Greece (unconnected autonomous)", code: "GRU" },
            { country: "Croatia", code: "HR" },
            { country: "Hungary", code: "HU" },
            { country: "Ireland", code: "IE" },
            { country: "Iceland", code: "IS" },
            { country: "Italy", code: "IT" },
            { country: "Lithuania", code: "LT" },
            { country: "Luxembourg", code: "LU" },
            { country: "Latvia", code: "LV" },
            { country: "Montenegro", code: "ME" },
            { country: "Malta", code: "MT" },
            { country: "Netherlands", code: "NL" },
            { country: "Norway", code: "NO" },
            { country: "Poland", code: "PL" },
            { country: "Portugal", code: "PT" },
            { country: "Serbia", code: "RS" },
            { country: "Sweden", code: "SE" },
            { country: "Slovenia", code: "SI" },
            { country: "Slovakia", code: "SK" }
        ];

        return (
            <select
                className="uk-select"
                onChange={event => this.handleInputChange(event, "country")}
            >
                {countriesList.map(el => (
                    <option
                        key={`${el.country}-${el.code}-verify`}
                        value={el.code}
                    >
                        {`${el.country} (${el.code})`}
                    </option>
                ))}
            </select>
        );
    }

    render() {
        return (
            <main>
                {this.state.isSet ? (
                    this.renderCompleted()
                ) : (
                    <>
                        <div className="uk-width-1-1 uk-height-1-1 uk-flex uk-flex-middle uk-flex-column uk-hidden@m">
                            <div className="uk-width-1-1">
                                <React.Fragment>
                                    {this.state.isSuccess ? (
                                        <React.Fragment>
                                            <div className="uk-width-1-1 uk-flex uk-flex-center uk-flex-middle uk-flex-column uk-margin-small-top">
                                                <img
                                                    src={logo}
                                                    alt=""
                                                    className="uk-width-2-5"
                                                />
                                                <h5 className="uk-text-bold uk-margin-large-bottom uk-margin-large-top">
                                                    Welcome to EMS!
                                                </h5>
                                                <p className="uk-text-break uk-margin-remove-top uk-text-justify uk-width-4-5">
                                                    Please fill these forms and
                                                    give us more information
                                                    about your company
                                                </p>
                                                <div className="uk-flex uk-flex-center uk-flex-middle uk-flex-column">
                                                    <form
                                                        className="uk-form-stacked uk-grid-small uk-width-4-5 uk-child-width-1-1"
                                                        data-uk-grid
                                                        onSubmit={event =>
                                                            this.handleClick(
                                                                event
                                                            )
                                                        }
                                                    >
                                                        <div>
                                                            <label className="uk-form-label uk-text-bold">
                                                                Registered Email
                                                            </label>
                                                            <input
                                                                className="uk-input uk-border-rounded"
                                                                type="text"
                                                                value={
                                                                    this.state
                                                                        .recEmail
                                                                }
                                                                disabled
                                                            />
                                                        </div>
                                                        <div>
                                                            <label className="uk-form-label uk-text-bold">
                                                                Initials&nbsp;
                                                                <span className="uk-text-danger">
                                                                    *
                                                                </span>
                                                            </label>
                                                            <input
                                                                className="uk-input uk-border-rounded"
                                                                type="text"
                                                                placeholder="Initials"
                                                                onChange={event =>
                                                                    this.handleInputChange(
                                                                        event,
                                                                        "initial"
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div>
                                                            <label className="uk-form-label uk-text-bold">
                                                                First name&nbsp;
                                                                <span className="uk-text-danger">
                                                                    *
                                                                </span>
                                                            </label>
                                                            <input
                                                                className="uk-input uk-border-rounded"
                                                                type="text"
                                                                placeholder="First name"
                                                                onChange={event =>
                                                                    this.handleInputChange(
                                                                        event,
                                                                        "firstName"
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div>
                                                            <label className="uk-form-label uk-text-bold">
                                                                Last name&nbsp;
                                                                <span className="uk-text-danger">
                                                                    *
                                                                </span>
                                                            </label>
                                                            <input
                                                                className="uk-input uk-border-rounded"
                                                                type="text"
                                                                placeholder="Last name"
                                                                onChange={event =>
                                                                    this.handleInputChange(
                                                                        event,
                                                                        "lastName"
                                                                    )
                                                                }
                                                            />
                                                        </div>

                                                        <div>
                                                            <label className="uk-form-label uk-text-bold">
                                                                Business
                                                                Position&nbsp;
                                                                <span className="uk-text-danger">
                                                                    *
                                                                </span>
                                                            </label>
                                                            <input
                                                                className="uk-input uk-border-rounded"
                                                                type="text"
                                                                placeholder="CEO, ..."
                                                                onChange={event =>
                                                                    this.handleInputChange(
                                                                        event,
                                                                        "contactType"
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div>
                                                            <label className="uk-form-label uk-text-bold">
                                                                Company
                                                                name&nbsp;
                                                                <span className="uk-text-danger">
                                                                    *
                                                                </span>
                                                            </label>
                                                            <input
                                                                className="uk-input uk-border-rounded"
                                                                type="text"
                                                                placeholder="Company name"
                                                                onChange={event =>
                                                                    this.handleInputChange(
                                                                        event,
                                                                        "company"
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div>
                                                            <label className="uk-form-label uk-text-bold">
                                                                Company ID (fill
                                                                in only if you
                                                                are NOT an
                                                                account holder.
                                                                Thus, you are a
                                                                User)
                                                            </label>
                                                            <input
                                                                className="uk-input uk-border-rounded"
                                                                type="text"
                                                                placeholder="Company ID"
                                                                onChange={event =>
                                                                    this.handleInputChange(
                                                                        event,
                                                                        "accountNumber"
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div>
                                                            <label className="uk-form-label uk-text-bold">
                                                                Company
                                                                Telephone&nbsp;
                                                                <span className="uk-text-danger">
                                                                    *
                                                                </span>
                                                            </label>
                                                            <input
                                                                className="uk-input uk-border-rounded"
                                                                type="text"
                                                                placeholder="Company Telephone"
                                                                onChange={event =>
                                                                    this.handleInputChange(
                                                                        event,
                                                                        "companyTelephone"
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div>
                                                            <label className="uk-form-label uk-text-bold">
                                                                Mobile
                                                                Number&nbsp;
                                                                <span className="uk-text-danger">
                                                                    *
                                                                </span>
                                                            </label>
                                                            <input
                                                                className="uk-input uk-border-rounded"
                                                                type="text"
                                                                placeholder="Mobile Number"
                                                                onChange={event =>
                                                                    this.handleInputChange(
                                                                        event,
                                                                        "mobile"
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div>
                                                            <label className="uk-form-label uk-text-bold">
                                                                Country&nbsp;
                                                                <span className="uk-text-danger">
                                                                    *
                                                                </span>
                                                            </label>
                                                            {this.renderCountry()}
                                                        </div>
                                                        <div>
                                                            <label className="uk-form-label uk-text-bold">
                                                                City&nbsp;
                                                                <span className="uk-text-danger">
                                                                    *
                                                                </span>
                                                            </label>
                                                            <input
                                                                className="uk-input uk-border-rounded"
                                                                type="text"
                                                                placeholder="City"
                                                                onChange={event =>
                                                                    this.handleInputChange(
                                                                        event,
                                                                        "city"
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div>
                                                            <label className="uk-form-label uk-text-bold">
                                                                Address&nbsp;
                                                                <span className="uk-text-danger">
                                                                    *
                                                                </span>
                                                            </label>
                                                            <input
                                                                className="uk-input uk-border-rounded"
                                                                type="text"
                                                                placeholder="Address"
                                                                onChange={event =>
                                                                    this.handleInputChange(
                                                                        event,
                                                                        "address"
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div>
                                                            <label className="uk-form-label uk-text-bold">
                                                                Zip Code&nbsp;
                                                                <span className="uk-text-danger">
                                                                    *
                                                                </span>
                                                            </label>
                                                            <input
                                                                className="uk-input uk-border-rounded"
                                                                type="text"
                                                                placeholder="Zip Code"
                                                                onChange={event =>
                                                                    this.handleInputChange(
                                                                        event,
                                                                        "zipCode"
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div>
                                                            <label className="uk-form-label uk-text-bold">
                                                                Trade Number
                                                            </label>
                                                            <input
                                                                className="uk-input uk-border-rounded"
                                                                type="text"
                                                                placeholder="Trade Number"
                                                                onChange={event =>
                                                                    this.handleInputChange(
                                                                        event,
                                                                        "companyTradeNumber"
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div>
                                                            <label className="uk-form-label uk-text-bold">
                                                                Fiscal Number
                                                            </label>
                                                            <input
                                                                className="uk-input uk-border-rounded"
                                                                type="text"
                                                                placeholder="Fiscal Number"
                                                                onChange={event =>
                                                                    this.handleInputChange(
                                                                        event,
                                                                        "companyFiscalNumber"
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div>
                                                            <label className="uk-form-label uk-text-bold">
                                                                RESC Code
                                                            </label>
                                                            <input
                                                                className="uk-input uk-border-rounded"
                                                                type="text"
                                                                placeholder="RESC Code"
                                                                onChange={event =>
                                                                    this.handleInputChange(
                                                                        event,
                                                                        "rescCode"
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div>
                                                            <label className="uk-form-label uk-text-bold">
                                                                Password&nbsp;
                                                                <span className="uk-text-danger">
                                                                    *
                                                                </span>
                                                            </label>
                                                            <div className="uk-form-controls uk-inline uk-width-1-1">
                                                                <span
                                                                    className="uk-form-icon"
                                                                    uk-icon="icon: lock"
                                                                ></span>
                                                                <input
                                                                    className="uk-input uk-border-rounded"
                                                                    type="password"
                                                                    placeholder="Password"
                                                                    onChange={event =>
                                                                        this.handleInputChange(
                                                                            event,
                                                                            "password"
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <label className="uk-form-label uk-text-bold">
                                                                Confirm
                                                                Password&nbsp;
                                                                <span className="uk-text-danger">
                                                                    *
                                                                </span>
                                                            </label>
                                                            <div className="uk-form-controls uk-inline uk-width-1-1">
                                                                <span
                                                                    className="uk-form-icon"
                                                                    uk-icon="icon: lock"
                                                                ></span>
                                                                <input
                                                                    className="uk-input uk-border-rounded"
                                                                    type="password"
                                                                    placeholder="Password"
                                                                />
                                                            </div>
                                                        </div>

                                                        {this.state.valid ? (
                                                            <div></div>
                                                        ) : (
                                                            <div>
                                                                <label className="uk-form-label uk-text-bold uk-text-danger">
                                                                    All fields
                                                                    with&nbsp;
                                                                    <span className="uk-text">
                                                                        *
                                                                    </span>{" "}
                                                                    are required
                                                                </label>
                                                            </div>
                                                        )}
                                                        <div>
                                                            <p className="uk-text-small uk-text-justify">
                                                                By signing up,
                                                                you agree to our{" "}
                                                                <a
                                                                    href="https://ems-soft.com/terms-of-service/"
                                                                    target="_blank"
                                                                >
                                                                    <span className="uk-text-link">
                                                                        Terms of
                                                                        Services
                                                                    </span>
                                                                </a>{" "}
                                                                and{" "}
                                                                <a
                                                                    href="https://ems-soft.com/privacy/"
                                                                    target="_blank"
                                                                    className="uk-margin-remove"
                                                                >
                                                                    <span className="uk-text-link">
                                                                        Privacy
                                                                        Policy
                                                                    </span>
                                                                </a>
                                                                .
                                                            </p>
                                                        </div>
                                                        <div className="uk-margin-top">
                                                            <button className="uk-button uk-button-primary uk-width-1-1 uk-border-rounded uk-margin-top">
                                                                Sign up
                                                            </button>
                                                        </div>
                                                        <div>
                                                            <p className="uk-text-small uk-text-center">
                                                                Already have an
                                                                account ?{" "}
                                                                <Link to="/login">
                                                                    <span className="uk-link">
                                                                        Login
                                                                    </span>
                                                                </Link>
                                                            </p>
                                                        </div>
                                                    </form>
                                                    <div className="uk-text-center uk-text-center uk-margin-top">
                                                        <a
                                                            href="https://ems-soft.com/#about-us"
                                                            target="_blank"
                                                        >
                                                            <span className="uk-link uk-margin-right">
                                                                About
                                                            </span>
                                                        </a>
                                                        <a
                                                            href="https://ems-soft.com/#contact"
                                                            target="_blank"
                                                        >
                                                            <span className="uk-link">
                                                                Contact Us
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    ) : (
                                        <div className="uk-flex uk-flex-center uk-flex-middle uk-flex-column uk-height-1-1 uk-text-danger uk-text-justify">
                                            <img
                                                src={logo}
                                                alt=""
                                                className="uk-width-2-3 uk-margin-xlarge-top"
                                            />
                                            <div className="uk-width-3-5 uk-margin-xlarge-top">
                                                <p>
                                                    Oops! It seems your link
                                                    either has been used before
                                                    or invalid! please&nbsp;
                                                    <Link to="/register">
                                                        re-register
                                                    </Link>
                                                    &nbsp; again
                                                </p>
                                                <p>
                                                    If none of the above is
                                                    correct, please contact EMS
                                                    TEAM
                                                </p>
                                            </div>
                                            <div className="uk-text-center uk-text-center uk-margin-top uk-width-4-5">
                                                <Link to="/about">
                                                    <span className="uk-link uk-margin-right">
                                                        About
                                                    </span>
                                                </Link>
                                                <Link to="/contact">
                                                    <span className="uk-link">
                                                        Contact Us
                                                    </span>
                                                </Link>
                                            </div>
                                        </div>
                                    )}
                                </React.Fragment>
                            </div>
                        </div>
                        <div className="uk-width-1-1 uk-height-1-1 uk-flex uk-flex-center uk-flex-middle uk-flex-row uk-visible@m">
                            <div className="uk-width-1-2 uk-height-1-1 uk-flex-right uk-flex uk-margin-large-right">
                                <div className="uk-width-4-5@m uk-width-3-5@l uk-width-2-5@xl">
                                    <React.Fragment>
                                        {this.state.isSet ? (
                                            this.renderCompleted()
                                        ) : this.state.isSuccess ? (
                                            <div className="uk-width-1-1 uk-flex uk-flex-center uk-flex-column uk-margin-large-top">
                                                <h5 className="uk-text-bold uk-margin-small-bottom">
                                                    Welcome to EMS!
                                                </h5>
                                                <p className="uk-text-break uk-margin-remove-top">
                                                    Please fill these forms and
                                                    give us more information
                                                    about your company
                                                </p>
                                                <div className="uk-flex uk-flex-center uk-flex-column">
                                                    <form
                                                        className="uk-form-stacked uk-grid-small uk-width-4-5 uk-child-width-1-1"
                                                        data-uk-grid
                                                        onSubmit={event =>
                                                            this.handleClick(
                                                                event
                                                            )
                                                        }
                                                    >
                                                        <div>
                                                            <label className="uk-form-label uk-text-bold">
                                                                Registered Email
                                                            </label>
                                                            <input
                                                                className="uk-input uk-border-rounded"
                                                                type="text"
                                                                value={
                                                                    this.state
                                                                        .recEmail
                                                                }
                                                                disabled
                                                            />
                                                        </div>
                                                        <div>
                                                            <label className="uk-form-label uk-text-bold">
                                                                Initials&nbsp;
                                                                <span className="uk-text-danger">
                                                                    *
                                                                </span>
                                                            </label>
                                                            <input
                                                                className="uk-input uk-border-rounded"
                                                                type="text"
                                                                placeholder="Initials"
                                                                onChange={event =>
                                                                    this.handleInputChange(
                                                                        event,
                                                                        "initial"
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div>
                                                            <label className="uk-form-label uk-text-bold">
                                                                First name&nbsp;
                                                                <span className="uk-text-danger">
                                                                    *
                                                                </span>
                                                            </label>
                                                            <input
                                                                className="uk-input uk-border-rounded"
                                                                type="text"
                                                                placeholder="First name"
                                                                onChange={event =>
                                                                    this.handleInputChange(
                                                                        event,
                                                                        "firstName"
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div>
                                                            <label className="uk-form-label uk-text-bold">
                                                                Last name&nbsp;
                                                                <span className="uk-text-danger">
                                                                    *
                                                                </span>
                                                            </label>
                                                            <input
                                                                className="uk-input uk-border-rounded"
                                                                type="text"
                                                                placeholder="Last name"
                                                                onChange={event =>
                                                                    this.handleInputChange(
                                                                        event,
                                                                        "lastName"
                                                                    )
                                                                }
                                                            />
                                                        </div>

                                                        <div>
                                                            <label className="uk-form-label uk-text-bold">
                                                                Business
                                                                Position&nbsp;
                                                                <span className="uk-text-danger">
                                                                    *
                                                                </span>
                                                            </label>
                                                            <input
                                                                className="uk-input uk-border-rounded"
                                                                type="text"
                                                                placeholder="Developer, ..."
                                                                onChange={event =>
                                                                    this.handleInputChange(
                                                                        event,
                                                                        "contactType"
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div>
                                                            <label className="uk-form-label uk-text-bold">
                                                                Company
                                                                name&nbsp;
                                                                <span className="uk-text-danger">
                                                                    *
                                                                </span>
                                                            </label>
                                                            <input
                                                                className="uk-input uk-border-rounded"
                                                                type="text"
                                                                placeholder="Company name"
                                                                onChange={event =>
                                                                    this.handleInputChange(
                                                                        event,
                                                                        "company"
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div>
                                                            <label className="uk-form-label uk-text-bold">
                                                                Company ID (fill
                                                                in only if you
                                                                are NOT an
                                                                account holder.
                                                                Thus, you are a
                                                                User)
                                                            </label>
                                                            <input
                                                                className="uk-input uk-border-rounded"
                                                                type="text"
                                                                placeholder="Company ID"
                                                                onChange={event =>
                                                                    this.handleInputChange(
                                                                        event,
                                                                        "accountNumber"
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div>
                                                            <label className="uk-form-label uk-text-bold">
                                                                Company
                                                                Telephone&nbsp;
                                                                <span className="uk-text-danger">
                                                                    *
                                                                </span>
                                                            </label>
                                                            <input
                                                                className="uk-input uk-border-rounded"
                                                                type="text"
                                                                placeholder="Company Telephone"
                                                                onChange={event =>
                                                                    this.handleInputChange(
                                                                        event,
                                                                        "companyTelephone"
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div>
                                                            <label className="uk-form-label uk-text-bold">
                                                                Mobile
                                                                Number&nbsp;
                                                                <span className="uk-text-danger">
                                                                    *
                                                                </span>
                                                            </label>
                                                            <input
                                                                className="uk-input uk-border-rounded"
                                                                type="text"
                                                                placeholder="Mobile Number"
                                                                onChange={event =>
                                                                    this.handleInputChange(
                                                                        event,
                                                                        "mobile"
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div>
                                                            <label className="uk-form-label uk-text-bold">
                                                                Country&nbsp;
                                                                <span className="uk-text-danger">
                                                                    *
                                                                </span>
                                                            </label>
                                                            {this.renderCountry()}
                                                        </div>
                                                        <div>
                                                            <label className="uk-form-label uk-text-bold">
                                                                City&nbsp;
                                                                <span className="uk-text-danger">
                                                                    *
                                                                </span>
                                                            </label>
                                                            <input
                                                                className="uk-input uk-border-rounded"
                                                                type="text"
                                                                placeholder="City"
                                                                onChange={event =>
                                                                    this.handleInputChange(
                                                                        event,
                                                                        "city"
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div>
                                                            <label className="uk-form-label uk-text-bold">
                                                                Address&nbsp;
                                                                <span className="uk-text-danger">
                                                                    *
                                                                </span>
                                                            </label>
                                                            <input
                                                                className="uk-input uk-border-rounded"
                                                                type="text"
                                                                placeholder="Address"
                                                                onChange={event =>
                                                                    this.handleInputChange(
                                                                        event,
                                                                        "address"
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div>
                                                            <label className="uk-form-label uk-text-bold">
                                                                Zip Code&nbsp;
                                                                <span className="uk-text-danger">
                                                                    *
                                                                </span>
                                                            </label>
                                                            <input
                                                                className="uk-input uk-border-rounded"
                                                                type="text"
                                                                placeholder="Zip Code"
                                                                onChange={event =>
                                                                    this.handleInputChange(
                                                                        event,
                                                                        "zipCode"
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div>
                                                            <label className="uk-form-label uk-text-bold">
                                                                Trade Number
                                                            </label>
                                                            <input
                                                                className="uk-input uk-border-rounded"
                                                                type="text"
                                                                placeholder="Trade Number"
                                                                onChange={event =>
                                                                    this.handleInputChange(
                                                                        event,
                                                                        "companyTradeNumber"
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div>
                                                            <label className="uk-form-label uk-text-bold">
                                                                Fiscal Number
                                                            </label>
                                                            <input
                                                                className="uk-input uk-border-rounded"
                                                                type="text"
                                                                placeholder="Fiscal Number"
                                                                onChange={event =>
                                                                    this.handleInputChange(
                                                                        event,
                                                                        "companyFiscalNumber"
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div>
                                                            <label className="uk-form-label uk-text-bold">
                                                                RESC Code
                                                            </label>
                                                            <input
                                                                className="uk-input uk-border-rounded"
                                                                type="text"
                                                                placeholder="RESC Code"
                                                                onChange={event =>
                                                                    this.handleInputChange(
                                                                        event,
                                                                        "rescCode"
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div>
                                                            <label className="uk-form-label uk-text-bold">
                                                                Password&nbsp;
                                                                <span className="uk-text-danger">
                                                                    *
                                                                </span>
                                                            </label>
                                                            <div className="uk-form-controls uk-inline uk-width-1-1">
                                                                <span
                                                                    className="uk-form-icon"
                                                                    uk-icon="icon: lock"
                                                                ></span>
                                                                <input
                                                                    className="uk-input uk-border-rounded"
                                                                    type="password"
                                                                    placeholder="Password"
                                                                    onChange={event =>
                                                                        this.handleInputChange(
                                                                            event,
                                                                            "password"
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <label className="uk-form-label uk-text-bold">
                                                                Confirm
                                                                Password&nbsp;
                                                                <span className="uk-text-danger">
                                                                    *
                                                                </span>
                                                            </label>
                                                            <div className="uk-form-controls uk-inline uk-width-1-1">
                                                                <span
                                                                    className="uk-form-icon"
                                                                    uk-icon="icon: lock"
                                                                ></span>
                                                                <input
                                                                    className="uk-input uk-border-rounded"
                                                                    type="password"
                                                                    placeholder="Password"
                                                                />
                                                            </div>
                                                        </div>
                                                        {this.state.valid ? (
                                                            <div></div>
                                                        ) : (
                                                            <div>
                                                                <label className="uk-form-label uk-text-bold uk-text-danger">
                                                                    All fields
                                                                    with&nbsp;
                                                                    <span className="uk-text">
                                                                        *
                                                                    </span>{" "}
                                                                    are required
                                                                </label>
                                                            </div>
                                                        )}
                                                        <div>
                                                            <p className="uk-text-small uk-text-justify">
                                                                By signing up,
                                                                you agree to our{" "}
                                                                <a
                                                                    href="https://ems-soft.com/terms-of-service/"
                                                                    target="_blank"
                                                                >
                                                                    <span className="uk-text-link">
                                                                        Terms of
                                                                        Services
                                                                    </span>
                                                                </a>{" "}
                                                                and{" "}
                                                                <a
                                                                    href="https://ems-soft.com/privacy/"
                                                                    target="_blank"
                                                                    className="uk-margin-remove"
                                                                >
                                                                    <span className="uk-text-link">
                                                                        Privacy
                                                                        Policy
                                                                    </span>
                                                                </a>
                                                                .
                                                            </p>
                                                        </div>
                                                        <div className="uk-margin-top">
                                                            <button className="uk-button uk-button-primary uk-width-1-1 uk-border-rounded uk-margin-top">
                                                                Sign up
                                                            </button>
                                                        </div>
                                                        <div>
                                                            <p className="uk-text-small uk-text-center">
                                                                Already have an
                                                                account ?{" "}
                                                                <Link to="/login">
                                                                    <span className="uk-link">
                                                                        Login
                                                                    </span>
                                                                </Link>
                                                            </p>
                                                        </div>
                                                    </form>
                                                    <div className="uk-text-center uk-text-center uk-margin-top uk-width-4-5">
                                                        <Link to="/about">
                                                            <span className="uk-link uk-margin-right">
                                                                About
                                                            </span>
                                                        </Link>
                                                        <Link to="/contact">
                                                            <span className="uk-link">
                                                                Contact Us
                                                            </span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="uk-flex uk-flex-center uk-flex-middle uk-flex-column uk-height-1-1 uk-text-danger">
                                                <p className="uk-margin-xlarge-top">
                                                    Oops! It seems your link
                                                    either has been used before
                                                    or invalid! please&nbsp;
                                                    <Link to="/register">
                                                        re-register
                                                    </Link>
                                                    &nbsp; again
                                                </p>
                                                <p>
                                                    If none of the above is
                                                    correct, please contact EMS
                                                    TEAM
                                                </p>
                                                <div className="uk-text-center uk-text-center uk-margin-top">
                                                    <Link to="/about">
                                                        <span className="uk-link uk-margin-right">
                                                            About
                                                        </span>
                                                    </Link>
                                                    <Link to="/contact">
                                                        <span className="uk-link">
                                                            Contact Us
                                                        </span>
                                                    </Link>
                                                </div>
                                            </div>
                                        )}
                                    </React.Fragment>
                                </div>
                            </div>

                            <div className="uk-width-1-2 uk-margin-large-left uk-flex-last">
                                <div className="uk-flex-center uk-flex-middle uk-position-fixed">
                                    <img
                                        src={logo}
                                        alt=""
                                        className="uk-width-4-5@m uk-width-3-5@l uk-width-2-5@xl"
                                    />
                                </div>
                                <div className="uk-text-meta uk-position-bottom-right uk-position-fixed">
                                    <span>
                                        Copyright &copy; 2019,{" "}
                                        {new Date().getFullYear()} Spenta B.V.,
                                        all rights reserved. Powered By
                                        codixt.com
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div
                            id="modal-overflow-error"
                            data-uk-modal="true"
                            className={
                                this.state.required &&
                                this.state.required.length > 0
                                    ? "uk-modal uk-open"
                                    : "uk-modal"
                            }
                            style={
                                this.state.required &&
                                this.state.required.length > 0
                                    ? { display: "block" }
                                    : { display: "none" }
                            }
                        >
                            <div className="uk-modal-dialog">
                                <button
                                    className="uk-modal-close-default"
                                    type="button"
                                    data-uk-close
                                ></button>
                                <div className="uk-modal-header uk-text-center">
                                    <p className="uk-margin-small-top">
                                        The following fields are required
                                    </p>
                                </div>
                                <div className="uk-modal-body uk-overflow-auto">
                                    <div className="uk-flex uk-flex-center">
                                        <p>
                                            {this.state.required
                                                ? this.state.required.join(", ")
                                                : null}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </main>
        );
    }
}

export default withRouter(VerifyEmail);
