import React, { Component } from "react";
import * as tools from "../../tools";
import Footer from "../footer/footer";
import logo from "../report/form/logo.png";
import { Link, Redirect } from "react-router-dom";

class LogIn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            logInSuccessful: false,
            hasError: false,
            code: ""
        };
    }

    email = "";
    password = "";

    handleClick(event) {
        event.preventDefault();
        if (!this.email || !this.password) {
            this.setState(
                {
                    errorMessage: "Email and Password Must be filled",
                    hasError: true
                },
                () => {
                    let t = this.state.errorMessage;
                }
            );
            return;
        }
        var payload = {
            email: this.email,
            password: this.password
        };
        tools.axiosInstance
            .post("user/temp-login", payload)
            .then(suc => {
                if (!suc.data.data[0].isValid) {
                    this.setState({ logInSuccessful: false, hasError: true });
                } else {
                    this.setState({
                        userSession: suc.data.data[0].session,
                        hasError: false,
                        qr: suc.data.data[0].qrImage,
                        hasError: false,
                        logInSuccessful: true
                    });
                }
            })
            .catch(err => {
                this.setState({ logInSuccessful: false, hasError: true });
            });
    }

    testCode(event) {
        event.preventDefault();
        if (!this.state.code) {
            this.setState({
                errorMessage: "Code Must be filled",
                hasError: true
            });
            return;
        }
        var payload = {
            tempSession: this.state.userSession,
            code: this.state.code
        };
        tools.axiosInstance
            .post("user/qr-image", payload)
            .then(suc => {
                this.setState({
                    testSent: true,
                    testResult: suc.data.data[0].correct,
                    testHasError: false
                });
            })
            .catch(err => {
                this.setState({ testSent: true, testHasError: true });
            });
    }

    renderLogInForm() {
        return (
            <main>
                <div className="uk-width-1-1 uk-child-width-1-1 uk-height-1-1 uk-flex uk-flex-center uk-flex-middle uk-flex-wrap uk-flex-wrap-around uk-margin-large-top uk-hidden@m">
                    <img
                        src={logo}
                        alt=""
                        className="uk-width-1-3 uk-margin-xlarge-top"
                    />
                    <div className="uk-flex uk-flex-center">
                        <div className="uk-width-4-5">
                            <div className="uk-flex uk-flex-center uk-flex-middle uk-flex-column">
                                <h3 className="uk-text-bold">
                                    Get your two factor (2FA) authentication QR
                                    code
                                </h3>
                                <div className="uk-flex uk-flex-center uk-flex-column">
                                    <form
                                        className="uk-form-stacked uk-grid-small uk-child-width-1-1"
                                        data-uk-grid
                                        onSubmit={event =>
                                            this.handleClick(event)
                                        }
                                    >
                                        <div>
                                            <label className="uk-form-label uk-text-bold">
                                                Company/Business Email
                                            </label>
                                            <div className="uk-form-controls uk-inline uk-width-1-1">
                                                <span
                                                    className="uk-form-icon"
                                                    uk-icon="icon: mail"
                                                ></span>
                                                <input
                                                    className="uk-input uk-border-rounded"
                                                    type="email"
                                                    placeholder="Company/Business Email"
                                                    onChange={event =>
                                                        (this.email =
                                                            event.target.value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <label className="uk-form-label uk-text-bold">
                                                Password
                                            </label>
                                            <div className="uk-form-controls uk-inline uk-width-1-1">
                                                <span
                                                    className="uk-form-icon"
                                                    uk-icon="icon: lock"
                                                ></span>
                                                <input
                                                    className="uk-input uk-border-rounded"
                                                    type="password"
                                                    placeholder="Password"
                                                    onChange={event =>
                                                        (this.password =
                                                            event.target.value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                        {this.state.hasError ? (
                                            <div className="uk-text-danger">
                                                User name and password do not
                                                match, Or your account has not
                                                been activated yet
                                            </div>
                                        ) : (
                                            <div></div>
                                        )}
                                        <div>
                                            <Link
                                                to="/forgot"
                                                className="uk-text-small"
                                            >
                                                Forgot Password ?
                                            </Link>
                                        </div>
                                        <div>
                                            <button
                                                className="uk-button uk-button-primary uk-width-1-1 uk-border-rounded"
                                                type="submit"
                                            >
                                                Get Authentication Code
                                            </button>
                                        </div>
                                        <div>
                                            <p className="uk-text-small uk-text-center">
                                                Don't have an account ?{" "}
                                                <Link to="/register">
                                                    <span className="uk-link">
                                                        Sign up
                                                    </span>
                                                </Link>
                                            </p>
                                        </div>
                                    </form>
                                    <div className="uk-text-center uk-text-center uk-margin-top">
                                        <Link to="/about">
                                            <span className="uk-link uk-margin-right">
                                                About
                                            </span>
                                        </Link>
                                        <Link to="/contact">
                                            <span className="uk-link">
                                                Contact Us
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="uk-margin-top uk-text-meta">
                        Copyright &copy; 2019, {new Date().getFullYear()} Spenta
                        B.V., all rights reserved. Powered By&nbsp;
                        <a href="http://codixt.com" target="_blank">
                            codixt
                        </a>
                    </div>
                </div>

                <div className="uk-width-1-1 uk-height-1-1 uk-flex uk-flex-center uk-flex-middle uk-flex-row uk-visible@m">
                    <div className="uk-width-1-2 uk-flex-right uk-flex uk-margin-large-right">
                        <div className="uk-width-4-5@m uk-width-3-5@l uk-width-2-5@xl">
                            <h3 className="uk-text-bold uk-text-center uk-margin-large-bottom">
                                Get your two factor (2FA) authentication QR code
                            </h3>
                            <div className="uk-flex uk-flex-center uk-flex-column">
                                <form
                                    className="uk-form-stacked uk-grid-small uk-child-width-1-1"
                                    data-uk-grid
                                    onSubmit={event => this.handleClick(event)}
                                >
                                    <div>
                                        <label className="uk-form-label uk-text-bold">
                                            Company/Business Email
                                        </label>
                                        <div className="uk-form-controls uk-inline uk-width-1-1">
                                            <span
                                                className="uk-form-icon"
                                                uk-icon="icon: mail"
                                            ></span>
                                            <input
                                                className="uk-input uk-border-rounded"
                                                type="email"
                                                placeholder="Company/Business Email"
                                                onChange={event =>
                                                    (this.email =
                                                        event.target.value)
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <label className="uk-form-label uk-text-bold">
                                            Password
                                        </label>
                                        <div className="uk-form-controls uk-inline uk-width-1-1">
                                            <span
                                                className="uk-form-icon"
                                                uk-icon="icon: lock"
                                            ></span>
                                            <input
                                                className="uk-input uk-border-rounded"
                                                type="password"
                                                placeholder="Password"
                                                onChange={event =>
                                                    (this.password =
                                                        event.target.value)
                                                }
                                            />
                                        </div>
                                    </div>
                                    {this.state.hasError ? (
                                        <div className="uk-text-danger">
                                            User name and password do not match,
                                            Or your account has not been
                                            activated yet
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}
                                    <div>
                                        <Link
                                            to="/forgot"
                                            className="uk-text-small"
                                        >
                                            Forgot Password ?
                                        </Link>
                                    </div>
                                    <div>
                                        <button
                                            className="uk-button uk-button-primary uk-width-1-1 uk-border-rounded"
                                            type="submit"
                                        >
                                            Get Authentication Code
                                        </button>
                                    </div>
                                    <div>
                                        <p className="uk-text-small uk-text-center">
                                            Don't have an account ?{" "}
                                            <Link to="/register">
                                                <span className="uk-link">
                                                    Sign up
                                                </span>
                                            </Link>
                                        </p>
                                    </div>
                                </form>
                                <div className="uk-text-center uk-text-center uk-margin-top">
                                    <Link to="/about">
                                        <span className="uk-link uk-margin-right">
                                            About
                                        </span>
                                    </Link>
                                    <Link to="/contact">
                                        <span className="uk-link">
                                            Contact Us
                                        </span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="uk-width-1-2 uk-margin-large-left">
                        <div className="uk-margin-large-bottom"></div>
                        <img
                            src={logo}
                            alt=""
                            className="uk-width-4-5@m uk-width-3-5@l uk-width-2-5@xl"
                        />
                    </div>
                </div>
                <div className="footer-div uk-text-meta">
                    Copyright &copy; 2019, {new Date().getFullYear()} Spenta
                    B.V., all rights reserved. Powered By&nbsp;
                    <a href="http://codixt.com" target="_blank">
                        codixt
                    </a>
                </div>
            </main>
        );
    }

    renderQrImage() {
        return (
            <main>
                <div className="uk-width-1-1 uk-child-width-1-1 uk-height-1-1 uk-flex uk-flex-center uk-flex-middle uk-flex-wrap uk-flex-wrap-around uk-margin-large-top uk-hidden@m">
                    <img src={logo} alt="" className="uk-width-2-3" />
                    <div className="uk-flex uk-flex-center">
                        <div className="uk-width-4-5">
                            <div className="uk-flex uk-flex-center uk-flex-middle uk-flex-column">
                                <h3 className="uk-text-bold">
                                    Your Authentication Code
                                </h3>
                                <div className="uk-flex uk-flex-center uk-flex-column">
                                    <form
                                        className="uk-form-stacked uk-grid-small uk-child-width-1-1"
                                        data-uk-grid
                                        onSubmit={event => this.testCode(event)}
                                    >
                                        <div>
                                            <label className="uk-form-label uk-text-bold">
                                                Please scan the QR image using
                                                authentication app &nbsp;
                                                <span
                                                    className="uk-link uk-text-break pointer uk-flex-right"
                                                    data-uk-toggle="target: #modal-overflow-how"
                                                >
                                                    (?)
                                                </span>
                                            </label>
                                            <img
                                                src={this.state.qr}
                                                alt=""
                                                className="uk-width-1-3 uk-flex uk-flex-center uk-flex-middle"
                                            />
                                        </div>
                                        <div>
                                            <label className="uk-form-label uk-text-bold">
                                                Enter the code from your
                                                authenticator app
                                            </label>
                                            <div className="uk-form-controls uk-inline uk-width-1-1">
                                                <span
                                                    className="uk-form-icon"
                                                    uk-icon="icon: lock"
                                                ></span>
                                                <input
                                                    className="uk-input uk-border-rounded"
                                                    type="text"
                                                    placeholder="123456"
                                                    onChange={event =>
                                                        this.setState({
                                                            code:
                                                                event.target
                                                                    .value
                                                        })
                                                    }
                                                    value={this.state.code}
                                                />
                                            </div>
                                        </div>
                                        {this.state.testHasError ? (
                                            <div className="uk-text-danger">
                                                Oops! There was an error, please
                                                try again later
                                            </div>
                                        ) : this.state.testSent &&
                                          !this.state.testResult ? (
                                            <div className="uk-text-danger">
                                                The entered code is not correct
                                            </div>
                                        ) : this.state.testSent &&
                                          this.state.testResult ? (
                                            <div className="uk-text">
                                                You have successfully generated
                                                the code,{" "}
                                                <Link to="/login">Log in</Link>
                                            </div>
                                        ) : (
                                            <div></div>
                                        )}
                                        <div>
                                            <button
                                                className="uk-button uk-button-primary uk-width-1-1 uk-border-rounded"
                                                type="submit"
                                            >
                                                Test Authentication Code
                                            </button>
                                        </div>
                                    </form>
                                    <div className="uk-text-center uk-text-center uk-margin-top">
                                        <Link to="/about">
                                            <span className="uk-link uk-margin-right">
                                                About
                                            </span>
                                        </Link>
                                        <Link to="/contact">
                                            <span className="uk-link">
                                                Contact Us
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-div uk-text-meta">
                        Copyright &copy; 2019, {new Date().getFullYear()} Spenta
                        B.V., all rights reserved. Powered By&nbsp;
                        <a href="http://codixt.com" target="_blank">
                            codixt
                        </a>
                    </div>
                </div>

                <div className="uk-width-1-1 uk-height-1-1 uk-flex uk-flex-center uk-flex-middle uk-flex-row uk-visible@m">
                    <div className="uk-width-1-2 uk-flex-right uk-flex uk-margin-large-right">
                        <div className="uk-width-4-5@m uk-width-3-5@l uk-width-2-5@xl">
                            <h3 className="uk-text-bold uk-text-center">
                                Your Authentication Code
                            </h3>
                            <div className="uk-flex uk-flex-center uk-flex-column">
                                <form
                                    className="uk-form-stacked uk-grid-small uk-child-width-1-1"
                                    data-uk-grid
                                    onSubmit={event => this.testCode(event)}
                                >
                                    <div>
                                        <label className="uk-form-label uk-text-bold">
                                            Please scan the QR image using
                                            authentication app &nbsp;
                                            <span
                                                className="uk-link uk-text-break pointer uk-flex-right"
                                                data-uk-toggle="target: #modal-overflow-how"
                                            >
                                                (?)
                                            </span>
                                        </label>
                                        <img
                                            src={this.state.qr}
                                            alt=""
                                            className="uk-width-1-1 uk-flex uk-flex-center uk-flex-middle"
                                        />
                                    </div>
                                    <div>
                                        <label className="uk-form-label uk-text-bold">
                                            Enter the code from your
                                            authenticator app
                                        </label>
                                        <div className="uk-form-controls uk-inline uk-width-1-1">
                                            <span
                                                className="uk-form-icon"
                                                uk-icon="icon: lock"
                                            ></span>
                                            <input
                                                className="uk-input uk-border-rounded"
                                                type="text"
                                                placeholder="123456"
                                                onChange={event =>
                                                    this.setState({
                                                        code: event.target.value
                                                    })
                                                }
                                                value={this.state.code}
                                            />
                                        </div>
                                    </div>
                                    {this.state.testHasError ? (
                                        <div className="uk-text-danger">
                                            Oops! There was an error, please try
                                            again later
                                        </div>
                                    ) : this.state.testSent &&
                                      !this.state.testResult ? (
                                        <div className="uk-text-danger">
                                            The entered code is not correct
                                        </div>
                                    ) : this.state.testSent &&
                                      this.state.testResult ? (
                                        <div className="uk-text">
                                            You have successfully generated the
                                            code,{" "}
                                            <Link to="/login">Log in</Link>
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}
                                    <div>
                                        <button
                                            className="uk-button uk-button-primary uk-width-1-1 uk-border-rounded"
                                            type="submit"
                                        >
                                            Test Authentication Code
                                        </button>
                                    </div>
                                </form>
                                <div className="uk-text-center uk-text-center uk-margin-top">
                                    <Link to="/about">
                                        <span className="uk-link uk-margin-right">
                                            About
                                        </span>
                                    </Link>
                                    <Link to="/contact">
                                        <span className="uk-link">
                                            Contact Us
                                        </span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-margin-large-left">
                        <div className="uk-margin-large-bottom"></div>
                        <img
                            src={logo}
                            alt=""
                            className="uk-width-4-5@m uk-width-3-5@l uk-width-2-5@xl"
                        />
                    </div>
                </div>

                <div className="footer-div uk-text-meta">
                    Copyright &copy; 2019, {new Date().getFullYear()} Spenta
                    B.V., all rights reserved. Powered By&nbsp;
                    <a href="http://codixt.com" target="_blank">
                        codixt
                    </a>
                </div>
                <div id="modal-overflow-how" data-uk-modal>
                    <div className="uk-modal-dialog">
                        <button
                            className="uk-modal-close-default"
                            type="button"
                            data-uk-close
                        ></button>
                        <div className="uk-modal-header uk-text-center">
                            <h5 className="uk-margin-small-top">
                                Authentication Code, How it works!
                            </h5>
                        </div>
                        <div className="uk-modal-body uk-text-justify">
                            <p className="uk-panel uk-margin-small-top">
                                Step 1: With your mobile, download and install
                                from App Store (Apple) or Google Play Store
                                (Android) either “Google Authenticator” and or
                                “Authy 2- factor Authenticator app”.
                            </p>
                            <p className="uk-panel uk-margin-small-top">
                                Step 2: Open the Google or Authy Authenticator
                                app on your mobile and scan the QR picture
                                provided on the screen.
                            </p>
                            <p className="uk-panel uk-margin-small-top">
                                Step 3: You will be provided a 6 digit code by
                                the authenticator app. Enter this code in the
                                field “Enter the code from the Authenticator
                                app.
                            </p>
                            <p className="uk-panel uk-margin-small-top">
                                Step 4: Click on the blue button that shows
                                “Test Authentication Code”
                            </p>
                            <p className="uk-panel uk-margin-small-top">
                                MAKE SURE YOU DO NOT REMOVE THE WEBSITE FROM
                                YOUR AUTHENTICATOR APP
                            </p>
                            <p className="uk-panel uk-margin-small-top">
                                You can download the authenticator apps using
                                the following links.
                            </p>
                            <div className="uk-flex uk-flex-column uk-flex-middle">
                                <h4 className="uk-text">
                                    Google Authenticator
                                </h4>
                                <div
                                    className="uk-margin-small-top uk-flex uk-flex-row uk-flex-wrap uk-grid-large"
                                    data-uk-grid
                                >
                                    <div>
                                        <a
                                            target="_blank"
                                            href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en"
                                        >
                                            <img
                                                src="https://img.favpng.com/0/10/20/google-play-android-app-store-png-favpng-jCmdkQnfYRzbA4R4ncuFytemJ.jpg"
                                                alt=""
                                                style={{
                                                    width: "100px"
                                                }}
                                            />
                                        </a>
                                    </div>
                                    <div>
                                        <a
                                            target="_blank"
                                            href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                                        >
                                            <img
                                                src="https://www.airconwithme.com/wp-content/uploads/2016/08/appstore-300x120.png"
                                                alt=""
                                                style={{
                                                    marginTop: "-1px;",
                                                    width: "90px"
                                                }}
                                            />
                                        </a>
                                    </div>
                                </div>
                                <h4 className="uk-text">Authy</h4>
                                <div
                                    className="uk-margin-small-top uk-flex uk-flex-row uk-flex-wrap uk-grid-large"
                                    data-uk-grid
                                >
                                    <div>
                                        <a
                                            target="_blank"
                                            href="https://play.google.com/store/apps/details?id=com.authy.authy&hl=en"
                                        >
                                            <img
                                                src="https://img.favpng.com/0/10/20/google-play-android-app-store-png-favpng-jCmdkQnfYRzbA4R4ncuFytemJ.jpg"
                                                alt=""
                                                style={{
                                                    width: "100px"
                                                }}
                                            />
                                        </a>
                                    </div>
                                    <div>
                                        <a
                                            target="_blank"
                                            href="https://apps.apple.com/us/app/authy/id494168017"
                                        >
                                            <img
                                                src="https://www.airconwithme.com/wp-content/uploads/2016/08/appstore-300x120.png"
                                                alt=""
                                                style={{
                                                    marginTop: "-1px;",
                                                    width: "90px"
                                                }}
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <p className="uk-panel uk-margin-small-top">
                                If you have any issues, contact&nbsp;
                                <Link to="/contact">EMS Team</Link>. &nbsp;We
                                will get in touch with you.
                            </p>
                        </div>
                    </div>
                </div>
            </main>
        );
    }

    render() {
        if (this.state.logInSuccessful) {
            return this.renderQrImage();
        } else {
            return this.renderLogInForm();
        }
    }
}

export default LogIn;
