import React, { Component } from "react";

class AccordionSection extends Component {
    constructor(props) {
        super(props);
    }

    openChange(event) {
        if (
            !Array.isArray(event._dispatchListeners) &&
            typeof this.props.openChangeHandler === "function"
        ) {
            this.props.openChangeHandler(this.props.id);
        }
    }

    render() {
        return (
            <li
                className={
                    this.props.closed
                        ? `uk-card uk-card-small uk-card-default uk-box-shadow-small`
                        : `uk-card uk-card-small uk-card-default uk-box-shadow-small uk-open`
                }
                onClick={event => this.openChange(event)}
                id={this.props.id}
            >
                <a
                    className="uk-card-header uk-accordion-title uk-text-small uk-bold"
                    href="#"
                >
                    {this.props.title}
                </a>
                <div className="uk-card-body uk-accordion-content uk-margin-remove uk-padding-remove">
                    {this.props.children}
                </div>
            </li>
        );
    }
}

export default AccordionSection;
