import React, { Component } from "react";
import Toolbar from "../toolbar/toolbar";
import ReportTitle from "../report/reportTitle/reportTitle";
import Form from "../report/form/form";
import Tab from "../report/tab/tab";
import Table from "../table/table";
import * as tools from "../../tools";

export default class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userAccounts: [],
            tradingAccounts: []
        };
    }

    componentDidMount() {
        tools.axiosInstance
            .get("/user")
            .then(suc => {
                this.setState(suc.data.data[0]);
            })
            .catch(err => {
                //this.props.history.push("/login");
                console.log(err);
            });
        tools.axiosInstance.get("/report/user-account-list").then(res =>
            this.setState({
                userAccounts: res.data.data.sort((a, b) =>
                    a.country.localeCompare(b.country)
                )
            })
        );
        tools.axiosInstance.get("/report/trading-account-list").then(res =>
            this.setState({
                tradingAccounts: res.data.data.sort((a, b) =>
                    a.country.localeCompare(b.country)
                )
            })
        );
    }

    groupData(isTrading) {
        const data = isTrading
            ? [...this.state.tradingAccounts]
            : [...this.state.userAccounts];
        const groups = [];
        const addedGroups = [];
        let index = -1;
        for (let i = 0, n = data.length; i < n; i++) {
            index = addedGroups.findIndex(el => el === data[i].country);
            if (index === -1) {
                addedGroups.push(data[i].country);
                let currentGroup = {
                    title: `Domain: ${data[i].country}, Uploaded At: ${new Date(
                        parseInt(data[i].uploadTime)
                    ).toUTCString()}`,
                    fromRow: i
                };
                let j = i + 1;
                for (; j < n; j++) {
                    if (data[j].country !== data[i].country) {
                        break;
                    }
                }
                currentGroup.toRow = j;
                groups.push(currentGroup);
            }
        }

        return groups;
    }

    sanitizeObject(obj) {
        const res = Object.assign({}, obj);
        delete res.country;
        delete res.uploadTime;
        return res;
    }

    renderUserAccountTable(isTrading) {
        const data = isTrading
            ? this.state.tradingAccounts.map(account =>
                  [""].concat(Object.values(this.sanitizeObject(account)))
              )
            : this.state.userAccounts.map(account =>
                  [""].concat(Object.values(this.sanitizeObject(account)))
              );

        if (!data.length) {
            return null;
        }
        const groups = this.groupData(isTrading);
        return (
            <Table
                headers={[
                    "",
                    isTrading ? "Trading A/c" : "User A/c",
                    "Holder Name",
                    "Active",
                    "Valid"
                ]}
                data={data}
                groups={groups}
                user={true}
                noHighlight={true}
            />
        );
    }

    render() {
        return (
            <main>
                <div
                    className="page  uk-grid-small uk-grid-match uk-grid"
                    data-uk-grid
                >
                    <div className="menu-bar uk-background-secondary uk-visible@m">
                        <Toolbar />
                    </div>
                    <div className="uk-width-expand  page-content">
                        <div className="ems-page-container">
                            <div
                                style={{
                                    borderBottom: "2px solid #e5e5e5",
                                    background: "white"
                                }}
                                data-uk-sticky
                            >
                                <ReportTitle title="Profile" />
                                <Form onlyTitle={true} title="Profile" />
                            </div>

                            <Tab titles={["Company", "Account"]}>
                                <div
                                    className="uk-padding-small uk-flex-column uk-width-1-1"
                                    style={{ borderTop: "2px solid #e5e5e5" }}
                                >
                                    <ul
                                        uk-accordion="multiple: true"
                                        className="uk-margin-top"
                                    >
                                        <li className="uk-open uk-card uk-card-small uk-card-default uk-box-shadow-small">
                                            <a
                                                className="uk-accordion-title uk-padding-small"
                                                href="#"
                                            >
                                                Company Details
                                            </a>
                                            <div className="uk-accordion-content uk-padding-small">
                                                <div
                                                    className="uk-grid uk-width-1-1 uk-children-width-1-7 uk-children-width-1-1@s"
                                                    data-uk-grid
                                                >
                                                    <div>
                                                        <span className="uk-text-bold">
                                                            Company
                                                            Name:&nbsp;&nbsp;
                                                        </span>
                                                        <span>
                                                            {
                                                                this.state
                                                                    .companyName
                                                            }
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span className="uk-text-bold">
                                                            Company
                                                            Email:&nbsp;&nbsp;{" "}
                                                        </span>
                                                        <span>
                                                            {this.state.email}
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span className="uk-text-bold">
                                                            Company
                                                            Number:&nbsp;&nbsp;{" "}
                                                        </span>
                                                        <span>
                                                            {
                                                                this.state
                                                                    .mobileNumber
                                                            }
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span className="uk-text-bold">
                                                            Zip
                                                            Code:&nbsp;&nbsp;{" "}
                                                        </span>
                                                        <span>
                                                            {this.state.zipCode}
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span className="uk-text-bold">
                                                            Country:&nbsp;&nbsp;{" "}
                                                        </span>
                                                        <span>
                                                            {this.state.country}
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span className="uk-text-bold">
                                                            City:&nbsp;&nbsp;{" "}
                                                        </span>
                                                        <span>
                                                            {this.state.city}
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span className="uk-text-bold">
                                                            Address:&nbsp;&nbsp;{" "}
                                                        </span>
                                                        <span>
                                                            {this.state.address}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="uk-open uk-card uk-card-small uk-card-default uk-box-shadow-small">
                                            <a
                                                className="uk-accordion-title uk-padding-small"
                                                href="#"
                                            >
                                                Company Trading Details
                                            </a>
                                            <div className="uk-accordion-content uk-padding-small">
                                                <div
                                                    className="uk-grid uk-width-1-1 uk-children-width-1-3 uk-children-width-1-1@s"
                                                    data-uk-grid
                                                >
                                                    <div>
                                                        <span className="uk-text-bold">
                                                            Fiscal
                                                            Number:&nbsp;&nbsp;
                                                        </span>
                                                        <span>
                                                            {
                                                                this.state
                                                                    .fiscalNumber
                                                            }
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span className="uk-text-bold">
                                                            Trading
                                                            Number:&nbsp;&nbsp;{" "}
                                                        </span>
                                                        <span>
                                                            {
                                                                this.state
                                                                    .tradingNumber
                                                            }
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span className="uk-text-bold">
                                                            RESC
                                                            Code:&nbsp;&nbsp;{" "}
                                                        </span>
                                                        <span>
                                                            {
                                                                this.state
                                                                    .rescCode
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="uk-open uk-card uk-card-small uk-card-default uk-box-shadow-small">
                                            <a
                                                className="uk-accordion-title uk-padding-small"
                                                href="#"
                                            >
                                                Personal Details
                                            </a>
                                            <div className="uk-accordion-content uk-padding-small">
                                                <div
                                                    className="uk-grid uk-width-1-1 uk-children-width-1-4 uk-children-width-1-1@s"
                                                    data-uk-grid
                                                >
                                                    <div>
                                                        <span className="uk-text-bold">
                                                            Initials:&nbsp;&nbsp;
                                                        </span>
                                                        <span>
                                                            {
                                                                this.state
                                                                    .initials
                                                            }
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span className="uk-text-bold">
                                                            First
                                                            Name:&nbsp;&nbsp;
                                                        </span>
                                                        <span>
                                                            {
                                                                this.state
                                                                    .firstName
                                                            }
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span className="uk-text-bold">
                                                            Last
                                                            Name:&nbsp;&nbsp;{" "}
                                                        </span>
                                                        <span>
                                                            {
                                                                this.state
                                                                    .lastName
                                                            }
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span className="uk-text-bold">
                                                            Mobile
                                                            Number:&nbsp;&nbsp;{" "}
                                                        </span>
                                                        <span>
                                                            {
                                                                this.state
                                                                    .mobileNumber
                                                            }
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span className="uk-text-bold">
                                                            Registration
                                                            Date:&nbsp;&nbsp;{" "}
                                                        </span>
                                                        <span>
                                                            {this.state
                                                                .registerTime
                                                                ? this.state.registerTime
                                                                      .split(
                                                                          "T"
                                                                      )[0]
                                                                      .split(
                                                                          "-"
                                                                      )
                                                                      .reverse()
                                                                      .join("-")
                                                                : ""}
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span className="uk-text-bold">
                                                            Contact
                                                            Type:&nbsp;&nbsp;
                                                        </span>
                                                        <span>
                                                            {
                                                                this.state
                                                                    .contactType
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="uk-open uk-card uk-card-small uk-card-default uk-box-shadow-small">
                                            <a
                                                className="uk-accordion-title uk-padding-small"
                                                href="#"
                                            >
                                                Purchase Details
                                            </a>
                                            <div className="uk-accordion-content uk-padding-small">
                                                <div
                                                    className="uk-grid uk-width-1-1 uk-children-width-1-4 uk-children-width-1-1@s"
                                                    data-uk-grid
                                                >
                                                    <div>
                                                        <span className="uk-text-bold">
                                                            Base
                                                            Amount:&nbsp;&nbsp;
                                                        </span>
                                                        <span>
                                                            {
                                                                this.state
                                                                    .baseAmount
                                                            }
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span className="uk-text-bold">
                                                            Tax
                                                            Amount:&nbsp;&nbsp;{" "}
                                                        </span>
                                                        <span>
                                                            {
                                                                this.state
                                                                    .taxAmount
                                                            }
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span className="uk-text-bold">
                                                            Total
                                                            Amount:&nbsp;&nbsp;{" "}
                                                        </span>
                                                        <span>
                                                            {
                                                                this.state
                                                                    .totalAmount
                                                            }
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span className="uk-text-bold">
                                                            Reference
                                                            Code:&nbsp;&nbsp;{" "}
                                                        </span>
                                                        <span>
                                                            {
                                                                this.state
                                                                    .referenceCode
                                                            }
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span className="uk-text-bold">
                                                            Purchase Date:{" "}
                                                        </span>
                                                        <span>
                                                            {this.state
                                                                .purchaseDate
                                                                ? this.state.purchaseDate
                                                                      .split(
                                                                          "T"
                                                                      )[0]
                                                                      .split(
                                                                          "-"
                                                                      )
                                                                      .reverse()
                                                                      .join("-")
                                                                : ""}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div
                                    className="uk-padding-small uk-flex-column uk-width-1-1"
                                    style={{ borderTop: "2px solid #e5e5e5" }}
                                >
                                    <ul
                                        uk-accordion="multiple: true"
                                        className="uk-margin-top"
                                    >
                                        <li className="uk-open uk-card uk-card-small uk-card-default uk-box-shadow-small">
                                            <a
                                                className="uk-accordion-title uk-padding-small"
                                                href="#"
                                            >
                                                Trading A/c List
                                            </a>
                                            <div className="uk-accordion-content uk-padding-small">
                                                {this.renderUserAccountTable(
                                                    true
                                                )}
                                            </div>
                                        </li>
                                        <li className="uk-open uk-card uk-card-small uk-card-default uk-box-shadow-small">
                                            <a
                                                className="uk-accordion-title uk-padding-small"
                                                href="#"
                                            >
                                                User A/c List
                                            </a>
                                            <div className="uk-accordion-content uk-padding-small">
                                                {this.renderUserAccountTable()}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </Tab>
                        </div>
                    </div>
                </div>

                <Toolbar hidden={true} />
                <div
                    className={
                        window.innerWidth < 900
                            ? "uk-margin-top uk-flex uk-flex-right uk-text-meta"
                            : "footer-div uk-text-meta"
                    }
                >
                    <span>
                        Copyright &copy; 2019, {new Date().getFullYear()} Spenta
                        B.V., all rights reserved. Powered By codixt.com
                    </span>
                </div>
            </main>
        );
    }
}
