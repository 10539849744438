import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "./logo.png";

class ReportTitle extends Component {
    constructor(props) {
        super(props);
        this.data = [
            "Beginning Inventory",
            "Statement of Additions",
            "Issued Certificates",
            "Imported Certificates",
            "Transferred to Trading Account",
            "Increase in Certificates Inventory",
            "Statement of Deductions",
            "Cancelled Certificates",
            "Exported Certificates",
            "Transferred out of Trading Account",
            "Withdrawals",
            "Decrease in Certificates Inventory",
            "Issued Certificates for Own Use",
            "Cancelled Certificates by Third party",
            "Certificates Inventory Summary",
            "Increase in Certificates Inventory",
            "Decrease in Certificates Inventory",
            "Mutation Movement (Net Inventory Change)",
            "End of Inventory (cannot be negative)",
            "Cancelled for Third Parties"
        ];
    }

    shouldRenderMenu() {
        return this.data.findIndex(el => el === this.props.title) > -1;
    }

    createSupReportList() {
        if (!this.shouldRenderMenu()) {
            return null;
        }

        return (
            <div data-uk-dropdown="pos: bottom-right">
                <div class="uk-dropdown-grid uk-flex uk-flex-row" data-uk-grid>
                    <div className="uk-flex uk-flex-column">
                        <ul className="uk-nav uk-dropdown-nav">
                            <li className="uk-nav-header">Inventory</li>
                            <li className="pointer">
                                <a href="/" className="uk-link">
                                    {" "}
                                    Overview{" "}
                                </a>
                            </li>
                            <li className="pointer">
                                <a
                                    href="/report/expiration"
                                    className="uk-link"
                                >
                                    {" "}
                                    Expiration{" "}
                                </a>
                            </li>
                            <li class="uk-nav-divider"></li>
                            <li className="pointer">
                                <a href="/report/begin" className="uk-link">
                                    {" "}
                                    Beginning{" "}
                                </a>
                            </li>
                            <li className="pointer">
                                <a href="/report/mutation" className="uk-link">
                                    {" "}
                                    Net Change{" "}
                                </a>
                            </li>
                            <li className="pointer">
                                <a href="/report/end" className="uk-link">
                                    {" "}
                                    End{" "}
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="uk-flex uk-flex-column">
                        <ul className="uk-nav uk-dropdown-nav">
                            <li className="uk-nav-header">Additions</li>
                            <li className="pointer">
                                <a href="/report/issue" className="uk-link">
                                    {" "}
                                    Issued{" "}
                                </a>
                            </li>
                            <li className="pointer">
                                <a href="/report/import" className="uk-link">
                                    {" "}
                                    Import{" "}
                                </a>
                            </li>
                            <li className="pointer">
                                <a
                                    href="/report/transferTo"
                                    className="uk-link"
                                >
                                    {" "}
                                    Transferred To{" "}
                                </a>
                            </li>
                            <li className="pointer">
                                <a href="/report/increase" className="uk-link">
                                    {" "}
                                    Increase{" "}
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="uk-flex uk-flex-column">
                        <ul className="uk-nav uk-dropdown-nav">
                            <li className="uk-nav-header">Deductions</li>
                            <li className="pointer">
                                <a
                                    href="/report/transferOut"
                                    className="uk-link"
                                >
                                    {" "}
                                    Transferred Out{" "}
                                </a>
                            </li>
                            <li className="pointer">
                                <a href="/report/cancel" className="uk-link">
                                    {" "}
                                    Cancelled{" "}
                                </a>
                            </li>
                            <li className="pointer">
                                <a
                                    href="/report/cancelledForThirdParty"
                                    className="uk-link"
                                >
                                    {" "}
                                    Cancelled For Third Party{" "}
                                </a>
                            </li>
                            <li className="pointer">
                                <a href="/report/expiry" className="uk-link">
                                    {" "}
                                    Expired{" "}
                                </a>
                            </li>
                            <li className="pointer">
                                <a
                                    href="/report/withdrawal"
                                    className="uk-link"
                                >
                                    {" "}
                                    Withdrawals{" "}
                                </a>
                            </li>
                            <li className="pointer">
                                <a href="/report/export" className="uk-link">
                                    {" "}
                                    Exported{" "}
                                </a>
                            </li>
                            <li className="pointer">
                                <a href="/report/decrease" className="uk-link">
                                    {" "}
                                    Decrease{" "}
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="uk-flex uk-flex-column">
                        <ul className="uk-nav uk-dropdown-nav">
                            <li className="uk-nav-header">Others</li>
                            <li className="pointer">
                                <a
                                    href="/report/issueOwnUse"
                                    className="uk-link"
                                >
                                    {" "}
                                    Issued for Own Use{" "}
                                </a>
                            </li>
                            <li className="pointer">
                                <a
                                    href="/report/cancelledByThirdParty"
                                    className="uk-link"
                                >
                                    {" "}
                                    Cancelled By Third Party{" "}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <nav
                className="ems-header-res uk-navbar-container uk-hidden@m uk-flex uk-flex-between "
                data-uk-navbar
                data-uk-sticky
            >
                <div className="uk-navbar-left">
                    <span
                        className="uk-navbar-toggle pointer"
                        data-uk-navbar-toggle-icon
                        uk-toggle="target: #offcanvas-usage"
                    ></span>
                    <div className="uk-margin-xlarge-left@m uk-flex-inline uk-flex-center uk-flex-middle uk-flex-wrap uk-flex-wrap-around">
                        <h3 className="uk-card-title uk-margin-remove">
                            {this.props.title ? this.props.title : "Operations"}
                        </h3>
                        {this.createSupReportList()}
                    </div>
                </div>
                <div className="uk-flex uk-flex-row uk-flex-between">
                    <div className="uk-margin-small-top">
                        <figure className="ems-logo uk-margin-remove-bottom uk-margin-small-right uk-border-circle">
                            <Link to="/">
                                <img src={logo} alt="" />
                            </Link>
                        </figure>
                    </div>
                </div>
            </nav>
        );
    }
}

export default ReportTitle;
