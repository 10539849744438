import React, { Component } from "react";
import * as tools from "../../tools";
import Toolbar from "../toolbar/toolbar";
import ReportTitle from "../report/reportTitle/reportTitle";
import Tab from "../report/tab/tab";
import Table from "./table";
import Form from "../report/form/form";
import UIkit from "uikit";

/**Class admin */
export default class Admin extends Component {
    /**
     * constructor, sets the default state
     * @param {object} params
     */
    constructor(params) {
        super(params);
        this.state = {
            isAdmin: false,
            activeUserList: [],
            pendingUserList: [],
            rowClickedId: -1,
            isPending: false,
            adminTitle: "Active Accounts"
        };
    }

    /**
     * get the last status per user
     * @param {object[]} userList
     */
    recentStatus(userList) {
        let recentStatus = [];
        let userAdded = false;
        for (let i = 0, n = userList.length; i < n; i++) {
            userAdded = recentStatus.findIndex(el => el.id === userList[i].id);
            if (userAdded < 0) {
                recentStatus.push(userList[i]);
            } else {
                recentStatus[userAdded] = userList[i];
            }
        }
        return recentStatus;
    }

    /**
     *
     * @param {object[]} userList
     * @param {bool} isActive
     */
    getActiveUsers(userList, isActive) {
        let active = userList.filter(el => {
            return Boolean(el.isActive) === isActive && !Boolean(el.isHidden);
        });
        return active;
    }

    /**
     *
     * @param {object[]} userList
     * @param {bool} isHidden
     */
    getHiddenUsers(userList, isHidden) {
        let hidden = userList.filter(
            el => el.isHidden === null || el.isHidden === 0
        );
        return hidden;
    }

    sendActivation() {
        // console.log("activation", this.state);
    }

    /**
     * get the user lists, parse them, set values to state
     */
    componentDidMount() {
        tools.axiosInstance
            .get("/user")
            .then(recData => {
                let isAdmin = recData.data.data[0].isAdmin === 1;
                if (isAdmin) {
                    // get user list
                    tools.axiosInstance
                        .get("/user/all")
                        .then(listData => {
                            let userList = listData.data.data;
                            userList = userList.map(el => {
                                if (el.purchaseDate) {
                                    el.purchaseDate = el.purchaseDate
                                        .replace("T", " ")
                                        .split(".")[0];
                                }

                                if (el.registerTime) {
                                    el.registerTime = el.registerTime
                                        .replace("T", " ")
                                        .split(".")[0];
                                }
                                return el;
                            });
                            userList = this.recentStatus(userList);
                            // console.log(this.recentStatus(userList), userList);
                            userList = this.getHiddenUsers(userList, false);
                            userList = userList.map(el => {
                                if (!el.accountHolder) {
                                    el.isClient = "A/c Holder";
                                    return el;
                                }
                                el.isClient = "User";
                                return el;
                            });
                            let userActiveList = this.getActiveUsers(
                                userList,
                                true
                            );
                            userActiveList = userActiveList.filter(
                                el => !el.accountHolder
                            );
                            let pendingUserList = this.getActiveUsers(
                                userList,
                                false
                            );
                            userActiveList = userActiveList.sort((a, b) =>
                                a.companyName > b.companyName
                                    ? 1
                                    : b.companyName > a.companyName
                                    ? -1
                                    : 0
                            );
                            pendingUserList = pendingUserList.sort((a, b) =>
                                a.companyName > b.companyName
                                    ? 1
                                    : b.companyName > a.companyName
                                    ? -1
                                    : 0
                            );
                            this.setState({
                                activeUserList: [...userActiveList],
                                pendingUserList: [...pendingUserList],
                                isAdmin
                            });
                        })
                        .catch(errList => {
                            // console.log(errList);
                        });
                }
            })
            .catch(err => console.log(err));
    }

    /**
     * converts object to an array by extracting the required parameters
     * @param {object} obj
     * @param {string[]} parametersToAdd
     */
    convertObjectToArray(obj, parametersToAdd) {
        let tempArray = [];
        if (!obj) {
            return tempArray;
        }

        let objectKeys = Object.keys(obj);
        if (objectKeys.length === 0) {
            return tempArray;
        }

        if (!parametersToAdd || parametersToAdd.length === 0) {
            for (let i = 0, n = objectKeys.length; i < n; i++) {
                tempArray.push(obj[objectKeys[i]]);
            }
            return tempArray;
        } else {
            for (let i = 0, n = parametersToAdd.length; i < n; i++) {
                tempArray.push(obj[parametersToAdd[i]]);
            }
            return tempArray;
        }
    }

    /**
     * create table header to be used in the admin tabs
     * @param {string[]} header
     */
    createTableHeader(header) {
        if (!header || !Array.isArray(header) || header.length === 0) {
            return null;
        }
        let tempArray = header.map((el, index) => (
            <div
                className={`uk-margin-left uk-width-1-${header.length +
                    1} uk-text-center`}
                key={`th-${index}`}
            >
                <span className="uk-text-bold">
                    <u>{el}</u>
                </span>
            </div>
        ));
        tempArray.push(
            <div className="uk-width-3-4" key={`th1`}>
                <span className=""></span>
            </div>
        );
        return (
            <div className="uk-flex uk-flex-between file-digest uk-border-rounded uk-padding-small uk-margin-small-top uk-card uk-card-default uk-card-body uk-width-9-10">
                <React.Fragment>{tempArray}</React.Fragment>
            </div>
        );
    }

    /**
     * creates buttons to be added to the rows of admin tables
     * @param {string[]} rowButtons
     * @param {int} rowId
     * @param {bool} isPending
     */
    createButtonCells(rowButtons, rowId, isPending) {
        let rowButtonCell = null;
        if (rowButtons && Array.isArray(rowButtons) && rowButtons.length > 0) {
            rowButtonCell = (
                <div className="uk-width-3-4" uk-grid>
                    {rowButtons.map((el, index) => (
                        <button
                            className={
                                el.className
                                    ? el.className
                                    : index % 2 === 0
                                    ? "uk-button uk-button-primary uk-width-auto@s uk-border-rounded"
                                    : "uk-button uk-button-danger uk-width-auto@s uk-border-rounded"
                            }
                            data-uk-toggle={`target: ${
                                typeof el.onClick !== "function"
                                    ? "#modal-overflow"
                                    : null
                            }`}
                            onClick={e => {
                                typeof el.onClick === "function" &&
                                    el.onClick(rowId, isPending);
                            }}
                            data-uk-icon={el.icon ? `icon: ${el.icon}` : null}
                            data-uk-tooltip={
                                el.tooltip ? `${el.tooltip}` : null
                            }
                        >
                            {el.icon ? null : el.title}
                        </button>
                    ))}
                </div>
            );
        }
        return rowButtonCell;
    }

    /**
     *
     * @param {string[]} data
     * @param {string} rowButtons
     * @param {bool} isPending
     */
    createTableBody(data, rowButtons, isPending) {
        //console.log("data",data)
        let body = data.map((el, index) => (
            <div
                className="uk-flex uk-flex-between file-digest uk-border-rounded uk-padding-small uk-margin-small-top uk-card uk-card-default uk-card-body uk-width-9-10"
                key={`body-row-${index}`}
                onClick={
                    this.rowClicked
                        ? () => this.rowClicked(index, isPending)
                        : null
                }
            >
                {el.map((col, idx) => {
                    if (!isNaN(Date.parse(col))) {
                        if (typeof col !== "string") {
                            return (
                                <div
                                    className={`uk-margin-left uk-width-1-${el.length +
                                        1} uk-text-center`}
                                    key={`body-td-${idx}`}
                                >
                                    <span>{col}</span>
                                </div>
                            );
                        }
                        return (
                            <div
                                className={`uk-margin-left uk-width-1-${el.length +
                                    1} uk-text-center`}
                                key={`body-td-${idx}`}
                            >
                                <span>
                                    {col.includes("T")
                                        ? col
                                              .split("T")[0]
                                              .split("-")
                                              .join("-")
                                        : col}
                                </span>
                            </div>
                        );
                    }

                    if (!col) {
                        return (
                            <div
                                className={`uk-margin-left uk-width-1-${el.length +
                                    1} uk-text-center`}
                                key={`body-td-${idx}`}
                            >
                                <span>--</span>
                            </div>
                        );
                    }
                    return (
                        <div
                            className={`uk-margin-left uk-width-1-${el.length +
                                1} uk-text-center`}
                            key={`body-td-${idx}`}
                        >
                            <span>{col}</span>
                        </div>
                    );
                })}
                {this.createButtonCells(rowButtons, index, isPending)}
            </div>
        ));
        return <React.Fragment>{body}</React.Fragment>;
    }

    /**
     * handle button clicks in the admin tables
     * @param {int} rowId
     * @param {bool} isPending
     */
    rowClicked(rowId, isPending) {
        this.setState({ rowClickedId: rowId, isPending });
    }

    /**
     * creates the admin table
     * @param {string[]} data
     * @param {string[]} header
     * @param {string[]} rowButton
     * @param {bool} isPending
     */
    createTable(data, header, rowButton, isPending) {
        let tableHeader = this.createTableHeader(header);
        let tableBody = this.createTableBody(data, rowButton, isPending);
        return (
            <React.Fragment>
                {tableHeader}
                {tableBody}
            </React.Fragment>
        );
    }

    /**
     *
     * @param {object[]} userList
     * @param {string[]} header
     * @param {string[]} rowButton
     * @param {bool} isPending
     */
    createActiveTable() {
        let tempArray = [];

        for (let i = 0, n = this.state.activeUserList.length; i < n; i++) {
            tempArray.push(
                this.convertObjectToArray(this.state.activeUserList[i], [
                    "id",
                    "companyName",
                    "country",
                    "email",
                    "registerTime",
                    "purchaseDate"
                ])
            );
        }
        //console.log("this.createActiveTable", tempArray);
        // return <Table headers={["Id", "Company", "Country", "Email"]}/>
        // return this.createTable(tempArray, header, rowButton, isPending);
        // console.log(tempArray);
        return tempArray;
    }

    /**
     *
     * @param {object[]} userList
     * @param {string[]} header
     * @param {string[]} rowButton
     * @param {bool} isPending
     */
    createPendingTable() {
        let tempArray = [];

        for (let i = 0, n = this.state.pendingUserList.length; i < n; i++) {
            tempArray.push(
                this.convertObjectToArray(this.state.pendingUserList[i], [
                    "id",
                    "companyName",
                    "country",
                    "isClient",
                    "email",
                    "registerTime"
                ])
            );
        }
        return tempArray;
        //console.log("this.createActiveTable", tempArray);
        // return this.createTable(tempArray, header, rowButton, isPending);
    }

    activateClickHandler(index) {}

    redirectToUsers(index) {
        const id = this.state.activeUserList[index].id;
        const url = `/admin/client/${id}`;
        this.props.history.push(url);
    }

    /**
     * send payment request
     */
    sendPayment() {
        let tempChosenUser = this.state.activeUserList[this.state.rowClickedId];
        if (this.state.isPending) {
            tempChosenUser = this.state.pendingUserList[
                this.state.rowClickedId
            ];
        }
        tools.axiosInstance
            .post(`/user/enable/${tempChosenUser.id}`, {
                isActive: true,
                isHidden: false
            })
            .then(suc => {
                //console.log("activateClickHandler", suc);
                let paymentLoad = {
                    baseAmount: this.state.baseAmount,
                    taxAmount: this.state.taxAmount,
                    totalAmount: this.state.totalAmount,
                    refCode: this.state.referenceCode
                };
                tools.axiosInstance
                    .post(`/user/purchase/${tempChosenUser.id}`, paymentLoad)
                    .then(suc1 => {
                        if (suc1.data.data[0].success) {
                            window.location.reload();
                        } else {
                            console.log("not success");
                        }
                    })
                    .catch(err1 => console.log("purchase", err1));
            })
            .catch(err => {
                console.log("activateClickHandler", err);
            });
    }

    /**
     * send deactivate request
     */
    sendDeactivation() {
        let tempChosenUser = this.state.activeUserList[this.state.rowClickedId];
        if (this.state.isPending) {
            tempChosenUser = this.state.pendingUserList[
                this.state.rowClickedId
            ];
        }
        tools.axiosInstance
            .post(`/user/enable/${tempChosenUser.id}`, {
                isActive: false,
                isHidden: false
            })
            .then(
                suc => window.location.reload(),
                err => window.location.reload()
            );
    }

    /**
     * send delete pending request
     */
    deletePendingRequest() {
        UIkit.modal
            .confirm("Are you sure?", { labels: { cancel: "No", ok: "Yes" } })
            .then(() => {
                let tempChosenUser = this.state.activeUserList[
                    this.state.rowClickedId
                ];
                if (this.state.isPending) {
                    tempChosenUser = this.state.pendingUserList[
                        this.state.rowClickedId
                    ];
                }
                tools.axiosInstance
                    .post(`/user/enable/${tempChosenUser.id}`, {
                        isHidden: true,
                        isActive: false
                    })
                    .then(suc => {
                        window.location.reload();
                    })
                    .catch(err => {
                        console.log(err);
                    });
            });
    }

    popUpClosed() {
        this.setState({ rowClickedId: -1 });
    }

    /**
     * render pop up contains user data
     */
    renderUserData() {
        if (this.state.rowClickedId === -1) {
            return null;
        }

        let userData = this.state.pendingUserList[this.state.rowClickedId];
        if (!this.state.isPending) {
            userData = this.state.activeUserList[this.state.rowClickedId];
        }
        let userNumber = userData.id;

        return (
            <div>
                <h2>{`Client Number: ${
                    userData.accountHolder ? `${userData.accountHolder}-` : ""
                } ${userData.country}-${userNumber}`}</h2>
                <label className="profileStatus">
                    {this.state.isPending
                        ? "(pending)"
                        : `(activated) ${
                              userData.userCount
                                  ? `${userData.userCount} users`
                                  : "0 users"
                          }`}
                </label>
                <ul uk-accordion="multiple: true" className="">
                    <li className="uk-open uk-card uk-card-small uk-card-default uk-box-shadow-small">
                        <a
                            className="uk-accordion-title uk-padding-small"
                            href="#"
                        >
                            Company Details
                        </a>
                        <div className="uk-accordion-content uk-padding-small">
                            <div
                                className="uk-grid uk-width-1-1 uk-children-width-1-7 uk-children-width-1-1@s"
                                data-uk-grid
                            >
                                <div>
                                    <span className="uk-text-bold">
                                        Company Name:
                                    </span>
                                    <span>&nbsp;{userData.companyName}</span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        Company/Business Email:
                                    </span>
                                    <span>&nbsp;{userData.email}</span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        Company Number:
                                    </span>
                                    <span>&nbsp;{userData.mobileNumber}</span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        Country:
                                    </span>
                                    <span>&nbsp;{userData.country}</span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">City:</span>
                                    <span>&nbsp;{userData.city}</span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        Zip Code:
                                    </span>
                                    <span>&nbsp;{userData.zipCode}</span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        Address:
                                    </span>
                                    <span>&nbsp;{userData.address}</span>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="uk-open uk-card uk-card-small uk-card-default uk-box-shadow-small">
                        <a
                            className="uk-accordion-title uk-padding-small"
                            href="#"
                        >
                            Company Trading Details
                        </a>
                        <div className="uk-accordion-content uk-padding-small">
                            <div
                                className="uk-grid uk-width-1-1 uk-children-width-1-3 uk-children-width-1-1@s"
                                data-uk-grid
                            >
                                <div>
                                    <span className="uk-text-bold">
                                        Fiscal Number:
                                    </span>
                                    <span>
                                        &nbsp;
                                        {userData.companyFiscalNumber}
                                    </span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        Trading Number: &nbsp;
                                        {userData.companyTradeNumber}
                                    </span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        RESC Code:
                                    </span>
                                    <span>&nbsp;{userData.rescCode}</span>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="uk-open uk-card uk-card-small uk-card-default uk-box-shadow-small">
                        <a
                            className="uk-accordion-title uk-padding-small"
                            href="#"
                        >
                            Personal Details
                        </a>
                        <div className="uk-accordion-content uk-padding-small">
                            <div
                                className="uk-grid uk-width-1-1 uk-children-width-1-4 uk-children-width-1-1@s"
                                data-uk-grid
                            >
                                <div>
                                    <span className="uk-text-bold">
                                        Initials:
                                    </span>
                                    <span>&nbsp;{userData.initials}</span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        First Name:
                                    </span>
                                    <span>&nbsp;{userData.firstName}</span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        Last Name:
                                    </span>
                                    <span>&nbsp;{userData.lastName}</span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        Mobile Number:
                                    </span>
                                    <span>&nbsp;{userData.mobileNumber}</span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        Registration Date:{" "}
                                    </span>
                                    <span>
                                        {userData.registerTime
                                            ? userData.registerTime
                                            : ""}
                                    </span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        Contact Type:
                                    </span>
                                    <span>&nbsp;{userData.contactType}</span>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="uk-open uk-card uk-card-small uk-card-default uk-box-shadow-small">
                        <a
                            className="uk-accordion-title uk-padding-small"
                            href="#"
                        >
                            Purchase Details
                        </a>
                        <div className="uk-accordion-content uk-padding-small">
                            <div
                                className="uk-grid uk-width-1-1 uk-children-width-1-4 uk-children-width-1-1@s"
                                data-uk-grid
                            >
                                <div>
                                    <span className="uk-text-bold">
                                        Base Amount:
                                    </span>
                                    <span>&nbsp;{userData.baseAmount}</span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        Tax Amount:
                                    </span>
                                    <span>&nbsp;{userData.taxAmount}</span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        Total Amount:
                                    </span>
                                    <span>&nbsp;{userData.totalAmount}</span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        Reference Code:
                                    </span>
                                    <span>&nbsp;{userData.referenceCode}</span>
                                </div>
                                <div>
                                    <span className="uk-text-bold">
                                        Purchase Date:
                                    </span>
                                    <span>
                                        &nbsp;
                                        {userData.purchaseDate
                                            ? userData.purchaseDate
                                            : ""}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <div>
                    <div className="uk-flex uk-flex-center uk-flex-between">
                        {this.state.isPending ? (
                            this.state.pendingUserList[this.state.rowClickedId]
                                .firstName ? (
                                <button
                                    className="uk-button-primary uk-width-1-3@m uk-width-1-4@l uk-text-large"
                                    data-uk-toggle="target: #modal-overflow-amount"
                                >
                                    Activate
                                </button>
                            ) : null
                        ) : (
                            <button
                                className="uk-button-primary uk-width-1-3@m uk-width-1-4@l uk-text-large"
                                onClick={this.sendDeactivation.bind(this)}
                            >
                                Deactivate
                            </button>
                        )}
                        <button
                            className={`uk-button-primary uk-width-1-3@m uk-width-1-4@l uk-text-large uk-button-danger ${
                                this.state.isPending ? "" : "uk-hidden"
                            }`}
                            onClick={() => this.deletePendingRequest()}
                        >
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    /**
     * fill payment object
     * @param {event} event
     * @param {string} variableName
     */
    handleInputChangeForPayment(event, variableName) {
        let tempState = {};
        tempState[variableName] = event.target.value;
        this.setState(tempState);
    }

    tabChangeListener(index) {
        if (index === 1) {
            this.setState({ adminTitle: "Pending Accounts" });
        } else {
            this.setState({ adminTitle: "Active Accounts" });
        }
    }

    render() {
        let header = [
            "Id",
            "Company",
            "Country",
            "Email",
            "Request Date",
            "Purchase Date"
        ];

        let pendingHeader = [
            "Id",
            "Company",
            "Country",
            "Type",
            "Email",
            "Request Date"
        ];

        let pendingRowButtons = [
            {
                tooltip: "info",
                // onClick: this.activateClickHandler.bind(this),
                className:
                    "uk-button uk-button-primary uk-width-auto@s uk-border-rounded uk-margin-small-bottom uk-margin-small-right uk-icon",
                icon: "info",
                onClick: index =>
                    this.setState({ rowClickedId: index, isPending: true }),
                "data-uk-toggle": `target: #modal-overflow`
            }
        ];

        let activeRowButtons = [
            {
                tooltip: "info",
                onClick: index =>
                    this.setState({ rowClickedId: index, isPending: false }),
                "data-uk-toggle": `target: #modal-overflow`,
                className:
                    "uk-button uk-button-primary uk-width-auto@s uk-border-rounded uk-margin-small-bottom uk-margin-small-right uk-icon",
                icon: "info"
            },
            {
                tooltip: "Users",
                onClick: index => this.redirectToUsers(index),
                className:
                    "uk-button uk-button-danger uk-width-auto@s uk-border-rounded uk-margin-small-bottom uk-icon",
                icon: "users"
            }
        ];

        return (
            <main>
                <div
                    className="page uk-grid-small uk-grid-match uk-grid"
                    data-uk-grid
                >
                    <div className="menu-bar uk-background-secondary uk-visible@m">
                        <Toolbar />
                    </div>
                    <div className="uk-width-expand page-content">
                        <div className="ems-page-container ems-admin">
                            <div
                                style={{
                                    borderBottom: "2px solid #e5e5e5",
                                    background: "white"
                                }}
                                data-uk-sticky
                            >
                                <ReportTitle
                                    title={`Admin: ${this.state.adminTitle}`}
                                />
                                <Form
                                    onlyTitle={true}
                                    title={`Admin: ${this.state.adminTitle}`}
                                />
                            </div>
                            <div
                                className="uk-flex-column uk-width-1-1"
                                style={{
                                    borderTop: "2px solid rgb(229, 229, 229)"
                                }}
                            >
                                <Tab
                                    titles={["Active", "Pending"]}
                                    tabChangeListener={index =>
                                        this.tabChangeListener(index)
                                    }
                                >
                                    {this.state.activeUserList ? (
                                        <div className="uk-margin-medium-left uk-margin-top">
                                            <label className="title">
                                                List of Account Holders (
                                                {
                                                    this.state.activeUserList
                                                        .length
                                                }
                                                )
                                            </label>
                                            {
                                                <Table
                                                    headers={header}
                                                    body={this.createActiveTable()}
                                                    rowButtons={
                                                        activeRowButtons
                                                    }
                                                    hideArrow
                                                />
                                            }
                                        </div>
                                    ) : null}
                                    <div className="uk-margin-medium-left uk-margin-top">
                                        {this.state.pendingUserList.length ? (
                                            <div className="pendingUsers">
                                                <label className="title">
                                                    Pending Requests (
                                                    {
                                                        this.state
                                                            .pendingUserList
                                                            .length
                                                    }
                                                    )
                                                </label>
                                                {
                                                    <Table
                                                        headers={pendingHeader}
                                                        body={this.createPendingTable()}
                                                        rowButtons={
                                                            pendingRowButtons
                                                        }
                                                        hideArrow
                                                        useColor
                                                    />
                                                }
                                            </div>
                                        ) : (
                                            "No data"
                                        )}
                                    </div>
                                </Tab>
                            </div>
                        </div>
                    </div>
                    <div id="modal-overflow" data-uk-modal>
                        <div className="uk-modal-dialog uk-width-8-10">
                            <button
                                className="uk-modal-close-default"
                                type="button"
                                data-uk-close
                            ></button>
                            <div className="uk-modal-header uk-text-center">
                                <h5 className="uk-margin-small-top">
                                    Profile of Company and User
                                </h5>
                            </div>
                            <div className="uk-modal-body uk-overflow-auto">
                                {this.renderUserData()}
                            </div>
                        </div>
                    </div>
                    <div id="modal-overflow-amount" data-uk-modal="stack: true">
                        <div className="uk-modal-dialog">
                            <button
                                className="uk-modal-close-default"
                                type="button"
                                data-uk-close
                            ></button>
                            <div className="uk-modal-header uk-text-center">
                                <p className="uk-margin-small-top">
                                    User Payment Details
                                </p>
                            </div>
                            <div className="uk-modal-body uk-overflow-auto">
                                <form className="uk-form-stacked">
                                    <div className="uk-margin">
                                        <label
                                            className="uk-form-label"
                                            htmlFor="form-stacked-text"
                                        >
                                            Base Amount
                                        </label>
                                        <div className="uk-form-controls">
                                            <input
                                                className="uk-input"
                                                id="form-stacked-text"
                                                type="text"
                                                placeholder="Base Amount"
                                                onChange={event =>
                                                    this.handleInputChangeForPayment(
                                                        event,
                                                        "baseAmount"
                                                    )
                                                }
                                                value={this.state.baseAmount}
                                            />
                                        </div>
                                    </div>

                                    <div className="uk-margin">
                                        <label
                                            className="uk-form-label"
                                            htmlFor="form-stacked-text"
                                        >
                                            Tax Amount
                                        </label>
                                        <div className="uk-form-controls">
                                            <input
                                                className="uk-input"
                                                id="form-stacked-text"
                                                type="text"
                                                placeholder="Tax Amount"
                                                onChange={event =>
                                                    this.handleInputChangeForPayment(
                                                        event,
                                                        "taxAmount"
                                                    )
                                                }
                                                value={this.state.taxAmount}
                                            />
                                        </div>
                                    </div>

                                    <div className="uk-margin">
                                        <label
                                            className="uk-form-label"
                                            htmlFor="form-stacked-text"
                                        >
                                            Total Amount
                                        </label>
                                        <div className="uk-form-controls">
                                            <input
                                                className="uk-input"
                                                id="form-stacked-text"
                                                type="text"
                                                placeholder="Total Amount"
                                                onChange={event =>
                                                    this.handleInputChangeForPayment(
                                                        event,
                                                        "totalAmount"
                                                    )
                                                }
                                                value={this.state.totalAmount}
                                            />
                                        </div>
                                    </div>

                                    <div className="uk-margin">
                                        <label
                                            className="uk-form-label"
                                            htmlFor="form-stacked-text"
                                        >
                                            Reference Code
                                        </label>
                                        <div className="uk-form-controls">
                                            <input
                                                className="uk-input"
                                                id="form-stacked-text"
                                                type="text"
                                                placeholder="Reference Code"
                                                onChange={event =>
                                                    this.handleInputChangeForPayment(
                                                        event,
                                                        "referenceCode"
                                                    )
                                                }
                                                value={this.state.referenceCode}
                                            />
                                        </div>
                                    </div>
                                    <div onClick={this.sendPayment.bind(this)}>
                                        <span className="uk-button uk-button-primary uk-width-auto@s">
                                            Set Payment
                                        </span>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div id="modal-overflow-delete" data-uk-modal="stack: true">
                        <div className="uk-modal-dialog">
                            <button
                                className="uk-modal-close-default"
                                type="button"
                                data-uk-close
                            ></button>
                            <div className="uk-modal-header uk-text-center">
                                <h5 className="uk-margin-small-top">
                                    Delete Company and User Account
                                </h5>
                            </div>
                            <div className="uk-modal-body uk-overflow-auto">
                                <form className="uk-form-stacked">
                                    <div className="uk-margin">
                                        <label
                                            className="uk-form-label"
                                            htmlFor="form-stacked-text"
                                        >
                                            Are You Sure You Want to Delete The
                                            User?
                                        </label>
                                    </div>

                                    <div className="uk-margin-xlarge-left">
                                        <span
                                            className="uk-button uk-button-danger uk-width-auto@s uk-margin-left"
                                            onClick={this.deletePendingRequest.bind(
                                                this
                                            )}
                                        >
                                            Yes
                                        </span>
                                        <span
                                            className="uk-button uk-button-primary uk-width-auto@s uk-margin-left"
                                            data-uk-close
                                        >
                                            No
                                        </span>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <Toolbar hidden={true} />
                <div
                    className={
                        window.innerWidth < 900
                            ? "uk-margin-top uk-flex uk-flex-right uk-text-meta"
                            : "footer-div uk-text-meta"
                    }
                >
                    <span>
                        Copyright &copy; 2019, {new Date().getFullYear()} Spenta
                        B.V., all rights reserved. Powered By codixt.com
                    </span>
                </div>
            </main>
        );
    }
}
