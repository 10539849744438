import React from "react";
import LogIn from "./component/login/login";
import QrCode from "./component/login/qrCode";
import LogOut from "./component/login/logOut";
import VerifyEmail from "./component/login/verifyEmail";
import Forgot from "./component/login/forgot";
import SignUp from "./component/signup/signup";
import Upload from "./component/upload/upload";
import Report from "./component/report/report";
import Admin from "./component/Admin/admin";
import UserList from "./component/Admin/users";
import Audit from "./component/Admin/audit";
import Auth from "./container/auth/auth";
import Profile from "./component/profile/profile";
import About from "./component/about/about";
import Contact from "./component/contact/contact";
import Delete from "./component/delete/delete";
import Footer from "./component/footer/footer";
import Ems from "./component/ems/ems";
import ConsolidatedReport from "./component/report/consolidatedReport";
import { Route, BrowserRouter } from "react-router-dom";
/**
 * App component
 * handles the routings
 */
function App() {
    return (
        <BrowserRouter>
            <Auth>
                <Route path="/" exact component={ConsolidatedReport} />
                <Route path="/admin" exact component={Admin} />
                <Route path="/admin/client/:id" exact component={UserList} />
                <Route path="/admin/audit/:id" exact component={Audit} />
                <Route path="/delete" exact component={Delete} />
                <Route path="/profile" exact component={Profile} />
                <Route path="/upload" exact={true} component={Upload} />
                <Route path="/report/:id" component={Report} />
                <Route path="/login" exact={true} component={LogIn} />
                <Route path="/qrcode" exact={true} component={QrCode} />
                <Route path="/logout" exact={true} component={LogOut} />
                <Route path="/forgot/:id" exact={true} component={Forgot} />
                <Route
                    path="/verify/:id"
                    exact={true}
                    component={VerifyEmail}
                />
                <Route path="/verify" exact={true} component={VerifyEmail} />
                <Route path="/forgot" exact={true} component={Forgot} />
                <Route path="/register" exact={true} component={SignUp} />
                <Route path="/about" exact={true} component={About} />
                <Route path="/ems" exact={true} component={Ems} />
                <Route path="/contact" exact={true} component={Contact} />
            </Auth>
        </BrowserRouter>
    );
}

export default App;
