import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import * as tools from "../../tools";

class LogOut extends Component {
    render() {
        tools.sendTrack("logout").finally(() => tools.deleteDataFromBrowser());
        return <Redirect to="/login" />;
    }
}

export default LogOut;
