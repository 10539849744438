import React, { Component } from "react";
import * as tools from "../../tools";
import logo from "../report/form/logo.png";
import { Link } from "react-router-dom";
import Footer from "../footer/footer";
/**Class signup handles the signup page */
class SignUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            isNew: false,
            isSuccess: null,
            isValid: true
        };
    }

    email = "";

    /**
     * send sign up request
     * @param {event} event
     */
    handleClick(event) {
        event.preventDefault();
        let validRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let isValid = validRegex.test(String(this.email).toLowerCase());
        this.setState({ isValid });
        if (isValid) {
            let payload = {
                email: this.email
            };
            tools.axiosInstance
                .post("user/register", payload)
                .then(suc => {
                    this.setState({
                        isNew: suc.data.data[0].isNew,
                        isSuccess: true
                    });
                })
                .catch(err => {
                    this.setState({ isSuccess: false });
                });
        }
    }

    reloadPage() {
        window.location.reload();
    }

    renderSuccess() {
        if (this.state.isSuccess === null) {
            return (
                <React.Fragment>
                    <div className="uk-flex uk-flex-center">
                        <form
                            className="uk-form-stacked uk-grid-small uk-width-1-1 uk-child-width-2-5"
                            data-uk-grid
                        >
                            <div>
                                <label className="uk-form-label uk-text-bold">
                                    Company/Business Email
                                </label>
                                <div className="uk-form-controls uk-inline uk-width-1-1 ">
                                    <span
                                        className="uk-form-icon"
                                        uk-icon="icon: mail"
                                    ></span>
                                    <input
                                        className={
                                            this.state.isValid
                                                ? "uk-input uk-border-rounded"
                                                : "uk-input uk-border-rounded uk-form-danger"
                                        }
                                        type="text"
                                        placeholder="Email"
                                        onChange={(event, newValue) => {
                                            return (this.email =
                                                event.target.value);
                                        }}
                                    />
                                </div>
                            </div>

                            {this.state.isValid ? (
                                <div></div>
                            ) : (
                                <div className="uk-flex uk-flex-center uk-text-danger">
                                    <p className="uk-text-justify">
                                        The email address entered is not valid!
                                    </p>
                                </div>
                            )}
                            <div>
                                <p className="uk-text-small uk-text-justify">
                                    By registering, you agree to our{" "}
                                    <a
                                        href="https://ems-soft.com/terms-of-service/"
                                        target="_blank"
                                    >
                                        <span className="uk-text-link">
                                            Terms of Services
                                        </span>
                                    </a>{" "}
                                    and{" "}
                                    <a
                                        href="https://ems-soft.com/privacy/"
                                        target="_blank"
                                        className="uk-margin-remove"
                                    >
                                        <span className="uk-text-link">
                                            Privacy Policy
                                        </span>
                                    </a>
                                    .
                                </p>
                            </div>

                            <div className="uk-margin-large-top">
                                <button
                                    className="uk-button uk-button-primary uk-width-1-1 uk-border-rounded"
                                    type="submit"
                                >
                                    Sign Up
                                </button>
                            </div>
                            <div>
                                <p className="uk-text-small uk-text-center">
                                    Already have an account ?{" "}
                                    <Link to="/login">
                                        <span className="uk-link">Login</span>
                                    </Link>
                                </p>
                            </div>
                        </form>
                    </div>
                </React.Fragment>
            );
        }
        if (this.state.isSuccess === true) {
            return (
                <div className="uk-flex uk-flex-center uk-flex-column">
                    <p className="uk-text-justify">
                        We have sent you an email to {" " + this.email}.
                    </p>
                    <p className="uk-text-justify">
                        {" "}
                        Please check your email inbox (including spam folder).
                    </p>
                </div>
            );
        }
        return (
            <div className="uk-flex uk-flex-center uk-flex-column">
                <p className="uk-text-justify">
                    Oops! There was an error in the sign up process, please
                    click&nbsp;
                    <Link to="/register" onClick={this.reloadPage.bind(this)}>
                        here
                    </Link>{" "}
                    to try again.
                </p>
                <p className="uk-text-justify">
                    If you have seen this page before please contact{" "}
                    <Link to="/contact">EMS Team</Link>
                </p>
            </div>
        );
    }

    render() {
        return (
            <main>
                <div className="uk-width-1-1 uk-child-width-1-1 page uk-flex uk-flex-center uk-flex-middle uk-flex-wrap uk-flex-wrap-around uk-margin-large-top uk-hidden@m">
                    <img src={logo} alt="" className="uk-width-1-3" />

                    <div className="uk-flex uk-flex-center">
                        <div className="uk-width-4-5">
                            <div className="uk-flex uk-flex-center uk-flex-middle uk-height-1-1 uk-flex-column">
                                <h3 className="uk-text-bold">Register</h3>
                                <div className="uk-flex uk-flex-center uk-flex-column">
                                    <form
                                        className="uk-form-stacked uk-grid-small uk-child-width-1-1"
                                        data-uk-grid
                                        onSubmit={event =>
                                            this.handleClick(event)
                                        }
                                    >
                                        {this.renderSuccess()}
                                    </form>
                                    <div className="uk-text-center uk-text-center uk-margin-top">
                                        <a
                                            href="https://ems-soft.com/#about-us"
                                            target="_blank"
                                        >
                                            <span className="uk-link uk-margin-right">
                                                About
                                            </span>
                                        </a>
                                        <a
                                            href="https://ems-soft.com/#contact"
                                            target="_blank"
                                        >
                                            <span className="uk-link">
                                                Contact Us
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="uk-width-1-1 page uk-flex uk-flex-center uk-flex-middle uk-flex-row uk-visible@m">
                    <div className="uk-width-1-2 uk-flex-right uk-flex uk-margin-large-right">
                        <div className="uk-width-4-5@m uk-width-3-5@l uk-width-2-5@xl">
                            <div className="uk-flex uk-flex-center uk-flex-middle uk-height-1-1 uk-flex-column">
                                <h3 className="uk-text-bold uk-margin-large-bottom">
                                    Register
                                </h3>
                                <div className="uk-flex uk-flex-center uk-flex-column">
                                    <form
                                        className="uk-form-stacked uk-grid-small uk-child-width-1-1"
                                        data-uk-grid
                                        onSubmit={event =>
                                            this.handleClick(event)
                                        }
                                    >
                                        {this.renderSuccess()}
                                    </form>
                                    <div className="uk-text-center uk-text-center uk-margin-top">
                                        <a
                                            href="https://ems-soft.com/#about-us"
                                            target="_blank"
                                        >
                                            <span className="uk-link uk-margin-right">
                                                About
                                            </span>
                                        </a>
                                        <a
                                            href="https://ems-soft.com/#contact"
                                            target="_blank"
                                        >
                                            <span className="uk-link">
                                                Contact Us
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="uk-width-1-2 uk-margin-large-left">
                        <div className="uk-margin-large-bottom"></div>
                        <img
                            src={logo}
                            alt=""
                            className="uk-width-4-5@m uk-width-3-5@l uk-width-2-5@xl"
                        />
                    </div>
                </div>
                <Footer />
            </main>
        );
    }
}

export default SignUp;
