import React, { Component } from "react";
import logo from "../report/form/logo.png";
import Footer from "../footer/footer";
import { Link, withRouter, Redirect } from "react-router-dom";
/**
 * class About, renders the about page
 */
class About extends Component {
    render() {
        return (
            <div className="static-page-container">
                <div className="page">
                    <div className="uk-flex uk-flex-middle uk-flex-center">
                        <div className="uk-width-5-6">
                            <div className="uk-flex uk-flex-center uk-flex-middle uk-flex-column uk-height-1-1">
                                <img
                                    src={logo}
                                    alt=""
                                    className="uk-width-1-4 uk-margin-large-bottom uk-margin-large-top"
                                />
                                <div className="uk-width-4-5 uk-text-justify">
                                    <p className="uk-margin-remove-top">
                                        <span className="uk-text-justify uk-text-bold uk-text-large">
                                            About EMS
                                        </span>
                                    </p>
                                    <p className="uk-margin-remove-top">
                                        <span className="uk-text-justify">
                                            We are a Dutch independent
                                            information technology and software
                                            as a service company based in the
                                            Netherlands.
                                        </span>
                                    </p>
                                    <p className="uk-margin-remove-top">
                                        <span className="uk-text-justify">
                                            Our mission is building and offering
                                            EMS, a SaaS (software-as-a-service)
                                            for renewable energy producers and
                                            users. We aim to transform and
                                            develop methods (cost effective,
                                            scalable, and flexible) through ease
                                            of use and outsourcing of
                                            operational management for the
                                            producers and users of renewable
                                            energy, environmental commodities,
                                            and CO2 footprint in the Netherlands
                                            and the European Union market place.
                                        </span>
                                    </p>
                                    <p className="uk-margin-remove-top">
                                        <span className="uk-text-justify">
                                            EMS will change the way companies
                                            manage business related to renewable
                                            energy, environmental commodities
                                            and CO2 footprint reduction no
                                            matter what size or which industry
                                            these may originate.
                                        </span>
                                    </p>
                                    <p className="uk-margin-remove-top">
                                        <span className="uk-text-justify">
                                            EMS Team
                                        </span>
                                    </p>
                                    <div className="uk-margin-top">
                                        <Link to="/contact">
                                            <span className="uk-link">
                                                Contact
                                            </span>
                                        </Link>
                                        <Link
                                            to="/register"
                                            className="uk-margin-large-left"
                                        >
                                            <span className="uk-link">
                                                Register
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-div uk-text-meta">
                    Copyright &copy; 2019, {new Date().getFullYear()} Spenta
                    B.V., all rights reserved. Powered By&nbsp;
                    <a href="http://codixt.com" target="_blank">
                        codixt
                    </a>
                </div>
            </div>
        );
    }
}

export default withRouter(About);
