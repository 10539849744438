import React, { Component } from "react";

class FileTypeTable extends Component {
    state = {
        showClose: false
    };

    createTable() {
        let tempData = this.props.data;
        if (!this.props.data) {
            return null;
        }

        let column = tempData.map((el, index) => {
            return (
                <div
                    className="uk-flex uk-flex-between file-digest uk-border-rounded uk-padding-small uk-margin-small-top uk-card uk-card-default uk-card-body uk-width-9-10"
                    key={`uploadTableBodyCell-${index}`}
                >
                    <div className="uk-flex-left">{el.title}</div>
                    <div className="uk-flex-right">{el.extra}</div>
                </div>
            );
        });

        let hasData = this.props.data.length > 0;
        let header = (
            <span>
                {this.props.title}
                {hasData ? (
                    <span
                        uk-icon="icon: check"
                        className="uk-margin-small-left uk-text-success"
                    ></span>
                ) : null}
            </span>
        );

        return (
            <div className="uk-first-column">
                <div className="uk-flex uk-flex-column">
                    {header}
                    {column}
                </div>
            </div>
        );
    }

    render() {
        return this.createTable();
    }
}

export default FileTypeTable;
