import React, { Component } from "react";
import * as tools from "../../tools";
import Footer from "../footer/footer";
import logo from "../report/form/logo.png";
import { Link, Redirect } from "react-router-dom";
import UIkit from "uikit";

class LogIn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            logInSuccessful: false,
            hasError: false
        };
        this.nameInput = React.createRef();
    }

    email = "";
    password = "";

    handleClick(event) {
        event.preventDefault();
        if (!this.email || !this.password) {
            this.setState(
                {
                    errorMessage: "Email and Password Must be filled",
                    hasError: true
                },
                () => {
                    let t = this.state.errorMessage;
                }
            );
            return;
        }
        var payload = {
            email: this.email,
            password: this.password
            //code: this.code
        };
        tools.axiosInstance
            .post("user/temp-login", payload)
            .then(suc => {
                if (!suc.data.data[0].isValid) {
                    this.setState({
                        logInSuccessful: false,
                        hasError: true
                    });
                } else {
                    this.setState(
                        {
                            logInSuccessful: false,
                            hasError: false
                        },
                        () => {
                            this.dialog = UIkit.modal(
                                document.getElementById("modal-overflow-amount")
                            );
                            this.dialog.show();
                            if (this.nameInput && this.nameInput.current) {
                                setTimeout(
                                    () => this.nameInput.current.focus(),
                                    100
                                );
                            }
                        }
                    );
                }
            })
            .catch(err => {
                this.setState({ logInSuccessful: false, hasError: true });
            });
    }

    logIn(event) {
        event.preventDefault();
        if (!this.email || !this.password) {
            this.setState(
                {
                    errorMessage: "Email and Password Must be filled",
                    hasError: true
                },
                () => {
                    let t = this.state.errorMessage;
                }
            );
            return;
        }
        var payload = {
            email: this.email,
            password: this.password,
            code: this.code
        };
        tools.axiosInstance
            .post("user/login", payload)
            .then(suc => {
                if (
                    Array.isArray(suc.data.data) &&
                    suc.data.data.length > 0 &&
                    suc.data.data[0].userSession
                ) {
                    tools.saveDataToBrowser(
                        "session",
                        suc.data.data[0].userSession
                    );
                    tools.readDataFromBrowser("session");
                    tools.sendTrack("login").finally(() => {
                        if (this.dialog) {
                            this.dialog.hide();
                        }
                        this.setState({
                            logInSuccessful:
                                suc.data.data[0].userSession !== null ||
                                suc.data.data[0].userSession !== "",
                            hasError:
                                suc.data.data[0].userSession === null ||
                                suc.data.data[0].userSession === "" ||
                                suc.data.data[0].userSession === undefined
                        });
                    });
                    tools.saveDataToBrowser("newLogIn", 1);
                } else {
                    this.setState({ logInWithCode: true });
                }
            })
            .catch(err => {});
    }

    handleInputChange(event, newValue) {}

    render() {
        if (this.state.logInSuccessful) {
            return <Redirect to="/" />;
        }
        return (
            <main>
                <div className="uk-width-1-1 uk-child-width-1-1 page uk-flex uk-flex-center uk-flex-middle uk-flex-wrap uk-flex-wrap-around uk-margin-large-top uk-hidden@m">
                    <img
                        src={logo}
                        alt=""
                        className="uk-width-1-3 uk-margin-xlarge-top"
                    />
                    <div className="uk-flex uk-flex-center">
                        <div className="uk-width-4-5">
                            <div className="uk-flex uk-flex-center uk-flex-middle uk-flex-column">
                                <h3 className="uk-text-bold">Login</h3>
                                <div className="uk-flex uk-flex-center uk-flex-column">
                                    <form
                                        className="uk-form-stacked uk-grid-small uk-child-width-1-1"
                                        data-uk-grid
                                        onSubmit={event =>
                                            this.handleClick(event)
                                        }
                                    >
                                        <div>
                                            <label className="uk-form-label uk-text-bold">
                                                Company/Business Email
                                            </label>
                                            <div className="uk-form-controls uk-inline uk-width-1-1">
                                                <span
                                                    className="uk-form-icon"
                                                    uk-icon="icon: mail"
                                                ></span>
                                                <input
                                                    className="uk-input uk-border-rounded"
                                                    type="email"
                                                    placeholder="Company/Business Email"
                                                    onChange={event =>
                                                        (this.email =
                                                            event.target.value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <label className="uk-form-label uk-text-bold">
                                                Password
                                            </label>
                                            <div className="uk-form-controls uk-inline uk-width-1-1">
                                                <span
                                                    className="uk-form-icon"
                                                    uk-icon="icon: lock"
                                                ></span>
                                                <input
                                                    className="uk-input uk-border-rounded"
                                                    type="password"
                                                    placeholder="Password"
                                                    onChange={event =>
                                                        (this.password =
                                                            event.target.value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                        {this.state.hasError ? (
                                            <div className="uk-text-danger">
                                                User name and password do not
                                                match Or your account has not
                                                been activated yet
                                            </div>
                                        ) : (
                                            <div></div>
                                        )}
                                        <div>
                                            <Link
                                                to="/forgot"
                                                className="uk-text-small"
                                            >
                                                Forgot Password ?
                                            </Link>
                                        </div>
                                        <div className="uk-row">
                                            <p className="uk-text-small uk-text-justify">
                                                By login, you agree to our{" "}
                                                <a
                                                    href="https://ems-soft.com/terms-of-service/"
                                                    target="_blank"
                                                >
                                                    <span className="uk-text-link">
                                                        Terms of Services
                                                    </span>
                                                </a>{" "}
                                                and{" "}
                                                <a
                                                    href="https://ems-soft.com/privacy/"
                                                    target="_blank"
                                                    className="uk-margin-remove"
                                                >
                                                    <span className="uk-text-link">
                                                        Privacy Policy
                                                    </span>
                                                </a>
                                                .
                                            </p>
                                        </div>
                                        <div>
                                            <button
                                                className="uk-button uk-button-primary uk-width-1-1 uk-border-rounded"
                                                type="submit"
                                            >
                                                Login
                                            </button>
                                        </div>
                                        <div>
                                            <p className="uk-text-small uk-text-center">
                                                Don't have an account ?{" "}
                                                <Link to="/register">
                                                    <span className="uk-link">
                                                        Sign up
                                                    </span>
                                                </Link>
                                            </p>
                                        </div>
                                    </form>
                                    <div className="uk-text-center uk-text-center uk-margin-top">
                                        <a
                                            href="https://ems-soft.com/#about-us"
                                            target="_blank"
                                        >
                                            <span className="uk-link uk-margin-right">
                                                About
                                            </span>
                                        </a>
                                        <a
                                            href="https://ems-soft.com/#contact"
                                            target="_blank"
                                        >
                                            <span className="uk-link">
                                                Contact Us
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="uk-width-1-1 page uk-flex uk-flex-center uk-flex-middle uk-flex-row uk-visible@m">
                    <div className="uk-width-1-2 uk-flex-right uk-flex uk-margin-large-right">
                        <div className="uk-width-4-5@m uk-width-3-5@l uk-width-2-5@xl">
                            <h3 className="uk-text-bold uk-text-center uk-margin-large-bottom">
                                Login
                            </h3>
                            <div className="uk-flex uk-flex-center uk-flex-column">
                                <form
                                    className="uk-form-stacked uk-grid-small uk-child-width-1-1"
                                    data-uk-grid
                                    onSubmit={event => this.handleClick(event)}
                                >
                                    <div>
                                        <label className="uk-form-label uk-text-bold">
                                            Company/Business Email
                                        </label>
                                        <div className="uk-form-controls uk-inline uk-width-1-1">
                                            <span
                                                className="uk-form-icon"
                                                uk-icon="icon: mail"
                                            ></span>
                                            <input
                                                className="uk-input uk-border-rounded"
                                                type="email"
                                                placeholder="Company/Business Email"
                                                onChange={event =>
                                                    (this.email =
                                                        event.target.value)
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <label className="uk-form-label uk-text-bold">
                                            Password
                                        </label>
                                        <div className="uk-form-controls uk-inline uk-width-1-1">
                                            <span
                                                className="uk-form-icon"
                                                uk-icon="icon: lock"
                                            ></span>
                                            <input
                                                className="uk-input uk-border-rounded"
                                                type="password"
                                                placeholder="Password"
                                                onChange={event =>
                                                    (this.password =
                                                        event.target.value)
                                                }
                                            />
                                        </div>
                                    </div>
                                    {this.state.hasError ? (
                                        <div className="uk-text-danger">
                                            User name and password do not match
                                            Or your account has not been
                                            activated yet
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}
                                    <div>
                                        <Link
                                            to="/forgot"
                                            className="uk-text-small"
                                        >
                                            Forgot Password ?
                                        </Link>
                                    </div>
                                    <div>
                                        <p className="uk-text-small uk-text-justify">
                                            By login, you agree to our{" "}
                                            <a
                                                href="https://ems-soft.com/terms-of-service/"
                                                target="_blank"
                                            >
                                                <span className="uk-text-link">
                                                    Terms of Services
                                                </span>
                                            </a>{" "}
                                            and{" "}
                                            <a
                                                href="https://ems-soft.com/privacy/"
                                                target="_blank"
                                                className="uk-margin-remove"
                                            >
                                                <span className="uk-text-link">
                                                    Privacy Policy
                                                </span>
                                            </a>
                                            .
                                        </p>
                                    </div>
                                    <div>
                                        <button
                                            className="uk-button uk-button-primary uk-width-1-1 uk-border-rounded"
                                            type="submit"
                                        >
                                            Login
                                        </button>
                                    </div>
                                    <div>
                                        <p className="uk-text-small uk-text-center">
                                            Don't have an account ?{" "}
                                            <Link to="/register">
                                                <span className="uk-link">
                                                    Sign up
                                                </span>
                                            </Link>
                                        </p>
                                    </div>
                                </form>
                                <div className="uk-text-center uk-text-center uk-margin-top">
                                    <a
                                        href="https://ems-soft.com/#about-us"
                                        target="_blank"
                                    >
                                        <span className="uk-link uk-margin-right">
                                            About
                                        </span>
                                    </a>
                                    <a
                                        href="https://ems-soft.com/#contact"
                                        target="_blank"
                                    >
                                        <span className="uk-link">
                                            Contact Us
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-margin-large-left">
                        <div className="uk-margin-large-bottom"></div>
                        <img
                            src={logo}
                            alt=""
                            className="uk-width-4-5@m uk-width-3-5@l uk-width-2-5@xl"
                        />
                    </div>
                </div>
                <div id="modal-overflow-amount" data-uk-modal="stack: true">
                    <div className="uk-modal-dialog">
                        <button
                            className="uk-modal-close-default"
                            type="button"
                            data-uk-close
                        ></button>
                        <div className="uk-modal-header uk-text-center">
                            <p className="uk-margin-small-top">
                                Enter Authentication Code
                            </p>
                        </div>
                        <div className="uk-modal-body uk-overflow-auto">
                            <form
                                className="uk-form-stacked"
                                onSubmit={event => this.logIn(event)}
                            >
                                <div className="uk-margin">
                                    <label
                                        className="uk-form-label"
                                        htmlFor="form-stacked-text"
                                    >
                                        Authentication Code
                                    </label>
                                    <div className="uk-form-controls">
                                        <input
                                            className="uk-input"
                                            id="form-stacked-text-code"
                                            type="text"
                                            placeholder="123456"
                                            ref={this.nameInput}
                                            onChange={event =>
                                                (this.code = event.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                                {this.state.logInWithCode ? (
                                    <div>
                                        <div className="uk-text-danger">
                                            The entered code is incorrect!
                                        </div>
                                    </div>
                                ) : null}
                                <div>
                                    <Link
                                        to="/qrcode"
                                        className="uk-text-small"
                                        onClick={event => {
                                            if (this.dialog) {
                                                this.dialog.hide();
                                            }
                                        }}
                                    >
                                        Get authentication code
                                    </Link>
                                </div>
                                <div className="uk-margin-small-top">
                                    <button
                                        type="submit"
                                        className="uk-button uk-button-primary uk-width-auto@s"
                                    >
                                        Proceed
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <Footer />
            </main>
        );
    }
}

export default LogIn;
