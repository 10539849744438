import React, { Component } from "react";
import Toolbar1 from "../toolbar/toolbar";
import ReportTitle from "../report/reportTitle/reportTitle";
import Form from "../report/form/form";
import { withRouter, Link } from "react-router-dom";

class Ems extends Component {
    render() {
        return (
            <main>
                <div
                    className="page uk-height-1-1 uk-grid-small uk-grid-match uk-grid"
                    data-uk-grid
                >
                    <div className="menu-bar uk-background-secondary uk-visible@m">
                        <Toolbar1 />
                    </div>
                    <div className="uk-width-expand uk-height-1-1 page-content">
                        <div className="ems-page-container">
                            <div
                                style={{
                                    borderBottom: "2px solid #e5e5e5",
                                    background: "white"
                                }}
                                data-uk-sticky
                            >
                                <ReportTitle title="Contact EMS" />
                                <Form onlyTitle={true} title="Contact EMS" />
                            </div>
                            <div
                                className="uk-padding uk-flex-column uk-width-1-1"
                                style={{ borderTop: "2px solid #e5e5e5" }}
                            >
                                <div className="uk-flex uk-flex-column uk-height-1-1">
                                    <div className="uk-margin-remove-top">
                                        <div className="uk-text-justify">
                                            <p className="uk-padding-remove">
                                                <span className="uk-text-justify">
                                                    EMS is a registered product
                                                    of Spenta B.V., with chamber
                                                    of commerce number (k.v.k):
                                                    60241098, The Hague, The
                                                    Netherlands.
                                                    <br /> Copyright &copy;
                                                    2019,{" "}
                                                    {new Date().getFullYear()}
                                                    {", "}
                                                    Spenta B.V., all rights
                                                    reserved.
                                                </span>
                                            </p>
                                            <p className="uk-padding-remove  uk-margin-remove-top">
                                                <span className="uk-text-justify">
                                                    Address:
                                                </span>
                                            </p>
                                            <p className="uk-padding-remove  uk-margin-remove-top">
                                                <span className="uk-text-justify">
                                                    Spenta B.V.,
                                                </span>
                                            </p>
                                            <p className="uk-padding-remove  uk-margin-remove-top">
                                                <span className="uk-text-justify">
                                                    Postbus 1268
                                                </span>
                                            </p>
                                            <p className="uk-padding-remove  uk-margin-remove-top">
                                                <span className="uk-text-justify">
                                                    2260 BG, Leidschendam
                                                </span>
                                            </p>
                                            <p className="uk-padding-remove  uk-margin-remove-top">
                                                <span className="uk-text-justify">
                                                    The Netherlands
                                                </span>
                                            </p>
                                            <p className="uk-padding-remove  uk-margin-remove-top">
                                                <span className="uk-text-justify">
                                                    Tel. +31 85 060 0332
                                                </span>
                                            </p>
                                            <p className="uk-padding-remove  uk-margin-medium-top uk-text-left uk-flex uk-flex-row">
                                                <span className="uk-text-justify">
                                                    For Information:{" "}
                                                    <a
                                                        href="mailto:info@ems-soft.com"
                                                        target="_top"
                                                    >
                                                        info@ems-soft.com
                                                    </a>
                                                </span>
                                            </p>
                                            <p className="uk-padding-remove  uk-margin-remove-top uk-text-left uk-flex uk-flex-row">
                                                <span className="uk-text-left">
                                                    For Support:
                                                </span>
                                                <span>
                                                    &nbsp;
                                                    <a
                                                        href="mailto:support@ems-soft.com"
                                                        target="_top"
                                                    >
                                                        support@ems-soft.com
                                                    </a>
                                                </span>
                                            </p>
                                            <p className="uk-padding-remove  uk-margin-medium-top">
                                                <span className="uk-text-justify">
                                                    EMS corporate website:{" "}
                                                    <a
                                                        href="http://www.ems-soft.com"
                                                        target="_blank"
                                                    >
                                                        www.ems-soft.com
                                                    </a>
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-margin-top uk-flex uk-flex-middle">
                                    <div>
                                        <a
                                            href="https://ems-soft.com/terms-of-service/"
                                            target="_blank"
                                        >
                                            <span className="uk-text-link">
                                                Terms of Services
                                            </span>
                                        </a>{" "}
                                        <a
                                            href="https://ems-soft.com/privacy/"
                                            target="_blank"
                                            className="uk-margin-large-left"
                                        >
                                            <span className="uk-text-link">
                                                Privacy Policy
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Toolbar1 hidden={true} />
            </main>
        );
    }
}

export default withRouter(Ems);
