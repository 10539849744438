export { axiosInstance } from "./axios";

export {
    readDataFromBrowser,
    saveDataToBrowser,
    deleteDataFromBrowser,
    deleteSingleParameter
} from "./browserStorage";

export { sendTrack } from "./track";

export { formatDate, dateFromTimeStamp } from "./utils";
