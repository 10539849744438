import React, { Component } from "react";
import { Link } from "react-router-dom";
import Footer from "../footer/footer";
import MyTable from "../table/table";
import Form from "./form/form";
import Tab from "./tab/tab";
import * as tools from "../../tools";
import Toolbar1 from "../toolbar/toolbar";
import Chart from "./chart/chart";
import ReportTitle from "./reportTitle/reportTitle";
import zipcelx from "zipcelx";
/**Class Consolidated */
class ConsolidatedReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showBeginning: false,
            beginningPlotData: [],
            headers: [],
            data: [[]],
            right: {
                header: [],
                data: [[]]
            },
            futureExpiration: [],
            futureHeader: [],
            groups: null,
            clickedRowIdx: -1,
            clickedLegend: "",
            chartTitle: [],
            userData: null,
            yearSelectedIndx: 1,
            columnsToShow:
                window.innerWidth > 1200 ? 12 : window.innerWidth > 900 ? 6 : 3,
            selectedTab: 0,
            currentIndex: 0,
            tableData: [[]],
            tableHeader: [],
            tableFooter: [],
            fromColumn: 0,
            toColumn:
                window.innerWidth > 1200 ? 12 : window.innerWidth > 900 ? 6 : 3,
            chartType: "Bar",
            relationData: [[]],
            overflowIdx: 1
        };

        this.tabTitles = ["Overview", "Expirations", "Relations"];

        this.left = {
            header: [""],
            data: [
                "Beginning Inventory",
                "Statement of Additions",
                "Issued Certificates",
                "Imported Certificates",
                "Transferred to Trading Account",
                "Increase in Certificates Inventory",
                "Statement of Deductions",
                "Cancelled Certificates",
                "Exported Certificates",
                "Transferred out of Trading Account",
                "Withdrawals",
                "Decrease in Certificates Inventory",
                "Issued Certificates for Own Use",
                "Cancelled Certificates by Third party",
                "Certificates Inventory Summary",
                "Increase in Certificates Inventory",
                "Decrease in Certificates Inventory",
                "Mutation Movement (Net Inventory Change)",
                "End of Inventory (cannot be negative)",
                "Cancelled for Third Parties"
            ]
        };

        this.monthList = [
            "Jan.",
            "Feb.",
            "Mar.",
            "Apr.",
            "May",
            "Jun.",
            "Jul.",
            "Aug.",
            "Sep.",
            "Oct.",
            "Nov.",
            "Dec."
        ];
        this.monthHeader = [];
        this.monthHeaderExpiration = [];
        this.yearCount = null;
        this.dateType = "transaction date";
        this.chartData = [[]];
    }

    /**
     * calculate the number of columns to show based on screen size
     */
    getColumnToShowCount() {
        return window.innerWidth > 1200 ? 12 : window.innerWidth > 900 ? 6 : 3;
    }

    /**
     * is expiration tab selected
     */
    getIsExpiration() {
        return this.state.selectedTab === 1;
    }

    /**
     * fills table data
     * @param {bool} isExpiration
     */
    fillTableData(isExpiration) {
        let tempTableData = this.state.data;
        if (isExpiration === 1) {
            tempTableData = this.state.futureExpiration.map(el =>
                this.createTableRow(el)
            );
            return tempTableData;
        }

        // if (isExpiration === 3) {
        //     tempTableData = this.state.data;
        // }

        if (this.state.data) {
            tempTableData = tempTableData.map(el => this.createTableRow(el));
        }
        return tempTableData;
    }

    /**
     * get fixed column count based on screen size
     */
    getFixedColumnCount() {
        if (window.innerWidth < 600) return 0;
        return this.state.selectedTab === 0 || this.state.selectedTab === 2
            ? 3
            : 1;
    }

    /**
     * create table row
     * @param {string[]} data
     */
    createTableRow(data) {
        let fixedColCount = this.getFixedColumnCount();

        return data
            .map(el => (typeof el === "undefined" ? 0 : el))
            .slice(0, 1)
            .concat(
                data.slice(
                    this.state.currentIndex * this.state.columnsToShow +
                        this.state.fromColumn +
                        1,
                    this.state.currentIndex * this.state.columnsToShow +
                        this.state.fromColumn +
                        this.state.columnsToShow +
                        1
                )
            )
            .concat(data.slice(data.length - fixedColCount, data.length));
    }

    /**
     * handles the number of clicks on pagination form
     */
    getTotalHorizontalScrollCount() {
        if (this.getIsExpiration()) {
            return 12 / this.state.columnsToShow;
        }
        if (!this.state.data) {
            return 1;
        }
        let columnsToShowCount = this.getColumnToShowCount();
        let fixedCount = this.getFixedColumnCount() + 1;
        return (this.state.data[0].length - fixedCount) / columnsToShowCount;
    }

    /**
     *
     */
    showHorizontalPagination() {
        return this.state.currentIndex < this.getTotalHorizontalScrollCount();
    }

    showNextPagination() {
        return this.showHorizontalPagination();
    }

    showPreviousPagination() {
        return this.state.currentIndex != 0;
    }

    /**
     * returns boolean
     * @param {int} currentIndex
     */
    shouldGoToNextYear(currentIndex) {
        if (this.getIsExpiration() && this.state.columnsToShow === 12) {
            return false;
        }
        let countToGo = 12 / this.state.columnsToShow;
        let temp = currentIndex % countToGo;
        // console.log({ temp, currentIndex, countToGo });
        return temp === 0;
    }

    /**
     * scroll to the first window
     */
    goToFirstView() {
        if (this.getIsExpiration()) {
            this.setState({ currentIndex: 0, yearSelectedIndx: 1 });
        } else {
            this.setState({ currentIndex: 0, yearSelectedIndx: 1 });
        }
    }

    /**
     * scroll to the last window
     */
    goToLastView() {
        if (this.getIsExpiration()) {
            this.setState({ currentIndex: 0, yearSelectedIndx: 1 });
        } else {
            this.setState({
                currentIndex: this.getTotalHorizontalScrollCount() - 1,
                yearSelectedIndx: this.yearCount
            });
        }
    }

    /**
     * year combo change event handler
     * @param {event} event
     */
    handleDropDownYearSelectChange(event) {
        if (event.target) {
            if (!this.getIsExpiration()) {
                this.setState({
                    yearSelectedIndx: parseInt(event.target.value),
                    currentIndex:
                        ((event.target.value - 1) * 12) /
                        this.state.columnsToShow
                });
            }
        } else {
            if (!this.getIsExpiration()) {
                this.setState({
                    yearSelectedIndx: parseInt(event),
                    currentIndex: ((event - 1) * 12) / this.state.columnsToShow
                });
            }
        }
    }

    /**
     * scroll to the next window if possible
     */
    goToNextView() {
        let currentIndex = this.state.currentIndex;
        if (currentIndex < this.getTotalHorizontalScrollCount() - 1) {
            currentIndex++;
            if (this.shouldGoToNextYear(currentIndex)) {
                let yearSelectedIndx = this.state.yearSelectedIndx + 1;
                if (yearSelectedIndx > this.yearCount) {
                    yearSelectedIndx = this.yearCount;
                }
                this.setState({ currentIndex, yearSelectedIndx });
            } else {
                if (this.getIsExpiration()) {
                    this.setState({ currentIndex, yearSelectedIndx: 1 });
                } else {
                    this.setState({ currentIndex });
                }
            }
        }
    }

    /**
     * scroll to the previous window if possible
     */
    goToPreviousView() {
        let currentIndex = this.state.currentIndex;
        if (currentIndex > 0) {
            currentIndex--;
            if (this.shouldGoToNextYear(currentIndex + 1)) {
                let yearSelectedIndx = this.state.yearSelectedIndx - 1;
                if (yearSelectedIndx < 0) {
                    yearSelectedIndx = 0;
                }
                this.setState({ currentIndex, yearSelectedIndx });
            } else if (this.getIsExpiration()) {
                this.setState({ currentIndex, yearSelectedIndx: 1 });
            } else {
                this.setState({ currentIndex });
            }
        }
    }

    getTypeData(arr, type) {
        for (let i = 0, n = arr.length; i < n; i++) {
            for (let key in arr[i]) {
                if (arr[i][key] === type) {
                    return arr[i];
                }
            }
        }
        return [];
    }

    /**
     * table row button's click handler
     * @param {string[]} data
     * @param {bool} isExpiration
     */
    handleTdClick(data, isExpiration) {
        if (isExpiration) {
            this.props.history.push("/report/expiration");
        }
        if (data[0].toLowerCase().includes("issue")) {
            this.props.history.push("/report/issue");
        }

        /** Changed to hidd Beginning and Net Change and End from list**/

        // if (data[0].toLowerCase().includes("net")) {
        //     this.props.history.push("/report/mutation");
        // }

        if (data[0].toLowerCase().includes("import")) {
            this.props.history.push("/report/import");
        }

        if (data[0].toLowerCase().includes("consumption")) {
            this.props.history.push("/report/ownConsumption");
        }

        if (data[0].toLowerCase().includes("use")) {
            this.props.history.push("/report/issueOwnUse");
        }

        /** Changed to hidd Beginning and Net Change and End from list**/
        // if (data[0].toLowerCase().includes("begin")) {
        //     this.props.history.push("/report/begin");
        // }

        if (data[0].toLowerCase().includes("transferred to")) {
            this.props.history.push("/report/transferTo");
        }

        if (data[0].toLowerCase().includes("transferred out")) {
            this.props.history.push("/report/transferOut");
        }

        if (data[0].toLowerCase().includes("withdrawal")) {
            this.props.history.push("/report/withdrawal");
        }

        if (data[0].toLowerCase().includes("expired")) {
            this.props.history.push("/report/expiry");
        }

        if (data[0].toLowerCase().includes("export")) {
            this.props.history.push("/report/export");
        }

        if (data[0].toLowerCase().includes("cancel")) {
            if (data[0].toLowerCase().includes("by")) {
                this.props.history.push("/report/cancelledByThirdParty");
            } else {
                if (data[0].toLowerCase().includes("for")) {
                    this.props.history.push("/report/cancelledForThirdParty");
                } else {
                    this.props.history.push("/report/cancel");
                }
            }
        }

        if (data[0].toLowerCase().includes("increase")) {
            this.props.history.push("/report/increase");
        }

        if (data[0].toLowerCase().includes("decrease")) {
            this.props.history.push("/report/decrease");
        }

        /** Changed to hidd Beginning and Net Change and End from list**/
        // if (data[0].toLowerCase().includes("begin")) {
        //     this.props.history.push("/report/begin");
        // }

        // if (data[0].toLowerCase().includes("end")) {
        //     this.props.history.push("/report/end");
        // }
    }

    /**
     * merge month and year data returned by the api call
     * @param {object[]} data
     */
    mergeMonthYearData(data) {
        if (data.length > 0 && data[0]) {
            if (data[0].month) {
                return data[0].month.concat(data[0].year);
            } else {
                return data.month.concat(data.year);
            }
        }
        if (data.month) {
            return data.month.concat(data.year);
        }
        return [];
    }

    extractMonthOfTheYearData(data, year) {
        let temp = data.filter(el => el.month.includes("" + year));
        temp = temp.filter(el => el);
        return temp;
    }

    arrangeDataYearPerRow(data, yearList) {
        let tempData = [];
        for (let i = 0, n = yearList.length; i < n; i++) {
            tempData[i] = this.createYearDataFromSubYearData(
                this.extractMonthOfTheYearData(data, yearList[i]),
                yearList[i]
            );
        }
        tempData = tempData.filter(el => el.length);
        return tempData;
    }

    createYearDataFromSubYearData(data, year) {
        let tempMonth = [
            "01",
            "02",
            "03",
            "04",
            "05",
            "06",
            "07",
            "08",
            "09",
            "10",
            "11",
            "12"
        ];
        let dataMonth = data.map(el => "" + el.month.split("-")[1]);
        let tempIdx = -1;
        let dataOutput = new Array(12).fill(0, 0, 12);
        let tempSum = 0;
        for (let i = 0; i < data.length; i++) {
            tempIdx = tempMonth.findIndex(el => el === dataMonth[i]);
            if (tempIdx > -1) {
                dataOutput[tempIdx] = data[i].quantity.value;
                tempSum += data[i].quantity.value;
            }
        }
        dataOutput.push(tempSum);
        dataOutput = ["" + year].concat(dataOutput);
        return dataOutput;
    }

    componentDidMount() {
        tools.readDataFromBrowser("session");
        tools.saveDataToBrowser("filterParameters", "");
        tools.sendTrack("overview");
        tools.axiosInstance.get("/report/consolidated").then(data => {
            let recData = data.data.data;
            this.yearCount = parseInt(recData.yearCount);

            let dataModified = this.modifyDataToState(recData);

            let groups = [
                { title: "Statement of Inventory", fromRow: 0, toRow: 3 },
                {
                    title: "Statement of Additions in Inventory",
                    fromRow: 3,
                    toRow: 7
                },
                {
                    title: "Statement of Deductions in Inventory",
                    fromRow: 7,
                    toRow: 14
                },
                { title: "Other", fromRow: 14, toRow: 16 }
            ];

            let tempYearForTest = this.createTableYearList(
                recData.futureExpiration[0].year.length,
                true
            );

            let tableFutureHeader = this.createTableHeader(1, true);

            let futureExpiration = this.mergeMonthYearData(
                recData.futureExpiration
            );

            futureExpiration = this.arrangeDataYearPerRow(
                recData.futureExpiration[0].month,
                tempYearForTest
            );

            let futureExpirationGroup = [
                { title: "Statement of Expirations", fromRow: 0, toRow: 3 }
            ];

            if (window.innerWidth > 1200) {
                this.setState({ columnsToShow: 12, divWidth: 100 / 17 });
            } else {
                if (window.innerWidth > 900) {
                    this.setState({ columnsToShow: 6, divWidth: 100 / 10 });
                } else {
                    this.setState({ columnsToShow: 3, divWidth: 100 / 7 });
                }
            }

            this.setState(
                {
                    headers: dataModified.tableHeader,
                    data: dataModified.tableData,
                    groups: groups,
                    futureExpiration: futureExpiration,
                    futureHeader: tableFutureHeader,
                    futureExpirationGroup,
                    selectedTab:
                        window.location.hash === "#relations"
                            ? 2
                            : window.location.hash === "#expirations"
                            ? 1
                            : 0
                },
                () => {
                    this.handleDropDownYearSelectChange(this.yearCount);
                    if (document.getElementsByClassName("ems-chart").length > 0)
                        // if (
                        //     window.innerWidth < 600 ||
                        //     window.innerHeight < 600
                        // ) {
                        //     return;
                        // }

                        setTimeout(() => {
                            if (
                                document.getElementsByClassName("ems-chart")
                                    .length > 0
                            ) {
                                const chartHeight = document.getElementsByClassName(
                                    "ems-chart"
                                )[0].clientHeight;

                                const chartHeight1 = document.getElementsByClassName(
                                    "uk-card-small"
                                )[0].clientHeight;
                                const bodyHeight = document.getElementsByTagName(
                                    "body"
                                )[0].clientHeight;
                                const overflow = document.getElementsByClassName(
                                    "uk-accordion"
                                );

                                if (this.state.columnsToShow < 6) {
                                    overflow[
                                        this.state.overflowIdx
                                    ].setAttribute(
                                        "style",
                                        `height: ${bodyHeight -
                                            chartHeight -
                                            chartHeight1 -
                                            210}px`
                                    );
                                    // overflow[2].setAttribute(
                                    //     "style",
                                    //     `height: ${bodyHeight -
                                    //         chartHeight -
                                    //         chartHeight1 -
                                    //         210}px`
                                    // );
                                } else {
                                    overflow[
                                        this.state.overflowIdx
                                    ].setAttribute(
                                        "style",
                                        `height: ${bodyHeight -
                                            chartHeight -
                                            chartHeight1 -
                                            130}px`
                                    );
                                    // overflow[2].setAttribute(
                                    //     "style",
                                    //     `height: ${bodyHeight -
                                    //         chartHeight -
                                    //         chartHeight1 -
                                    //         110}px`
                                    // );
                                }

                                //54 px is the row height
                                let rowsPerPage = Math.floor(
                                    (bodyHeight -
                                        chartHeight -
                                        chartHeight1 -
                                        110) /
                                        54
                                );
                                if (rowsPerPage > 2) {
                                    rowsPerPage -= 2;
                                }
                                this.setState({ rowsPerPage });
                            }
                        }, 10);
                }
            );
        });

        tools.axiosInstance
            .get("/user")
            .then(rec => {
                this.setState({ userData: rec.data.data[0] });
            })
            .catch(err => {
                // console.log(err);
                this.setState({ userError: true });
            });
    }

    searchClicked(visible) {
        if (
            visible &&
            document.getElementsByClassName("ems-chart").length > 0
        ) {
            setTimeout(() => {
                const searchUi = document.getElementById("search-ui")
                    .clientHeight;
                if (document.getElementsByClassName("ems-chart").length > 0) {
                    const chartHeight = document.getElementsByClassName(
                        "ems-chart"
                    )[0].clientHeight;

                    const chartHeight1 = document.getElementsByClassName(
                        "uk-card-small"
                    )[0].clientHeight;
                    const bodyHeight = document.getElementsByTagName("body")[0]
                        .clientHeight;
                    const overflow = document.getElementsByClassName(
                        "uk-accordion"
                    );
                    if (this.state.columnsToShow < 6) {
                        overflow[this.state.overflowIdx].setAttribute(
                            "style",
                            `height: ${bodyHeight -
                                chartHeight -
                                chartHeight1 -
                                searchUi}px`
                        );
                        // overflow[2].setAttribute(
                        //     "style",
                        //     `height: ${bodyHeight -
                        //         chartHeight -
                        //         chartHeight1 -
                        //         210}px`
                        // );
                    } else {
                        overflow[this.state.overflowIdx].setAttribute(
                            "style",
                            `height: ${bodyHeight -
                                chartHeight -
                                chartHeight1 -
                                searchUi}px`
                        );
                        // overflow[2].setAttribute(
                        //     "style",
                        //     `height: ${bodyHeight -
                        //         chartHeight -
                        //         chartHeight1 -
                        //         110}px`
                        // );
                    }

                    //54 px is the row height
                    let rowsPerPage = Math.floor(
                        (bodyHeight -
                            chartHeight -
                            chartHeight1 -
                            searchUi -
                            110) /
                            54
                    );
                    if (rowsPerPage > 2) {
                        rowsPerPage -= 2;
                    }
                    this.setState({ rowsPerPage });
                }
            }, 10);
        } else {
            setTimeout(() => {
                const searchUi = document.getElementById("search-ui")
                    .clientHeight;
                // console.log({ searchUi });
                if (document.getElementsByClassName("ems-chart").length > 0) {
                    const chartHeight = document.getElementsByClassName(
                        "ems-chart"
                    )[0].clientHeight;

                    const chartHeight1 = document.getElementsByClassName(
                        "uk-card-small"
                    )[0].clientHeight;
                    const bodyHeight = document.getElementsByTagName("body")[0]
                        .clientHeight;
                    const overflow = document.getElementsByClassName(
                        "uk-accordion"
                    );
                    if (this.state.columnsToShow < 6) {
                        overflow[this.state.overflowIdx].setAttribute(
                            "style",
                            `height: ${bodyHeight -
                                chartHeight -
                                chartHeight1 -
                                210}px`
                        );
                        // overflow[2].setAttribute(
                        //     "style",
                        //     `height: ${bodyHeight -
                        //         chartHeight -
                        //         chartHeight1 -
                        //         210}px`
                        // );
                    } else {
                        overflow[this.state.overflowIdx].setAttribute(
                            "style",
                            `height: ${bodyHeight -
                                chartHeight -
                                chartHeight1 -
                                110}px`
                        );
                        // overflow[2].setAttribute(
                        //     "style",
                        //     `height: ${bodyHeight -
                        //         chartHeight -
                        //         chartHeight1 -
                        //         110}px`
                        // );
                    }

                    //54 px is the row height
                    let rowsPerPage = Math.floor(
                        (bodyHeight - chartHeight - chartHeight1 - 110) / 54
                    );
                    if (rowsPerPage > 2) {
                        rowsPerPage -= 2;
                    }
                    this.setState({ rowsPerPage });
                }
            }, 10);
        }
    }

    isMobileDevice() {
        return (
            typeof window.orientation !== "undefined" ||
            navigator.userAgent.indexOf("IEMobile") !== -1
        );
    }

    /**
     * handle responsiveness
     */
    updateDimensions(event) {
        if (event) event.stopPropagation();
        if (document.getElementsByClassName("ems-chart").length > 0) {
            const chartHeight = document.getElementsByClassName("ems-chart")[0]
                .clientHeight;

            const chartHeight1 = document.getElementsByClassName(
                "uk-card-small"
            )[0].clientHeight;
            const bodyHeight = document.getElementsByTagName("body")[0]
                .clientHeight;
            const overflow = document.getElementsByClassName("uk-accordion");

            //54 px is the row height
            let rowsPerPage = Math.floor(
                (bodyHeight - chartHeight - chartHeight1 - 110) / 54
            );
            if (rowsPerPage > 2) {
                rowsPerPage -= 2;
            }
            if (rowsPerPage !== this.state.rowsPerPage)
                this.setState({ rowsPerPage });

            if (overflow.length > 0 && overflow[this.state.overflowIdx]) {
                if (this.state.columnsToShow < 6) {
                    overflow[this.state.overflowIdx].setAttribute(
                        "style",
                        `height: ${bodyHeight -
                            chartHeight -
                            chartHeight1 -
                            210}px`
                    );
                } else {
                    overflow[this.state.overflowIdx].setAttribute(
                        "style",
                        `height: ${bodyHeight -
                            chartHeight -
                            chartHeight1 -
                            130}px`
                    );
                }
            }
        }

        let columnsToShow = 12;
        let divWidth = 100 / 17;
        let currentIndex = Math.floor(
            (this.state.currentIndex * this.state.columnsToShow) / 12
        );

        if (window.innerWidth > 1200) {
            //if(this.state.columnsToShow !== columnsToShow || this.state.divWidth !== divWidth || this.state.currentIndex !== currentIndex)
            this.setState({
                columnsToShow,
                divWidth,
                currentIndex
            });
        } else {
            if (window.innerWidth > 900) {
                columnsToShow = 6;
                divWidth = 100 / 10;
                currentIndex = Math.floor(
                    (this.state.currentIndex * this.state.columnsToShow * 2) /
                        12
                );
                //if(this.state.columnsToShow !== columnsToShow || this.state.divWidth !== divWidth || this.state.currentIndex !== currentIndex)
                this.setState({
                    columnsToShow,
                    divWidth,
                    currentIndex
                });
            } else {
                columnsToShow = 3;
                divWidth = 100 / 7;
                currentIndex = Math.floor(
                    (this.state.currentIndex * this.state.columnsToShow * 4) /
                        12
                );
                //if(this.state.columnsToShow !== columnsToShow || this.state.divWidth !== divWidth || this.state.currentIndex !== currentIndex)
                this.setState({
                    columnsToShow,
                    divWidth,
                    currentIndex
                });
            }
        }
    }

    componentWillMount() {
        window.addEventListener("resize", event =>
            this.updateDimensions(event)
        );
        window.addEventListener("orientationchange", event =>
            this.updateDimensions(event)
        );
    }

    componentWillUnmount() {
        window.removeEventListener("resize", event =>
            this.updateDimensions(event)
        );
        window.removeEventListener("orientationchange", event =>
            this.updateDimensions(event)
        );
    }

    /**
     *
     * @param {int} yearCount
     * @param {bool} isExpiration
     */
    createTableHeader(yearCount, isExpiration) {
        let headers = [""];
        let today = new Date();
        let monthCount = yearCount * 12;
        let fromYear =
            today.getFullYear() - yearCount + (isExpiration ? yearCount : 1);

        today = new Date(`${fromYear}-01-01`);
        let viewYear = "";
        let viewMonth = "";
        this.monthHeaderExpiration = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec"
        ];
        if (isExpiration) {
            this.monthHeaderExpiration = this.monthHeaderExpiration.concat(
                this.monthHeaderExpiration
            );
            // for (let i = 1; i <= 2 * 12; i++) {
            //     viewYear = ("" + today.getFullYear()).substr(2);
            //     viewMonth =
            //         "" +
            //         (today.getMonth() + 1 < 10
            //             ? "0" + (today.getMonth() + 1)
            //             : today.getMonth() + 1);
            //     this.monthHeaderExpiration.push(`${viewMonth}`);
            //     today = new Date(today.setMonth(today.getMonth() + 1));
            // }
        } else {
            this.monthHeader = [];
            for (let i = 1; i <= yearCount * 12; i++) {
                viewYear = ("" + today.getFullYear()).substr(2);
                viewMonth =
                    "" +
                    (today.getMonth() + 1 < 10
                        ? "0" + (today.getMonth() + 1)
                        : today.getMonth() + 1);
                this.monthHeader.push(`${viewMonth}-'${viewYear}`);
                today = new Date(today.setMonth(today.getMonth() + 1));
            }
        }

        today = new Date(`${fromYear}-01-01`);
        if (!isExpiration) {
            for (let i = 0; i < monthCount; i++) {
                headers.push(
                    `${today.getFullYear()}-${
                        today.getMonth() + 1 < 10
                            ? "0" + (today.getMonth() + 1)
                            : today.getMonth() + 1
                    }`
                );
                today = new Date(today.setMonth(today.getMonth() + 1));
            }
        }
        if (isExpiration) {
            for (let i = 0; i < 24; i++) {
                headers.push(
                    `${today.getFullYear()}-${
                        today.getMonth() + 1 < 10
                            ? "0" + (today.getMonth() + 1)
                            : today.getMonth() + 1
                    }`
                );
                today = new Date(today.setMonth(today.getMonth() + 1));
            }
            this.monthHeaderExpiration.push("Total");
            headers.push("Total");
            this.headers = headers;
            return headers;
        }
        today = new Date();
        fromYear = new Date().getFullYear() - 2 + (isExpiration ? 1 : 0);
        for (let i = 0; i < 3; i++) {
            if (isExpiration) {
                this.monthHeaderExpiration.push("Total " + fromYear);
            } else {
                this.monthHeader.push(fromYear);
            }
            headers.push("Total " + fromYear);
            fromYear++;
        }

        this.headers = headers;
        return headers;
    }

    /**
     *
     * @param {int} yearCount
     * @param {bool} isExpiration
     */
    createTableYearList(yearCount, isExpiration) {
        let today = new Date();
        let fromYear = today.getFullYear() - yearCount + 1;
        if (isExpiration) {
            return [today.getFullYear(), today.getFullYear() + 1];
        }

        let yearList = [];
        for (let i = 1; i <= yearCount; i++) {
            yearList.push(fromYear);
            fromYear++;
        }
        return yearList;
    }

    /**
     *
     * @param {string[]} headers
     * @param {object[][]} data
     * @param {string} rowTitle
     */
    fitDataIntoTableBody(headers, data, rowTitle) {
        let tempData = data;
        // if (tempData.length === 0) {
        //     return [[]];
        // }

        let tempRow = [];
        let tempHeader = headers;
        let resultData = [];
        let headerIdx = -1;
        let headerLength = tempHeader.length;
        for (let j = 0, m = headerLength; j < m; j++) {
            headerIdx = tempData.findIndex((el, idx) => {
                if (el.month) {
                    return el.month === tempHeader[j];
                } else return "Total " + el.year === tempHeader[j];
            });
            if (headerIdx < 0) {
                tempRow.push(0);
            } else {
                tempRow.push(tempData[headerIdx].quantity.value);
            }
        }
        if (rowTitle) {
            tempRow = [rowTitle].concat(tempRow.slice(1));
        }
        resultData.push(tempRow);
        return resultData;
    }

    /**
     * update chart
     * @param {*} data
     * @param {*} value
     * @param {*} headers
     * @param {*} rowVal
     * @param {*} colVal
     * @param {*} rowIdx
     * @param {*} colIdx
     */
    updatePlot(data, value, headers, rowVal, colVal, rowIdx, colIdx) {
        this.setState(
            {
                isExpiration: false,
                clickedRowIdx: rowIdx,
                clickedLegend: rowVal
            },
            () => {
                let t = this.state.clickedRowIdx;
            }
        );
    }

    updatePlotExpiration(data, value, headers, rowVal, colVal, rowIdx, colIdx) {
        this.setState({
            isExpiration: true,
            clickedRowIdx: rowIdx,
            clickedLegend: rowVal
        });
    }

    modifyDataToState(recData) {
        let tableHeader = this.createTableHeader(recData.yearCount, false);

        let cancelledByThirdParty = recData.cancelledByThirdParty[0].month.concat(
            recData.cancelledByThirdParty[0].year
        );
        cancelledByThirdParty = this.fitDataIntoTableBody(
            tableHeader,
            cancelledByThirdParty,
            "Cancelled by Third Party"
        );

        let cancelledForThirdParty = recData.cancelledForThirdParty[0].month.concat(
            recData.cancelledForThirdParty[0].year
        );
        cancelledForThirdParty = this.fitDataIntoTableBody(
            tableHeader,
            cancelledForThirdParty,
            "Cancelled for Third Party"
        );

        let transferOut = this.mergeMonthYearData(recData.transferOut);
        transferOut = this.fitDataIntoTableBody(
            tableHeader,
            transferOut,
            "Transferred out"
        );

        let issuedForOwnUse = this.mergeMonthYearData(recData.issuedForOwnUse);
        issuedForOwnUse = this.fitDataIntoTableBody(
            tableHeader,
            issuedForOwnUse,
            "Issued for Own Use"
        );

        let issuedForTrading = this.mergeMonthYearData(
            recData.issuedForTrading
        );
        issuedForTrading = this.fitDataIntoTableBody(
            tableHeader,
            issuedForTrading,
            "Issued"
        );

        let ownConsumption = this.mergeMonthYearData(recData.ownConsumption);
        ownConsumption = this.fitDataIntoTableBody(
            tableHeader,
            ownConsumption,
            "Own Consumption"
        );

        let cancelled = this.mergeMonthYearData(recData.cancelled);
        cancelled = this.fitDataIntoTableBody(
            tableHeader,
            cancelled,
            "Cancelled"
        );

        let transferTo = recData.transferTo[0].month.concat(
            recData.transferTo[0].year
        );
        transferTo = this.fitDataIntoTableBody(
            tableHeader,
            transferTo,
            "Transferred to"
        );

        let expired = this.getTypeData(recData.additionDeduction, "expiry");
        expired = this.mergeMonthYearData(expired);
        expired = this.fitDataIntoTableBody(tableHeader, expired, "Expired");

        let withdrawal = this.getTypeData(
            recData.additionDeduction,
            "withdrawal"
        );
        withdrawal = this.mergeMonthYearData(withdrawal);
        withdrawal = this.fitDataIntoTableBody(
            tableHeader,
            withdrawal,
            "Withdrawals"
        );

        let imported = this.getTypeData(recData.additionDeduction, "import");
        imported = this.mergeMonthYearData(imported);
        imported = this.fitDataIntoTableBody(tableHeader, imported, "Imported");

        let exported = this.getTypeData(recData.additionDeduction, "export");
        exported = this.mergeMonthYearData(exported);
        exported = this.fitDataIntoTableBody(tableHeader, exported, "Exported");

        let increase = [[]];
        for (let i = 0; i < issuedForTrading[0].length; i++) {
            increase[0][i] =
                issuedForTrading[0][i] + imported[0][i] + transferTo[0][i];
        }
        increase[0][0] = "Increase";

        let decrease = [[]];
        for (let i = 0; i < issuedForTrading[0].length; i++) {
            decrease[0][i] =
                cancelled[0][i] +
                transferOut[0][i] +
                exported[0][i] +
                withdrawal[0][i] +
                cancelledForThirdParty[0][i] +
                expired[0][i];
        }
        decrease[0][0] = "Decrease";

        let mutation = [[]];
        for (let i = 0; i < issuedForTrading[0].length; i++) {
            mutation[0][i] = increase[0][i] - decrease[0][i];
        }
        mutation[0][0] = "Net Change";

        let end = mutation[0].slice(1, 12 * this.yearCount + 1);
        for (let i = 1, n = end.length; i < n; i++) {
            end[i] += end[i - 1];
        }
        let beginningInventory = [[]];
        beginningInventory[0] = [0].concat(end.slice(0, end.length - 1));

        for (let i = this.yearCount - 3; i < this.yearCount; i++) {
            end.push(end[(i + 1) * 12 - 1]);
            beginningInventory[0].push(end[i * 12 - 1]);
        }
        beginningInventory[0] = beginningInventory[0].map(el =>
            typeof el === "undefined" ? 0 : el
        );
        end = ["End"].concat(end);

        beginningInventory[0] = ["Beginning"].concat(beginningInventory[0]);

        let tableData = [
            beginningInventory[0],
            mutation[0],
            end,
            issuedForTrading[0],
            imported[0],
            transferTo[0],
            increase[0],
            transferOut[0],
            cancelled[0],
            cancelledForThirdParty[0],
            expired[0],
            withdrawal[0],
            exported[0],
            decrease[0],
            issuedForOwnUse[0],
            cancelledByThirdParty[0]
            //ownConsumption[0],
        ];

        return { tableData, tableHeader };
    }

    /**
     *
     * @param {object[]} data
     * @param {object} formState
     */
    searchReturn(data, formState) {
        if (!data) {
            this.setState({ noData: true });
            return;
        }

        let recData = data.result.data;
        let dataModified = this.modifyDataToState(recData);

        this.yearCount = parseInt(recData.yearCount);
        this.dateType = formState.dateType;
        this.dateType += " date";
        //tools.saveDataToBrowser("filterParameters", JSON.stringify(formState));

        let groups = [
            { title: "Statement of Inventory", fromRow: 0, toRow: 3 },
            { title: "Statement of Additions", fromRow: 3, toRow: 7 },
            { title: "Statement of Deductions", fromRow: 7, toRow: 14 },
            { title: "Other", fromRow: 14, toRow: 16 }
        ];

        let tempYearForTest = this.createTableYearList(
            recData.futureExpiration[0].year.length,
            true
        );

        let tableFutureHeader = this.createTableHeader(1, true);

        let futureExpiration = this.mergeMonthYearData(
            recData.futureExpiration
        );

        futureExpiration = this.arrangeDataYearPerRow(
            recData.futureExpiration[0].month,
            tempYearForTest
        );

        let minYear = 1;
        let min = [];
        let currentTemp = 0;
        let result = dataModified.tableData;
        for (let i = 0, n = result.length; i < n; i++) {
            currentTemp = result[i]
                //.reverse()
                .findIndex((el, idx) => el > 0 && idx > 0);
            if (currentTemp > -1) {
                min.push(currentTemp);
            }
            //result[i].reverse();
        }
        min = Math.min(...min);
        minYear = this.yearCount - Math.floor(min / 12);
        if (isNaN(minYear) || !isFinite(minYear)) {
            this.setState(
                {
                    headers: dataModified.tableHeader,
                    data: dataModified.tableData,
                    groups: groups,
                    futureExpiration: futureExpiration,
                    futureHeader: tableFutureHeader,
                    searchParameters: { ...formState },
                    noData: false,
                    relationData: dataModified.tableData
                },
                () => this.updateDimensions()
            );
            return;
        }

        minYear = minYear > 0 ? minYear : 1;
        minYear = minYear > this.yearCount ? this.yearCount : minYear;

        let updateYear = false;

        this.setState(
            {
                headers: dataModified.tableHeader,
                data: dataModified.tableData,
                groups: groups,
                futureExpiration: futureExpiration,
                futureHeader: tableFutureHeader,
                searchParameters: { ...formState },
                noData: false
            },
            () => {
                this.updateDimensions();
                if (updateYear & !isNaN(minYear)) {
                    this.handleDropDownYearSelectChange(
                        this.yearCount - minYear + 1
                    );
                }
            }
        );
    }

    createDropDownYearList() {
        let yearList = this.createTableYearList(this.yearCount, false);
        let yearListOptions = yearList.map((el, index) => (
            <option value={index + 1} key={`year-div-list-${index}`}>
                {el}
            </option>
        ));
        return (
            <select
                className="uk-select uk-form-small ems-years"
                onChange={event => this.handleDropDownYearSelectChange(event)}
            >
                {yearListOptions}
            </select>
        );
    }

    handleChartTypeSelect(event) {
        this.setState({ chartType: event });
    }

    /**
     *
     * @param {string[]} headers
     * @param {object[][]} arr
     */
    prepCSV(headers, arr) {
        let row = "";
        let newLine = "\r\n";

        let plainArr = headers.join(",") + newLine;
        let tempArray = new Array(headers.length);
        tempArray.fill("", 0, headers.length);
        tempArray[0] = this.tabTitles[this.state.selectedTab];
        row += arr.join(newLine);
        return tempArray.join(",") + newLine + plainArr + row;
    }

    generateXLSXRow(data, dataType = "string") {
        return data.map((el, index) => {
            return { value: el, type: index === 0 ? "string" : dataType };
        });
    }

    handleDownloadDataClick() {
        let tempHeader = [this.state.headers[0]].concat(
            this.state.headers.slice(
                (this.state.yearSelectedIndx - 1) * 12 + 1,
                this.state.yearSelectedIndx * 12 + 1
            )
        );

        tempHeader[0] = "Overview - " + this.dateType;

        let tempData = this.state.data.map(el =>
            [el[0]].concat(
                el.slice(
                    (this.state.yearSelectedIndx - 1) * 12 + 1,
                    this.state.yearSelectedIndx * 12 + 1
                )
            )
        );
        tempData = tempData.map(el => this.generateXLSXRow(el, "number"));
        tempData = [this.generateXLSXRow(tempHeader)].concat(tempData);

        const config = {
            filename: `EMS-${tempHeader[0]}`,
            sheet: {
                data: tempData
            }
        };
        zipcelx(config);
    }

    tabChangeListener(index) {
        if (index === 1) {
            window.location.hash = "#expirations";
            this.setState(
                {
                    showRelations: false,
                    selectedTab: index,
                    clickedRowIdx: -1,
                    yearSelectedIndx: this.yearCount,
                    currentIndex: 0
                },
                () => this.updateDimensions()
            );
            tools.sendTrack("expiration");
        }

        if (index === 0) {
            window.location.hash = "#overview";
            this.setState(
                {
                    showRelations: false,
                    selectedTab: index,
                    clickedRowIdx: -1,
                    yearSelectedIndx: this.yearCount,
                    overflowIdx: 1,
                    currentIndex:
                        ((this.yearCount - 1) * 12) / this.state.columnsToShow
                },
                () => this.updateDimensions()
            );
            tools.sendTrack("overview");
        }

        if (index === 2) {
            window.location.hash = "#relations";
            this.setState(
                {
                    showRelations: true,
                    selectedTab: index,
                    clickedRowIdx: -1,
                    yearSelectedIndx: this.yearCount,
                    overflowIdx: 3,
                    currentIndex:
                        ((this.yearCount - 1) * 12) / this.state.columnsToShow
                },
                () => setTimeout(() => this.updateDimensions(), 500)
            );
            tools.sendTrack("relations");
        }
    }

    getChartData() {
        let tableData = this.fillTableData(this.state.selectedTab);
        let chartData = [];
        let chartLegend = ["Beginning", "Decrease", "Increase", "Net Change"];

        if (this.state.selectedTab === 0 || this.state.selectedTab === 2) {
            chartData = [[]];
            if (tableData && tableData.length > 2) {
                chartData = [
                    tableData[0].slice(1, this.state.columnsToShow + 1),
                    tableData[13].slice(1, this.state.columnsToShow + 1),
                    tableData[6].slice(1, this.state.columnsToShow + 1),
                    tableData[1].slice(1, this.state.columnsToShow + 1)
                ];
            }
            if (this.state.clickedRowIdx > -1) {
                let tempLegend = this.state.clickedLegend.split(" ")[0];
                if (
                    chartLegend.indexOf(tempLegend) < 0 &&
                    chartLegend.indexOf(this.state.clickedLegend) < 0
                ) {
                    chartData[4] = tableData[this.state.clickedRowIdx].slice(
                        1,
                        this.state.columnsToShow + 1
                    );
                    chartLegend[4] = this.state.clickedLegend;
                }
            }
        }

        if (
            this.state.selectedTab === 1 &&
            this.state.futureExpiration &&
            this.state.futureExpiration.length > 0
        ) {
            tableData = this.fillTableData(1);
            chartData = [];
            chartData = [tableData[0].slice(1, this.state.columnsToShow + 1)];
            chartLegend = [];
            chartLegend = [this.state.futureExpiration[0][0]];

            if (this.state.clickedRowIdx > -1) {
                let tempLegend = this.state.clickedLegend.split(" ")[0];
                if (
                    chartLegend.indexOf(tempLegend) < 0 &&
                    chartLegend.indexOf(this.state.clickedLegend) < 0
                ) {
                    chartData = [
                        tableData[this.state.clickedRowIdx].slice(
                            1,
                            this.state.columnsToShow + 1
                        )
                    ];
                    chartLegend[0] = this.state.clickedLegend;
                }
            }
        }

        let chartTitle = this.createChartTitle();
        return { chartData, chartLegend, chartTitle };
    }

    createChartTitle() {
        if (this.state.selectedTab === 1) {
            return this.createTableRow(this.monthHeaderExpiration);
        }
        return this.monthHeader.slice(
            this.state.currentIndex * this.state.columnsToShow +
                this.state.fromColumn,
            this.state.currentIndex * this.state.columnsToShow +
                this.state.fromColumn +
                this.state.columnsToShow
        );
    }

    chartDownloaded() {}

    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }
    render() {
        if (this.state.userError) {
            return (
                <main className="uk-background-muted">
                    <div className="uk-flex uk-flex-center uk-flex-middle uk-width-3-5">
                        <div className="uk-text-justify">
                            <p>
                                Your account might have been disable, please{" "}
                                <Link to="/contact">contact</Link> the admin.
                            </p>
                            <p>
                                You can <Link to="/logout">logout</Link> again
                            </p>
                        </div>
                    </div>
                </main>
            );
        }

        if (
            window.location.hash === "#relations" &&
            this.state.selectedTab !== 2
        ) {
            this.setState({ selectedTab: 2, showRelations: true });
        }

        if (
            window.location.hash === "#expirations" &&
            this.state.selectedTab !== 1
        ) {
            this.setState({ selectedTab: 1, showRelations: false });
        }

        let tableData = this.fillTableData(this.state.selectedTab);

        let chartRelated = {};
        if (this.state.data.length > 1) {
            chartRelated = this.getChartData();
        }

        let select = null;
        if (this.state.tableData) {
            select = this.createDropDownYearList();
        }

        let yearList = this.createTableYearList(this.yearCount, false);

        return (
            <React.Fragment>
                <main className="uk-background-muted">
                    <div
                        className="page uk-height-1-1 uk-grid-small uk-grid-match uk-grid"
                        data-uk-grid
                    >
                        <div className="menu-bar uk-background-secondary uk-visible@m">
                            <Toolbar1 />
                        </div>
                        <div className="uk-width-expand uk-height-1-1 page-content">
                            <div className="ems-page-container">
                                <div>
                                    <ReportTitle
                                        title={`${
                                            this.tabTitles[
                                                this.state.selectedTab
                                            ]
                                        }`}
                                    />
                                    <div className="uk-card uk-card-default uk-card-small uk-width-1-1 uk-box-shadow-small">
                                        <Form
                                            onlyTitle={false}
                                            chartDownloaded={this.chartDownloaded.bind(
                                                this
                                            )}
                                            resetParameters={true}
                                            handleDownloadDataClick={this.handleDownloadDataClick.bind(
                                                this
                                            )}
                                            yearList={yearList}
                                            handleDropDownYearSelectChange={this.handleDropDownYearSelectChange.bind(
                                                this
                                            )}
                                            submitted={this.searchReturn.bind(
                                                this
                                            )}
                                            handleChartTypeSelect={this.handleChartTypeSelect.bind(
                                                this
                                            )}
                                            yearSelectedIndx={
                                                this.state.yearSelectedIndx
                                            }
                                            disableDateList={
                                                this.state.selectedTab === 1
                                            }
                                            disableYearList={
                                                this.state.selectedTab === 1
                                            }
                                            title={
                                                this.tabTitles[
                                                    this.state.selectedTab
                                                ]
                                            }
                                            fullScreenDate={
                                                this.state.columnsToShow < 6
                                            }
                                            searchClicked={clicked =>
                                                this.searchClicked(clicked)
                                            }
                                            showRelations={
                                                this.state.showRelations
                                            }
                                        />
                                    </div>
                                    {!this.state.noData &&
                                    this.state.data.length > 1 ? (
                                        <Chart
                                            data={chartRelated.chartData}
                                            legend={chartRelated.chartLegend}
                                            title={chartRelated.chartTitle}
                                            chartTitle="Inventory Overview"
                                            chartType={this.state.chartType}
                                        />
                                    ) : null}
                                </div>

                                {!this.state.noData && (
                                    <Tab
                                        titles={this.tabTitles}
                                        tabChangeListener={this.tabChangeListener.bind(
                                            this
                                        )}
                                        index={this.state.selectedTab}
                                    >
                                        <MyTable
                                            data={tableData}
                                            headers={this.createTableRow(
                                                [""].concat(this.monthHeader)
                                            )}
                                            groups={this.state.groups}
                                            trClickHandler={(
                                                data,
                                                value,
                                                headers,
                                                rowVal,
                                                colVal,
                                                rowIdx,
                                                colIdx,
                                                fromColumn,
                                                toColumn,
                                                footer
                                            ) =>
                                                this.updatePlot(
                                                    data,
                                                    value,
                                                    headers,
                                                    rowVal,
                                                    colVal,
                                                    rowIdx,
                                                    colIdx,
                                                    fromColumn,
                                                    toColumn,
                                                    footer
                                                )
                                            }
                                            useEvents={true}
                                            rowButtonClick={data =>
                                                this.handleTdClick(data)
                                            }
                                            rowsPerPage={
                                                this.state.rowsPerPage
                                                    ? this.state.rowsPerPage
                                                    : 1
                                            }
                                        />
                                        <MyTable
                                            key="2"
                                            headers={this.createTableRow(
                                                [""].concat(
                                                    this.monthHeaderExpiration
                                                )
                                            )}
                                            data={tableData}
                                            footers={[[]]}
                                            trClickHandler={(
                                                data,
                                                value,
                                                headers,
                                                rowVal,
                                                colVal,
                                                rowIdx,
                                                colIdx,
                                                fromColumn,
                                                toColumn,
                                                footer
                                            ) =>
                                                this.updatePlotExpiration(
                                                    data,
                                                    value,
                                                    headers,
                                                    rowVal,
                                                    colVal,
                                                    rowIdx,
                                                    colIdx,
                                                    fromColumn,
                                                    toColumn,
                                                    footer
                                                )
                                            }
                                            groups={
                                                this.state.futureExpirationGroup
                                            }
                                            useEvents={true}
                                            rowButtonClick={data =>
                                                this.handleTdClick(data, true)
                                            }
                                        />
                                        <MyTable
                                            key="3"
                                            data={tableData}
                                            headers={this.createTableRow(
                                                [""].concat(this.monthHeader)
                                            )}
                                            groups={this.state.groups}
                                            trClickHandler={(
                                                data,
                                                value,
                                                headers,
                                                rowVal,
                                                colVal,
                                                rowIdx,
                                                colIdx,
                                                fromColumn,
                                                toColumn,
                                                footer
                                            ) =>
                                                this.updatePlot(
                                                    data,
                                                    value,
                                                    headers,
                                                    rowVal,
                                                    colVal,
                                                    rowIdx,
                                                    colIdx,
                                                    fromColumn,
                                                    toColumn,
                                                    footer
                                                )
                                            }
                                            useEvents={true}
                                            rowButtonClick={data =>
                                                this.handleTdClick(data)
                                            }
                                            rowsPerPage={
                                                this.state.rowsPerPage
                                                    ? this.state.rowsPerPage
                                                    : 1
                                            }
                                        />
                                    </Tab>
                                )}
                                <div className="uk-background-muted uk-width-1-1 uk-position-fixed uk-position-bottom">
                                    <div className="uk-flex uk-flex-center uk-flex-middle uk-margin-small-top">
                                        <div className="uk-flex uk-flex-row">
                                            <span
                                                className="uk-icon pointer"
                                                uk-icon="chevron-double-left"
                                                onClick={this.goToFirstView.bind(
                                                    this
                                                )}
                                            ></span>
                                            <span
                                                className="uk-icon uk-margin-left pointer"
                                                uk-icon="chevron-left"
                                                onClick={this.goToPreviousView.bind(
                                                    this
                                                )}
                                            ></span>
                                        </div>
                                        <div className="uk-flex uk-flex-row uk-margin-large-left">
                                            <span
                                                className="uk-icon pointer"
                                                uk-icon="chevron-right"
                                                onClick={this.goToNextView.bind(
                                                    this
                                                )}
                                            ></span>
                                            <span
                                                className="uk-icon uk-margin-left pointer"
                                                uk-icon="chevron-double-right"
                                                onClick={this.goToLastView.bind(
                                                    this
                                                )}
                                            ></span>
                                        </div>
                                    </div>
                                    <Footer />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Toolbar1 hidden={true} />
                </main>
            </React.Fragment>
        );
    }
}

export default ConsolidatedReport;
